import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {useAuth} from "react-oidc-context";

import './logIn.scss';
import {LogoDark} from "../../../image";

import {getPropsFromState} from "../../../redux/mapStateToProps";
import {LoginUser} from "../../../redux/actions";
import {uris} from "../../../utils/constants";
import {history} from "../../../utils/history";
import config from "../../../utils/config";


function LogIn(props) {
    // Записываем в константу хук из react-router-dom
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [loginDirty, setLoginDirty] = useState(false);
    const [passwordDirty, setPasswordDirty] = useState(false);
    const [loginError, setLoginError] = useState('Логин пустой');
    const [passwordError, setPasswordError] = useState('Пароль пустой');

    const isMax = config.productName.includes('max')

    useEffect(() => {
        localStorage.clear();
        if (props.token) {
            // history.push('/dashboard')
        }
    }, [])

    // Функция проверяющая правильность логина и пароля
    const blurHandler = (e) => {
        switch (e.target.name) {
            case 'login':
                setLoginDirty(true);
                break;
            case 'password':
                setPasswordDirty(true);
                break;
            default:
        }
    };

    // Функция проверяющая правильность логина
    const loginHandler = (e) => {
        const {value} = e.target;
        setLogin(value);
        if (!value || value.length < 3) {
            setLoginError('Логин должен быть не менее трех символов');
        } else {
            setLoginError('');
        }
    };

    // Функция проверяющая правильность пароля
    const passwordHandler = (e) => {
        const {value} = e.target;
        setPassword(value);
        if (!value || value.length < 3) {
            setPasswordError('Пароль должен быть не менее трех символов');
        } else {
            setPasswordError('');
        }
    };

    // Функция, которая запускает функцию отправки запроса, переносит пользователя на начальную страницу после авторизации
    const handleSubmit = () => {
        props.LoginUser({
            email: login,
            password
        }).then(() => {
            history.push(props.redirectUrl ? props.redirectUrl : uris.LOGIN_REDIRECT)
        })
            .catch(() => {
                setPasswordError('Неправильный адрес электронной почты или пароль');
            })
    };

    const auth = useAuth();
    const handleOIDCAuth = () => {
        auth.signinRedirect();
    };

    return (
        <div className="log-in">
            <div className="log-in__logo">
                <LogoDark className="logo"/>
            </div>
            <div className="log-in__content">

                <div className="log-in__content-title">Авторизоваться</div>

                <div className="log-in__content-login">
                    <div className="input-label">Эл. почта</div>
                    <input
                        onInput={(e) => loginHandler(e)}
                        onBlur={(e) => blurHandler(e)}
                        value={login}
                        name="login"
                        placeholder="user@example.com"
                        type="login"
                        className="log-in__content__input"
                    />
                    {loginDirty && loginError && (
                        <div className="log-in__error">{loginError}</div>
                    )}
                </div>

                <div className="log-in__content-password">
                    <div className="input-label">Пароль</div>
                    <input
                        onInput={(e) => passwordHandler(e)}
                        onBlur={(e) => blurHandler(e)}
                        value={password}
                        name="password"
                        placeholder="Пароль"
                        type="password"
                        className="log-in__content__input"
                    />
                    {passwordDirty && passwordError && (
                        <div className="log-in__error">{passwordError}</div>
                    )}
                </div>

                <button className={`log-in__content-button ${!!login && !!password ? 'active' : ''}`}
                        disabled={!password && !login}
                        onClick={handleSubmit}>
                    Log In
                </button>

                {isMax && <button className="log-in__content-button"
                                  onClick={handleOIDCAuth}>
                    Log In OIDC
                </button>}

                <div className={`log-in__content-text`}>
                    Нет аккаунта?
                    <Link to={'/registration'} className="link">Создайте сейчас</Link>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, ['token', 'redirectUrl'])
};

const mapDispatchToProps = {LoginUser};

export default connect(mapStateToProps, mapDispatchToProps)(LogIn);
