export const PERSONS_CONSTS = {
    GET_PERSONS: "GET_PERSONS",
    GET_NEW_PERSONS: "GET_NEW_PERSONS",
    CLEAR_PERSONS: "CLEAR_PERSONS",
    GET_USERS_UNRECOGNIZED: "GET_USERS_UNRECOGNIZED",
    UPDATE_PERSONS: "UPDATE_PERSONS",
    UPDATE_PERSON_TAGS: "UPDATE_PERSON_TAGS",
    DELETE_PERSON_TAGS: "DELETE_PERSON_TAGS",
    UPDATE_NEW_PERSONS: "UPDATE_NEW_PERSONS",
    GET_PERSON_BY_ID: "GET_PERSON_BY_ID",
    CLEAR_PERSONS_BY_ID: "CLEAR_PERSONS_BY_ID",
    COMBINE_PERSONS: "COMBINE_PERSONS",
};
