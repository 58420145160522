// Import packages
import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {connect} from "react-redux";
import {Link, useLocation, useParams} from "react-router-dom";

// Import assets
import './video-details.scss';
import {
    ArrowDownIcon, DeletingIcon,
    EditGreenIcon, EditIcon, RefreshIcon, ShareIcon
} from "../../../../image";
import 'react-resizable/css/styles.css';

//Import utils
import {getPropsFromState} from "../../../../redux/mapStateToProps";
import {
    AddVideoDescription,
    ClearVideoById, CreateVideoUrl,
    DeleteProtocol,
    GetVideoById,
    UpdateProtocol,
    UpdateTranscription,
    GetChannels,
    UpdateTranscriptionAll,
    MarkVideo,
    DownloadTranscription,
    ReplaceTranscriptionWord,
    GetLink,
    SaveRedirectUrl,
    DeleteVideo,
    ReprocessVideo, ChangeReprocessStatus
} from "../../../../redux/actions";
import {GetProtocol, AddProtocol} from "../../../../redux/actions";
import {history} from "../../../../utils/history";

//Import Component
import {Breadcrumb} from "react-bootstrap";
import NewPlayer from "./new-player/NewPlayer";
import {changeTimeFormat} from "../../../../utils/helperFunctions";
import {useTranslation} from "react-i18next";
import UserInfoModal from "../Users/modals/userInfoModal/UserInfoModal";
import UpdateVideoModal from "../../../../shared/modals/updateVideolModal/UpdateVideoModal";
import Notifications from "../../../../shared/features/notification/Notifications";
import VideoIntroduction from "../../../../shared/features/VideoIntroduction/VideoIntroduction";
import Video from "./Video/Video";
import config from "../../../../utils/config";
import DeleteModal from "../../../../shared/modals/deleteModal/DeleteModal";
import {statusesList, statusesListRu} from "../../../../utils/constants";
import InfoTooltip from "../Profile/tooltip";
import ShearModal from "../../../../shared/modals/shearModal/ShearModal";
import CustomToast from "../../../../shared/features/CustomToast/CustomToast";
import {io} from "socket.io-client";
import {HOST_NAME} from "../../../../redux/api";

const headerHeight = 85


function VideoDetails(props) {
    const {videoById, protocolList, token, userRoles} = props
    const params = useParams();
    const location = useLocation();
    const videoPlayer = useRef();
    const videoHeight = useRef();
    const playerHeight = useRef();
    const pageRef = useRef();

    const {t, i18n} = useTranslation();
    const currentLanguage = i18n.language;
    const isMax = !config.productName.includes('max')

    const [videoTimelineScale, setVideoTimelineScale] = useState({
        scaleX: 1,
        minScaleX: 0.5,
        maxScaleX: 5,
        stepScaleX: 0.1,
    });
    const [isPlayed, setIsPlayed] = useState(false)
    const [videoPlayerCurrentTime, setVideoPlayerCurrentTime] = useState(0)
    const [videoPlayerDuration, setVideoPlayerDuration] = useState(0)
    const [videoPlayerCurrentTimeScroll, setVideoPlayerCurrentTimeScroll] = useState(0)
    const [showRenameModal, setShowRenameModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showShearModal, setShowShearModal] = useState(false);
    const [showModal, setShowModal] = useState(false)
    const [currentUser, setCurrentUser] = useState(null)
    const [isLoading, setIsLoading] = useState(false);
    const [status, setStatus] = useState(null);

    useEffect(() => {
        // console.log('11111111')
        props.SaveRedirectUrl(location?.pathname)
        if (location.pathname?.includes('download')) {
            props.DownloadTranscription(params?.videoId)
        }
    }, [])
    useEffect(() => {
        setStatus(statusesList[videoById?.video_status])
    }, [videoById?.video_status])

    useLayoutEffect(() => {
        props.ClearVideoById()
    }, [])

    useEffect(() => {
        // console.log('222222222222')
        const urlParams = new URLSearchParams(location.search);
        const paramsTime = urlParams.get('t')
        const paramsUuid = urlParams.get('uuid')
        setIsLoading(true)
        props.GetVideoById(params.videoId, paramsUuid).then(() => {
            videoHeight.current = (videoPlayer?.current?.offsetHeight || 0) + headerHeight
        }).finally(() => {
            setIsLoading(false)
        })
        // props.GetProtocol(params.videoId, paramsUuid)

        if (params?.time) {
            setVideoPlayerCurrentTime(params?.time)
            changeVideoTime(params?.time)
        }
        if (paramsTime) {
            setVideoPlayerCurrentTime(paramsTime)
            changeVideoTime(paramsTime)
        }
        // console.log(location?.state, 'openEdit', params.videoId, params?.time);
        if (location?.state?.openEdit) {
            setShowRenameModal(true)
        }
        return () => {
            props.ClearVideoById()
        };
    }, [params.videoId, params?.time])

    useEffect(() => {
        // For inject to Player Component (for timeline)
        setInterval(() => {
            if (videoPlayer && videoPlayer?.current?.currentTime && videoPlayer?.current?.duration) {
                setVideoPlayerCurrentTime(videoPlayer?.current?.currentTime)
                setVideoPlayerDuration(videoPlayer?.current?.duration)
                // For timeupdate while pause
                // videoPlayer.current?.addEventListener('timeupdate', function () {
                //     setVideoPlayerCurrentTime(this.currentTime)
                //     setVideoPlayerDuration(this.duration)
                // }, false);
            }
        }, 25);
    }, [])

    useEffect(() => {
        const socket = io(`${HOST_NAME}/user_notification`);
        socket.on('connect', () => {
            socket.emit('login', props.token);
        });

        socket.on('updated', () => {
            const urlParams = new URLSearchParams(location.search);
            const paramsUuid = urlParams.get('uuid')

            props.GetVideoById(videoById?.id, paramsUuid, null, false).then((res) => {
                setStatus(statusesList[res?.video_status])
            })
        });
    }, []);

    const getTime = (value) => {
        let secNum = parseInt(value, 10) || 0;
        let hours = Math.floor(secNum / 3600);
        let minutes = Math.floor((secNum - hours * 3600) / 60);
        let seconds = secNum - hours * 3600 - minutes * 60;

        if (hours < 10) {
            hours = "0" + hours;
        }
        if (minutes < 10) {
            minutes = "0" + minutes;
        }
        if (seconds < 10) {
            seconds = "0" + seconds;
        }

        const scaleX = parseFloat(videoTimelineScale.scaleX);
        if (scaleX > 0.4) {
            return hours + ":" + minutes + ":" + seconds;
        }
        if (0.2 < scaleX && scaleX <= 0.4) {
            return hours + ":" + minutes + ":" + seconds;
        } else {
            return hours + ":" + minutes + ":" + seconds;
        }
    }

    /* Change video time */
    const changeVideoTime = (time) => {
        setIsPlayed(true)
        videoPlayer.current?.play()
        setTimeout(() => {
            setVideoPlayerCurrentTime(Number(time || 0))
            setVideoPlayerCurrentTimeScroll(time || 0)
            videoPlayer?.current?.currentTime && (videoPlayer.current.currentTime = time || 0)
        }, 0)
    }

    function openRenameModal() {
        if (token) {
            setShowRenameModal(true)
        } else {
            history.push('/');
        }
    }

    function closeRenameModal() {
        setShowRenameModal(false)
        if (location.state.openEdit){
            window.history.replaceState({
                ...location.state,
                openEdit: false
            }, '')
        }
    }

    function closeDeleteModal() {
        setShowDeleteModal(false)
    }

    function closeShearModal() {
        setShowShearModal(false)
    }

    const changeVideoSpeed = (videoSpeed = 1) => {
        videoPlayer.current.playbackRate = videoSpeed;
    };

    function toggleUserModal(person) {
        setShowModal(!showModal)
        setCurrentUser(person)
    }

    function deleteVideo() {
        props.DeleteVideo(params.videoId).finally(() => {
            history.push(
                !!location?.state?.routs?.[location?.state?.routs?.length - 1]?.path
                    ? location?.state?.routs?.[location?.state?.routs?.length - 1]?.path
                    : '/dashboard/videos/manage'
            )
        })
    }

    function toggleReprocess(e) {
        e.stopPropagation()
        props.ReprocessVideo(videoById?.id)
        props.ChangeReprocessStatus(videoById?.id)
    }

    return !isLoading && <>
        {
            (videoById?.url || videoById?.url_s3) ? <div
                    className={`video_details ${props.isFullWidth ? '' : 'full'}`}
                    ref={pageRef}>
                    <Breadcrumb>
                        {
                            !!location?.state?.routs?.length && location?.state?.routs?.map((item, index) => {
                                return <Breadcrumb.Item key={item?.name} active={item?.active}>
                                    {
                                        item?.id ? <div className={'breadcrumb-item'}
                                                        onClick={() => toggleUserModal(item)}>
                                            {item?.name}
                                        </div> : <Link to={{
                                            pathname: item?.path,
                                            state: {
                                                routs: location?.state?.routs.slice(0, index + 1),
                                                activePage: location.state.activePage
                                            }
                                        }}>{item?.name}</Link>
                                    }
                                </Breadcrumb.Item>
                            })
                        }
                        <Breadcrumb.Item active>
                            <div className="breadcrumb_name">
                                {videoById?.description}
                            </div>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="video_details__header">
                        <div className="video_details__header__description">
                            {videoById?.description}
                            <div className="video_details__header__date">
                                {changeTimeFormat(videoById?.creation_time, currentLanguage)}
                            </div>
                            <div className="video_details__header__date"
                                 style={{
                                     color: statusesListRu[status?.key]?.color
                                 }}>
                                {(statusesListRu[status?.key]?.title || '')}
                            </div>
                        </div>
                        <div className="video_details__header__buttons">
                            {userRoles?.permissions?.find(i => i.name === 'videos_reprocess') &&
                                <InfoTooltip
                                    placement={'left'}
                                    elem={<button className="video_details__header__refresh_btn" onClick={toggleReprocess}>
                                        <RefreshIcon/>
                                    </button>}
                                    title={'Переобработка видео'}/>
                            }
                            <button className="video_details__header__edit_btn" onClick={openRenameModal}>
                                <EditGreenIcon/>
                                {t('button_edit')}
                            </button>
                            <button className="video_details__header__share_btn" onClick={() => {
                                setShowShearModal(true)

                                // props.CreateVideoUrl({
                                //     base_url: `${window.location.origin + location.pathname}`,
                                //     video_id: videoById?.id
                                // }).then((res) => {
                                //     navigator.clipboard.writeText(res.url);
                                //     setCopied(true)
                                // })
                            }}>
                                <ShareIcon/>
                                {t('button_share')}
                            </button>
                            {userRoles?.permissions?.find(i => i.name === 'videos_delete')
                                && <button className="video_details__header__delete_btn"
                                           onClick={() => setShowDeleteModal(true)
                                           }>
                                    <DeletingIcon/> {t('button_delete')}
                                </button>}

                            <Notifications/>
                        </div>
                    </div>
                    <div className="video_details__header_mobile">
                        <div className="">
                            {
                                !!location?.state?.routs?.length && <Link to={{
                                    pathname: location?.state?.routs?.[0]?.path,
                                    state: {
                                        routs: location?.state?.routs.slice(0, location?.state?.routs?.length),
                                        activePage: location.state.activePage
                                    }
                                }} className="video_details__header_mobile__rout">
                                    <ArrowDownIcon/>
                                    {/*{location?.state?.routs?.[0]?.name}*/}
                                </Link>
                            }
                            {videoById?.description}
                        </div>

                        <div className="buttons">
                            <EditIcon className="video_details__header_mobile__edit" onClick={() => {
                                setShowRenameModal(true)
                            }}/>
                            <ShareIcon className="video_details__header_mobile__share" onClick={() => {
                                setShowShearModal(true)
                            }}/>
                        </div>
                    </div>
                    <div className="video_details__owner">
                        {t('table_owner')}:&nbsp;
                        <span onClick={(e) => {
                            e.stopPropagation()
                            toggleUserModal(videoById?.owner)
                        }}>{videoById?.owner?.name ? videoById?.owner?.name : ''}</span>
                    </div>
                    <div className="video_details__top">
                        <VideoIntroduction videoById={videoById}
                                           videoPlayerCurrentTime={videoPlayerCurrentTime}
                                           changeVideoTime={changeVideoTime}
                                           getTime={getTime}
                                           status={status}
                                           setStatus={setStatus}/>
                        <Video pageRef={pageRef}
                               videoPlayer={videoPlayer}
                               isPlayed={isPlayed}
                               setIsPlayed={setIsPlayed}
                               videoPlayerCurrentTime={videoPlayerCurrentTime}
                        />

                        <div className="video_details__mobile-description">
                            <div className="mobile-owner">
                                {t('table_owner')}:&nbsp;
                                <span onClick={(e) => {
                                    e.stopPropagation()
                                    toggleUserModal(videoById?.owner)
                                }}>{videoById?.owner?.name ? videoById?.owner?.name : ''}</span>
                            </div>
                            <div className="mobile-date">
                                {changeTimeFormat(videoById?.creation_time, currentLanguage)}
                            </div>
                        </div>

                    </div>

                    {isMax && <NewPlayer videoPlayer={videoPlayer}
                                         getTime={getTime}
                                         playerHeight={playerHeight}
                                         changeVideoSpeed={changeVideoSpeed}
                                         changeVideoTime={changeVideoTime}
                                         isPlayed={isPlayed}
                                         setIsPlayed={setIsPlayed}
                                         videoTimelineScale={videoTimelineScale}
                                         setVideoTimelineScale={setVideoTimelineScale}
                                         videoPlayerDuration={videoPlayerDuration}
                                         videoPlayerCurrentTime={videoPlayerCurrentTime}
                                         videoPlayerCurrentTimeScroll={videoPlayerCurrentTimeScroll}
                                         setVideoPlayerCurrentTimeScroll={setVideoPlayerCurrentTimeScroll}
                                         protocolList={protocolList}
                    />}

                    {showModal && <UserInfoModal show={showModal}
                                                 closeModal={toggleUserModal}
                                                 user={currentUser}
                                                 videoPersonsList={videoById.persons}/>}
                    {showRenameModal && <UpdateVideoModal showModal={showRenameModal}
                                                          onHide={closeRenameModal}
                                                          video={videoById}/>}
                    {showDeleteModal && <DeleteModal showModal={showDeleteModal}
                                                     onHide={closeDeleteModal}
                                                     handleDelete={deleteVideo}
                                                     title={'видео'}
                                                     description={videoById?.description}/>}
                    {showShearModal && <ShearModal showModal={showShearModal}
                                                   onHide={closeShearModal}
                                                   videoById={videoById}/>}
                </div>
                : <div className={`video_details empty ${props.isFullWidth ? '' : 'full'}`}>
                    <div className="empty-page">
                        <div className="text">К сожалению у вас нет прав для просмотра этого видео</div>
                        <Link to={`/dashboard/videos/review`} className={'link-btn'}>Вернуться на главную</Link>
                    </div>
                </div>
        }
        <CustomToast description={'Ссылка скопирована в буферобмен'}/>
    </>;
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'videoById',
        'token',
        'protocolList',
        'channels',
        'personsList',
        'isFullWidth',
        'userRoles',
        'videoIsEmpty',
    ])
};

const mapDispatchToProps = {
    GetVideoById,
    AddProtocol,
    ClearVideoById,
    GetProtocol,
    UpdateProtocol,
    DeleteProtocol,
    AddVideoDescription,
    UpdateTranscription,
    UpdateTranscriptionAll,
    GetChannels,
    CreateVideoUrl,
    MarkVideo,
    ReplaceTranscriptionWord,
    DownloadTranscription,
    GetLink,
    SaveRedirectUrl,
    DeleteVideo,
    ReprocessVideo,
    ChangeReprocessStatus
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoDetails);
