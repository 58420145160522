import {TAGS_CONSTS} from "../constants";

export const initialState = {
    tagsList: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case TAGS_CONSTS.GET_TAGS:
            return {
                ...state,
                tagsList: action.payload,
            };
        case TAGS_CONSTS.ADD_TAGS:
            return {
                ...state,
                tagsList: [...state.tagsList, action.payload],
            };
        case TAGS_CONSTS.DELETE_TAGS:
            return {
                ...state,
                tagsList: state.tagsList.filter(item => item.id !== action.payload),
            };
        case TAGS_CONSTS.UPDATE_TAGS:
            return {
                ...state,
                tagsList: state.tagsList?.map(item => item.id === action.payload.id ? {
                    ...item,
                    name: action.payload.data.name
                } : item),
            };
        default:
            return state;
    }
}
