// Import packages
import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {Accordion, Modal} from "react-bootstrap";

// Import assets
import './addUserModal.scss';
import '../../../Profile/contacts/profile-contacts.scss'
import {ArrowDownIcon, CloseIcon, EyeIcon} from "../../../../../../image";

// Import Utils
import {getPropsFromState} from "../../../../../../redux/mapStateToProps";
import {AddUser} from "../../../../../../redux/actions";
import {useTranslation} from "react-i18next";
import {profileErrorMessages} from "../../../../../../utils/constants";
import isEmail from "validator/es/lib/isEmail";
import validator from "validator";
import config from "../../../../../../utils/config"

// Import Components
import ProfileMedia from "../../../Profile/media";
import ProfileContacts from "../../../Profile/contacts";
import InfoTooltip from "../../../Profile/tooltip";
import UserRoles from "../../../../../../shared/profile/UserRoles";

const initialState = {
    first_name: undefined,
    last_name: undefined,
    company: undefined,
    position: undefined,
    login: undefined,
    password: undefined,
    note: undefined,
}
const initialContactState = {
    email: [''],
    phone_number: [''],
    linkedin: [''],
    facebook: [''],
}
const initialErrors = {
    login: '',
    password: '',
    first_name: '',
    last_name: '',
    phone_number: [],
    email: [],
    linkedin: [],
    facebook: [],
}

function AddUserModal(props) {
    const {show, personById, rolesList} = props;

    const {t} = useTranslation();

    const [userInfo, setUserInfo] = useState(initialState);
    const [contactItems, setContactItems] = useState(initialContactState);
    const [errors, setErrors] = useState(initialErrors);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [userRole, setUserRole] = useState(undefined);

    let disabledBtn = !!Object.values(userInfo).find(item => !!item)?.length
    !disabledBtn && (disabledBtn = !!Object.values(contactItems).find(item => !!item.find(i => !!i))?.length)

    useEffect(() => {
        setUserRole(rolesList?.[2])
    }, [rolesList])
    useEffect(() => {
        let input = document.getElementById('myInput');
        !!input && (input.type = passwordVisible ? "password" : 'text')
    }, [passwordVisible])

    // console.log(props.userRoles, 'props.userRoles');

    function getInputValue(e) {
        const {name, value} = e.target
        setUserInfo({
            ...userInfo,
            [name]: value
        })
        setErrors({
            ...errors,
            [name]: false
        })
    }

    function validateData(data) {
        const {first_name, last_name, email, linkedin, facebook, phone_number, user, login, password} = data
        setErrors(initialErrors)
        let isValid = true;
        let errorList = {...initialErrors}

        if (!first_name?.length || first_name?.length < 2) {
            isValid = false
            errorList = {
                ...errorList,
                first_name: profileErrorMessages.first_name
            }
        }

        if (!!last_name?.length && last_name?.length < 2) {
            isValid = false
            errorList = {
                ...errorList,
                last_name: profileErrorMessages.last_name
            }
        }

        if (!login || !isEmail(login)) {
            isValid = false
            errorList = {
                ...errorList,
                login: profileErrorMessages.required
            }
        }
        if (!password || password?.length < 8) {
            isValid = false
            errorList = {
                ...errorList,
                password: profileErrorMessages.required
            }
        }

        !!email?.length && email.forEach((item, index) => {
            if (!isEmail(item)) {
                (isValid = false)
                errorList = {
                    ...errorList,
                    email: email?.map((mail, i) => {
                        return i === index ? profileErrorMessages?.email : undefined
                    })
                }
            }
        })
        !!linkedin?.length && linkedin.forEach((item, index) => {
            const isValidLinkedinURL = validator.isURL(item, {
                host_whitelist: ['linkedin.com', 'www.linkedin.com']
            })
            if (!isValidLinkedinURL) {
                (isValid = false)
                errorList = {
                    ...errorList,
                    linkedin: facebook?.map((mail, i) => {
                        return i === index ? profileErrorMessages.linkedin : undefined
                    })
                }
            }
        })
        !!facebook?.length && facebook.forEach((item, index) => {
            const isValidFacebookURL = validator.isURL(item, {
                host_whitelist: ['facebook.com', 'www.facebook.com']
            })
            if (!isValidFacebookURL) {
                isValid = false
                errorList = {
                    ...errorList,
                    facebook: facebook?.map((mail, i) => {
                        return i === index ? profileErrorMessages.facebook : undefined
                    })
                }
            }
        })
        !!phone_number?.length && phone_number.forEach((item, index) => {
            if (item?.length < 11) {
                isValid = false
                errorList = {
                    ...errorList,
                    phone_number: phone_number?.map((mail, i) => {
                        return i === index ? profileErrorMessages.phone_number : undefined
                    })
                }
            }
        })
        if (user?.password?.length < 8) {
            isValid = false;
            errorList = {
                ...errorList,
                password: profileErrorMessages.password
            }
        }
        if (!!user?.login && personById?.associated_user?.id) {
            isValid = false;
            errorList = {
                ...errorList,
                login: profileErrorMessages?.login
            }
        }
        setErrors(errorList)

        return isValid
    }

    function toggleRole(e, role) {
        e.stopPropagation()
        setUserRole(role)
    }

    function saveChanges() {
        let recData = {
            ...userInfo,
            role_ids: [userRole?.id]
        };

        if (validateData(recData)) {
            props.AddUser(recData)
            onClose()
        }
    }

    function onClose() {
        setUserInfo(initialState)
        setErrors(initialErrors)
        setContactItems(initialContactState)
        props.closeModal()
    }

    return <Modal
        show={show}
        onHide={onClose}
        size="lg"
        className={'info_modal'}
        centered
    >
        <div className="add_user_modal">
            <div className="user-header">
                {t('add_user_title')}
                <CloseIcon onClick={onClose}/>
            </div>
            <div className="add_user_modal__wrapper">
                <div className="add_user_modal__media">
                    <ProfileMedia/>
                    {/*<TabsDropdown personById={personById} show={show}/>*/}
                </div>
                <div className="add_user_modal__content">
                    <div className={'input-form'}>
                        <div className="row-item">
                            <div className="input_group">
                                <label htmlFor="">{t('input_name')}</label>
                                <input type="text"
                                       value={userInfo?.first_name}
                                       autoComplete={'off'}
                                       name={'first_name'}
                                       onChange={getInputValue}
                                       placeholder={t('input_name')}
                                />
                                {!!errors.first_name && (
                                    <div className="input_group__error">{errors.first_name}</div>
                                )}
                            </div>
                            <div className="input_group">
                                <label htmlFor="">{t('input_surname')}</label>
                                <input type="text"
                                       value={userInfo?.last_name}
                                       name={'last_name'}
                                       autoComplete="off"
                                       onChange={getInputValue}
                                       placeholder={t('input_surname')}
                                />
                                {!!errors.last_name && (
                                    <div className="input_group__error">{errors.last_name}</div>
                                )}
                            </div>
                        </div>
                        <div className="row-item">
                            <div className="input_group">
                                <label htmlFor="">{t('input_company')}</label>
                                <input type="text"
                                       value={userInfo?.company}
                                       name={'company'}
                                       disabled={true}
                                       onChange={getInputValue}
                                       placeholder={t('input_company')}
                                />
                            </div>
                            <div className="input_group">
                                <label htmlFor="">{t('input_position')}</label>
                                <input type="text"
                                       value={userInfo?.position}
                                       name={'position'}
                                       disabled={true}
                                       onChange={getInputValue}
                                       placeholder={t('input_position')}
                                />
                            </div>
                        </div>

                        <div className="input_group full">
                            <label htmlFor="">{t('input_notes')}</label>
                            <textarea value={userInfo?.note}
                                      name={'note'}
                                      disabled={true}
                                      onChange={getInputValue}
                                      placeholder={t(config.productName)}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <ProfileContacts contactItems={contactItems}
                             setContactItems={setContactItems}
                             errors={errors}
                             setErrors={setErrors}
                             personById={personById}/>

           {/* <div className="settings_tab">
                <Tabs
                    defaultActiveKey="0"
                    id="uncontrolled-tab-example">
                    {/*<Tab eventKey="0"
                         className={'settings_content'}
                         title={
                             <span className="tab-title">
                        {t('profile_info_title')}
                                 <InfoTooltip title={t('tooltip_info_title')}
                                              description={t('tooltip_info_description')}/>
                    </span>
                         }>
                        <ParticipationData avatar={personById?.avatar}
                                           userRoles={props.userRoles}/>
                    </Tab>
                    <Tab eventKey="1"
                         className={'settings_content'}
                         title={
                             <span className="tab-title">
                        {t('profile_settings_title')}
                                 <InfoTooltip title={t('tooltip_settings_title')}
                                              description={t('tooltip_settings_description')}/>
                    </span>
                         }>
                        <div className="user-info-settings">
                            <p>{t('profile_settings_desc')}</p>
                            <div className="input_group">
                                <label htmlFor="">{t('input_login')}</label>
                                <input type="text"
                                       value={userInfo?.login}
                                       name={'login'}
                                       onChange={getInputValue}
                                       placeholder={t('input_login')}
                                       autoComplete={'off'}
                                />
                                {!!errors.login && (
                                    <div className="input_group__error">{errors.login}</div>
                                )}
                            </div>
                            <div className="input_group">
                                <label htmlFor="">{t('input_password')}</label>
                                <input
                                    type={`${passwordVisible ? "text" : "password"}`}
                                    value={userInfo?.password}
                                    name={'password'}
                                    onChange={getInputValue}
                                    placeholder={t('input_password')}
                                    autoComplete="new-password"
                                />
                                <EyeIcon className="input-icon"
                                         onClick={() => setPasswordVisible(!passwordVisible)}/>
                                {!!errors.password && (
                                    <div className="input_group__error">{errors.password}</div>
                                )}
                            </div>
                        </div>
                    </Tab>
                </Tabs>
            </div>*/}

            <Accordion className={'contacts-wrapper'}>
                <Accordion.Item eventKey="1" className={'profile-page__contacts'}>
                    <Accordion.Header
                        className={`contact-header `}>
                        <ArrowDownIcon className={`arrow-icon`}/>
                        {t('profile_settings_title')}
                        <InfoTooltip title={t('tooltip_settings_title')}
                                     description={t('tooltip_settings_description')}/>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className="user-info-settings">
                            {/*<p>{t('profile_settings_desc')}</p>*/}
                            <div className="input_group">
                                <label htmlFor="">{t('input_login')}</label>
                                <input type="text"
                                       value={userInfo?.login}
                                       name={'login'}
                                       onChange={getInputValue}
                                       placeholder={t('input_login')}
                                       autoComplete={'off'}
                                />
                                {!!errors.login && (
                                    <div className="input_group__error">{errors.login}</div>
                                )}
                            </div>
                            <div className="input_group">
                                <label htmlFor="">{t('input_password')}</label>
                                <input
                                    type={`${passwordVisible ? "text" : "password"}`}
                                    value={userInfo?.password}
                                    name={'password'}
                                    onChange={getInputValue}
                                    placeholder={t('input_password')}
                                    autoComplete="new-password"
                                />
                                <EyeIcon className="input-icon"
                                         onClick={() => setPasswordVisible(!passwordVisible)}/>
                                {!!errors.password && (
                                    <div className="input_group__error">{errors.password}</div>
                                )}
                            </div>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1" className={'profile-page__contacts'}>
                    <Accordion.Header
                        className={`contact-header `}>
                        <ArrowDownIcon className={`arrow-icon`}/>
                        {t('profile_roles')}
                    </Accordion.Header>
                    <Accordion.Body>
                        <UserRoles rolesList={rolesList}
                                   toggleRole={toggleRole}
                                   userRole={userRole}
                                   isEdited={true}
                        />

                       {/* <div className={'connect_person'}>
                            <span>{t('connect_person_roles')}</span>

                            <CombinePersonsDropdown user={personById}/>
                        </div>*/}
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <div className="buttons-wrapper">
                <button className={`close_btn`} onClick={onClose}
                >
                    {t('button_cancel')}
                </button>

                <button className={`save_btn ${!disabledBtn ? 'disabled' : ''}`}
                        disabled={!disabledBtn}
                        onClick={saveChanges}
                >
                    {t('button_save_changes')}
                </button>
            </div>
        </div>
    </Modal>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, ['videosList', 'personById', 'rolesList', 'userRoles'])
};

const mapDispatchToProps = {
    AddUser
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUserModal);
