import React from "react";
import {store} from "../redux/store";

export function getTimeString(start_time) {
    let sec_num = parseInt(start_time) || 0;
    let hours = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    let seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours < 10) {
        hours = "0" + hours;
    }
    if (minutes < 10) {
        minutes = "0" + minutes;
    }
    if (seconds < 10) {
        seconds = "0" + seconds;
    }
    return hours + ':' + minutes + ':' + seconds;
}

export function getFullTime(start_time, isShort = false) {
    let sec_num = parseInt(start_time) || 0;
    let hours = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    let seconds = sec_num - (hours * 3600) - (minutes * 60);

    let result = ''
    hours && (result = result + hours + `${isShort ? ' ч ' : ' часов '}`)
    minutes && (result = result + minutes + `${isShort ? ' м ' : ' минут'}`)
    isShort && seconds && (result = result + seconds + ' с')

    return result;
}

export function parseTime(time) {
    const timeArr = time.split(':')
    let sec = parseInt(timeArr[timeArr.length - 1]) || 0;
    let min = parseInt(timeArr[timeArr.length - 2]) || 0;
    let hour = parseInt(timeArr.length >= 3 ? timeArr[0] : 0) || 0
    return sec + (min * 60) + (hour * 3600)
}

export const timeWithDots = (time) => {
    let arr = time.split('')
    let newArr = []

    for (let i = 0; i < arr.length; i++) {
        if ((i + 1) % 3 === 0) {
            newArr.push(':')
        } else {
            newArr.push(arr[i])
        }
    }

    return newArr.join('')
}

export const changeTimeFormat = (date, language = 'ru') => {
    const newDate = new Date(date)

    const day = newDate?.getDate()
    const month = newDate?.toLocaleDateString(`${language}-${language?.toLocaleUpperCase()}`, {month: 'long'})
    const year = newDate?.getFullYear()
    const hour = newDate?.getHours()
    const min = newDate?.getMinutes()
    const sec = newDate?.getSeconds()

    return `${day} ${month} ${year}, ${hour}:${min < 10 ? '0' + min : min}:${sec < 10 ? '0' + sec : sec}`
}

export function getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

export function getAuthorName(data, id) {
    return data?.first_name ? `${data.first_name ? data.first_name : ''} ${data?.last_name ? data?.last_name : ''}`
        : `id-${id || data?.id || ''}`
}

export function getLocalTime(date) {
    const utcDate = new Date(date);
    const userLocale = navigator.language || 'en-US';

    const options = {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    return new Intl.DateTimeFormat(userLocale, options).format(utcDate);
}

export function hasPermission(key) {
    const permissions = store.getState().profile?.userRoles?.permissions
    return !!permissions?.find(i => i.name === key)
}

export function underscoreChannelSort(a, b) {
    const startsWithUnderscoreA = a?.name?.startsWith('__user');
    const startsWithUnderscoreB = b?.name?.startsWith('__user');

    if (startsWithUnderscoreA === startsWithUnderscoreB) {
        return a?.name.localeCompare(b?.name);
    }

    if (startsWithUnderscoreA) {
        return 1;
    }

    if (startsWithUnderscoreB) {
        return -1;
    }
}
