import {LINKS_CONSTS} from "../constants";

export const initialState = {
    link: {},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LINKS_CONSTS.GET_LINK:
            return {
                ...state,
                link: action.payload,
            };
        default:
            return state;
    }
}
