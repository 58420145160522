import {PERSONS_CONSTS, TAGS_CONSTS} from "../constants";

export const initialState = {
    personsList: {},
    newPersonsList: {},
    personById: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case PERSONS_CONSTS.GET_PERSONS:
            return {
                ...state,
                personsList: action.payload,
            };
        case PERSONS_CONSTS.GET_NEW_PERSONS:
            return {
                ...state,
                newPersonsList: action.payload,
            };
        case PERSONS_CONSTS.CLEAR_PERSONS:
            return {
                ...state,
                personsList: {},
                newPersonsList: {},
            };
        case PERSONS_CONSTS.GET_PERSON_BY_ID:
            return {
                ...state,
                personById: action.payload,
            };
        case PERSONS_CONSTS.CLEAR_PERSONS_BY_ID:
            return {
                ...state,
                personById: null,
            };
        case PERSONS_CONSTS.UPDATE_PERSON_TAGS:
            return {
                ...state,
                personById: {
                    ...state.personById,
                    // tags: state.personById.tags.map(item => item.id !== action.payload)
                },
            };
        case PERSONS_CONSTS.DELETE_PERSON_TAGS:
            return {
                ...state,
                personById: {
                    ...state.personById,
                    tags: state.personById.tags.filter(item => item.id !== action.payload)
                },
            };
        case PERSONS_CONSTS.UPDATE_PERSONS:
            let updatedList = state.personsList?.results?.map(item => {
                return (item.id || item.person_id) === action.payload.id ? {
                    ...item,
                    first_name: action.payload.user.first_name,
                    last_name: action.payload.user.last_name,
                    company: action.payload.user.company,
                    position: action.payload.user.position,
                } : item
            })
            const filteredPersons = {
                ...state.newPersonsList,
                count: state?.newPersonsList?.results.find(i => action.payload.id !== i.id)
                    ? state.newPersonsList?.count - 1 : state.newPersonsList?.count ,
                results: state?.newPersonsList?.results.filter(i => action.payload.id !== i.id)
            }

            return {
                ...state,
                newPersonsList: filteredPersons,
                personsList: {
                    ...state.personsList,
                    results: updatedList
                },
                personById: {
                    ...state.personById,
                   ...action.payload.user,
                    associated_user: {
                        ...state.personById.associated_user,
                        login: action.payload.login
                    }
                }
            };
        case PERSONS_CONSTS.UPDATE_NEW_PERSONS:
            let updatedNewList = state.newPersonsList.results.map(item => {
                return item.id === action.payload.id ? {
                    ...item,
                    first_name: action.payload.user.first_name,
                    last_name: action.payload.user.last_name,
                    company: action.payload.user.company,
                    position: action.payload.user.position,
                } : item
            })

            return {
                ...state,
                newPersonsList: {
                    ...state.newPersonsList,
                    results: updatedNewList
                },
            };
        case TAGS_CONSTS.ADD_TAGS:
            const list = state.personById?.tags || []
            return {
                ...state,
                personById: {
                    ...state.personById,
                    tags: [...list, action.payload]
                },
            };
        case TAGS_CONSTS.DELETE_TAGS:
            return {
                ...state,
                personById: {
                    ...state.personById,
                    tags: state.personById?.tags?.filter(item => item.id !== action.payload)
                },
            };
        default:
            return state;
    }
}
