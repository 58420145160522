import {_urlLink, request} from "../api";
import {LINKS_CONSTS} from "../constants";


export const GetLink = ({video_id, protocol_text_id}) => {
    let requestData = {
        url: `${_urlLink}?`,
        method: "GET",
    };
    video_id && (requestData.url = requestData?.url + `video_id=${video_id}`)
    protocol_text_id && (requestData.url = requestData?.url + `&protocol_text_id=${protocol_text_id}`)

    return () => {
        return request(requestData)
            .then((res) => {
                // const data = res && res.data;
                // await dispatch({
                //     type: LINKS_CONSTS.GET_LINK,
                //     payload: data
                // })
                return  res && res.data;
            })
    };
};

export const UpdateLink = (link_id, data, video_id) => {
    let requestData = {
        url: `${_urlLink}/${link_id}`,
        method: "PATCH",
        data
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                // console.log('UpdateTranscription', res);
                dispatch({
                    type: LINKS_CONSTS.UPDATE_LINK,
                    payload: {...data, video_id}
                })
            })
    };
};
