// Import packages
import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

// Import assets
import "react-datepicker/dist/react-datepicker.css";
import './settings-modal.scss';
import {GoogleCalendarIcon} from "../../../../image";

//Import components
import {Form, Modal} from "react-bootstrap";

//Import utils
import {getPropsFromState} from "../../../../redux/mapStateToProps";
import {CalendarLogOut, GetUserCalendars, UpdateUserCalendars, GetCalendarSynchronize, GoogleAuth} from "../../../../redux/actions";
import {googleLogout, GoogleOAuthProvider, useGoogleLogin} from '@react-oauth/google';
import {useTranslation} from "react-i18next";
import InfoTooltip from "../../../../pages/Dashboard/containers/Profile/tooltip";

function SettingsModal(props) {
    const {
        showSettingsModal, onHide, userCalendars, targetRef, user, googleToken, clientId, setGoogleUser,
        handleOnSuccess
    } = props;
    const {t} = useTranslation();

    const [checkedChannels, setCheckedChannels] = useState(null);

    const tagColors = ['#4F40F7', '#2877D4', '#F7404B', '#CB43C6', '#CB7443'];
    let checkList = checkedChannels && Object.keys(checkedChannels).filter(i => checkedChannels?.[i]?.enable_sync)
    let initList = userCalendars && userCalendars.filter(i => i?.enable_sync).map(i => i.id)
    const disabledBtn = checkList?.sort()?.toString() !== initList?.sort()?.toString()

    const calendars = userCalendars

    useEffect(() => {
        props.GetUserCalendars()

        const element = targetRef.current;
        if (element) {
            const selectedElement = element.querySelector('.react-datepicker__current-month');

            if (selectedElement) {
                const words = selectedElement?.innerText?.split(' ');
                const lastWords = words[1];
                selectedElement.innerHTML = selectedElement?.innerText?.replace(lastWords, `<span class="last-words">${lastWords}</span>`);
            }
        }
    }, [showSettingsModal]);

    useEffect(() => {
        let channels = {}
        !!userCalendars?.length && userCalendars.forEach(item => {
            channels = {
                ...channels,
                [item?.id]: {
                    id: item?.id,
                    enable_sync: item?.enable_sync
                }
            }
        })
        setCheckedChannels(channels)
        return () => {
            setCheckedChannels(null)
        }
    }, [userCalendars?.length]);

    function checkChannel(e, id) {
        let channels = {
            ...checkedChannels,
            [id]: {
                id,
                enable_sync: e.target.checked
            }
        }
        setCheckedChannels(channels)
    }

    function googleLogOut() {
        googleLogout()
        props.CalendarLogOut()
        onHide()
        setCheckedChannels(null)
        setGoogleUser(undefined)
    }

    function saveResult() {
        !!userCalendars?.length && userCalendars.forEach(item => {
            if (item?.enable_sync !== checkedChannels[item?.id]?.enable_sync) {
                props.UpdateUserCalendars(item?.id, {
                    enable_sync: checkedChannels[item?.id]?.enable_sync
                })
                props.GetCalendarSynchronize()
                onHide()
            }
        })
    }

    return <Modal
        show={showSettingsModal}
        onHide={onHide}
        onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
        }}
        contentClassName={'settings_modal'}
        centered
    >
        <div className="settings_title">{t('calendar_settings_title')}</div>

        <div className="settings-content" onClick={e => e.stopPropagation()}>
            <div className="title">{t('calendar_settings_calendar')}</div>
            <div className="list">
                {!!calendars?.length && calendars?.map((data, index) => {
                    return <div key={`default-${data?.id}`} className="channel-item">
                        <Form.Check
                            type={'checkbox'}
                            checked={checkedChannels?.[data.id]?.enable_sync}
                            onChange={(e) => checkChannel(e, data.id)}
                        />
                        <div className="label"
                             style={{
                                 backgroundColor: tagColors[index >= 5 ? index % 5 : index] + '14',
                                 color: tagColors[index >= 5 ? index % 5 : index],
                             }}
                        >
                            {data?.calendar_id?.length > 27 ? <InfoTooltip elem={data?.calendar_id}
                                                                    title={data?.calendar_id}/> : data?.calendar_id}
                        </div>
                    </div>
                })}
            </div>

            <div className="title">{t('calendar_settings_integrations')}</div>

            <div className="google-integrations">
                <div className="google-info">
                    <GoogleCalendarIcon/>
                    <div className="user">
                        <div className="user-title">{t('calendar_settings_user_title')}</div>
                        {user?.email && <div className="user-name">
                            <span>{t('calendar_settings_user_name')}</span>
                            {user?.email}
                        </div>}
                    </div>
                </div>
                {
                    googleToken ? <button className="google-log-out" onClick={googleLogOut}>
                            {t('calendar_settings_disconnect')}
                        </button>
                        : clientId && <GoogleOAuthProvider clientId={clientId}>
                        <GoogleButton GoogleAuth={props?.GoogleAuth}
                                      t={t}
                                      handleOnSuccess={handleOnSuccess}/>
                    </GoogleOAuthProvider>

                    // <button className="google-connect" onClick={googleLogOut}>
                    //     Подключиться
                    // </button>
                }
            </div>

            <div className="buttons">
                <button className={'cancel-btn'}
                        onClick={onHide}>{t('button_cancel')}
                </button>
                <button className={`save-btn ${!disabledBtn ? 'disabled' : ''}`}
                        disabled={!disabledBtn}
                        onClick={saveResult}
                >{t('button_save')}
                </button>
            </div>
        </div>
    </Modal>
}

function GoogleButton({handleOnSuccess, t}) {
    const login = useGoogleLogin({
        flow: 'auth-code',
        scope: 'https://www.googleapis.com/auth/calendar',
        onSuccess: async (codeResponse) => {
            handleOnSuccess(codeResponse)
        },
        onError: errorResponse => console.log(errorResponse),
    });
    return <button className="google-connect"
                   onClick={() => login()}>
        {t('connect')}
    </button>;
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, ['userCalendars'])
};

const mapDispatchToProps = {
    GetUserCalendars,
    UpdateUserCalendars,
    CalendarLogOut,
    GetCalendarSynchronize,
    GoogleAuth
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsModal);
