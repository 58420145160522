import {_urlVideos, request} from "../api";

export const uploadVideo = (formData) => {
    let requestData = {
        url: `${_urlVideos}/download_video`,
        customHeaders: {
            'Content-Type': 'multipart/form-data',
        },
        method: "POST",
        data: formData
    };
    // console.log(formData, 'formData');
    return () => {
        return request(requestData)
    };
};
