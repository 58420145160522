import {ANALYSIS_PIPELINES_CONSTS} from "../constants";
import {_urlAnalysisPipelines, _urlLanguageModels, request} from "../api";

export const GetLanguageModels = () => {
    let requestData = {
        url: `${_urlLanguageModels}`,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;

                await dispatch({
                    type: ANALYSIS_PIPELINES_CONSTS.GET_LANGUAGE_MODELS,
                    payload: data
                })
            })
    };
};
export const GetAnalysisPipelines = () => {
    let requestData = {
        url: `${_urlAnalysisPipelines}`,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;

                await dispatch({
                    type: ANALYSIS_PIPELINES_CONSTS.GET_ANALYSIS_PIPELINES,
                    payload: data
                })
            })
    };
};

export const AddAnalysisPipeline = (data) => {
    let requestData = {
        url: `${_urlAnalysisPipelines}`,
        method: "POST",
        data,
    };

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                await dispatch({
                    type: ANALYSIS_PIPELINES_CONSTS.ADD_ANALYSIS_PIPELINES,
                    payload: {
                        data:  {
                            ...data,
                            id: res?.data?.protocol_text_id,
                            marked: true
                        },
                    }
                })
            })
    };
};

export const UpdateAnalysisPipeline = (id, data) => {
    const requestData = {
        url: `${_urlAnalysisPipelines}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };

    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: ANALYSIS_PIPELINES_CONSTS.UPDATE_ANALYSIS_PIPELINES,
                    payload: {
                        id, data
                    }
                });
            })
            .catch((err) => {
                console.log(err, 'ERROR');
            })

    }
};

export const DeleteAnalysisPipeline = data => {
    const requestData = {
        url: `${_urlAnalysisPipelines}/${data?.id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: ANALYSIS_PIPELINES_CONSTS.DELETE_ANALYSIS_PIPELINES,
                    payload: data
                });
            })
            .catch(() => {
            })
    }
};
