// Import packages
import React from 'react';

// Import assets
import './user-info-item.scss';

// Import Utils
import {generateMediaUrl} from "../../../utils/generateMediaUrl";
import {getAuthorName} from "../../../utils/helperFunctions";

function UserInfoItem(props) {
    const {data, cb} = props;
    let userInfo = {...data}
    data.name && ( userInfo.first_name = data.name)
    return <div className="user-info-item"
                onClick={cb}>
        <img
            src={generateMediaUrl(data?.avatar)}
            key={data.id}
            alt=""
        />
        <div className="info-content">
            <div className="name">{getAuthorName(userInfo)}</div>
            <div className="email">
                {data.email || 'user@gmail.com'}
            </div>
        </div>
    </div>;
}

export default UserInfoItem;
