// Import packages
import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

// Import assets
import "react-datepicker/dist/react-datepicker.css";
import "./link-content.scss"

// Import Components
import DatePicker from "react-datepicker";
import DatePickerDropdowns from "../DatePickerDropdowns/DatePickerDropdowns";

// Import Utils
import {getPropsFromState} from "../../../../../../redux/mapStateToProps";
import {Form} from "react-bootstrap";
import {ShowSuccessToast, UpdateLink} from "../../../../../../redux/actions";
import {hasPermission} from "../../../../../../utils/helperFunctions";
import {LinkIcon} from "../../../../../../image";

function LinkContent(props) {
    const {onCopy, link, handleLinkData, onHide, videoId} = props;
    const [checkedType, setCheckedType] = useState(true);
    const [channelInfo, setChannelInfo] = useState(false);
    const [startDate, setStartDate] = useState(new Date());

    // const {t} = useTranslation();

    useEffect(() => {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        setStartDate(link?.expiration_time ? new Date(link?.expiration_time) : tomorrow)
        setCheckedType(link?.is_public)
        setChannelInfo(!!link?.expiration_time)
    }, [])

    useEffect(() => {
        handleLinkData && handleLinkData({
            is_public: checkedType,
            expiration_time: !channelInfo ? null : startDate
        })
    }, [checkedType, channelInfo, startDate])

    function toggleCheck() {
        setChannelInfo(!channelInfo)
    }

    function changeTime(date) {
        const dateStart = new Date(date);
        setStartDate(dateStart);

        dateStart.setHours(0, 0, 0, 0);

        props.UpdateLink(link?.id, {
            is_public: checkedType,
            expiration_time: !channelInfo ? null : dateStart
        }, videoId)
        // props.GetCalendarsEvents({
        //     datetime_start: dateStart.toISOString(),
        //     datetime_end: dateEnd.toISOString()
        // })
    }

    function toggleType(e) {
        e?.stopPropagation()
        setCheckedType(e.target.checked)
        props.UpdateLink(link?.id, {
            is_public: e.target.checked
        }, videoId)
    }

    const changeDateFormat = (date) => {
        const day = date?.getDate()
        const month = date?.getMonth() + 1
        const year = date?.getFullYear()

        return `${day}/${month}/${year}`
    }

    function onClose() {
        onHide()
        setCheckedType(true)
        setChannelInfo(false)
        setStartDate(new Date())
    }

   /* function saveChanges() {
        props.UpdateLink(link?.id, {
            is_public: checkedType,
            expiration_time: !channelInfo ? null : startDate
        }, videoId)
        onClose()
    }

    let disabledBtn =
        (link?.is_public === checkedType)
        && (changeDateFormat(new Date(link?.expiration_time)) === changeDateFormat(startDate))
        && (!!link?.expiration_time === !!channelInfo)*/

    return <div className={`link-content-wrapper`}>
        <div className="link-description">
            {
                !checkedType ? 'Доступ к видео ограничен. Настройте ссылку, чтобы сделать видео публичным'
                    : 'Видео доступно по публичной ссылке. Поделитесь этой ссылкой или закройте  доступ, ' +
                    'если хотите ограничить просмотр.'
            }
        </div>

        <div className="link-title">
            Ссылка
            {/*<CloseIcon onClick={handleCloseMenu}/>*/}
        </div>
        <div className="link-content">
            <CustomSwitch checkedType={checkedType}
                          toggleType={toggleType}/>

            {checkedType && <Form className="link-types">
                <Form.Check
                    inline
                    name="type"
                    label={'Бессрочно'}
                    type={'radio'}
                    checked={!channelInfo}
                    onChange={(e) => toggleCheck({
                        name: 'participant',
                        value: e?.target?.checked,
                    })}
                />
                <Form.Check
                    inline
                    name="type"
                    label={`По дате (до ${changeDateFormat(startDate)})`}
                    type={'radio'}
                    checked={channelInfo}
                    onChange={(e) => toggleCheck({
                        name: 'all',
                        value: e?.target?.checked,
                    })}
                />
            </Form>}

            {
                checkedType && channelInfo && <div className={`calendar-wrapper`}>
                    <DatePickerDropdowns changeTime={changeTime}
                                         date={startDate}/>
                    <DatePicker
                        selected={startDate}
                        onChange={(date) => {
                            changeTime(date)
                        }}
                        startDate={startDate}
                        dateFormat="yyyy/MM/dd"
                        inline
                    />
                </div>
            }
        </div>


        <div className="buttons-wrapper">
            <button className={``}
                    onClick={()=>{
                        onCopy()
                        ShowSuccessToast()
                        onClose()
                    }}>
               <LinkIcon/> Копировать ссылку
            </button>
            {/*<button className={`save_btn ${disabledBtn ? 'disabled' : ''}`}*/}
            {/*        disabled={disabledBtn}*/}
            {/*        onClick={saveChanges}*/}
            {/*>Готово*/}
            {/*</button>*/}
        </div>
    </div>
}

function CustomSwitch({checkedType, toggleType}) {
    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
        setIsChecked(checkedType)
    }, [checkedType])

    return <Form className={'link-switch'}>
        <Form.Check
            type="switch"
            id="switch-one"
            disabled={!hasPermission('someone_else_share_link_update')}
            label={isChecked ? 'Публичная' : 'Непубличная'}
            checked={isChecked}
            onChange={(e) => {
                toggleType(e)
            }}
        />
    </Form>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [])
};

const mapDispatchToProps = {
    UpdateLink, ShowSuccessToast
};

export default connect(mapStateToProps, mapDispatchToProps)(LinkContent);
