import {initialState as videos} from "../reducer/videos";
import {initialState as utils} from "../reducer/utils";
import {initialState as auth} from "../reducer/auth";
import {initialState as persons} from "../reducer/persons";
import {initialState as protocol} from "../reducer/protocol";
import {initialState as channels} from "../reducer/channels";
import {initialState as users} from "../reducer/users";
import {initialState as profile} from "../reducer/profile";
import {initialState as tags} from "../reducer/tags";
import {initialState as highlights} from "../reducer/highlights";
import {initialState as userCalendars} from "../reducer/userCalendars";
import {initialState as roles} from "../reducer/roles";
import {initialState as link} from "../reducer/link";
import {initialState as notification} from "../reducer/notification";
import {initialState as statistics} from "../reducer/statistics";
import {initialState as prompts} from "../reducer/prompts";
import {initialState as analysisPipelines} from "../reducer/analysisPipelines";

const reducers = {
    videos,
    utils,
    auth,
    persons,
    protocol,
    users,
    channels,
    profile,
    tags,
    highlights,
    userCalendars,
    roles,
    link,
    notification,
    statistics,
    prompts,
    analysisPipelines
};
export default state => {
    const sData = {};
    Object.keys(reducers).forEach(selectorKey => {
        Object.keys(reducers[selectorKey]).forEach(key => {
            sData[key] = state[selectorKey][key];
        });
    });
    return sData;
};





