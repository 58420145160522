// Import packages
import React, {useEffect, useRef, useState} from 'react';

// Import assets
import './dropdown-menu.scss';
import {EllipsisIcon} from "../../../image";
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {connect} from "react-redux";
import Popup from "reactjs-popup";

function DropdownMenu(props) {
    const {
        children,
        button,
        closeAfterToggle = true,
        closeOnDocumentClick = true,
        className,
        contentClassName,
        closeDropdown,
        onClose,
        disabled,
        isOpen,
        contentWidth,
        zIndex,
        position = "bottom right",
        fullWidth = false
    } = props;

    const menuButtonRef = useRef()

    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (!closeDropdown) {
            hide()
        }
    }, [closeDropdown]);

    useEffect(() => {
        if (isOpen) {
            setOpen(isOpen)
        }
    }, [isOpen]);

    const hide = () => {
        setTimeout(() => {
            setOpen(false);
            onClose && onClose()
        }, 200)
    };

    return <div className={`menu-wrapper ${className ? className : ''}`}
                ref={menuButtonRef}>
        <Popup
            trigger={<div className={`menu-button ${open ? 'active' : ''}`}>
                {button ? button : <EllipsisIcon/>}
            </div>}
            position={position}
            on="click"
            open={open}
            closeOnDocumentClick={closeOnDocumentClick}
            contentStyle={{padding: '0px', border: 'none', zIndex: zIndex || 1}}
            arrow={false}
            disabled={disabled}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            className={contentClassName}
        >
            <div className={`dropdown-content ${contentClassName ? contentClassName : ''}`}
                 style={{
                     width: fullWidth ? menuButtonRef?.current?.offsetWidth : contentWidth
                 }}
                // onClick={(e) => !disabled &&  hide(e)}
                 onClick={() => {
                     closeAfterToggle && setOpen(false)
                 }}
            >
                {children}
            </div>
        </Popup>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [''])
};

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(DropdownMenu);
