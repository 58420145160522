// Import packages
import React, {useEffect, useRef, useState} from 'react';

// Import assets
import './texts.scss';

//Import Components
import TextItem from "./TextItem/TextItem";

function Texts({
                   texts,
                   getTime,
                   videoPlayerCurrentTime,
                   token,
                   searchText,
                   setFoundedTexts,
                   // AddProtocol,
                   videoId,
                   changeVideoTime,
                   videoPersons,
                   UpdateTranscription,
                   // lastProtocolId,
                   isAI,
                   MarkVideo,
                   ReplaceTranscriptionWord,
                   GetVideoById,
                   DeleteTranscription
               }) {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [scrolled, setScrolled] = useState(true)

    const textsContent = useRef();
    const currentIndex = useRef();

    // useEffect(() => {
    //
    // }, [videoPlayerCurrentTime])

    useEffect(() => {
        let list = []
        texts?.length && texts.forEach((item, index) => {
            if (videoPlayerCurrentTime > item.end_time) {
                currentIndex.current = index
            }
            const textArr = item?.text?.split(' ')
            textArr?.length && textArr.forEach(word => {
                if (searchText && word && word?.includes(searchText)) {
                    list.push(index)
                }
            })
        })
        setFoundedTexts && setFoundedTexts(list)

    }, [searchText, texts])

    useEffect(() => {
        // Moving vert scroll via endless cycle
        const delta = 20;
        let startTime = 0;
        let endTime = 0;

        texts?.length && texts.forEach((item, index) => {
            if (videoPlayerCurrentTime > item.end_time) {
                currentIndex.current = index
            }
        })

        if (videoPlayerCurrentTime > texts[currentIndex?.current || 0]?.end_time) {
            currentIndex.current = (currentIndex.current || 0) + 1
        }

        if (!texts?.[currentIndex?.current] || !texts[currentIndex?.current]?.start_time ||
            !texts[currentIndex?.current]?.end_time) {
            return;
        }

        startTime = parseFloat(texts[currentIndex?.current]?.start_time);
        endTime = parseFloat(texts[currentIndex?.current]?.end_time);

        let timeElem = document.querySelector(`.times_index_${currentIndex?.current}`);

        if ((startTime <= Math.ceil(videoPlayerCurrentTime)) && (Math.ceil(videoPlayerCurrentTime) <= endTime)) {
            // Get time elem
            if (!timeElem) {
                return;
            }
            scrolled && textsContent?.current && textsContent.current.scrollTo({
                top: timeElem?.offsetTop - delta,
            });
        }else {
            videoPlayerCurrentTime === 0 && scrolled && textsContent?.current && textsContent.current.scrollTo({
                top: 0,
            });
        }
    // }, [videoPlayerCurrentTime, isPlayed, texts, currentIndex])
    }, [videoPlayerCurrentTime, texts, currentIndex])

    const handleScroll = (e, position) => {
        const currentPosition = e?.target?.scrollTop;
        currentPosition && setScrollPosition(position ? position : currentPosition);
    };

   /* const toggleAddProtocol = (e, data) => {
        e.preventDefault()
        const {start_time, text, author_name, person_id, author} = data
        let reqData = {
            video_id: videoId,
            // start_time: parseInt(start_time) || 0,
            author_name,
            text,
            insert_after_protocol_block_id: lastProtocolId,
        }
        author !== 'unrecognized' && person_id && (reqData.person_id = person_id)
        author_name && (reqData.author_name = reqData.person_id ? null : author_name)
        start_time && (reqData.start_time = parseInt(start_time))
        AddProtocol(reqData).then(() => {
            MarkVideo(data)
        });
    }*/

    return (
        <div className={`texts ${isAI ? '' : 'custom-scroll'}`}
             id={'video_texts'}
             ref={textsContent}
             onScroll={handleScroll}
             onMouseOver={(e) => {
                 e.stopPropagation()
                 setScrolled(false)
             }}
             onMouseLeave={(e) => {
                 e.stopPropagation()
                 setScrolled(true)
             }}>
            {
                !!texts?.length &&
                texts.map((item, index) => {
                    const isLastItem = texts?.length - 1 === index;
                    const currentItem = item?.start_time <= videoPlayerCurrentTime &&
                        (isLastItem ? item?.end_time >= videoPlayerCurrentTime : item?.end_time > videoPlayerCurrentTime);

                    return <TextItem key={item?.id}
                                     item={item}
                                     searchText={searchText}
                                     index={index}
                                     token={token}
                                     videoId={videoId}
                                     scrollPosition={scrollPosition}
                                     currentItem={currentItem}
                                     videoPersons={videoPersons}
                                     getTime={getTime}
                                     GetVideoById={GetVideoById}
                                     handleScroll={handleScroll}
                                     UpdateTranscription={UpdateTranscription}
                                     // toggleAddProtocol={toggleAddProtocol}
                                     ReplaceTranscriptionWord={ReplaceTranscriptionWord}
                                     changeVideoTime={changeVideoTime}
                                     MarkVideo={MarkVideo}
                                     DeleteTranscription={DeleteTranscription}
                    />
                })
            }
        </div>
    );
}

export default Texts;
