import {_urlUsers, _urlUsersPersons, request} from "../api";
import {USERS_CONSTS} from "../constants";

export const GetUsers = () => {
    let requestData = {
        url: `${_urlUsers}`,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;

                await dispatch({
                    type: USERS_CONSTS.GET_USERS,
                    payload: data
                })
            })
    };
};

export const SearchUsers = (text) => {
    let requestData = {
        url: `${_urlUsers}/search?q=${text}`,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;

                await dispatch({
                    type: USERS_CONSTS.SEARCH_USERS,
                    payload: data
                })
            })
    };
};

export const ClearUsersSearch = () => {
    return async dispatch => {
        await dispatch({
            type: USERS_CONSTS.SEARCH_USERS_CLEAR
        })
    };
};

export const GetUserById = (id) => {
    let requestData = {
        url: `${_urlUsers}/${id}`,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;

                await dispatch({
                    type: USERS_CONSTS.GET_USER_BY_ID,
                    payload: data
                })
            })
    };
};

export const GetUsersPersons = ({
                                    offset = 0,
                                    limit = 10,
                                    filter = 'users',
                                    person_status = undefined,
                                    role_id = undefined,
                                    name_like = undefined,
                                    tag_ids = undefined,
                                    role_ids = undefined,
                                    company_ids = undefined,
                                    positions = undefined,
                                    sort_by = undefined,
                                } = {}) => {
    let requestData = {
        url: `${_urlUsersPersons}?offset=${offset}&limit=${limit}&filter=${filter}`,
        method: "GET",
    };
    person_status && (requestData.url = requestData.url + `&person_status=${person_status}`);
    role_id && (requestData.url = requestData.url + `&role_id=${role_id}`);
    name_like && (requestData.url = requestData.url + `&name_like=${name_like}`);
    tag_ids && (requestData.url = requestData.url + `&tag_ids=${tag_ids}`);
    role_ids && (requestData.url = requestData.url + `&role_ids=${role_ids}`);
    company_ids && (requestData.url = requestData.url + `&company_ids=${company_ids}`);
    positions && (requestData.url = requestData.url + `&positions=${positions}`);
    sort_by && (requestData.url = requestData.url + `&sort_by=${sort_by}`)
    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;

                await dispatch({
                    type: USERS_CONSTS.GET_USERS_PERSONS,
                    payload: data
                })
            })
    };
};
export const GetUserPositions = () => {
    let requestData = {
        url: `${_urlUsersPersons}/positions`,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;

                await dispatch({
                    type: USERS_CONSTS.GET_USERS_POSITIONS,
                    payload: data
                })
            })
    };
};

export const ClearUsers = () => {
    return async dispatch => {
        await dispatch({
            type: USERS_CONSTS.CLEAR_USERS
        })
    };
};

export const ClearUserById = () => {
    return async dispatch => {
        await dispatch({
            type: USERS_CONSTS.CLEAR_USER_BY_ID
        })
    };
};

export const AddUser = (data) => {
    let requestData = {
        url: `${_urlUsers}`,
        method: "POST",
        data,
    };

    return (dispatch) => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: USERS_CONSTS.ADD_USER,
                    payload: data
                });
            })
    };
};

export const UpdateUser = (id, data) => {
    const requestData = {
        url: `${_urlUsers}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: USERS_CONSTS.UPDATE_USER,
                    payload: {
                        id, data
                    }
                });
            })
            .catch((err) => {
                console.log(err, 'ERROR');
            })

    }
};

export const DeleteUser = id => {
    const requestData = {
        url: `${_urlUsers}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: USERS_CONSTS.DELETE_USER,
                    payload: id
                });
            })
            .catch(() => {
            })
    }
};

