import React, {useEffect, useState} from "react";
import {StopIcon} from "../../../image";

const Timer = () => {
    useEffect(() => {
        startTimer()
    }, [])

    // The state for our timer
    const [timer, setTimer] = useState({
        s: "00",
        m: "00",
        h: "00"
    });
    let start = 0;
    let intrvl = undefined;

    const getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / 1000 / 60 / 60) % 24);
        return {
            total, hours, minutes, seconds
        };
    }


    const startTimer = () => {
        if (start === 1) return;

        start = 1;
        let ss = 0,
            mm = 0,
            hh = 0;
        intrvl = setInterval(() => {
            ss++;
            if (ss === 60) {
                ss = 0;
                mm++;
            }
            if (mm === 60) {
                mm = 0;
                hh++;
            }
            setTimer({
                s: setZeros(ss),
                m: setZeros(mm),
                h: setZeros(hh)
            });
        }, 1000);
    }

    const stopTimer = () => {
        if (start === 0) return;
        start = 0;
        setTimer({
            s: "00",
            m: "00",
            h: "00"
        });
        clearInterval(intrvl);
    };

    const setZeros = (i) => {
        if (i < 10) return "0" + i;
        return i;
    };

    return <span className="count_timer">
        <StopIcon/>
        {timer.h + ":" + timer.m + ":" + timer.s}
    </span>
}

export default Timer;