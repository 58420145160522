import {_urlPersons, request} from "../api";
import {PERSONS_CONSTS} from "../constants";

export const GetPersons = ({
                               offset = 0,
                               limit = 10,
                               status = undefined,
                           } = {}) => {
    let requestData = {
        url: `${_urlPersons}?offset=${offset}&limit=${limit}`,
        method: "GET",
    };
    status && (requestData.url = requestData.url + `&status=${status}`);

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;
                await dispatch({
                    type: PERSONS_CONSTS.GET_PERSONS,
                    payload: data
                })
            })
    };
};
export const GetNewPersons = ({
                                  offset = 0,
                                  limit = 10,
                                  status = undefined,
                              } = {}) => {
    let requestData = {
        url: `${_urlPersons}?offset=${offset}&limit=${limit}`,
        method: "GET",
    };
    status && (requestData.url = requestData.url + `&status=${status}`);

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;

                await dispatch({
                    type: PERSONS_CONSTS.GET_NEW_PERSONS,
                    payload: data
                })
            })
    };
};

export const ClearPersons = () => {
    return async dispatch => {
        await dispatch({
            type: PERSONS_CONSTS.CLEAR_PERSONS
        })
    };
};

export const GetPersonById = (id, setData) => {
    let requestData = {
        url: `${_urlPersons}/${id}`,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;
                if (setData) {
                    return data
                } else {
                    await dispatch({
                        type: PERSONS_CONSTS.GET_PERSON_BY_ID,
                        payload: data
                    })
                }
            })
    };
};

export const ClearPersonById = () => {
    return async dispatch => {
        await dispatch({
            type: PERSONS_CONSTS.CLEAR_PERSONS_BY_ID
        })
    };
};

export const UpdatePersons = (id, data, user) => {
    let requestData = {
        url: `${_urlPersons}/${id}`,
        method: "PATCH",
        // customHeaders: {
        //     'Content-Type': 'multipart/form-data'
        // },
        data
    };
    return dispatch => {
        return request(requestData)
            .then(async () => {
                await dispatch({
                    type: PERSONS_CONSTS.UPDATE_PERSONS,
                    payload: {id, user, data}
                })
            })
    };
};

export const UpdatePersonTags = (id, data, tagsList) => {
    let requestData = {
        url: `${_urlPersons}/${id}/change_tags`,
        method: "PATCH",
        data
    };

    return dispatch => {
        return request(requestData)
            .then(async () => {
                await dispatch({
                    type: PERSONS_CONSTS.UPDATE_PERSON_TAGS,
                    payload: {id, data, tagsList}
                })
            })
    };
};
/*export const DeletePersonTags = (id, tagId, data) => {
    let requestData = {
        url: `${_urlPersons}/${id}/change_tags`,
        method: "PATCH",
        data
    };

    return dispatch => {
        return request(requestData)
            .then(async () => {
                await dispatch({
                    type: PERSONS_CONSTS.DELETE_PERSON_TAGS,
                    payload: tagId
                })
            })
    };
};*/
/*export const UpdateNewPersons = (id, data, user) => {
    let requestData = {
        url: `${_urlPersons}/${id}`,
        method: "PATCH",
        // customHeaders: {
        //     'Content-Type': 'multipart/form-data'
        // },
        data
    };

    return dispatch => {
        return request(requestData)
            .then(async () => {
                await dispatch({
                    type: PERSONS_CONSTS.UPDATE_NEW_PERSONS,
                    payload: {id, user}
                })
            })
    };
};*/

export const CombinePersons = (id, data) => {
    let requestData = {
        url: `${_urlPersons}/${id}/combine`,
        method: "POST",
        data,
    };
    return () => {
        return request(requestData)
        .then(async (res) => {
            console.log(res, 'RES');
            console.log(data, 'DATE');
            return data
        })
    };
};
export const UserAttach = (person_id, data) => {
    let requestData = {
        url: `${_urlPersons}/${person_id}/attach`,
        method: "POST",
        data
    };

    return () => {
        return request(requestData)
            .then(async () => {
                return data
            })
    };
};



