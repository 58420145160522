import {NOTIFICATIONS_CONSTS} from "../constants";

export const initialState = {
    notificationsList: [],
    notificationsCount: 0,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case NOTIFICATIONS_CONSTS.GET_NOTIFICATION:
            return {
                ...state,
                notificationsList: action.payload,
            };
        case NOTIFICATIONS_CONSTS.GET_NOTIFICATION_COUNT:
            return {
                ...state,
                notificationsCount: action.payload?.new_count,
            };
        case NOTIFICATIONS_CONSTS.SEE_ALL_NOTIFICATIONS:
            return {
                ...state,
                notificationsCount: 0,
                notificationsList: state.notificationsList?.map(item => {
                    return {
                        ...item,
                        is_new: false
                    }
                }),
            };
        case NOTIFICATIONS_CONSTS.UPDATE_NOTIFICATION:
            return {
                ...state,
                notificationsList: state.notificationsList?.map(item => {
                    return action.payload?.id === item?.id ? {
                        ...item,
                        is_new: false
                    } : item
                }),
                notificationsCount: state.notificationsCount > 1 ? state.notificationsCount - 1 : 0
            };
        default:
            return state;
    }
}
