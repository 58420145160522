// Import packages
import React from 'react';
import {connect} from "react-redux";

// Import assets
import './profile-contacts.scss';
import {ArrowDownIcon, DeletingIcon, InfoCheckIcon, PlusIcon} from "../../../../../image";

// Import Components
import {Accordion} from "react-bootstrap";

// Import Utils
import {getPropsFromState} from "../../../../../redux/mapStateToProps";
import {useTranslation} from "react-i18next";
import InfoTooltip from "../tooltip";

function ProfileContacts(props) {
    const {
        contactItems, setContactItems, haveChanges, setHaveChanges, userProfile, errors, setErrors, personById
    } = props;

    const {t} = useTranslation();

    function openContactItem(key) {
        if (contactItems[key].length < 2) {
            setContactItems({
                ...contactItems,
                [key]: [...contactItems[key], '']
            })
        }
    }

    function deleteContactItem(key, index) {
        setContactItems({
            ...contactItems,
            [key]: contactItems[key].filter((_, i) => i !== index)
        })

        const changes = new Map(haveChanges);
        if (contactItems[key]?.length === personById?.[key]?.length) {
            changes.set(key, index)
        } else {
            changes.delete(key)
        }
        setHaveChanges(changes)
    }

    function getContactValue(e, index) {
        const {name, value} = e.target
        let val = value

        if (name.includes('phone')) {
            val = val?.replaceAll('+', '')
            val = '+' + val
        }

        if (name.includes('linkedin')) {
            val = val?.replaceAll('https://www.', '')
            val = val.startsWith('https://www') ? 'https://www.' : 'https://www.' + val
            // val = (!val || val?.startsWith("https://www.")) ? val : ("https://www." + val)
        }
        if (name.includes('facebook')) {
            val = val?.replaceAll('https://', '')
            val = val.startsWith('https:/') ? 'https://' : 'https://' + val
        }

        const phoneRegExp = /^[+\d]\d*$/;
        if (name.includes('phone') && val && !phoneRegExp.test(val)) {
            return;
        }
        setContactItems({
            ...contactItems,
            [name]: contactItems[name].map((item, i) => {
                return index === i ? val : item
            })
        })

        setErrors({
            ...errors, [name]: errors[name]?.map((mail, i) => {
                return i === index ? undefined : mail
            })
        })
        const changes = new Map(haveChanges);
        if (!personById[name]?.find(i => i === (value || undefined))) {
            changes.set(`${name}_${index}`, value)
        } else {
            changes.delete(`${name}_${index}`)
        }
        setHaveChanges(changes)
    }

   /* function focusContactValue(e, index) {
        const {name, value} = e.target;
        let val = value;

        if (name.includes('phone')) {
            val = val?.replaceAll('+', '')
            val = '+' + val
        }

        if (name.includes('linkedin') || name.includes('facebook')) {
            if (!val.startsWith("https://")) {
                return "https://" + val;
            }
        }

        setContactItems({
            ...contactItems,
            [name]: contactItems[name].map((item, i) => {
                return index === i ? val : item
            })
        })
    }*/


    let hasContact = Object.values(contactItems).some(item => item.length > 0 && (item?.[0]?.length > 0)) ? '0' : ''

    return <Accordion defaultActiveKey={'0'} className={'contacts-wrapper'}>
        <Accordion.Item eventKey={''} className={'profile-page__contacts'}>
            <Accordion.Header
                className={`contact-header`}>
                <ArrowDownIcon className={`arrow-icon`}/>
                {t('profile_contacts')}
                {
                    !hasContact ? <InfoTooltip title={t('tooltip_contact_title')}
                                               description={t('tooltip_contact_description')}/>
                        : <InfoCheckIcon/>
                }
            </Accordion.Header>
            <Accordion.Body>
                <div className="contact-item">
                    {!!contactItems?.email?.length && contactItems?.email?.map((item, index) => {
                        return <div className="input_group" key={index}>
                            <label htmlFor="">{t('input_email')}</label>
                            <input type="text"
                                   value={contactItems?.email[index]}
                                   name={'email'}
                                   disabled={!userProfile?.attached_person?.id && !personById?.id}
                                   onChange={(e) => getContactValue(e, index)}
                                   placeholder={t('input_email')}
                            />
                            <DeletingIcon onClick={() => deleteContactItem('email', index)}
                                          className="input_group__delete"/>
                            {!!errors.email?.[index] && (
                                <div className="input_group__error">{errors.email?.[index]}</div>
                            )}
                        </div>
                    })
                    }
                    {contactItems?.email?.length < 2 && <button className="add-contact"
                                                                onClick={() => openContactItem('email')}>
                        <PlusIcon/>
                        {t('profile_add_email')}
                    </button>}
                </div>
                <div className="contact-item">

                    {!!contactItems?.phone_number?.length && contactItems?.phone_number?.map((item, index) => {
                        return <div className="input_group" key={index}>
                            <label htmlFor="">{t('input_phone')}</label>
                            <input type="text"
                                   value={contactItems?.phone_number[index]}
                                   name={'phone_number'}
                                   disabled={!userProfile?.attached_person?.id && !personById?.id}
                                   onChange={(e) => getContactValue(e, index)}
                                   placeholder={'+0 (000) 000-00-00'}
                            />
                            <DeletingIcon onClick={() => deleteContactItem('phone_number', index)}
                                          className="input_group__delete"/>
                            {!!errors.phone_number?.[index] && (
                                <div className="input_group__error">{errors.phone_number?.[index]}</div>
                            )}
                        </div>
                    })
                    }
                    {contactItems?.phone_number?.length < 2 && <button className="add-contact"
                                                                       onClick={() => openContactItem('phone_number')}>
                        <PlusIcon/>{t('profile_add_phone')}
                    </button>}
                </div>

                {/*{
                    !isMax && <>
                        <div className="contact-item">
                            {!!contactItems?.linkedin?.length && contactItems?.linkedin?.map((item, index) => {
                                return <div className="input_group" key={index}>
                                    <label htmlFor="">{t('input_linkedin')}</label>
                                    <input type="text"
                                           value={contactItems?.linkedin[index]}
                                           name={'linkedin'}
                                           disabled={!userProfile?.attached_person?.id && !personById?.id}
                                           onChange={(e) => getContactValue(e, index)}
                                           onFocus={(e) => focusContactValue(e, index)}
                                           placeholder={'https://www.'}
                                    />
                                    <DeletingIcon onClick={() => deleteContactItem('linkedin', index)}
                                                  className="input_group__delete"/>
                                    {!!errors.linkedin?.[index] && (
                                        <div className="input_group__error">{errors.linkedin?.[index]}</div>
                                    )}
                                </div>
                            })
                            }
                            {contactItems?.linkedin?.length < 2 && <button className="add-contact"
                                                                           onClick={() => openContactItem('linkedin')}>
                                <PlusIcon/> {t('profile_add_link')}
                            </button>}
                        </div>
                        <div className="contact-item">
                            {!!contactItems?.facebook?.length && contactItems?.facebook?.map((item, index) => {
                                return <div className="input_group" key={index}>
                                    <label htmlFor="">{t('input_facebook')}</label>
                                    <input type="text"
                                           value={contactItems?.facebook[index]}
                                           name={'facebook'}
                                           disabled={!userProfile?.attached_person?.id && !personById?.id}
                                           onChange={(e) => getContactValue(e, index)}
                                           placeholder={'https://'}
                                    />
                                    <DeletingIcon onClick={() => deleteContactItem('facebook', index)}
                                                  className="input_group__delete"/>
                                    {!!errors.facebook?.[index] && (
                                        <div className="input_group__error">{errors.facebook?.[index]}</div>
                                    )}
                                </div>
                            })
                            }
                            {contactItems?.facebook?.length < 2 && <button className="add-contact"
                                                                           onClick={() => openContactItem('facebook')}>
                                <PlusIcon/> {t('profile_add_link')}
                            </button>}
                        </div>
                    </>
                }*/}
            </Accordion.Body>
        </Accordion.Item>
    </Accordion>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'userProfile',
    ])
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileContacts);
