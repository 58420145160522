import {_urlServiceStatuses, _urlShare, _urlVideos, request} from "../api";
import {VIDEOS_CONSTS} from "../constants";
import {history} from "../../utils/history";
import {REQUEST_LIMIT} from "../../utils/constants";

export const UpdateVideoChannel = (id, data) => {
    const requestData = {
        url: `${_urlVideos}/${id}/change_channels`,
        token: true,
        method: "PATCH",
        data,
    };

    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: VIDEOS_CONSTS.UPDATE_VIDEO_CHANNEL,
                    payload: {id, channels: data.channels}
                });
            })

    }
};


export const GetVideos = ({
                              offset = 0,
                              limit = REQUEST_LIMIT,
                              filters = [],
                              returnList = false,
                              manage = false,
                          } = {}) => {
    let requestData = {
        url: `${_urlVideos}?offset=${offset}&limit=${limit}&manage=${manage}`,
        method: "POST",
        data: {
            params: {
                filters: JSON.stringify(filters)
            }
        }
    };

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;
                if (!returnList) {
                    await dispatch({
                        type: VIDEOS_CONSTS.GET_VIDEOS,
                        payload: data
                    })
                } else {
                    return data
                }
            })
    };
};
export const GetVideoList = ({
                                 offset = 0,
                                 limit = REQUEST_LIMIT,
                                 channel_id = undefined,
                                 sort_by = undefined,
                                 returnList = false,
                                 manage = false,
                                 description_like = false,
                                 date_start = false,
                                 date_end = false,
                                 owner_name_like = false,
                                 statuses = false,
                                 share_link_types = false,
                                 channel_ids = false,
                                 meeting_participant_nickname_like = false,
                                 isInfinite = false,
                             } = {}) => {
    let requestData = {
        url: `${_urlVideos}?offset=${offset}&limit=${limit}&manage=${manage}`,
        method: "GET",
    };
    channel_id && (requestData.url = requestData.url + `&channel_id=${channel_id}`)
    sort_by && (requestData.url = requestData.url + `&sort_by=${sort_by}`)
    description_like && (requestData.url = requestData.url + `&description_like=${description_like}`)
    date_start && (requestData.url = requestData.url + `&date_start=${date_start?.toISOString()?.substring(0, 10)}`)
    date_end && (requestData.url = requestData.url + `&date_end=${date_end?.toISOString()?.substring(0, 10)}`)
    owner_name_like && (requestData.url = requestData.url + `&owner_name_like=${owner_name_like}`)
    statuses && (requestData.url = requestData.url + `&statuses=${statuses}`)
    share_link_types && (requestData.url = requestData.url + `&share_link_types=${share_link_types}`)
    channel_ids && (requestData.url = requestData.url + `&channel_ids=${channel_ids}`)
    meeting_participant_nickname_like && (requestData.url = requestData.url + `&meeting_participant_nickname_like=${meeting_participant_nickname_like}`)

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;
                // console.log('data?.length === limit', data?.results?.length , data?.results?.length === limit);
                if (!returnList) {
                    await dispatch({
                        type: VIDEOS_CONSTS.GET_VIDEOS,
                        payload: {
                            data,
                            isInfinite,
                            hasMore: data?.results?.length === limit
                        }
                    })
                } else {
                    return data
                }
            })
    };
};

export const DeleteVideo = id => {
    const requestData = {
        url: `${_urlVideos}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: VIDEOS_CONSTS.DELETE_VIDEOS,
                    payload: id
                });
            })
            .catch(() => {
            })
    }
};

export const ReprocessVideo = id => {
    const requestData = {
        url: `${_urlVideos}/${id}/transcript_reprocess`,
        token: true,
        method: "POST",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: VIDEOS_CONSTS.REPROCESS_VIDEOS,
                    payload: id
                });
            }).then(() => {
                console.log('THEN');
            })
            .catch((error) => {
                console.log('CATCH', error);
            })
    }
};

export const ChangeReprocessStatus = id => {
    return dispatch => {
        dispatch({
            type: VIDEOS_CONSTS.CHANGE_REPROCESS_STATUS,
            payload: id
        });
    }
};

export const GetVideoById = (id, uuid, location, returnData = true) => {
    let requestData = {
        url: `${_urlVideos}/${id}`,
        method: "GET",
        location
    };
    uuid && (requestData.url = requestData.url + `?uuid=${uuid}`)
    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;
                if (returnData) {
                    await dispatch({
                        type: VIDEOS_CONSTS.GET_VIDEO_BY_ID,
                        payload: data
                    })
                }
                !data && history.push("/")
                return data
            })
            .catch((error) => {
                console.log('error', error);
                dispatch({
                    type: VIDEOS_CONSTS.VIDEO_ERROR,
                    // payload: error
                });
                // return error
                // history.push("/");
            })
    };
};

export const ClearVideoList = () => {
    return async dispatch => {
        await dispatch({
            type: VIDEOS_CONSTS.CLEAR_VIDEOS
        })
    };
};

export const ClearVideoById = () => {
    return async dispatch => {
        await dispatch({
            type: VIDEOS_CONSTS.CLEAR_VIDEO_BY_ID
        })
    };
};

export const GetVideosSearch = (data, returnData) => {
    let requestData = {
        url: `${_urlVideos}/search`,
        method: "POST",
        data
    };
    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;
                if (returnData) {
                    return data
                } else {
                    await dispatch({
                        type: VIDEOS_CONSTS.GET_VIDEOS_SEARCH,
                        payload: data
                    })
                }
            })
    };
};

export const ClearVideosSearch = () => {
    return async dispatch => {
        await dispatch({
            type: VIDEOS_CONSTS.CLEAR_VIDEOS_SEARCH
        })
    };
};

export const AddVideoDescription = (video_id, description) => {
    let requestData = {
        url: `${_urlVideos}/${video_id}/description`,
        method: "PATCH",
        data: {
            description
        }
    };

    return dispatch => {
        return request(requestData)
            .then(async () => {
                await dispatch({
                    type: VIDEOS_CONSTS.ADD_VIDEO_DESCRIPTION,
                    payload: {
                        video_id,
                        description
                    }
                })
            })
    };
};


export const CreateVideoUrl = (data) => {
    let requestData = {
        url: `${_urlShare}`,
        method: "POST",
        data,
    };

    return () => {
        return request(requestData)
            .then(async (res) => {
                console.log('res', res.data);
                return res.data
            })
    };
};


export const GetServiceStatuses = () => {
    let requestData = {
        url: `${_urlServiceStatuses}`,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;

                await dispatch({
                    type: VIDEOS_CONSTS.GET_SERVICE_STATUSES,
                    payload: data
                })
            })
    };
};

export const VideoUrlRecord = (data) => {
    let requestData = {
        url: `${_urlVideos}/record`,
        method: "POST",
        data,
    };

    return () => {
        return request(requestData)
            .then(async (res) => {
                // console.log('res', res.data);
                return res.data
            })
    };
};

export const MarkVideo = (data) => {
    return async dispatch => {
        await dispatch({
            type: VIDEOS_CONSTS.MARK_VIDEO,
            payload: data
        })
    };
};

export const UpdateVideo = (video_id, data) => {
    let requestData = {
        url: `${_urlVideos}/${video_id}`,
        method: "PATCH",
        data
    };

    return dispatch => {
        return request(requestData)
            .then(async () => {
                await dispatch({
                    type: VIDEOS_CONSTS.ADD_VIDEO_DESCRIPTION,
                    payload: {
                        video_id,
                        data
                    }
                })
            })
    };
};

// export const UnmarkVideo = (data) => {
//     return async dispatch => {
//         await dispatch({
//             type: VIDEOS_CONSTS.UNMARK_VIDEO,
//             payload: data
//         })
//     };
// };

export const DetectProvider = (data) => {
    const requestData = {
        url: `${_urlVideos}/detect_provider`,
        token: true,
        method: "POST",
        data
    };
    return () => {
        return request(requestData)
            .then((res) => {
                return res
                // dispatch({
                //     type: VIDEOS_CONSTS.REPROCESS_VIDEOS,
                //     payload: id
                // });
            })
    }
};
