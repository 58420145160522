import {UTIL_CONSTS} from "../constants/utilConsts";

export const initialState = {
    isMobile: false,
    isFullWidth: true,
    headerTitle: '',
    eventsColor: {},
    showErrorToast: false,
    showSuccessToast: false,
    gridView: true,
    showMenu: true,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case UTIL_CONSTS.SET_MOBILE:
            return {
                ...state,
                isMobile: action.payload,
            };
        case UTIL_CONSTS.SHOW_MENU:
            return {
                ...state,
                showMenu: action.payload,
            };
        case UTIL_CONSTS.SET_FULL_WIDTH:
            return {
                ...state,
                isFullWidth: action.payload,
            };
        case UTIL_CONSTS.CHANGE_HEADER_TITLE:
            return {
                ...state,
                headerTitle: action.payload,
            };
        case UTIL_CONSTS.EVENT_COLORS:
            return {
                ...state,
                eventsColor: {
                    id: action.payload.id,
                    colors: action.payload.data,
                },
            };
        case UTIL_CONSTS.SHOW_ERROR_TOAST:
            return {
                ...state,
                showErrorToast: true,
            };
        case UTIL_CONSTS.HIDE_ERROR_TOAST:
            return {
                ...state,
                showErrorToast: false,
            };
        case UTIL_CONSTS.SHOW_SUCCESS_TOAST:
            return {
                ...state,
                showSuccessToast: true,
            };
        case UTIL_CONSTS.HIDE_SUCCESS_TOAST:
            return {
                ...state,
                showSuccessToast: false,
            };
        case UTIL_CONSTS.CHANGE_GRID_VIEW:
            return {
                ...state,
                gridView: action.payload,
            };
        default:
            return state;
    }
}
