// Import packages
import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

// Import assets
import './roles.scss';
import {PlusGreenIcon} from "../../../../image";

// Import Components
import AddRole from "./modals/addRole/AddRole";
import ListItem from "./listItem/ListItem";

// Import Utils
import {getPropsFromState} from "../../../../redux/mapStateToProps";
import UserInfoModal from "../Users/modals/userInfoModal/UserInfoModal";
import {useTranslation} from "react-i18next";
import {ChangeHeaderTitle, ClearRoles, DeleteRole, GetRoleById, GetRoles} from "../../../../redux/actions";
import CustomToast from "../../../../shared/features/CustomToast/CustomToast";

function Roles(props) {
    const [showPersonsModal, setShowPersonsModal] = useState(false)
    const [showAddRoleModal, setShowAddRoleModal] = useState(false)
    const [currentUser, setCurrentUser] = useState(null)
    const [editedRole, setEditedRole] = useState(undefined)

    const {rolesList} = props

    const {t} = useTranslation();

    useEffect(() => {
        props.ChangeHeaderTitle(t('roles_page_title'))
        !rolesList?.length &&  props.GetRoles()
    }, [])

    function openModal() {
        setShowPersonsModal(true)
    }

    function closeModal() {
        setShowPersonsModal(false)
        setCurrentUser(null)
    }

    function openAddRoleModal() {
        setShowAddRoleModal(true)
    }

    function closeAddRoleModal() {
        setShowAddRoleModal(false)
    }

    return (
        <div className={`main-container roles`}>

            <div className="page-title" style={{padding: '20px 0 0'}}>{t('roles_page_title')}</div>
            <div className="roles__header">
                <div className="roles__header__right">
                    <div className="roles__title">{t('roles_list_title')}</div>
                </div>
                {props?.userRoles?.permissions?.find(i => i.name === 'roles_create') &&
                    <button className="roles__header__add-user" onClick={openAddRoleModal}>
                    <PlusGreenIcon/>
                    Добавить роль
                </button>}
            </div>

            <div className="roles__list-header">
                <div className="roles__list-header__content">
                    <div className="item_col">{t('table_role')}</div>
                  <div className="item_col">{t('table_ldap')}</div>
                    <div className="item_col">{t('table_persons')}</div>
                </div>
            </div>
            {
                !!rolesList?.length && rolesList?.map(item => {
                    return <ListItem key={`${item.person_id}_${item.user_id}`}
                                     data={item}
                                     openModal={openModal}
                                     setShowAddRoleModal={setShowAddRoleModal}
                                     DeleteRole={props.DeleteRole}
                                     setEditedRole={setEditedRole}
                    />
                })
            }

            {showPersonsModal && <UserInfoModal show={showPersonsModal}
                                                closeModal={closeModal}
                                                user={currentUser}
                                                videoPersonsList={props?.usersPersonsList?.results}/>}
            {showAddRoleModal && <AddRole show={showAddRoleModal}
                                          editedRole={editedRole}
                                          setEditedRole={setEditedRole}
                                          closeModal={closeAddRoleModal}
                                          GetRoleById={props.GetRoleById}
                                          roleById={props.roleById}
            />}
            <CustomToast/>
        </div>
    );
}


const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'personsList',
        'usersList',
        'rolesList',
        'roleById',
        'isFullWidth',
        'userRoles',
        'headerTitle'
    ])
};

const mapDispatchToProps = {
    GetRoles, ChangeHeaderTitle, ClearRoles, DeleteRole, GetRoleById
};

export default connect(mapStateToProps, mapDispatchToProps)(Roles);
