// Import packages
import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {Link, useParams} from "react-router-dom";

// Import assets
import './search-user-page.scss';

// Import Components
import {Breadcrumb} from "react-bootstrap";

// Import Utils
import {getPropsFromState} from "../../../../redux/mapStateToProps";
import {
    GetVideosSearch,
    GetPersons,
    ClearVideosSearch
} from "../../../../redux/actions";
import {useTranslation} from "react-i18next";
import ListItem from "../Users/persons/listItem/ListItem";
import UserInfoModal from "../Users/modals/userInfoModal/UserInfoModal";

function SearchUserPage(props) {
    const {searchVideos} = props;
    const [showPersonsModal, setShowPersonsModal] = useState(false)
    const [currentUser, setCurrentUser] = useState(null)
    const [list, setList] = useState(undefined);

    const params = useParams();
    const {t} = useTranslation();

    useEffect(() => {
        props.GetVideosSearch({
            search_query: params?.text
        }, true)
            .then((res)=>{
                setList(res?.[params?.type])
            })

        return ()=>{
            props.ClearVideosSearch()
        }
    }, [params?.text])

    function openModal() {
        setShowPersonsModal(true)
    }
    function closeModal() {
        setShowPersonsModal(false)
        setCurrentUser(null)
        props.GetVideosSearch({
            search_query: params?.text
        })
    }

    return <div className={`main-container search-container ${props.isFullWidth ? '' : 'full'}`}>
        <div className="search-page">
            <div className="search-page-header">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to={'/dashboard/videos/review'}>{t('menu_item_video')}</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={'/dashboard/videos/review'}>{t('menu_item_review')}</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <a>Результаты поиска</a>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        <div className="search-page__name">
                            Видео по запросу “{params?.text}”
                        </div>
                    </Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className="persons__list-header">
                <div className="persons__list-header__content">
                    <div className="user_info item_col">{t('table_name')}</div>
                    <div className="item_col">{t('table_tags')}</div>
                    <div className="item_col">{t('table_role')}</div>
                    <div className="item_col">{t('table_company')}</div>
                    <div className="item_col">{t('table_position')}</div>
                </div>
                <div className="persons__list-header__actions">
                    <div className="video_count item_col">{t('table_video')}</div>
                </div>
            </div>
            {
                !!list?.length && list?.map((item, index) => {
                    return <ListItem key={`${item.person_id}_${item.user_id}`}
                                     data={item}
                                     index={index}
                                     openModal={openModal}
                                     rolesList={props.rolesList}
                                     // badge={filterList?.find(i => i.id === selectedFilter)?.name}
                                     setCurrentUser={setCurrentUser}/>
                })
            }
        </div>
        {showPersonsModal && <UserInfoModal show={showPersonsModal}
                                            closeModal={closeModal}
                                            user={currentUser}
                                            videoPersonsList={searchVideos?.[params?.type]}/>}
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'channels',
        'isFullWidth',
        'searchVideos',
    ])
};

const mapDispatchToProps = {
    GetVideosSearch,
    GetPersons,
    ClearVideosSearch
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchUserPage);
