// Import packages
import React from 'react';
import {Toast} from "react-bootstrap";
import {ErrorIcon, SuccessIcon} from "../../../image";
import {useTranslation} from "react-i18next";
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {connect} from "react-redux";
import './custom-toast.scss'
import {HideErrorToast, HideSuccessToast} from "../../../redux/actions";

function CustomToast(props) {
    const {showErrorToast,showSuccessToast, description, HideErrorToast, HideSuccessToast} = props;
    const {t} = useTranslation();

    function toggleToast() {
        showErrorToast && HideErrorToast()
        showSuccessToast && HideSuccessToast()
    }

    console.log('showSuccessToast', showSuccessToast);
    return <>
        {
            showErrorToast && <Toast show={showErrorToast}
                                     onClose={toggleToast}
                                     className={'custom_toast'}
                                     autohide={true}
                                     delay={3000}
            >
                <ErrorIcon/>
                <div className="toast-content">
                    <strong className="title">{t('toast_error_title')}</strong>
                    {description && <p className={'description'}>{description}</p>}
                </div>
            </Toast>
        }
        {
            showSuccessToast && <Toast show={showSuccessToast}
                                       onClose={toggleToast}
                                       className={'custom_toast'}
                                       autohide={true}
                                       delay={3000}>
                <SuccessIcon/>
                <div className="toast-content">
                    <strong className="title">{t('toast_success_title')}</strong>
                    {description && <p className={'description'}>{description}</p>}
                </div>
            </Toast>
        }
    </>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, ['showErrorToast', 'showSuccessToast'])
};

const mapDispatchToProps = {HideErrorToast, HideSuccessToast};

export default connect(mapStateToProps, mapDispatchToProps)(CustomToast);

