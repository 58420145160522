import {ANALYSIS_PIPELINES_CONSTS} from "../constants";

export const initialState = {
    analysisPipelinesList: [],
    languageModels: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ANALYSIS_PIPELINES_CONSTS.GET_LANGUAGE_MODELS:
            return {
                ...state,
                languageModels: action.payload,
            };
        case ANALYSIS_PIPELINES_CONSTS.GET_ANALYSIS_PIPELINES:
            return {
                ...state,
                analysisPipelinesList: action.payload,
            };
        case ANALYSIS_PIPELINES_CONSTS.ADD_ANALYSIS_PIPELINES:
            return {
                ...state,
                analysisPipelinesList: [action.payload.data, ...state.analysisPipelinesList],
            };
        case ANALYSIS_PIPELINES_CONSTS.UPDATE_ANALYSIS_PIPELINES:
            return {
                ...state,
                analysisPipelinesList: state.analysisPipelinesList.map(item => item?.id === action.payload.id ? {
                    ...item,
                    ...action.payload.data,
                    isMarked: !!action.payload.data?.start_time
                } : item),
            };
        case ANALYSIS_PIPELINES_CONSTS.DELETE_ANALYSIS_PIPELINES:
            return {
                ...state,
                analysisPipelinesList: state.analysisPipelinesList.filter(item => item.id !== action.payload?.id),
            };
        default:
            return state;
    }
}
