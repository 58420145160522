// Import packages
import React, {useEffect, useRef, useState} from 'react';
import {connect} from "react-redux";
import Highlighter from "react-highlight-words";
import {Link, useLocation} from "react-router-dom";
import {Form, Toast} from "react-bootstrap";

// Import assets
import {ReactComponent as LoopIcon} from "./../../../image/icons/loupe.svg";
import './searcher.scss';

// Import Components
import FuseSearchInput from '../FuseSearchInput';
import NewRecorder from "../RecorderNew/NewRecorder";
import DropdownMenu from "../dropdownMenu";

// Import Utils
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {
    ClearVideosSearch,
    GetVideosSearch,
    uploadVideo,
    VideoUrlRecord,
    GetVideos,
    DetectProvider,
    SetShowMenu
} from "../../../redux/actions";
import {generateMediaUrl} from "../../../utils/generateMediaUrl";
import {changeTimeFormat} from "../../../utils/helperFunctions";
import UserInfoModal from "../../../pages/Dashboard/containers/Users/modals/userInfoModal/UserInfoModal";
import {CalendarIcon, Logo, MenuIcon, VideoCameraIcon} from "../../../image";
import {history} from "../../../utils/history";
import {useTranslation} from "react-i18next";
import Notifications from "../notification/Notifications";
import {providerTypes} from "../../../utils/constants";
import Calendar from "../calendar/Calendar";

function usePrevious(value) {
    const ref = React.useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

function useDebounce(cb, delay) {
    const [debounceValue, setDebounceValue] = useState(cb);
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebounceValue(cb);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [cb, delay]);
    return debounceValue;
}

function Searcher(props) {
    const {changeVideoList, className, searchVideos, isFullWidth, videoIsEmpty, showMenu} = props;
    const [loading, setLoading] = useState(false);
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [file, setFile] = useState(undefined);
    const [showToast, setShowToast] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [searchText, setSearchText] = useState('');
    const [showAll, setShowAll] = useState({
        videos: false,
        persons: false,
        transcriptions: false,
        summaries: false,
        retelling: false,
    });
    const [showPersonsModal, setShowPersonsModal] = useState(false)
    const [currentUser, setCurrentUser] = useState(null)
    const [focusedURL, setFocusedURL] = useState(false)
    const [urlValue, setUrlValue] = useState('')
    const [errorUrl, setErrorUrl] = useState(false)
    const [openSearch, setOpenSearch] = useState(false)
    const [showIsEmpty, setShowIsEmpty] = useState(false)
    const [selectedProvider, setSelectedProvider] = useState(undefined)
    const [loadDetection, setLoadDetection] = useState(false)
    const [showCalendar, setShowCalendar] = useState(false)

    const uploadRef = useRef();
    const location = useLocation();
    const prevLocation = usePrevious(location);

    const debounceValue = useDebounce(urlValue, 500);

    const {t} = useTranslation();

    useEffect(() => {
        if (!searchText?.length) {
            props.ClearVideosSearch()
        }
    }, [])

    useEffect(() => {
        if (prevLocation && location !== prevLocation) {
            toggleSearch(false)
        }
    }, [location, prevLocation]);

    useEffect(() => {
        urlValue && handleDetect(urlValue)
    }, [debounceValue]);

    useEffect(() => {
        if (!focusedURL){
            setUrlValue('')
        }
    }, [focusedURL]);

    function openModal(data) {
        setShowPersonsModal(true)
        setCurrentUser(data)
    }

    function closeModal() {
        setShowPersonsModal(false)
    }

    function submitFile(newFile) {
        let formData = new FormData();
        formData.append('file', newFile);
        setLoading(true)
        props.uploadVideo(formData)
            .then(() => {
                props.GetVideos({limit: 20, manage: true})
            })
            .catch(() => {
                setShowToast(true)
            })
            .finally(() => {
                setLoading(false)
                setFile(undefined)
            });
    }

    function handleUploadClick() {
        uploadRef.current?.click();
    }

    /* function toggleShowAll(name) {
         setShowAll({
             ...showAll,
             [name]: !showAll[name]
         })
     }*/

    function handleFileUpload(e) {
        if (!e.target.files) {
            return;
        }
        if (e.target.files[0].type.includes('mp4')) {
            setFile(e.target.files[0]);
            submitFile(e.target.files[0])
        } else {
            setErrorText(t('error_file'));
            toggleToast()
        }
    }

    function handleBlur() {
        // setSearchText('')
        // props.ClearVideosSearch()
    }

    const toggleToast = () => setShowToast(!showToast);

    function ErrorToast() {
        return <Toast show={showToast} onClose={toggleToast} className={'upload_video_answer'}>
            <Toast.Header>
                <img
                    src=""
                    className="rounded me-2"
                    alt=""
                />
                <strong className="me-auto">{t('error_title')}</strong>
            </Toast.Header>
            <Toast.Body>{errorText ? errorText : t('error_description')}</Toast.Body>
        </Toast>
    }

    function getInputValue(e) {
        const {value} = e.target

        setUrlValue(value)
        setErrorUrl(false)

        // setTimeout(() => {
        //     handleDetect(value)
        // }, 2000)
    }

    function handleDetect(url) {
        setLoadDetection(true)
        props.DetectProvider({
            url: url ? url : urlValue
        }).then((res) => {
            setSelectedProvider(res.provider ? res.provider : res?.data?.provider)
        }).catch(() => {
            console.log('ERROR');
            setShowToast(true)
        }).finally(() => {
            setLoadDetection(false)
            setErrorText('Ошибка подключения к совещанию')
        })
    }

    function saveUrl() {
        if (urlValue) {
            const data = {
                meeting_url: urlValue,
            }
            selectedProvider && (data.provider = selectedProvider)
            props.VideoUrlRecord(data).then((res) => {
                setUrlValue('')
                // setFocusedURL(false)
                // setSelectedProvider(res.provider)
                history.push({
                    pathname: `/dashboard/videos/video/${res?.video_id}`,
                    state: {openEdit: true}
                })
            }).catch(() => {
                console.log('ERROR');
                setErrorUrl(true)
                setShowToast(true)
            }).finally(() => {
                setLoadDetection(false)
                setErrorText('Ошибка подключения к совещанию')
            })

        }
    }

    function toggleSearch(isClosed) {
        setSearchText('')
        setOpenSearch(isClosed)
        setShowIsEmpty(false)
        setShowAll({
            videos: false,
            persons: false,
            transcriptions: false,
            summaries: false,
            retelling: false,
        })
        setFocusedURL(false)
        props.ClearVideosSearch()
    }

    function handleOpenSearch(event) {
        event.stopPropagation()
        setOpenSearch(true)
    }

    return (
        <div className={`searcher-wrapper ${className ? className : ''} ${isFullWidth ? '' : 'full'}`}>
            <div className="left-part">
                <MenuIcon className="menu-icon"
                          onClick={() => {
                              props.SetShowMenu(!showMenu)
                          }}/>
                <Logo className="logo" onClick={() => {
                    history.push('/dashboard/videos/review')
                }}/>
            </div>
            {/*<div className="search-right-side">*/}
            <DropdownMenu className={'search-input-wrapper'}
                          contentClassName={'search'}
                          toggle={toggleSearch}
                          isCustomToggle={true}
                          isOpen={openSearch}
                          zIndex={1010}
                          button={<div className="search__input"
                                       onClick={handleOpenSearch}>
                              <FuseSearchInput
                                  type="text"
                                  placeholder={t('input_search')}
                                  className="search__input-search"
                                  searchKeysDeep={['name']}
                                  searchText={searchText}
                                  handleOpenSearch={handleOpenSearch}
                                  setSearchText={setSearchText}
                                  setShowIsEmpty={setShowIsEmpty}
                                  changeVideoList={changeVideoList}
                                  handleBlur={handleBlur}
                                  loading={loadingSearch}
                                  setLoadingSearch={setLoadingSearch}
                                  GetVideosSearch={props.GetVideosSearch}
                              />
                              <LoopIcon className="search__logo"
                                        onClick={() => {
                                            if (searchText.length > 2) {
                                                setLoadingSearch(true)
                                                props.GetVideosSearch({
                                                    search_query: searchText
                                                }).finally(() => {
                                                    setLoadingSearch(false)
                                                    setShowIsEmpty(true)
                                                })
                                                changeVideoList()
                                            }
                                        }}/>
                          </div>}
                          closeAfterToggle={false}
                          contentWidth={'670px'}>
                {(!!Object.keys(searchVideos)?.length &&
                    (!!searchVideos?.videos?.length ||
                        !!searchVideos?.persons?.length
                        || !!searchVideos?.summaries?.length
                        || !!searchVideos?.retelling?.length
                        || !!searchVideos?.transcriptions?.length
                    )) ?
                    <>
                        {!!searchVideos?.videos?.length && <div className="videos">
                            <div className="header">
                                <span className="title">
                                    {/*{searchVideos?.videos?.length}*/}
                                    {t('video')}
                                </span>
                                {searchVideos?.videos?.length > 2 && <span className="see-all" onClick={(e) => {
                                    e.stopPropagation()
                                    history.push(`/dashboard/videos/search/${searchText}/videos`)
                                    // toggleShowAll('videos')
                                }}>
                                    {showAll.videos ? `${t('hide')}`
                                        : `${t('see_all')} (${searchVideos?.videos?.length})`}
                                </span>}
                            </div>
                            <div className="list">
                                {searchVideos?.videos?.slice(0, showAll.videos ? searchVideos?.videos?.length : 2)?.map(item => {
                                    return <Link to={{
                                        pathname: `/dashboard/videos/video/${item?.id}`,
                                        state: {
                                            routs: [
                                                {
                                                    name: t('input_search')
                                                },
                                            ]
                                        }
                                    }}
                                                 className="video-item" key={item.id}>
                                        <video width="114" height="74">
                                            <source src={item?.url_s3 ? item?.url_s3 : generateMediaUrl(item?.url)}
                                                    type="video/mp4"/>
                                        </video>
                                        <div className="video-item__content">
                                            <Highlighter
                                                className={'description'}
                                                highlightClassName="highlight-text"
                                                searchWords={[searchText]}
                                                autoEscape={true}
                                                textToHighlight={item?.description}
                                            />
                                            <div className="information_date">
                                                    <span className="date">
                                                        {changeTimeFormat(item?.created_at)}
                                                    </span>
                                                {item?.duration && <div className="video-time">
                                                    {item?.duration}
                                                </div>}
                                            </div>
                                        </div>
                                    </Link>
                                })}
                            </div>
                            {/*<div className="search-count">*/}
                            {/*    {searchVideos?.videos?.length > 1 && <span onClick={() => {*/}
                            {/*        toggleShowAll('videos')*/}
                            {/*    }}>{showAll.videos ? t('hide') : t('all')} {t('video')}</span>}*/}
                            {/*</div>*/}
                        </div>}

                        {!!searchVideos?.persons?.length && <div className="persons">
                            <div className="header">
                                <span className="title">
                                    {t('speakers')}
                                </span>
                                {searchVideos?.persons?.length > 2 && <span className="see-all" onClick={() => {
                                    // toggleShowAll('persons')
                                    history.push(`/dashboard/search/${searchText}/persons`)
                                }}>
                                    {showAll.videos ? `${t('hide')}`
                                        : `${t('see_all')} (${searchVideos?.persons?.length})`}
                                </span>}
                            </div>
                            <div className="list">
                                {searchVideos?.persons?.slice(0, showAll.persons ? searchVideos?.persons?.length : 2)?.map(item => {
                                    return <div className="person-item" key={item.id}
                                                onClick={() => openModal(item)}>
                                        <img src={generateMediaUrl(item?.avatar)} alt=""/>
                                        <div className="video-item__content">
                                            <div
                                                className="description">{item?.first_name ?
                                                <Highlighter
                                                    highlightClassName="highlight-text"
                                                    searchWords={[searchText]}
                                                    autoEscape={true}
                                                    textToHighlight={item?.first_name}
                                                />
                                                : ''} {item?.last_name ?
                                                <Highlighter
                                                    highlightClassName="highlight-text"
                                                    searchWords={[searchText]}
                                                    autoEscape={true}
                                                    textToHighlight={item?.last_name}
                                                />
                                                : ''}</div>
                                            <div className="email">
                                                <Highlighter
                                                    highlightClassName="highlight-text"
                                                    searchWords={[searchText]}
                                                    autoEscape={true}
                                                    textToHighlight={item?.email || 'mail@gmail.com'}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                })}
                            </div>
                        </div>}

                        {!!searchVideos?.users?.length && <div className="persons">
                            <div className="header">
                                <span className="title">
                                    {t('users')}
                                </span>
                                {searchVideos?.users?.length > 2 && <span className="see-all" onClick={() => {
                                    // toggleShowAll('users')
                                    history.push(`/dashboard/search/${searchText}/users`)
                                }}>
                                    {showAll.videos ? `${t('hide')}`
                                        : `${t('see_all')} (${searchVideos?.users?.length})`}
                                </span>}
                            </div>
                            <div className="list">
                                {searchVideos?.users?.slice(0, showAll.users ? searchVideos?.users?.length : 2)?.map(item => {
                                    return <div className="person-item" key={item.id}
                                                onClick={() => openModal(item)}>
                                        <img src={generateMediaUrl(item?.avatar)} alt=""/>
                                        <div className="video-item__content">
                                            <div
                                                className="description">{item?.first_name ?
                                                <Highlighter
                                                    highlightClassName="highlight-text"
                                                    searchWords={[searchText]}
                                                    autoEscape={true}
                                                    textToHighlight={item?.first_name}
                                                />
                                                : ''} {item?.last_name ?
                                                <Highlighter
                                                    highlightClassName="highlight-text"
                                                    searchWords={[searchText]}
                                                    autoEscape={true}
                                                    textToHighlight={item?.last_name}
                                                />
                                                : ''}</div>
                                            <div className="email">
                                                <Highlighter
                                                    highlightClassName="highlight-text"
                                                    searchWords={[searchText]}
                                                    autoEscape={true}
                                                    textToHighlight={item?.emal || 'mail@gmail.com'}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                })}
                            </div>
                            {/*<div className="search-count">*/}
                            {/*    {searchVideos?.users?.length > 1 && <span onClick={() => {*/}
                            {/*        toggleShowAll('users')*/}
                            {/*    }}>{showAll.users ? t('hide') : t('all')} {t('users')}</span>}*/}
                            {/*</div>*/}
                        </div>}

                        {!!searchVideos?.summaries?.length && <div className="summaries">
                            <div className="header">
                                <span className="title">
                                    {t('summaries')}
                                </span>
                                {searchVideos?.summaries?.length > 2 && <span className="see-all" onClick={() => {
                                    // toggleShowAll('summaries')

                                    history.push(`/dashboard/videos/search/${searchText}/summaries`)
                                }}>
                                    {showAll.videos ? `${t('hide')}`
                                        : `${t('see_all')} (${searchVideos?.summaries?.length})`}
                                </span>}
                            </div>
                            <div className="list">
                                {searchVideos?.summaries?.slice(0, showAll.summaries ? searchVideos?.summaries?.length : 2)?.map(item => {
                                    let textItem = item?.summary?.split('\n')?.find(i => i.includes(searchText))

                                    return <Link
                                        to={{
                                            pathname: `/dashboard/videos/video/${item?.video_id}`,
                                            state: {
                                                routs: [
                                                    {
                                                        name: t('input_search')
                                                    }
                                                ]
                                            }
                                        }}
                                        className="summaries-item" key={item.id}>
                                        <div className="summaries-item-header">
                                            <span>{item?.video_description}</span>
                                            <span className={'date'}>{item?.video_created_at}</span>
                                        </div>
                                        <Highlighter
                                            className="summaries-item-text"
                                            highlightClassName="highlight-text"
                                            searchWords={[searchText]}
                                            autoEscape={true}
                                            textToHighlight={textItem ? textItem : item?.summary}
                                        />
                                    </Link>
                                })}
                            </div>
                        </div>}

                        {!!searchVideos?.retelling?.length && <div className="summaries">
                            <div className="header">
                                <span className="title">
                                    {t('retelling')}
                                </span>
                                {searchVideos?.retelling?.length > 2 && <span className="see-all" onClick={() => {
                                    // toggleShowAll('retelling')

                                    history.push(`/dashboard/videos/search/${searchText}/retelling`)
                                }}>
                                    {showAll.videos ? `${t('hide')}`
                                        : `${t('see_all')} (${searchVideos?.retelling?.length})`}
                                </span>}
                            </div>
                            <div className="list">
                                {searchVideos?.retelling?.slice(0, showAll.retelling ? searchVideos?.retelling?.length : 2)?.map(item => {
                                    let textItem = item?.retelling?.split('\n')?.find(i => i.includes(searchText))

                                    return <Link
                                        to={{
                                            pathname: `/dashboard/videos/video/${item?.video_id}`,
                                            state: {
                                                routs: [
                                                    {
                                                        name: t('input_search')
                                                    }
                                                ]
                                            }
                                        }}
                                        className="summaries-item" key={item.id}>
                                        <div className="summaries-item-header">
                                            <span>{item?.video_description}</span>
                                            <span className={'date'}>{item?.video_created_at}</span>
                                        </div>
                                        <Highlighter
                                            className="summaries-item-text"
                                            highlightClassName="highlight-text"
                                            searchWords={[searchText]}
                                            autoEscape={true}
                                            textToHighlight={textItem ? textItem : item?.retelling}
                                        />
                                    </Link>
                                })}
                            </div>
                        </div>}

                        {!!searchVideos?.transcriptions?.length && <div className="transcriptions">
                            <div className="header">
                                <span className="title">
                                    {t('transcribed')}
                                </span>
                                {searchVideos?.transcriptions?.length > 2 && <span className="see-all" onClick={() => {
                                    // toggleShowAll('transcriptions')
                                    history.push(`/dashboard/videos/search/${searchText}/transcriptions`)
                                }}>
                                    {showAll.videos ? `${t('hide')}`
                                        : `${t('see_all')} (${searchVideos?.transcriptions?.length})`}
                                </span>}
                            </div>
                            <div className="list">
                                {searchVideos?.transcriptions?.slice(0, showAll.transcriptions ? searchVideos?.transcriptions?.length : 2)?.map(item => {
                                    return <Link
                                        to={{
                                            pathname: `/dashboard/videos/video/${item?.video_id}/time/${item?.start_time}`,
                                            state: {
                                                routs: [
                                                    {
                                                        name: t('input_search')
                                                    }
                                                ]
                                            }
                                        }}
                                        className="transcriptions-item" key={item.id}>
                                        <Highlighter
                                            highlightClassName="highlight-text"
                                            searchWords={[searchText]}
                                            autoEscape={true}
                                            textToHighlight={item?.text}
                                        />
                                    </Link>
                                })}
                            </div>
                        </div>}
                    </>
                    : showIsEmpty && <div className="no-search-result">
                    Не удалось найти результатов, соответствующих вашему запросу
                </div>}
            </DropdownMenu>
            <div className={`search__buttons ${focusedURL ? 'open-url' : ''}`}>
                <div className={`url-wrapper ${errorUrl ? 'error' : ''}`}
                     onClick={() => {
                         setFocusedURL(true)
                         setSelectedProvider(undefined)
                     }}
                     style={{
                         display: !videoIsEmpty && location.pathname.includes('/dashboard/videos/video') ? 'none' : 'flex'
                     }}>
                    {
                        loadDetection ? <div className="spinner-border" role="status"/> : <VideoCameraIcon/>
                    }

                    <input
                        onChange={getInputValue}
                        value={urlValue}
                        name="description"
                        placeholder={t('input_paste_url')}
                        type="text"
                        autoComplete="off"
                        // onKeyDown={(e) => {
                        //     if (e.key === 'Enter') {
                        //         saveUrl()
                        //     }
                        // }}
                    />
                    <div className="buttons-wrap">
                        <DropdownMenu button={
                            <button className="provider-btn"
                            > {selectedProvider ? <>
                                {providerTypes.find(i => i.key === selectedProvider).icon}
                                {providerTypes.find(i => i.key === selectedProvider).name}
                            </> : "Выбать ВКС"}</button>
                        }
                                      contentClassName={'provider-select'}
                                      closeAfterToggle={false}
                                      zIndex={1100}
                                      contentWidth={'353px'}>
                            <div className="provider-content">
                                <div className="header">Выбор ВКС</div>
                                <div className="provider-list">
                                    {
                                        providerTypes?.map(item => {
                                            return <div className="provider-item" key={item?.key}
                                                        onClick={() => {
                                                            // handleSort(item)
                                                        }}>
                                                <Form>
                                                    <Form.Check
                                                        className="provider-item__check"
                                                        type={'radio'}
                                                        label={<div className="00">
                                                            {item?.icon}&nbsp;
                                                            {item?.name}
                                                        </div>}
                                                        checked={item.key === selectedProvider}
                                                        onChange={() => {
                                                            setSelectedProvider(item.key)
                                                        }}
                                                    />
                                                </Form>
                                                {/*{item?.name}*/}
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                        </DropdownMenu>
                        <button className="start"
                                onClick={() => {
                                    saveUrl()
                                }}
                        >{t('button_start')}</button>
                    </div>
                </div>
                <div className="overlay" onClick={() => setFocusedURL(false)}/>
                <NewRecorder token={props.token} videoIsEmpty={videoIsEmpty}/>
                <button className="search__buttons-import"
                        disabled={loading}
                        onClick={handleUploadClick}>
                    {loading && <div className="spinner-border" role="status"/>}
                    {file ? t('button_upload') : t('button_import')}
                </button>

                <input
                    type="file"
                    ref={uploadRef}
                    onChange={handleFileUpload}
                    style={{display: 'none'}}
                />
                {/*     {record ? (
                            <Recorder record={record}/>
                        ) : (
                            <button
                                onClick={() => setRecord(true)}
                                className="search__buttons-record"
                            >
                                <RecordIcon/> Record
                            </button>
                        )}*/}
            </div>
            <div className="mobile-btns">
                {/*<div className="btn-wrap" onClick={()=> setShowCalendar(!showCalendar)}>*/}
                {/*    <CalendarIcon/>*/}
                {/*</div>*/}
                <DropdownMenu className={'btn-wrap'}
                              contentClassName={'btn-wrap'}
                              button={<CalendarIcon className={'calendar-icon'}/>}
                              closeAfterToggle={false}
                              contentWidth={'670px'}
                              closeDropdown={!showCalendar}
                >
                    <Calendar onClose={() => setShowCalendar(!showCalendar)}/>
                </DropdownMenu>
                <Notifications/>
            </div>
            {/*</div>*/}
            {ErrorToast()}
            {showPersonsModal && <UserInfoModal show={showPersonsModal} closeModal={closeModal} user={currentUser}/>}
        </div>
    );
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'isMobile',
        'showMenu',
        'isFullWidth',
        'headerTitle',
        'searchVideos',
        'token',
        'videoIsEmpty',
    ])
};

const mapDispatchToProps = {
    uploadVideo, GetVideosSearch, ClearVideosSearch, VideoUrlRecord, GetVideos, DetectProvider, SetShowMenu
};

export default connect(mapStateToProps, mapDispatchToProps)(Searcher);
