// Import packages
import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

// Import assets
import './metrics.scss';

// Import Components
import UserInfoModal from "../Users/modals/userInfoModal/UserInfoModal";

// Import Utils
import {getPropsFromState} from "../../../../redux/mapStateToProps";
import {useTranslation} from "react-i18next";
import DropdownMenu from "../../../../shared/features/dropdownMenu";
import {
    CompanyIcon,
    DislikeIcon,
    DownIcon,
    GuestIcon,
    LikeIcon,
    PlaySmallIcon,
    TimmeIcon
} from "../../../../image";
import {timeFilterTypes} from "../../../../utils/constants";
import {Form} from "react-bootstrap";
import {GetCompanies, GetStatistics, GetStatisticsVideo} from "../../../../redux/actions/statisticsActions";
import {GetAuthCheck} from "../../../../redux/actions";
import {changeTimeFormat, getFullTime, hasPermission} from "../../../../utils/helperFunctions";
import DatePicker from "react-datepicker";
import DatePickerDropdowns from "../Videos/ManageVideo/DatePickerDropdowns/DatePickerDropdowns";
import {history} from "../../../../utils/history";

function Metrics(props) {
    const [showModal, setShowModal] = useState(false)
    const [currentUser, setCurrentUser] = useState(null)
    const [selectedCompany, setSelectedCompany] = useState(undefined);
    const [selectedFilter, setSelectedFilter] = useState(undefined);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(null);
    const [menuIsClosed, setMenuIsClosed] = useState(true);

    const {companies, statistics, statisticVideo} = props

    const {t} = useTranslation();

    useEffect(() => {
        props.GetAuthCheck()
        hasPermission('companies_read') && props.GetCompanies()
        let tomorrow = new Date();
        tomorrow.setDate(tomorrow?.getDate() + 1);

        setStartDate(new Date())
        setEndDate(tomorrow)
        setSelectedFilter(timeFilterTypes.today)
    }, [])

    useEffect(() => {
        hasPermission('companies_read') &&
        companies?.[0] && setSelectedCompany(companies?.[0])
    }, [companies])

    useEffect(() => {
        if (hasPermission('statistics_read')) {
            props.GetStatistics({
                company_id: selectedCompany?.id,
                date_start: startDate,
                date_end: endDate
            })
            props.GetStatisticsVideo({
                company_id: selectedCompany?.id,
                date_start: startDate,
                date_end: endDate
            })
        }
    }, [selectedCompany?.id])

    function toggleCompanies(item) {
        setSelectedCompany(item)
    }

    function closeModal() {
        setShowModal(false)
        setCurrentUser(null)
    }

    function changeTime(dates) {
        const [start, end] = dates;

        setStartDate(start);
        setEndDate(end);
    }

    function toggleFilter(item) {
        setSelectedFilter(item)
        const today = new Date()
        today.setHours(0, 0, 0);

        let startDate = new Date(today.setDate(today.getDate() - item.date));
        let endDate = new Date(today.setDate(today.getDate() + item.date + 1));

        if (item.key === timeFilterTypes.today.key) {
            const date = new Date();
            const firstDay = new Date(date.setDate(date.getDate() - item.date));
            const lastDay = new Date(date.setDate(date.getDate() + item.date + 1));
            startDate = firstDay
            endDate = lastDay
        }
        if (item.key === timeFilterTypes.current_month.key) {
            const date = new Date();
            const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
            const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            startDate = firstDay
            endDate = lastDay
        }
        if (item.key === timeFilterTypes.last_month.key) {
            let now = new Date();
            let firstDay = new Date(now.getFullYear(), now.getMonth() - 1, 1);
            let lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
            startDate = firstDay
            endDate = lastDay
        }

        changeTime([startDate, endDate])

        props.GetStatistics({
            company_id: selectedCompany?.id,
            date_start: startDate,
            date_end: endDate
        })
        props.GetStatisticsVideo({
            company_id: selectedCompany?.id,
            date_start: startDate,
            date_end: endDate
        })
    }

    function changeStartTime(date) {
        const dateStart = new Date(date);
        setStartDate(dateStart);
        dateStart.setHours(0, 0, 0, 0);
    }

    function changeEndTime(date) {
        const dateEnd = new Date(date);
        setEndDate(dateEnd);
        dateEnd.setHours(0, 0, 0, 0);
    }

    function onHide() {
        setMenuIsClosed(!menuIsClosed)
    }

    function saveResult() {
        const nextToEndDay = endDate && new Date(endDate);
        nextToEndDay?.setDate(nextToEndDay?.getDate() + 1);

        props.GetStatistics({
            company_id: selectedCompany?.id,
            date_start: startDate,
            date_end: nextToEndDay
        })
        props.GetStatisticsVideo({
            company_id: selectedCompany?.id,
            date_start: startDate,
            date_end: nextToEndDay
        }).then(() => {
            onHide()
        })
        setSelectedFilter(timeFilterTypes.other.key)
    }

    const navigateVideo = (data) => {
        history.push({
            pathname: `/dashboard/videos/video/${data?.id}`,
            state: {
                routs: [
                    {
                        path: '/dashboard/metrics',
                        name: t('menu_item_metrics')
                    }
                ]
            }
        })
    };


    return (
        <div className={`main-container metrics full`}>
            <div className="page-title" style={{padding: '20px 0 0'}}>{t('menu_item_metrics')}</div>

            {hasPermission('companies_read') && <DropdownMenu button={
                <button className="sort-btn">
                    <span className="bold">Компания:&nbsp;</span>
                    <span className="sort-btn__name">
                                {selectedCompany?.name}
                        </span>

                    <DownIcon className="arrow-icon"/>
                </button>
            }
                                                              closeAfterToggle={true}
                                                              className={'sort-select'}
                                                              contentClassName={'sort-select__list'}
                                                              contentWidth={'238px'}
                                                              fullWidth={true}
            >
                {
                    !!companies?.length && companies?.map(item => {
                        return <div className="sort-item" key={item?.id}
                                    onClick={() => {
                                        toggleCompanies(item)
                                    }}>
                            <Form>
                                <Form.Check
                                    className="sort-item__check"
                                    type={'radio'}
                                    checked={item?.id === selectedCompany?.id}
                                    onChange={() => {
                                        toggleCompanies(item)
                                    }}
                                />
                            </Form>

                            {item?.name}
                        </div>
                    })
                }
            </DropdownMenu>}

            <div className="info-carts">
                <div className="info-carts-item">
                    <div className="info-title">Пользователей в системе</div>
                    <div className="info-desc">
                        <GuestIcon className="guest-icon"/>
                        {statistics?.users_count || 0}
                    </div>
                </div>
                <div className="info-carts-item">
                    <div className="info-title">Общее время обработки</div>
                    <div className="info-desc">
                        <TimmeIcon/>
                        {getFullTime(statistics?.time_process_all_videos_sec) || 0}
                    </div>
                </div>
                {hasPermission('companies_read') && <div className="info-carts-item">
                    <div className="info-title">Созданные компании</div>
                    <div className="info-desc">
                        <CompanyIcon/>
                        {statistics?.companies_count || 0}
                    </div>
                </div>}
                <div className="info-carts-item">
                    <div className="info-title">Общее количество видео</div>
                    <div className="info-desc">
                        <PlaySmallIcon/>
                        {statistics?.videos_count || 0} {t('video')}
                    </div>
                </div>
            </div>

            <div className="time-filters">
                {
                    Object.keys(timeFilterTypes)?.slice(0, -1).map(item => {
                        return <div className={`time-filters-item 
                        ${timeFilterTypes[item]?.key === selectedFilter?.key ? 'selected' : ''}`}
                                    onClick={() => toggleFilter(timeFilterTypes[item])}
                                    key={item}>
                            {timeFilterTypes[item]?.name}
                        </div>
                    })
                }
                <DropdownMenu button={
                    <div className="time-filters-item">{changeTimeFormat(startDate).slice(0, -14)}</div>
                }
                              closeAfterToggle={false}
                              closeDropdown={menuIsClosed}
                              onClose={onHide}
                              contentClassName="introduction-update-menu"
                              contentWidth={'648px'}
                >
                    <div className="calendar-row">
                        {startDate && <div className="dropdowns-wrap">
                            с
                            <DatePickerDropdowns changeTime={changeStartTime}
                                                 date={startDate}/>
                        </div>}
                        <div className="dropdowns-wrap">
                            по
                            {endDate && <DatePickerDropdowns changeTime={changeEndTime}
                                                             date={endDate}/>}
                        </div>
                    </div>
                    <DatePicker
                        inline
                        selected={startDate}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={(date) => changeTime(date)}
                        monthsShown={2}
                        selectsRange
                    />
                    <div className="buttons">
                        <button className={'cancel-btn'}
                                onClick={onHide}>{t('button_cancel')}
                        </button>
                        <button className={`save-btn`}
                                onClick={saveResult}
                        >Применить
                        </button>
                    </div>
                </DropdownMenu>
            </div>

            <div className="row">
                <div className="half">
                    <div className="cart-title">Статистика оценок пользователей</div>
                    <div className="cart-graph-wrapper">
                        <div className="graph-area">
                            <div className="graph" style={{
                                height: statistics?.quality_control_chart_percent?.find(i => i.rating_value === 5)?.percent || 1 + '%',
                                backgroundColor: '#FFC75A'
                            }}/>
                            <div className="graph" style={{
                                height: statistics?.quality_control_chart_percent?.find(i => i.rating_value === 1)?.percent || 1 + '%',
                                backgroundColor: '#689AE4'
                            }}/>
                        </div>
                        <div className="graph-info">
                            <p className="text">
                                <span className="dot" style={{backgroundColor: '#FFC75A'}}/>
                                Положительные оценки -<span>
                                {statistics?.quality_control_chart_percent?.find(i => i.rating_value === 5)?.percent || 0}% {/*- 34*/}
                            </span>
                            </p>
                            <p className="text">
                                <span className="dot" style={{backgroundColor: '#689AE4'}}/>
                                Отрицательные оценки -<span>
                                {statistics?.quality_control_chart_percent?.find(i => i.rating_value === 1)?.percent || 0}% {/*- 6*/}
                            </span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="half">
                    <div className="cart-title">Длительность видео</div>
                    <div className="cart-content">
                        <div className="cart-content-header">
                            <span>Параметры</span>
                            <span>Длительность</span>
                        </div>
                        <div className="cart-content-text">
                            <span>Минимальная  длительность</span>
                            <span>{getFullTime(statistics?.min_video_duration_sec, true) || 0} м</span>
                        </div>
                        <div className="cart-content-text">
                            <span>Средняя  длительность</span>
                            <span>{getFullTime(statistics?.middle_video_duration_sec, true) || 0}</span>
                        </div>
                        <div className="cart-content-text">
                            <span>Максимальная  длительность</span>
                            <span>{getFullTime(statistics?.max_video_duration_sec, true) || 0}</span>
                        </div>
                    </div>
                </div>
            </div>

            {!!statisticVideo?.results?.length && <div className="ratings">
                <div className="ratings-title">Оценки пользователей</div>
                <div className="ratings-list-item header">
                    <div className="name">Видео</div>
                    <div className="viewed">Длительность</div>
                    <div className="meeting">Оценки итогов встреч</div>
                    <div className="retelling">Оценки пересказа</div>
                    {/*<div className="video-persons">Персоны</div>*/}
                </div>

                {
                    statisticVideo?.results?.map(item => {
                        return <div className="ratings-list-item" key={item?.id}>
                            <div className="name"
                                 onClick={() => navigateVideo(item)}>{item?.description}</div>
                            <div className="viewed">{getFullTime(item?.duration, true)}</div>
                            <div className="meeting">
                                <div className="rate-wrap">
                                    <LikeIcon className={`rate-icon`}/>
                                    <span>{item?.quality_controls?.filter(i => i?.entity_type === 'summary'
                                        && i?.rating === 5)?.length}</span>
                                </div>
                                <div className="rate-wrap">
                                    <DislikeIcon className={`rate-icon dislike`}/>
                                    <span>{item?.quality_controls?.filter(i => i?.entity_type === 'summary'
                                        && i?.rating === 1)?.length}</span>
                                </div>
                            </div>
                            <div className="retelling">
                                <div className="rate-wrap">
                                    <LikeIcon className={`rate-icon`}/>
                                    <span>{item?.quality_controls?.filter(i => i?.entity_type === 'retelling'
                                        && i?.rating === 5)?.length}</span>
                                </div>
                                <div className="rate-wrap">
                                    <DislikeIcon className={`rate-icon dislike`}/>
                                    <span>{item?.quality_controls?.filter(i => i?.entity_type === 'retelling'
                                        && i?.rating === 1)?.length}</span>
                                </div>
                            </div>
                            {/*<div className="video-persons">*/}
                            {/*    <UserIcons persons={item?.persons}/>*/}
                            {/*</div>*/}
                        </div>
                    })
                }
            </div>}

            {showModal && <UserInfoModal show={showModal}
                                         closeModal={closeModal}
                                         user={currentUser}
                                         videoPersonsList={props?.usersPersonsList?.results}/>}
        </div>
    );
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'isFullWidth',
        'companies',
        'statistics',
        'statisticVideo'
    ])
};

const mapDispatchToProps = {
    GetCompanies,
    GetStatistics,
    GetStatisticsVideo,
    GetAuthCheck
};

export default connect(mapStateToProps, mapDispatchToProps)(Metrics);
