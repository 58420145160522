export const UTIL_CONSTS = {
    SET_MOBILE: "SET_MOBILE",
    SHOW_MENU: "SHOW_MENU",
    CHANGE_HEADER_TITLE: "CHANGE_HEADER_TITLE",
    EVENT_COLORS: "EVENT_COLORS",
    SET_FULL_WIDTH: "SET_FULL_WIDTH",
    SHOW_ERROR_TOAST: "SHOW_ERROR_TOAST",
    HIDE_ERROR_TOAST: "HIDE_ERROR_TOAST",
    SHOW_SUCCESS_TOAST: "SHOW_SUCCESS_TOAST",
    HIDE_SUCCESS_TOAST: "HIDE_SUCCESS_TOAST",
    CHANGE_GRID_VIEW: "CHANGE_GRID_VIEW",
};
