import React, {useState} from "react";
import {DownloadIcon, EllipsisIcon, ErrorIcon, LoopIcon, SuccessIcon} from "../../../../../image";
import './texts-update.scss'
import {useTranslation} from "react-i18next";
import DropdownMenu from "../../../../../shared/features/dropdownMenu";
import {Toast} from "react-bootstrap";
import {hasPermission} from "../../../../../utils/helperFunctions";

function TextsUpdate(props) {
    const {
        DownloadTranscription, videoId, setEditAll, videoName, setKey
    } = props;

    const [showErrorToast, setShowErrorToast] = useState(false);
    const [showSuccessToast, setShowSuccessToast] = useState(false);

    const {t} = useTranslation();

    const toggleErrorToast = () => setShowErrorToast(!showErrorToast);
    const toggleSuccessToast = () => setShowSuccessToast(!showSuccessToast);

    function ErrorToast() {
        return <Toast show={showErrorToast}
                      onClose={toggleErrorToast}
                      className={'upload_video_answer'}
                      autohide={true}
                      delay={3000}>
            <ErrorIcon/>
            <div className="toast-content">
                <strong className="title">{t('toast_error_title')}</strong>
                <p className={'description'}>{t('toast_error_description')}</p>
            </div>
        </Toast>
    }

    function SuccessToast() {
        return <Toast show={showSuccessToast}
                      onClose={toggleSuccessToast}
                      className={'upload_video_answer'}
                      autohide={true}
                      delay={3000}>
            <SuccessIcon/>
            <div className="toast-content">
                <strong className="title">{t('toast_success_title')}</strong>
                <p className={'description'}>{t('toast_success_description')}</p>
            </div>
        </Toast>
    }

    return <>
        <DropdownMenu button={
            <EllipsisIcon className={'icon-menu'}
                          onClick={() => {
                              setKey('3')
                          }}/>}
                      closeAfterToggle={true}
                      zIndex={100}
                      className="texts-update-menu"
                      contentWidth={'326px'}
        >
            {hasPermission('transcriptions_update') && <div className={'menu-item'}
                                                            onClick={() => {
                                                                setEditAll(true)
                                                            }}>
                <LoopIcon className={'loop-icon'}/>
                {t('edit_transcription')}
            </div>}
            <div className={'menu-item'} onClick={() => {
                DownloadTranscription(videoId, videoName)
                    .then(() => toggleSuccessToast())
                    .catch(() => toggleErrorToast())
            }}>
                <DownloadIcon className={'download-icon'}/>
                {t('download_transcription')}
            </div>
        </DropdownMenu>
        {ErrorToast()}
        {SuccessToast()}
    </>
}

export default TextsUpdate
