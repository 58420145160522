// Import packages
import React, {useEffect, useRef, useState} from 'react';
import {Link} from "react-router-dom";

// Import assets
import './video-card.scss';
import UserIcons from "../../../../../shared/features/userIcons/UserIcons";

// Import Utils
import {generateMediaUrl, generateVideoUrl} from "../../../../../utils/generateMediaUrl";
import {changeTimeFormat} from "../../../../../utils/helperFunctions";
import {getPropsFromState} from "../../../../../redux/mapStateToProps";
import {connect} from "react-redux";
import UserInfoModal from "../../Users/modals/userInfoModal/UserInfoModal";

function VideoCard(props) {
    const {video, channel, className, channelsList, routs, text, gridView} = props;
    const [showChannels, setShowChannels] = useState(false);
    const [showUserModal, setShowUserModal] = useState(false)
    const ref = useRef();

    useEffect(() => {
        document.addEventListener('click', toggleMenu, {capture: true});
        return () => {
            document.removeEventListener('click', toggleMenu)
        }
    }, []);

    const toggleMenu = (event) => {
        const path = event.composedPath ? event.composedPath() : event.path
        if (!path?.includes(ref?.current)) {
            setShowChannels(false)
        }
    }

    function toggleUserModal() {
        setShowUserModal(!showUserModal);
    }

    return <div
        className={`video_card ${className ? className : ''} ${gridView ? 'grid' : 'list'}`}
        ref={ref}
    >
        <Link to={{
            pathname: `/dashboard/videos/video/${video?.video_id || video?.id }`,
            state: {routs}
        }} className={'video_card__video_wrapper'}>
            {
                video?.thumbnail_url ? <img src={generateMediaUrl(video?.thumbnail_url)} alt=""/>
                    : <video width="279" height="167">
                        <source src={video?.url_s3 ? video?.url_s3 : generateVideoUrl(video?.url)} type="video/mp4"/>
                    </video>
            }
        </Link>
        {video?.duration && <div className="video_card__video-time">
            {video?.duration}
        </div>}
        {!!channel && <div className="video_card__video-section"
                         onClick={() => setShowChannels(!showChannels)}
        >
            <span className="dote" style={{backgroundColor: channel?.bg}}/>
            {channel?.name}
        </div>}
        {video?.channels?.length > 1 && <div className="channels_count"
                                             onClick={() => setShowChannels(!showChannels)}>
            +{video?.channels?.length - 1}
        </div>}
        {
            showChannels && !!video?.channels?.length && <div className="channels_list">
                {
                    video?.channels?.map(item => {
                        return <div className="channel_item"
                                    key={channelsList[item]?.name}
                                    style={{
                                        backgroundColor: channelsList[item]?.bgColor,
                                        color: channelsList[item]?.color,
                                    }}>
                            {channelsList[item]?.name}
                        </div>
                    })
                }
            </div>
        }
        <div className="video_card__video-information"
             onClick={(e) => e.stopPropagation()}>
            <div className="information_wrapper">
                <div className="information_name">
                    {video?.description || video?.video_description}
                </div>
                <p className="information_date">
                    {changeTimeFormat(video?.created_at || video?.video_created_at)}
                </p>
                <div className="video_card__owner" onClick={(e) => {
                    e.stopPropagation()
                    toggleUserModal(video?.owner)
                }}>
                    {video?.owner?.name ? video?.owner?.name : ''}
                </div>
            </div>

            <UserIcons persons={video?.meeting_participants}/>
        </div>

        {
            text && <div className="sum-text">{text}</div>
        }
        {showUserModal && <UserInfoModal show={showUserModal}
                                         closeModal={toggleUserModal}
                                         user={video?.owner}
            // videoPersonsList={videoById?.persons}
        />}
    </div>
}

const mapDispatchToProps = {};

const mapStateToProps = (state) => {
    return getPropsFromState(state, ['gridView'])
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoCard);
