import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";

import config from "../../utils/config";

export default function SetTitleComponent() {
  const { t } = useTranslation();

  useEffect(() => {
    // Предполагаем, что getConfig().productName возвращает ключ для локализации
    document.title = t(config.productName);
  }, [t]); // Эффект будет перезапускаться только если функция t изменилась

  return (
    // JSX для вашего компонента
    <div>
      {/* Ваш контент компонента */}
    </div>
  );
};

export function useProductName() {
  const { t } = useTranslation();
  return t(config.productName);
}
