import {_urlTranscriptions, request} from "../api";
import {TRANSCRIPTIONS_CONSTS} from "../constants";

export const UpdateTranscription = (transcriptions_id, person, transcription, data) => {
    let requestData = {
        url: `${_urlTranscriptions}/${transcriptions_id}`,
        method: "PATCH",
        data: data || {
            person_id: person.id
        }
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: TRANSCRIPTIONS_CONSTS.UPDATE_TRANSCRIPTION,
                    payload: {
                        transcriptions_id,
                        transcription,
                        person,
                        data
                    }
                })
            })
    };
};

export const UpdateTranscriptionAll = (data) => {
    let requestData = {
        url: `${_urlTranscriptions}/replace_person_id`,
        method: "POST",
        data
    };

    return () => {
        return request(requestData)
    };
};

export const DownloadTranscription = (id, name) => {
    let requestData = {
        url: `${_urlTranscriptions}/download?video_id=${id}`,
        method: "GET",
    };
    console.log('222222222',name);
    return () => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;
                const blob = new Blob([data], {type: 'text/plain'});
                const blobUrl = URL.createObjectURL(blob);
                const anchor = document.createElement("a");

                anchor.href = blobUrl;
                anchor.download = `${name}.txt`;
                document.body.appendChild(anchor);
                anchor.click();
                URL.revokeObjectURL(blobUrl);
            })
    };
};

export const ReplaceTranscriptionWord = (data) => {
    let requestData = {
        url: `${_urlTranscriptions}/replace_word`,
        method: "POST",
        data
    };

    return () => {
        return request(requestData)
    };
};

export const DeleteTranscription = id => {
    const requestData = {
        url: `${_urlTranscriptions}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: TRANSCRIPTIONS_CONSTS.DELETE_TRANSCRIPTION,
                    payload: id
                });
            })
            .catch(() => {
            })
    }
};
