import isEmail from "validator/es/lib/isEmail";
import validator from "validator";

export function validateData({data, initialErrors, t, id}) {
    const {first_name, last_name, email, linkedin, facebook, phone_number, user} = data
    console.log('USER', data);
    let isValid = true;
    let errorList = {...initialErrors}

    if (!first_name?.length || first_name?.length < 2) {
        console.log('aaaaaaaaaaa');
        isValid = false
        errorList = {
            ...errorList,
            first_name: t('error_first_name')
        }
    }
    if (!!last_name?.length && last_name?.length < 2) {
        isValid = false
        errorList = {
            ...errorList,
            last_name: t('error_last_name')
        }
    }

    !!email?.length && email.forEach((item, index) => {
        if (!isEmail(item)) {
            (isValid = false)
            errorList = {
                ...errorList,
                email: email?.map((mail, i) => {
                    return i === index ? t('error_email') : undefined
                })
            }
        }
    })
    !!linkedin?.length && linkedin.forEach((item, index) => {
        const isValidLinkedinURL = validator.isURL(item, {
            host_whitelist: ['linkedin.com', 'www.linkedin.com']
        })
        if (!isValidLinkedinURL) {
            (isValid = false)
            errorList = {
                ...errorList,
                linkedin: facebook?.map((mail, i) => {
                    return i === index ? t('error_linkedin') : undefined
                })
            }
        }
    })
    !!facebook?.length && facebook.forEach((item, index) => {
        const isValidFacebookURL = validator.isURL(item, {
            host_whitelist: ['facebook.com', 'www.facebook.com']
        })
        if (!isValidFacebookURL) {
            isValid = false
            errorList = {
                ...errorList,
                facebook: facebook?.map((mail, i) => {
                    return i === index ? t('error_facebook') : undefined
                })
            }
        }
    })
    !!phone_number?.length && phone_number.forEach((item, index) => {
        if (item?.length < 11) {
            isValid = false
            errorList = {
                ...errorList,
                phone_number: phone_number?.map((mail, i) => {
                    return i === index ? t('error_phone_number') : undefined
                })
            }
        }
    })
    if (user?.password?.length < 8) {
        // console.log('11111111', user?.password);
        isValid = false;
        errorList = {
            ...errorList,
            password: t('error_password')
        }
    }
    if (!!user?.login && id) {
        // console.log('22222222',user?.login, id );
        isValid = false;
        errorList = {
            ...errorList,
            login: t('error_login')
        }
    }

    return {isValid, errors: errorList}
}
