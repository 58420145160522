// Import packages
import React, {useEffect, useState} from 'react';
import {
    NavLink, useLocation
} from 'react-router-dom';
import {connect} from "react-redux";
import {Accordion, OverlayTrigger, Tooltip} from "react-bootstrap";

// Import assets
import video from '../../../image/icons/ic_video.svg';
import roles from '../../../image/icons/ic_roles.svg';
import {
    ArrowTopIcon,
    BuildingsIcon,
    CatalogueMenuIcon, LLMIcon, MetricsIcon,
    Logo, LogOutIcon, MenuIcon, OpenMenuIcon,
} from "../../../image";
import './menu.scss';

// Import Components
import DropdownMenu from "../dropdownMenu";

// Import Utils
import {history} from "../../../utils/history";
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {
    GetUserById,
    GetUserProfile,
    LogOut,
    SetIsFullWidth,
    GetPersonById,
    ClearProfile,
    SetUserAvatar,
    ClearRedirectUrl,
    GetChannels,
    GetRoles,
    SetShowMenu
} from "../../../redux/actions";
import {generateMediaUrl} from "../../../utils/generateMediaUrl";
import {useTranslation} from "react-i18next";
import {getAuthorName, underscoreChannelSort, hasPermission} from "../../../utils/helperFunctions";

function Menu({
                  LogOut,
                  GetUserProfile,
                  userProfile,
                  GetUserById,
                  userById,
                  GetPersonById,
                  userRoles,
                  ClearProfile,
                  ClearUserById,
                  SetUserAvatar,
                  userAvatar,
                  ClearRedirectUrl,
                  token,
                  channels,
                  GetChannels,
                  GetRoles,
                  showMenu,
                  SetShowMenu,
                  isMobile
              }) {

    const location = useLocation()
    const {t} = useTranslation();

    const [openChannels, setOpenChannels] = useState(false);

    useEffect(() => {
        // userProfile?.attached_person?.id && GetPersonById(userProfile?.attached_person?.id).then((res) => {
        //     setAvatar(res?.avatar)
        GetUserProfile().then((res) => {
            res?.attached_person?.id && GetPersonById(res?.attached_person?.id, true).then((res) => {
                SetUserAvatar(res?.avatar)
            })
        })
        GetChannels()
       !isMobile &&  hasPermission('companies_read') &&  GetRoles()
    }, [location?.pathname])

    useEffect(() => {
        GetUserById(userProfile?.id)
    }, [userProfile?.id])

    const renderTooltip = (props, text) => (
        <Tooltip id="menu-tooltip" {...props}>
            {text}
        </Tooltip>
    );

    const tooltipWrapper = ({element, text}) => {
        return <OverlayTrigger
            flip={false}
            popperConfig={{}}
            onHide={undefined}
            onToggle={undefined}
            target={'auto-start'}
            defaultShow={false}
            show={undefined}
            trigger={undefined}
            placement="right"
            delay={{show: 100, hide: 200}}
            overlay={(props) => renderTooltip(props, text)}
        >
            {element}
        </OverlayTrigger>
    }

    const logOut = () => {
        LogOut()
            .then(() => {
                history.push('/');
            })
        ClearRedirectUrl()
        ClearProfile()
        ClearUserById()
    }


    let x = "Личное";
    let newChannelsList = !!channels?.length && channels?.sort((a, b) => b.id - a.id);
    hasPermission('someone_else_channels_read') &&
    (newChannelsList = !!newChannelsList?.length && newChannelsList?.sort(underscoreChannelSort))

    newChannelsList = !!newChannelsList?.length && newChannelsList?.sort((a, b) => {
        if (a.name === x) {
            return -1;
        }
        if (b.id === x) {
            return 1;
        }
        return 0;
    });

    return (
        <>
            <div className={`menu-overlay ${showMenu ? 'show-menu' : ''}`} onClick={() => {
                SetShowMenu(false)
            }}/>
            <div className={`menu max-menu ${showMenu ? 'show-menu' : 'hide-menu'}`}>
                <div className="">
                    <div className="logo-wrapper">
                        <MenuIcon className="menu-icon"
                                  onClick={() => {
                                      SetShowMenu(!showMenu)
                                  }}/>
                        <Logo className="logo"  onClick={() => {
                            SetShowMenu(!showMenu)
                            history.push('/dashboard/videos/review')
                        }}/>
                        <OpenMenuIcon className={'close-menu'} onClick={() => {
                            SetShowMenu(false)
                        }}/>
                    </div>
                    {
                        <NavLink to="/dashboard/videos/review"
                                 className={`menu__logo `}
                                 exact>
                            <Logo className="logo"/>
                        </NavLink>}
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0"
                                        className={`sub_menu `}>
                            <Accordion.Header
                                onClick={() => {
                                    history.push('/dashboard/videos/review')
                                }}
                                className={`menu_item ${location.pathname.includes('videos') ? 'active_menu' : ''} `}>
                                <div className="menu_link">
                                    <>
                                        <img src={video} alt="house"/>
                                        <p>{t('menu_item_video')}</p>
                                    </>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                {<div
                                    className={`menu_link menu__sub_item ${openChannels ? 'active-channel-menu' : ''}`}
                                >
                                    <div className={'channels-list-wrapper'}>
                                        <div className={`channel-menu-item`}
                                             onClick={() => setOpenChannels(!openChannels)}>
                                            {/*{t('table_channels')}*/}
                                            {<>
                                                {t('table_channels')}
                                            </>}
                                            <ArrowTopIcon/>
                                        </div>
                                        {openChannels && !!newChannelsList?.length &&
                                            <div className="channel-menu-list">
                                                {
                                                    newChannelsList?.map(item => {
                                                        return <NavLink to={`/dashboard/videos/channels/${item?.id}`}
                                                                        key={item?.id}
                                                                        className="menu_link menu__sub_item text channel-item"
                                                                        activeClassName="active_link"
                                                                        exact>
                                                            {item?.name?.length > 19 ? tooltipWrapper({
                                                                element: <span>{item?.name}</span>,
                                                                text: item?.name
                                                            }) : <span>{item?.name}</span>}
                                                        </NavLink>
                                                    })
                                                }
                                            </div>}
                                    </div>
                                </div>}
                                <NavLink to={'/dashboard/videos/manage'}
                                         className="menu_link menu__sub_item manage"
                                         activeClassName="active_link"
                                         exact>
                                    {t('menu_item_manage_video')}
                                </NavLink>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2" className={`sub_menu `}>
                            {
                                <Accordion.Header
                                    className={`menu_item nav_item ${location.pathname.includes('users') ? 'active_menu' : ''}`}>
                                    <NavLink to={'/dashboard/users/persons'}
                                             className="menu_link"
                                             activeClassName="active_link"
                                             exact>
                                        {
                                            <>
                                                <CatalogueMenuIcon/>
                                                {<p>{t('menu_item_catalogue')}</p>}
                                            </>
                                        }
                                    </NavLink>
                                </Accordion.Header>
                            }
                        </Accordion.Item>
                        {userRoles?.permissions?.find(i => i.name === 'roles_read') &&
                            <Accordion.Item eventKey="3" className={`sub_menu `}>
                                <Accordion.Header
                                    className={`menu_item nav_item ${location.pathname.includes('roles') ? 'active_menu' : ''}`}>
                                    <NavLink to={'/dashboard/roles'}
                                             className="menu_link"
                                             activeClassName="active_link"
                                             exact>
                                        {
                                            <>
                                                <img src={roles} alt="roles"/>
                                                <p>{t('menu_item_roles')}</p>
                                            </>
                                        }
                                    </NavLink>
                                </Accordion.Header>
                            </Accordion.Item>}
                        {hasPermission('statistics_read') && <Accordion.Item eventKey="4" className={`sub_menu`}>
                            <Accordion.Header
                                className={`menu_item nav_item ${location.pathname.includes('metrics') ? 'active_menu' : ''}`}>
                                <NavLink to={'/dashboard/metrics'}
                                         className="menu_link"
                                         activeClassName="active_link"
                                         exact>
                                    {
                                        // isFullWidth ?
                                        <>
                                            <MetricsIcon/>
                                            <p>{t('menu_item_metrics')}</p>
                                        </>
                                        /* : tooltipWrapper({
                                             element: <MetricsIcon/>,
                                             text: t('menu_item_metrics')
                                         })*/
                                    }
                                </NavLink>
                            </Accordion.Header>
                        </Accordion.Item>}

                        {<Accordion.Item eventKey="5" className={`sub_menu`}>
                            <Accordion.Header
                                className={`menu_item ${(location.pathname.includes('llm') || location.pathname.includes('prompts'))
                                    ? 'active_menu' : ''} `}>
                                <div className="menu_link">
                                    {
                                        <>
                                            <LLMIcon/>
                                            <p>{t('menu_item_llm')}</p>
                                        </>
                                        // isFullWidth ? <>
                                        //     <LLMIcon/>
                                        //     {isFullWidth && <p>{t('menu_item_llm')}</p>}
                                        // </> : tooltipWrapper({
                                        //     element: <LLMIcon/>,
                                        //     text: t('menu_item_llm')
                                        // })
                                    }
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                {hasPermission('analysis_pipelines_read') && <NavLink to={'/dashboard/llm'}
                                                                                      className="menu_link menu__sub_item"
                                                                                      activeClassName="active_link"
                                                                                      exact>
                                    Группы пользователей
                                </NavLink>}
                                <NavLink to={'/dashboard/prompts'}
                                         className="menu_link menu__sub_item"
                                         activeClassName="active_link"
                                         exact>
                                    Промты
                                </NavLink>
                            </Accordion.Body>
                        </Accordion.Item>}
                    </Accordion>

                </div>
                {
                    token && <div className="bottom-part">
                        <DropdownMenu button={
                            <div className={`menu__user-content`}>
                                <img src={generateMediaUrl(userAvatar)} alt=""/>
                                <div className="inner">
                                    <div className="name">
                                        {getAuthorName(userProfile?.attached_person?.id ? userProfile?.attached_person : userById)}
                                    </div>
                                    <div className="company">
                                        <BuildingsIcon/>
                                        <span>{userProfile?.company?.name}</span>
                                    </div>
                                    {!!userProfile?.roles?.length &&
                                        <div className="user">{userProfile?.roles?.[0]?.name}</div>}
                                </div>
                            </div>
                        }
                                      disabled={window.offsetWidth < 624}
                                      contentClassName={`menu-popup`}
                                      placement={'top'}
                                      closeAfterToggle={true}
                                      position={'top center'}
                                      zIndex={1100}
                                      contentWidth={'190px'}>
                            <div className="menu-item" onClick={() => {
                                history.push('/dashboard/profile');
                            }}>{t('profile_profile')}
                            </div>
                            <div className="menu-item log-out" onClick={logOut}>
                                {t('profile_log_out')}
                            </div>
                        </DropdownMenu>
                        <button className="log-out" onClick={logOut}>
                            <LogOutIcon/>
                            Выход
                        </button>
                    </div>
                }
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'isMobile',
        'showMenu',
        'userById',
        'personById',
        'userProfile',
        'isFullWidth',
        'userRoles',
        'userAvatar',
        'token',
        'channels',
    ])
};

const mapDispatchToProps = {
    LogOut,
    GetUserById,
    GetUserProfile,
    SetIsFullWidth,
    GetPersonById,
    ClearProfile,
    SetUserAvatar,
    ClearRedirectUrl,
    GetChannels,
    GetRoles,
    SetShowMenu
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
