// Import packages
import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

// Import assets
import './video.scss';

// Import Components

// Import Utils
import {getPropsFromState} from "../../../../../redux/mapStateToProps";
import Draggable from "react-draggable";
import {ResizableBox} from "react-resizable";
import {generateVideoUrl} from "../../../../../utils/generateMediaUrl";

function Video(props) {
    const {videoById, videoPlayer} = props;

    const [videoPosition, setVideoPosition] = useState({x: 0, y: 0});
    const [size, setSize] = useState({
        width: 260,
        height: 140,
    });

    useEffect(() => {
        setVideoPosition({x: 0, y: 0})
    }, [])

    useEffect(() => {
        const videoElement = document.querySelector('video');
        videoElement.oncontextmenu = function () {
            return false;
        };
    }, [])

    const drugHandler = (e, data) => {
        e.stopPropagation()
        setVideoPosition({
            x: data.x,
            y: data.y,
        })
        // setTimeout(() => {
        //     setShowControls(true);
        // }, 200)
    }

    const drugStart = (e) => {
        e.stopPropagation();
        // setShowControls(false);
    }

    const handleResize = (event, {size}) => {
        setSize(size);
    };

    return <Draggable disabled={true}
                      position={videoPosition}
                      defaultClassName={'draggable-video'}
                      onStart={drugStart}
                      onStop={drugHandler}>
        <ResizableBox
            width={448}
            height={0}
            onResize={handleResize}
        >
            <div
                className={`video_details__video`}>
                <div className="video-wrapper">
                    {(videoById?.url || videoById?.url_s3) &&
                        <video controls={true}
                               controlsList="nodownload" onContextMenu="return false;"
                               ref={videoPlayer}>
                            <source
                                src={videoById?.url_s3 ? videoById?.url_s3 : generateVideoUrl(videoById?.url)}
                                type="video/mp4"/>
                        </video>}
                </div>
            </div>
        </ResizableBox>
    </Draggable>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, ['videoById'])
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Video);
