import {STATISTICS_CONSTS} from "../constants";
import {_urlCompanies, _urlStatistics, request} from "../api";
import {REQUEST_LIMIT} from "../../utils/constants";


export const GetStatistics = ({
                                  company_id = undefined,
                                  date_start = undefined,
                                  date_end = undefined,
                              } = {}) => {
    let requestData = {
        url: `${_urlStatistics}?`,
        method: "GET",
    };
    company_id && (requestData.url = requestData.url + `&company_id=${company_id}`);
    date_start && (requestData.url = requestData.url + `&date_start=${date_start?.toISOString()?.substring(0, 10)}`);
    date_end && (requestData.url = requestData.url + `&date_end=${date_end?.toISOString()?.substring(0, 10)}`);

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;
                await dispatch({
                    type: STATISTICS_CONSTS.GET_STATISTICS,
                    payload: data
                })
            })
    };
};

export const GetStatisticsVideo = ({
                                       offset = 0,
                                       limit = REQUEST_LIMIT,
                                       company_id = undefined,
                                       date_start = undefined,
                                       date_end = undefined,
                                   } = {}) => {
    let requestData = {
        url: `${_urlStatistics}/videos?offset=${offset}&limit=${limit}&`,
        method: "GET",
    };
    company_id && (requestData.url = requestData.url + `&company_id=${company_id}`);
    date_start && (requestData.url = requestData.url + `&date_start=${date_start?.toISOString()?.substring(0, 10)}`);
    date_end && (requestData.url = requestData.url + `&date_end=${date_end?.toISOString()?.substring(0, 10)}`);

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;
                await dispatch({
                    type: STATISTICS_CONSTS.GET_STATISTICS_VIDEO,
                    payload: data
                })
            })
    };
};

export const GetCompanies = () => {
    let requestData = {
        url: `${_urlCompanies}?`,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;
                await dispatch({
                    type: STATISTICS_CONSTS.GET_COMPANIES,
                    payload: data
                })
            })
            .catch(async () => {
                await dispatch({
                    type: STATISTICS_CONSTS.GET_COMPANIES,
                    payload: []
                })
            })
    };
};

