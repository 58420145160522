// Import packages
import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {Form} from "react-bootstrap";
import {useTranslation} from "react-i18next";

// Import assets
import './filters.scss';

// Import Components
import DropdownMenu from "../dropdownMenu";
import DatePickerDropdowns
    from "../../../pages/Dashboard/containers/Videos/ManageVideo/DatePickerDropdowns/DatePickerDropdowns";
import DatePicker from "react-datepicker";

// Import Utils
import {GetCompanies} from "../../../redux/actions/statisticsActions";
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {changeTimeFormat, hasPermission, underscoreChannelSort} from "../../../utils/helperFunctions";
import {CloseIcon, DownIcon, FilterCenterIcon, SearchIcon, SortIcon} from "../../../image";
import {linkTypes, statusesListRu} from "../../../utils/constants";

const initFilters = {
    description_like: '',
    date_start: '',
    date_end: '',
    statuses: '',
    owner_name_like: '',
    share_link_types: '',
    channel_ids: '',
    meeting_participant_nickname_like: '',
    sort_by: '',
    name_like: '',
    role_ids: '',
    company_ids: '',
}

function Filters(props) {
    const {channels, selectedFilters, filters, setFilters, sortList, rolesList, companies} = props;
    const [searchItems, setSearchItems] = useState(initFilters);
    const [menuIsClosed, setMenuIsClosed] = useState(true);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(null);
    const [selectedSort, setSelectedSort] = useState(undefined);

    const {t} = useTranslation();

    useEffect(() => {
        hasPermission('companies_read') && props.GetCompanies()

        let tomorrow = new Date();
        tomorrow.setDate(tomorrow?.getDate() + 1);

        setStartDate(new Date())
        setEndDate(tomorrow)
        setSelectedSort(sortList?.creation_time_desc?.key ? sortList?.creation_time_desc?.key
            : sortList?.name?.key)
    }, []);

    function getSearchItemsValues(e) {
        const {value, name} = e.target;
        setSearchItems({
            ...searchItems,
            [name]: value
        })
    }

    function changeStartTime(date) {
        const dateStart = new Date(date);
        setStartDate(dateStart);
        dateStart.setHours(0, 0, 0, 0);
    }

    function changeEndTime(date) {
        const dateEnd = new Date(date);
        setEndDate(dateEnd);
        dateEnd.setHours(0, 0, 0, 0);
    }

    function changeTime(dates) {
        const [start, end] = dates;

        setStartDate(start);
        setEndDate(end);
    }

    function handleSort(sort) {
        setSelectedSort(sort)
        setFilters({
            ...filters,
            sort_by: sort
        })
    }

    function clearItem(name) {
        setSearchItems({
            ...searchItems,
            [name]: ''
        })
        setFilters({
            ...filters,
            [name]: ''
        })
        setMenuIsClosed(true)
    }

    function onHideMenu(isClosed) {
        setMenuIsClosed(isClosed)
    }

    let x = "Личное";
    let newChannelsList = !!channels?.length && channels?.sort((a, b) => b.id - a.id);
    hasPermission('someone_else_channels_read') &&
    (newChannelsList = !!newChannelsList?.length && newChannelsList?.sort(underscoreChannelSort))

    newChannelsList = !!newChannelsList?.length && newChannelsList?.sort((a, b) => {
        if (a.name === x) {
            return -1;
        }
        if (b.id === x) {
            return 1;
        }
        return 0;
    });

    return <div className="filters-row">
        <DropdownMenu button={
            <button className="sort-btn">
                <SortIcon className="sort-icon"/>
                <span className="sort-btn__name">
                                {sortList?.[selectedSort]?.name}
                        </span>
                <DownIcon className="arrow-icon"/>
            </button>
        }
                      className={'sort-select'}
                      contentClassName={'sort-content'}
                      zIndex={1010}
        >
            <div className="sort-content__list">
                {
                    Object.keys(sortList)?.map(item => {
                        return <div className="sort-item" key={item}
                                    onClick={() => {
                                        handleSort(item)
                                    }}>
                            <Form>
                                <Form.Check
                                    className="sort-item__check"
                                    type={'radio'}
                                    checked={item === selectedSort}
                                    onChange={() => {
                                        handleSort(item)
                                    }}
                                />
                            </Form>

                            {sortList[item]?.name}
                        </div>
                    })
                }
            </div>
        </DropdownMenu>

        {selectedFilters?.includes('person_name') && <SearchDropDown t={t}
                                                                     title={'Имя'}
                                                                     getSearchItemsValues={getSearchItemsValues}
                                                                     onHideMenu={onHideMenu}
                                                                     setMenuIsClosed={setMenuIsClosed}
                                                                     clearItem={clearItem}
                                                                     searchItems={searchItems}
                                                                     setFilters={setFilters}
                                                                     menuIsClosed={menuIsClosed}
                                                                     name={'name_like'}
                                                                     filters={filters}/>}

        {selectedFilters?.includes('name') && <SearchDropDown t={t}
                                                              title={'Название'}
                                                              getSearchItemsValues={getSearchItemsValues}
                                                              onHideMenu={onHideMenu}
                                                              setMenuIsClosed={setMenuIsClosed}
                                                              clearItem={clearItem}
                                                              searchItems={searchItems}
                                                              setFilters={setFilters}
                                                              menuIsClosed={menuIsClosed}
                                                              name={'description_like'}
                                                              filters={filters}/>}

        {selectedFilters?.includes('data') && <DropdownMenu button={
            <button className="filter-btn name-filter">
                <FilterCenterIcon/>
                Дата:&nbsp;
                {filters.date_start ? changeTimeFormat(filters?.date_start)?.slice(0, -14) : 'Все'}
                {filters.date_end && ` - ${changeTimeFormat(filters?.date_end)?.slice(0, -9)}`}
                {filters.date_start && <CloseIcon className="filter-clear"
                                                  onClick={(e) => {
                                                      e.stopPropagation()
                                                      let tomorrow = new Date();
                                                      tomorrow.setDate(tomorrow?.getDate() + 1);

                                                      setStartDate(new Date())
                                                      setEndDate(tomorrow)
                                                      setFilters({
                                                          ...filters,
                                                          date_start: '',
                                                          date_end: '',
                                                      })
                                                  }}/>}
            </button>}
                                                            closeAfterToggle={false}
                                                            closeDropdown={menuIsClosed}
                                                            onClose={onHideMenu}
                                                            className="filter-menu"
                                                            contentClassName="filter-name"
        >
            <div className="calendar-row">
                {startDate && <div className="dropdowns-wrap">
                    с
                    <DatePickerDropdowns changeTime={changeStartTime}
                                         date={startDate}/>
                </div>}
                <div className="dropdowns-wrap">
                    по
                    {endDate && <DatePickerDropdowns changeTime={changeEndTime}
                                                     date={endDate}/>}
                </div>
            </div>
            <DatePicker
                inline
                selected={startDate}
                startDate={startDate}
                endDate={endDate}
                onChange={(date) => changeTime(date)}
                monthsShown={2}
                selectsRange
            />

            <div className="update_buttons">
                <button className={`close_btn`}
                    // onClick={onClose}
                >
                    {t('button_cancel')}
                </button>

                <button className={`save_btn ${!endDate ? 'disabled' : ''}`}
                        disabled={!endDate}
                        onClick={() => {
                            setFilters({
                                ...filters,
                                date_start: startDate,
                                date_end: endDate,
                            })
                        }}
                >
                    Применить
                </button>
            </div>
        </DropdownMenu>}
        {selectedFilters?.includes('status') && <CheckListDropDown t={t}
                                                                   title={`Статус: ${filters.statuses ?
                                                                       statusesListRu[filters.statuses]?.key : 'Все'}`}
                                                                   list={statusesListRu}
                                                                   getSearchItemsValues={getSearchItemsValues}
                                                                   onHideMenu={onHideMenu}
                                                                   clearItem={clearItem}
                                                                   searchItems={searchItems}
                                                                   setFilters={setFilters}
                                                                   name={'statuses'}
                                                                   filters={filters}/>}
        {selectedFilters?.includes('owner') && <SearchDropDown t={t}
                                                               title={'Владелец'}
                                                               getSearchItemsValues={getSearchItemsValues}
                                                               onHideMenu={onHideMenu}
                                                               setMenuIsClosed={setMenuIsClosed}
                                                               menuIsClosed={menuIsClosed}
                                                               searchItems={searchItems}
                                                               clearItem={clearItem}
                                                               setFilters={setFilters}
                                                               name={'owner_name_like'}
                                                               filters={filters}/>}
        {selectedFilters?.includes('link') && <CheckListDropDown t={t}
                                                                 title={`Ссылка: ${filters.share_link_types
                                                                     ? linkTypes[filters.share_link_types]?.name : 'Все'}`}
                                                                 list={linkTypes}
                                                                 getSearchItemsValues={getSearchItemsValues}
                                                                 onHideMenu={onHideMenu}
                                                                 clearItem={clearItem}
                                                                 searchItems={searchItems}
                                                                 setFilters={setFilters}
                                                                 name={'share_link_types'}
                                                                 filters={filters}/>}
        {selectedFilters?.includes('role')
            && hasPermission('roles_read') && <CheckListDropDown t={t}
                                                                     title={`Роль: ${filters.role_ids ?
                                                                         rolesList?.find(i => i.id === filters.role_ids)?.name : 'Все'}`}
                                                                     list={rolesList}
                                                                     getSearchItemsValues={getSearchItemsValues}
                                                                     onHideMenu={onHideMenu}
                                                                     clearItem={clearItem}
                                                                     searchItems={searchItems}
                                                                     setFilters={setFilters}
                                                                     name={'role_ids'}
                                                                     withSearch={true}
                                                                     filters={filters}/>}
        {hasPermission('companies_read') && selectedFilters?.includes('company') && <CheckListDropDown t={t}
                                                                    title={`Компания: ${filters.company_ids ? companies?.find(i => i.id === filters.company_ids)?.name : 'Все'}`}
                                                                    list={companies}
                                                                    getSearchItemsValues={getSearchItemsValues}
                                                                    onHideMenu={onHideMenu}
                                                                    clearItem={clearItem}
                                                                    searchItems={searchItems}
                                                                    setFilters={setFilters}
                                                                    name={'company_ids'}
                                                                    withSearch={true}
                                                                    filters={filters}/>}
        {selectedFilters?.includes('channels') && <CheckListDropDown t={t}
                                                                     title={`Каналы: ${filters.channel_ids ?
                                                                         newChannelsList?.find(i => i.id === filters.channel_ids)?.name : 'Все'}`}
                                                                     list={newChannelsList}
                                                                     getSearchItemsValues={getSearchItemsValues}
                                                                     onHideMenu={onHideMenu}
                                                                     clearItem={clearItem}
                                                                     searchItems={searchItems}
                                                                     setFilters={setFilters}
                                                                     name={'channel_ids'}
                                                                     withSearch={true}
                                                                     filters={filters}/>}

        {selectedFilters?.includes('users') && <SearchDropDown t={t}
                                                               title={'Участники'}
                                                               getSearchItemsValues={getSearchItemsValues}
                                                               onHideMenu={onHideMenu}
                                                               clearItem={clearItem}
                                                               searchItems={searchItems}
                                                               setFilters={setFilters}
                                                               setMenuIsClosed={setMenuIsClosed}
                                                               menuIsClosed={menuIsClosed}
                                                               name={'meeting_participant_nickname_like'}
                                                               filters={filters}/>}
    </div>;
}

function MyCheckbox({checked, handleCheckboxChange, disabled, label, className}) {
    const [isChecked, setIsChecked] = useState(false);
    useEffect(() => {
        setIsChecked(checked)
    }, [checked])

    return (
        <Form>
            <Form.Check
                type="checkbox"
                checked={isChecked}
                className={className}
                disabled={disabled}
                label={label}
                onChange={handleCheckboxChange}
            />
        </Form>
    );
}

function SearchDropDown({
                            name,
                            title,
                            t,
                            clearItem,
                            getSearchItemsValues,
                            searchItems,
                            onHideMenu,
                            setFilters,
                            filters,
                            menuIsClosed
                        }) {
    return <DropdownMenu button={
        <button className="filter-btn name-filter">
            <FilterCenterIcon/>
            {title}:&nbsp;
            {filters[name] ? filters[name] : 'Все'}
            {filters[name] && <CloseIcon className="filter-clear"
                                         onClick={(e) => {
                                             e.stopPropagation()
                                             clearItem(name)
                                         }}/>}
        </button>
    }
                         closeAfterToggle={false}
                         closeDropdown={menuIsClosed}
                         onClose={onHideMenu}
                         className="filter-menu"
                         contentClassName="filter-name"
    >
        <div className="name-search-wrapper">
            <SearchIcon/>
            <input
                onChange={getSearchItemsValues}
                value={searchItems[name]}
                name={name}
                placeholder={t('input_search')}
                type="text"
            />
        </div>
        <div className="update_buttons">
            <button className={`close_btn search_btn`}
                    onClick={() => {
                        clearItem(name)
                        onHideMenu(false)
                    }}
            >
                {t('button_cancel')}
            </button>

            <button className={`save_btn search_btn ${!searchItems[name] ? 'disabled' : ''}`}
                    disabled={!searchItems[name]}
                    onClick={() => {
                        onHideMenu(false)
                        setFilters({
                            ...filters,
                            [name]: searchItems[name]
                        })
                    }}
            >
                Применить
            </button>
        </div>
    </DropdownMenu>
}

function CheckListDropDown({
                               name,
                               title,
                               t,
                               clearItem,
                               getSearchItemsValues,
                               searchItems,
                               onHideMenu,
                               setFilters,
                               filters,
                               menuIsClosed,
                               list,
                               withSearch = false
                           }) {

    const [text, setText] = useState('');

    const items = withSearch ? list?.filter(i => i.name.includes(text))
        : Object.keys(list)

    return <DropdownMenu button={
        <button className="filter-btn name-filter">
            <FilterCenterIcon/>
            {title}
            {filters[name] && <CloseIcon className="filter-clear"
                                         onClick={(e) => {
                                             e.stopPropagation()
                                             clearItem(name)
                                         }}/>}
        </button>
    }
                         closeAfterToggle={false}
                         closeDropdown={menuIsClosed}
                         onClose={onHideMenu}
                         className="filter-menu"
                         contentClassName="filter-name"
    >
        {
            !!list?.length && withSearch && <div className="name-search-wrapper">
                <SearchIcon/>
                <input
                    onChange={(e) => setText(e.target.value)}
                    value={text}
                    name="text"
                    placeholder={t('input_search')}
                    type="text"
                />
            </div>
        }
        <div className="list">
            {
                items?.map(item => {
                    return <MyCheckbox checked={withSearch ? searchItems[name] === item.id : searchItems[name] === item}
                                       label={withSearch ? item?.name : name === 'statuses' ? list[item]?.key : list[item]?.name}
                                       key={withSearch ? item?.id : item}
                                       className={`filters-item`}
                                       handleCheckboxChange={() => {
                                           getSearchItemsValues({
                                               target: {
                                                   name,
                                                   value: withSearch ? searchItems[name] === item?.id ? '' : item?.id
                                                       : searchItems[name] === item ? '' : item
                                               }
                                           })
                                       }}/>
                })
            }
        </div>
        <div className="update_buttons">
            <button className={`close_btn search_btn`}
                    onClick={() => {
                        onHideMenu(true)
                        clearItem(name)
                    }}
            >
                {t('button_cancel')}
            </button>

            <button className={`save_btn search_btn ${!searchItems[name] ? 'disabled' : ''}`}
                    disabled={!searchItems[name]}
                    onClick={(e) => {
                        e.stopPropagation()
                        setFilters({
                            ...filters,
                            [name]: searchItems[name]
                        })
                        onHideMenu(true)
                    }}
            >
                Применить
            </button>
        </div>
    </DropdownMenu>
}


const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'channels',
        'rolesList',
        'companies',
    ])
};

const mapDispatchToProps = {GetCompanies};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
