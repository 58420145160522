// Import packages
import React from 'react';
import {connect} from "react-redux";

// Import assets
import './card-view-buttons.scss';

// Import Components

// Import Utils
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {GridIcon, ListIcon} from "../../../image";
import {ChangeGridView} from "../../../redux/actions";

function CardViewButtons(props) {
    const {gridView, ChangeGridView} = props;

    return <div className="view-buttons">
        <GridIcon className={`${gridView ? 'active' : ''}`}
                  onClick={() => ChangeGridView(true)}/>
        <ListIcon className={`${gridView ? '' : 'active'}`}
                  onClick={() => ChangeGridView(false)}/>
    </div>;
}

const mapDispatchToProps = {ChangeGridView};

const mapStateToProps = (state) => {
    return getPropsFromState(state, ['gridView'])
};

export default connect(mapStateToProps, mapDispatchToProps)(CardViewButtons);
