// Import packages
import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

// Import assets
import "react-datepicker/dist/react-datepicker.css";
import './event-item.scss';
import {
    AlarmIcon, CalendarVideoIcon,
    GoogleMeetIcon,
    GuestIcon, RecordGreenIcon, SettingsIcon,
    ShareGreenIcon, VideoGreyIcon
} from "../../../../image";


//Import utils
import {getPropsFromState} from "../../../../redux/mapStateToProps";
import {ReplaceProvider, UpdateCalendarProvider, UpdateCalendarsEvents} from "../../../../redux/actions";
import {getLocalTime} from "../../../../utils/helperFunctions";
import {useTranslation} from "react-i18next";
import {Form, Toast} from "react-bootstrap";
import {history} from "../../../../utils/history";
import InfoTooltip from "../../../../pages/Dashboard/containers/Profile/tooltip";
import {providerTypes} from "../../../../utils/constants";
import DropdownMenu from "../../dropdownMenu";

function EventItem(props) {
    const {data} = props;
    const [enabled, setEnabled] = useState(true);
    const [showToast, setShowToast] = useState(false);
    const [selectedProvider, setSelectedProvider] = useState(undefined);
    const [onlyThis, setOnlyThis] = useState(true);

    const {t} = useTranslation();

    useEffect(() => {
        setEnabled(data?.need_record)
    }, [data?.need_record])

    useEffect(() => {
        data?.link_provider && setSelectedProvider(data?.link_provider)
    }, [data?.link_provider])

    function copyPersonLink(url) {
        navigator.clipboard.writeText(url);
        toggleToast()
    }

    function toggleNeedRecord() {
        setEnabled(!enabled)
        props.UpdateCalendarsEvents(data?.id, {
            "need_record": !enabled
        })
    }

    function navigateTo() {
        data?.is_actual ? window.open(data?.html_link)
            : history.push({
                pathname: `/dashboard/videos/video/${data?.video_id}`,
                state: {
                    routs: [
                        {
                            name: t('calendar_title')
                        },
                    ]
                }

            })
    }

    const toggleToast = () => setShowToast(!showToast);

    function ErrorToast() {
        return <Toast show={showToast} onClose={toggleToast} className={'upload_video_answer'}>
            <Toast.Header>
                <img
                    src=""
                    className="rounded me-2"
                    alt=""
                />
                <strong className="me-auto">Успешно</strong>
            </Toast.Header>
            <Toast.Body>Успешно скопировано</Toast.Body>
        </Toast>
    }

    const dateStart = getLocalTime(data?.datetime_start)
    const dateEnd = getLocalTime(data?.datetime_end)


    return <>
        <div className={`calendar__event ${data?.is_actual ? '' : 'passed'}`}>
            <div className="calendar__event__title">
            <span>
               {data?.summary}
            </span>
                <DropdownMenu button={
                    <InfoTooltip elem={<SettingsIcon/>}
                                 title={'Выбрать ВКС'}
                                 className={'settings-icon'}
                                 placement={'left'}/>
                }
                              contentClassName={'provider-select event-provider'}
                              closeAfterToggle={false}
                              zIndex={1100}
                              contentWidth={'353px'}>
                    <div className="provider-content">
                        <div className="header">Выбор ВКС</div>
                        <div className="provider-list">
                            {
                                providerTypes?.map(item => {
                                    return <div className="provider-item" key={item?.key}
                                                onClick={() => {
                                                    // handleSort(item)
                                                }}>
                                        <Form>
                                            <Form.Check
                                                className="provider-item__check"
                                                type={'radio'}
                                                label={<div className="">
                                                    {item?.icon}
                                                    {item?.name}
                                                </div>}
                                                checked={item.key === selectedProvider}
                                                onChange={() => {
                                                    setSelectedProvider(item.key)
                                                    //handleSort(item)
                                                }}
                                            />
                                        </Form>
                                        {/*{item?.name}*/}
                                    </div>
                                })
                            }
                        </div>
                        <div className="meet-types">
                            <Form>
                                <Form.Check
                                    className="provider-item__check"
                                    type={'radio'}
                                    label={'Только эта встреча'}
                                    checked={onlyThis}
                                    onChange={() => {
                                        setOnlyThis(true)
                                    }}
                                />
                                <Form.Check
                                    className="provider-item__check"
                                    type={'radio'}
                                    label={'Эта встреча и последующие'}
                                    checked={!onlyThis}
                                    onChange={() => {
                                        setOnlyThis(false)
                                    }}
                                />
                            </Form>
                        </div>
                        <div className="buttons-wrapper">
                            <button className={`close_btn`}
                                // onClick={onClose}
                            >
                                {t('button_cancel')}
                            </button>

                            <button className={`save_btn `}
                                    onClick={() => {
                                        props.UpdateCalendarProvider(data?.id, {
                                            need_record: true,
                                            provider: selectedProvider
                                        })
                                        !onlyThis && props.ReplaceProvider({
                                            start_calendar_event_id: data?.id,
                                            provider: selectedProvider
                                        })
                                    }}
                            >
                                {t('button_confirm')}
                            </button>
                        </div>
                    </div>
                </DropdownMenu>
            </div>
            <div className="calendar__event__row">
                <AlarmIcon/>
                {dateStart} - {dateEnd}
            </div>
            <div className="calendar__event__row">
                <GuestIcon/>
                {data?.attendees?.length} {t('calendar_guest')}
            </div>
            {data?.link_provider && <div className="calendar__event__row">
                {providerTypes.find(i => i.key === data?.link_provider).icon}
                {providerTypes.find(i => i.key === data?.link_provider).name}
            </div>}
            <div className="calendar__event__row">
                <div onClick={navigateTo} className="social see-video">
                    {!data?.is_actual ? <VideoGreyIcon/> :
                        data?.user_calendar?.calendar_service?.name === 'google' ? <GoogleMeetIcon/> :
                            <CalendarVideoIcon/>
                    }
                    {data?.is_actual ? t('calendar_join_meeting') : t('see')}
                </div>
                <div className="social">
                    <ShareGreenIcon className="social-icon"
                                    onClick={() => data?.is_actual && copyPersonLink(data?.html_link)}/>
                    <div className={`custom_switch ${enabled ? 'enabled' : ''}`}
                         onClick={data?.is_actual && toggleNeedRecord}>
                        <RecordGreenIcon/>
                    </div>
                </div>
            </div>
        </div>

        {ErrorToast()}
    </>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [])
};

const mapDispatchToProps = {
    UpdateCalendarsEvents,
    UpdateCalendarProvider,
    ReplaceProvider
};

export default connect(mapStateToProps, mapDispatchToProps)(EventItem);
