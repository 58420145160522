// Import packages
import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

// Import assets
import './video-introduction.scss';
import 'rc-rate/assets/index.css';
import {DislikeIcon, DownIcon, EditIcon, LikeIcon} from "../../../image";

// Import Components
import {Form, Tab, Tabs} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import UpdateIntroduction
    from "../../../pages/Dashboard/containers/VideoDetails/update-introduction/UpdateIntroduction";

// Import Utils
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {
    ChangeUserRetellings,
    ChangeUserSummaries,
    SetQualityControls,
    GetVideoById,
    DownloadTranscription,
    AddProtocol,
    ReplaceTranscriptionWord,
    UpdateTranscription, DeleteTranscription, UpdateVideo, GetPrompts
} from "../../../redux/actions";
import {changeTimeFormat, getAuthorName, hasPermission} from "../../../utils/helperFunctions";
import {generateMediaUrl} from "../../../utils/generateMediaUrl";
import UserInfoModal from "../../../pages/Dashboard/containers/Users/modals/userInfoModal/UserInfoModal";
import TextsUpdate from "../../../pages/Dashboard/containers/VideoDetails/tests-update/TextsUpdate";
import SearchTexts from "../../../pages/Dashboard/containers/VideoDetails/search-texts/SearchTexts";
import Texts from "../../../pages/Dashboard/containers/VideoDetails/texts/Texts";
import {useLocation} from "react-router-dom";
import UpdateTextModal from "../../modals/UpdateTextModal/UpdateTextModal";
import DropdownMenu from "../dropdownMenu/DropdownMenu";
import InfoTooltip from "../../../pages/Dashboard/containers/Profile/tooltip";
import {statusesList} from "../../../utils/constants";

const INTRODUCTION_TYPES = {
    SUMMARY: 'summary',
    RETELLING: 'retelling'
}

const initialState = {
    search: '',
    replace: '',
}

function VideoIntroduction(props) {
    const {videoById, token, changeVideoTime, getTime, videoPlayerCurrentTime, promptList, status, setStatus} = props;

    let summaryText = videoById?.user_summaries?.[0]?.text ? videoById?.user_summaries?.[0]?.text : videoById?.summary
    let retellingsText = videoById?.user_retellings?.[0]?.text ? videoById?.user_retellings?.[0]?.text : videoById?.retelling

    const [key, setKey] = useState(INTRODUCTION_TYPES.SUMMARY);
    const [rating, setRating] = useState({
        summary: 0,
        retelling: 0
    });
    const [introduction, setIntroduction] = useState({
        summary: summaryText ? summaryText : '',
        retelling: retellingsText ? retellingsText : '',
        summaryComment: '',
        retellingComment: ''
    });
    const [editing, setIsEditing] = useState({
        summary: false,
        retelling: false
    });
    const [isRated, setIsRated] = useState({
        summary: false,
        retelling: false
    });
    const [showModal, setShowModal] = useState(false)
    const [showTextModal, setShowTextModal] = useState(false)
    const [currentUser, setCurrentUser] = useState(null)
    const [editAll, setEditAll] = useState(false)
    const [searchText, setSearchText] = useState('');
    const [foundedTexts, setFoundedTexts] = useState([]);
    const [activeTextIndex, setActiveTextIndex] = useState(0);
    const [textInfo, setTextInfo] = useState(initialState)
    const [type, setType] = useState(undefined)
    const [selectedIntroduction, setSelectedIntroduction] = useState(undefined);

    const {t} = useTranslation();
    const location = useLocation();

    useEffect(() => {
        props.GetPrompts()
        if (videoById.quality_controls?.length) {
            setRating(getQualityValues())
        }
    }, [])

    useEffect(() => {
        setSelectedIntroduction(videoById?.summary_prompt)
    }, [videoById.summary_prompt?.id])


    function getQualityValues() {
        if (videoById.quality_controls?.length) {
            const sum = videoById.quality_controls?.find(i => i.entity_type === INTRODUCTION_TYPES.SUMMARY)
            const ret = videoById.quality_controls?.find(i => i.entity_type === INTRODUCTION_TYPES.RETELLING)
            return {
                summary: sum?.rating || 0,
                retelling: ret?.rating || 0
            }
        }
    }

    function toggleUserModal(person) {
        setShowModal(!showModal);
        setCurrentUser(person);
    }

    const getInputValue = (e) => {
        const {name, value} = e.target
        setIntroduction({
            ...introduction,
            [name]: value
        })
    };

    const changeEditingType = (type) => {
        setIsEditing({
            ...editing,
            [type]: true
        })
    };

    const saveResult = (type) => {
        type === INTRODUCTION_TYPES.SUMMARY ?
            props.ChangeUserSummaries({
                video_id: videoById?.id,
                text: introduction.summary
            }).then((res) => {
                summaryText = res.text
                setIntroduction({
                    ...introduction,
                    summary: res.text
                })
                props.GetVideoById(videoById?.id)
            })
            : props.ChangeUserRetellings({
                video_id: videoById?.id,
                text: introduction.retelling
            }).then((res) => {
                console.log('res', res);
                retellingsText = res.text
                setIntroduction({
                    ...introduction,
                    retelling: res.text
                })
                props.GetVideoById(videoById?.id)
            })
        onClose(type)
    };

    const sendComment = (type, rate) => {
        setRating({
            ...rating,
            [type]: rate
        })
        props.SetQualityControls({
            video_id: videoById?.id,
            comment: introduction?.[`${type}Comment`],
            entity_type: type,
            rating: rate,
        })
        onCloseComment(type)
    };

    function onClose(type) {
        setIntroduction({
            ...introduction,
            [type]: videoById[type]
        })
        setIsEditing({
            summary: false,
            retelling: false
        })
        setRating({
            ...rating,
            [type]: getQualityValues()?.[type]
        })
        setType(undefined)
    }

    function onCloseComment(type) {
        setIntroduction({
            ...introduction,
            [`${type}Comment`]: ''
        })
        setIsRated({
            summary: false,
            retelling: false
        })
    }

    const toggleActiveTextIndex = (index) => {
        setActiveTextIndex(index)
        changeVideoTime(videoById?.texts?.[foundedTexts[index]]?.start_time || 0)
    };

    function replaceNthOccurrence(str, word, n, replacement) {
        let index = -1;
        for (let i = 0; i < n; i++) {
            index = str.indexOf(word, index + 1);
            if (index === -1) break;
        }

        if (index !== -1) {
            return str.substring(0, index) + replacement + str.substring(index + word?.length);
        } else {
            return str;
        }
    }


    function updateVideoInfo() {
        const urlParams = new URLSearchParams(location.search);
        const paramsUuid = urlParams.get('uuid')

        props.GetVideoById(videoById?.id, paramsUuid)
    }

    function changeText() {
        const item = videoById?.texts?.[foundedTexts[activeTextIndex]]
        let count = 0
        foundedTexts.forEach((i, index) => {
            if (index < activeTextIndex && i === foundedTexts[activeTextIndex]) {
                count++
            }
        })

        let result = replaceNthOccurrence(item?.text, searchText, count, textInfo?.replace);

        props.UpdateTranscription(
            item?.id,
            undefined, undefined,
            {
                text: count ? result : item?.text?.replace(searchText, textInfo?.replace),
                start_time: Number(item?.start_time),
                end_time: Number(item?.end_time)
            })
            .finally(() => {
                updateVideoInfo()
            })

        onCloseSearch()
    }

    function changeAll() {
        props.ReplaceTranscriptionWord({
            video_id: videoById?.id,
            search: searchText,
            replace: textInfo?.replace
        })
        updateVideoInfo()
        onCloseSearch()
    }

    function getTextValue(e) {
        const {name, value} = e.target

        setTextInfo({
            ...textInfo,
            [name]: value
        })
    }

    function onCloseSearch() {
        setEditAll(false)
        setTextInfo(initialState)
        setSearchText('')
        setFoundedTexts([])
        setActiveTextIndex(0)
    }

    function handleSort(introduction) {
        setSelectedIntroduction(introduction)
    }

    let disabledSummaryBtn = introduction.summary === summaryText
    // let disabledRetellingBtn = introduction.retelling === retellingsText
    const data = {
        ...videoById?.user_summaries?.[0]?.user,
        user_id: videoById?.user_summaries?.[0]?.user.id
    }
    const userName = <span className={'user-name'}
                           onClick={(e) => {
                               e.stopPropagation();
                               toggleUserModal(data)
                           }}>
        {getAuthorName(videoById?.user_summaries?.[0]?.user)}
    </span>

    const haveChanges = searchText && textInfo.replace

    const retailingList = introduction?.retelling?.split('\n').map((item) => {
        // console.log('index', index);
        const openTag = item?.includes('<time>') ? '<time>' : '<'
        const closeTag = item?.includes('</time>') ? '</time>' : '>'
        let list = !!item.split(openTag)?.[1] && item?.split(closeTag).map(i => {
            return {
                time: i?.split(openTag)?.[1],
                text: i?.split(openTag)?.[0],
            }
        })
        return {
            title: !item.split(openTag)?.[1] && item.split('/n')?.[0],
            list,
        }
    })

    return <div className="video-introduction">
        <Tabs id="controlled-tab-example"
              activeKey={key} onSelect={(k) => setKey(k)}
              className={'introduction-tabs'}>
            <Tab eventKey={INTRODUCTION_TYPES.SUMMARY} title={<>
                {t('summaries')}
                <UpdateIntroduction ReplaceTranscriptionWord={props.ReplaceTranscriptionWord}
                                    setKey={setKey}
                                    text={introduction?.summary}
                                    changeEditingType={changeEditingType}
                                    type={INTRODUCTION_TYPES.SUMMARY}
                />
            </>}>
                <div className="select_group">
                    <DropdownMenu button={
                        <button className="sort-btn">
                            <span className="sort-btn__name">
                                {selectedIntroduction?.name || 'Выберите формат отчета'}
                            </span>
                            <DownIcon className="arrow-icon"/>
                        </button>
                    }
                                  disabled={!hasPermission('analysis_pipelines_read') || videoById?.video_status === statusesList.processing.key}
                                  closeAfterToggle={true}
                                  fullWidth={true}
                                  className={'sort-select'}
                                  contentClassName={'sort-select'}
                                  position={window.offsetWidth > 624 ? "bottom right" : "bottom left"}
                                  contentWidth={'450px'}>
                        {
                            !!promptList?.length && promptList?.filter(i => i.type === 'summary')?.map((promptItem, idx) => {
                                return <div className={'sort-item'} onClick={() => {
                                    handleSort(promptItem)
                                }} key={idx}>
                                    <Form className="">
                                        <Form.Check
                                            label={promptItem.name}
                                            type={'radio'}
                                            checked={promptItem?.id === selectedIntroduction?.id}
                                            onChange={() => {
                                                handleSort(promptItem)
                                            }}
                                        />
                                    </Form>
                                </div>
                            })
                        }
                    </DropdownMenu>
                    <button className="generate"
                            disabled={
                                (status?.key === statusesList.processing.key)
                                || !selectedIntroduction?.name
                            }
                            onClick={() => {
                                // setLoading(true)

                                props.UpdateVideo(videoById.id, {
                                    summary_prompt_id: selectedIntroduction.id
                                }).then(() => {
                                    setStatus(statusesList.processing)
                                })
                                    .finally(() => {
                                        // setLoading(false)
                                        updateVideoInfo()
                                    });
                            }}>Сгенерировать
                    </button>
                </div>
                {/*<LoadingIcon className="loading-icon"/>*/}
                {
                    (!!introduction.summary) ? <>
                            <div className="summary-title">
                                {
                                    !!videoById?.user_summaries?.length ? <span>
                                        Отредактированно {userName} &nbsp;
                                            {changeTimeFormat(videoById?.user_summaries?.[0]?.save_datetime)}
                                        </span>
                                        : t('ai_text')
                                }
                            </div>

                            {
                                !editing.summary ? <>
                                    <div className="summary-description">
                                        <EditIcon className="edit-btn" onClick={(e) => {
                                            e.stopPropagation()
                                            setShowTextModal(true)
                                            setType(INTRODUCTION_TYPES.SUMMARY)
                                        }}/>
                                        {introduction.summary}
                                    </div>
                                    <div className="rate-title">
                                        {t('ai_answer')}
                                    </div>
                                    <div className="rate-buttons">
                                        <LikeIcon
                                            className={`rate-icon like ${rating.summary === 5 ? 'active' : ''} ${rating.summary !== 0 ? 'disabled' : ''}`}
                                            onClick={() => {
                                                // rating.summary === 0 &&
                                                sendComment(INTRODUCTION_TYPES.SUMMARY, 5)
                                            }}/>
                                        <DislikeIcon
                                            className={`rate-icon dislike ${rating.summary === 1 ? 'active' : ''} ${rating.summary !== 0 ? 'disabled' : ''}`}
                                            onClick={() => {
                                                // if (rating.summary === 0) {
                                                setRating({
                                                    ...rating,
                                                    summary: 1
                                                })
                                                setIsRated({
                                                    ...isRated,
                                                    summary: true
                                                })
                                                // }
                                            }}/>
                                    </div>

                                    {
                                        isRated.summary && <div className="set-comment">
                        <textarea
                            onChange={getInputValue}
                            value={introduction.summaryComment}
                            name="summaryComment"
                            placeholder="Пожалуйста, оставьте любые комментарии или предложения по улучшению"
                        />
                                            <div className="update_buttons">
                                                <button className={`close_btn`}
                                                        onClick={() => {
                                                            onCloseComment(INTRODUCTION_TYPES.SUMMARY)
                                                            setRating({
                                                                ...rating,
                                                                [INTRODUCTION_TYPES.SUMMARY]: getQualityValues()?.[INTRODUCTION_TYPES.SUMMARY]
                                                            })
                                                        }}
                                                >
                                                    {t('button_cancel')}
                                                </button>

                                                <button className={`save_btn`}
                                                        onClick={() => {
                                                            sendComment(INTRODUCTION_TYPES.SUMMARY, 1)
                                                        }}
                                                >
                                                    {t('button_save')}
                                                </button>
                                            </div>
                                        </div>
                                    }

                                    {rating.summary !== 0 && !isRated.summary && <div className="rate-answer">
                                        Благодарим за обратную связь!
                                    </div>}
                                </> : <div className="text_update">
                        <textarea
                            onChange={getInputValue}
                            value={introduction.summary}
                            name="summary"
                        />
                                    <div className="update_buttons">
                                        <button className={`close_btn`}
                                                onClick={() => onClose(INTRODUCTION_TYPES.SUMMARY)}
                                        >
                                            {t('button_cancel')}
                                        </button>

                                        <button className={`save_btn ${disabledSummaryBtn ? 'disabled' : ''}`}
                                                disabled={disabledSummaryBtn}
                                                onClick={() => {
                                                    saveResult(INTRODUCTION_TYPES.SUMMARY)
                                                }}
                                        >
                                            {t('button_save')}
                                        </button>
                                    </div>
                                </div>
                            }
                        </>
                        : (statusesList.processing?.key === status?.key) ? <div className="loading-wrapper">
                            <div className="spinner-border" role="status"/>
                            <div className="empty-text">
                                AI ассистент MAX генерирует итоги встречи. Это займет всего несколько минут.
                            </div>
                        </div> : <div className="empty-text">
                            Чтобы сформировать итоги встречи, выберите один из предложенных промптов и нажмите
                            <br/>
                            'Сгенерировать'.
                            <br/><br/>
                            Вы также можете перегенерировать отчет, выбрав другой промпт для наилучшего соответствия
                            вашим потребностям.
                        </div>
                }
            </Tab>
            <Tab eventKey={INTRODUCTION_TYPES.RETELLING} title={<>
                {'Пересказ'}
                <UpdateIntroduction ReplaceTranscriptionWord={props.ReplaceTranscriptionWord}
                                    setKey={setKey}
                                    text={introduction?.retelling}
                                    changeEditingType={changeEditingType}
                                    type={INTRODUCTION_TYPES.RETELLING}
                />
            </>}>
                <div className="summary-title">
                    {
                        !!videoById?.user_retellings?.length ?
                            `Отредактированно ${getAuthorName(videoById?.user_retellings?.[0]?.user)} ${changeTimeFormat(videoById?.user_retellings?.[0]?.save_datetime)}`
                            : t('ai_text')
                    }
                </div>
                {
                    !!retailingList?.length && retailingList?.map(item => {
                        return <>
                            <div className="summary-text">
                                {item?.title}
                            </div>
                            <div className="summary-list">
                                {
                                    !!item?.list?.length && item?.list?.map(item => {
                                        let time = item?.time

                                        return item.time && <div className="summery-item">
                                            <div className="time" onClick={() => {
                                                const timeArr = time?.split(':')
                                                const h = Number(timeArr[0]) * 60 * 60
                                                const s = Number(timeArr[2])
                                                let ms = h + (Number(time.split(':')[1]) * 60) + s;
                                                changeVideoTime(ms)
                                            }}>{time}</div>
                                            <div className="text">{item?.text}</div>
                                            <InfoTooltip
                                                placement={'left'}
                                                elem={<EditIcon className="edit-btn"/>}
                                                title={t('button_edit')}/>

                                        </div>
                                    })
                                }
                            </div>
                        </>
                    })
                }
                {/*{introduction.retelling && <>
                    <div className="summary-title">
                        {
                            !!videoById?.user_retellings?.length ?
                                `Отредактированно ${getAuthorName(videoById?.user_retellings?.[0]?.user)} ${changeTimeFormat(videoById?.user_retellings?.[0]?.save_datetime)}`
                                : t('ai_text')
                        }
                    </div>

                        {
                            !editing.retelling ? <>
                                <div className="summary-description">
                                    <EditIcon className="edit-btn" onClick={(e) => {
                                        e.stopPropagation()
                                        setShowTextModal(true)
                                        setType(INTRODUCTION_TYPES.RETELLING)
                                    }}/>
                                    {introduction.retelling}
                                </div>
                                <div className="rate-title">
                                    {t('ai_answer')}
                                </div>

                            <div className="rate-buttons">
                                <LikeIcon
                                    className={`rate-icon like ${rating.retelling === 5 ? 'active' : ''} ${rating.retelling !== 0 ? 'disabled' : ''}`}
                                    onClick={() => {
                                        // rating.retelling === 0 &&
                                        sendComment(INTRODUCTION_TYPES.RETELLING, 5)
                                    }}/>
                                <DislikeIcon
                                    className={`rate-icon dislike ${rating.retelling === 1 ? 'active' : ''} ${rating.retelling !== 0 ? 'disabled' : ''}`}
                                    onClick={() => {
                                        setRating({
                                            ...rating,
                                            retelling: 1
                                        })
                                        setIsRated({
                                            ...isRated,
                                            retelling: true
                                        })
                                    }}/>
                            </div>
                            {
                                isRated.retelling && <div className="set-comment">
                        <textarea
                            onChange={getInputValue}
                            value={introduction.retellingComment}
                            name="retellingComment"
                            placeholder="Пожалуйста, оставьте любые комментарии или предложения по улучшению"
                        />
                                    <div className="update_buttons">
                                        <button className={`close_btn`}
                                                onClick={() => {
                                                    onCloseComment(INTRODUCTION_TYPES.RETELLING)
                                                    setRating({
                                                        ...rating,
                                                        [INTRODUCTION_TYPES.RETELLING]: getQualityValues()?.[INTRODUCTION_TYPES.RETELLING]
                                                    })
                                                }}
                                        >
                                            {t('button_cancel')}
                                        </button>

                                        <button className={`save_btn`}
                                                onClick={() => {
                                                    sendComment(INTRODUCTION_TYPES.RETELLING, 1)
                                                }}
                                        >
                                            {t('button_save')}
                                        </button>
                                    </div>
                                </div>
                            }

                            {rating.retelling !== 0 && !isRated.retelling && <div className="rate-answer">
                                Благодарим за обратную связь!
                            </div>}
                        </> : <div className="text_update">
                        <textarea
                            onChange={getInputValue}
                            value={introduction.retelling}
                            name="retelling"
                        />
                            <div className="update_buttons">
                                <button className={`close_btn`}
                                        onClick={() => onClose(INTRODUCTION_TYPES.RETELLING)}
                                >
                                    {t('button_cancel')}
                                </button>

                                <button className={`save_btn ${disabledRetellingBtn ? 'disabled' : ''}`}
                                        disabled={disabledRetellingBtn}
                                        onClick={() => {
                                            saveResult(INTRODUCTION_TYPES.RETELLING)
                                        }}
                                >
                                    {t('button_save')}
                                </button>
                            </div>
                        </div>
                    }
                </>}*/}
            </Tab>
            {hasPermission('transcriptions_read') && <Tab eventKey={'3'} title={<>
                {'Стенограмма'}
                <TextsUpdate DownloadTranscription={props.DownloadTranscription}
                             ReplaceTranscriptionWord={props.ReplaceTranscriptionWord}
                             videoId={videoById?.id}
                             videoName={videoById?.description}
                             setKey={setKey}
                             setEditAll={setEditAll}/>
            </>}>
                {!editAll && <SearchTexts searchText={searchText}
                                          setSearchText={setSearchText}
                                          foundedTexts={foundedTexts}
                                          activeTextIndex={activeTextIndex}
                                          toggleActiveTextIndex={toggleActiveTextIndex}
                />}

                {
                    editAll && <div className="edit-texts-wrapper">
                        <SearchTexts searchText={searchText}
                                     setSearchText={setSearchText}
                                     foundedTexts={foundedTexts}
                                     activeTextIndex={activeTextIndex}
                                     toggleActiveTextIndex={toggleActiveTextIndex}
                        />
                        <div className="input_group">
                            <EditIcon/>
                            <input onChange={getTextValue}
                                   value={textInfo.replace}
                                   name="replace"
                                   placeholder={'Заменить на'}
                                   type="text"
                            />
                        </div>

                        <div className="buttons">
                            <button className={`close_btn`}
                                    onClick={onCloseSearch}
                            >
                                {t('button_cancel')}
                            </button>

                            <button className={`save_btn ${!haveChanges ? 'disabled' : ''}`}
                                    disabled={!haveChanges}
                                    onClick={changeText}
                            >
                                Заменить
                            </button>
                            <button className={`save_btn ${!haveChanges ? 'disabled' : ''}`}
                                    disabled={!haveChanges}
                                    onClick={changeAll}
                            >
                                Заменить всё
                            </button>
                        </div>
                    </div>
                }

                <Texts texts={videoById?.texts}
                       activeTextIndex={activeTextIndex}
                       searchText={searchText}
                       setFoundedTexts={setFoundedTexts}
                       getTime={getTime}
                       changeVideoTime={changeVideoTime}
                       AddProtocol={props.AddProtocol}
                       GetVideoById={props.GetVideoById}
                       UpdateTranscription={props.UpdateTranscription}
                       ReplaceTranscriptionWord={props.ReplaceTranscriptionWord}
                       videoId={videoById?.id}
                       videoPersons={videoById.persons}
                       token={token}
                    // isPlayed={isPlayed}
                    // lastProtocolId={protocolList?.[protocolList?.length - 1]?.id}
                       videoPlayerCurrentTime={videoPlayerCurrentTime}
                       MarkVideo={props?.MarkVideo}
                       DeleteTranscription={props?.DeleteTranscription}
                />
            </Tab>}
            <Tab eventKey={'4'} title={'Участники'}>
                <div className="video-persons">
                    {
                        !!videoById?.meeting_participants?.length
                        && videoById?.meeting_participants?.map((item) => {
                            const data = {
                                ...item,
                                person_id: item.id
                            }
                            delete data.id
                            return <div className={`person-item`}
                                        key={item?.id}>
                                <img
                                    src={generateMediaUrl(item?.image_path)}
                                    alt=""
                                    className="person-item-img"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        toggleUserModal(data)
                                    }}
                                />
                                <div className="person-item-info"
                                     onClick={(e) => {
                                         e.stopPropagation();
                                         !data?.nickname && toggleUserModal(data)
                                     }}>
                                    {data?.nickname ? data?.nickname : getAuthorName(item)}
                                </div>
                            </div>
                        })
                    }
                </div>
            </Tab>
        </Tabs>
        {showModal && <UserInfoModal show={showModal}
                                     closeModal={toggleUserModal}
                                     user={currentUser}
                                     videoPersonsList={videoById?.meeting_participants}/>}
        {showTextModal && <UpdateTextModal show={showTextModal}
                                           introduction={introduction}
                                           type={type}
                                           getInputValue={getInputValue}
                                           saveResult={saveResult}
                                           onHide={() => setShowTextModal(!showTextModal)}/>}
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, ['token', 'isMobile', 'promptList'])
};

const mapDispatchToProps = {
    ChangeUserSummaries,
    ChangeUserRetellings,
    SetQualityControls,
    GetVideoById,
    AddProtocol,
    ReplaceTranscriptionWord,
    UpdateTranscription,
    DownloadTranscription,
    DeleteTranscription,
    UpdateVideo,
    GetPrompts
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoIntroduction);
