import { _hostMediaUrl } from "../redux/api";
import {user_img} from "../image";

export function generateMediaUrl (path) {
    if (path?.startsWith('http')) {
        return path;
    }

    return path && path !== 'None' ? `${_hostMediaUrl}/${path}` : user_img;
}

export function generateVideoUrl (path) {
    if (path?.startsWith('http')) {
        return path;
    }

    return path && path !== 'None' && `${_hostMediaUrl}/${path}`;
}
