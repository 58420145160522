import React, {useState, useEffect} from 'react';
import DropdownMenu from "../../../../../../shared/features/dropdownMenu";
import {ArrowDownIcon} from "../../../../../../image";

function DatePickerDropdowns({changeTime, date}) {
    const [day, setDay] = useState('');
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');

    // Month names
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];

    // Generate years (example: 1900 - current year)
    const years = Array.from({length: new Date().getFullYear() - 1899}, (_, i) => 1900 + i).reverse();

    // Generate days based on month and year
    const getDaysInMonth = (monthIndex, year) => new Date(year, monthIndex + 1, 0).getDate();
    const days = Array.from({length: getDaysInMonth(monthNames.indexOf(month), year)}, (_, i) => i + 1);

    useEffect(() => {
        const day = date?.getDate()
        const month = date?.getMonth()
        const year = date?.getFullYear()

        setDay(day)
        setMonth(monthNames[month])
        setYear(year)
    }, [date])

    useEffect(() => {
        if (day && month && year) {
            const formattedDate = `${year}-${monthNames.indexOf(month) + 1}-${day}`;

            changeTime(formattedDate)
        }
    }, [day, month, year]);

    return (
        <div className={`date-dropdowns`}>
            <DropdownMenu button={<>
                    <span>{day}</span>
                    <ArrowDownIcon className={'icon-menu'}/>
                </>}
                          closeAfterToggle={true}
                          className="day"
                          contentWidth={'54px'}
            >
                {days.map((d) => {
                    return <div className={`date-item`}
                                onClick={() => setDay(d)}
                                key={d}>{d}</div>
                })}
            </DropdownMenu>

            <DropdownMenu button={<>
                    <span>{month}</span>
                    <ArrowDownIcon className={'icon-menu'}/>
                </>}
                          closeAfterToggle={true}
                          className="month"
                          contentWidth={'102px'}
            >
                {monthNames.map((m) => {
                    return <div className={`date-item`}
                                onClick={() => setMonth(m)}
                                key={m}>{m}</div>
                })}
            </DropdownMenu>

            <DropdownMenu button={<>
                    <span>{year}</span>
                    <ArrowDownIcon className={'icon-menu'}/>
                </>}
                          closeAfterToggle={true}
                          className="year"
                          contentWidth={'70px'}
            >
                {years.map((y) => {
                    return <div className={`date-item`}
                                onClick={() => setYear(y)}
                                key={y}>{y}</div>
                })}
            </DropdownMenu>

        </div>
    );
}

export default DatePickerDropdowns;
