import {PERSONS_CONSTS, USERS_CONSTS} from "../constants";

export const initialState = {
    usersList: {},
    usersPersonsList: {},
    userById: null,
    searchedUsers: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case USERS_CONSTS.GET_USERS:
            return {
                ...state,
                usersList: action.payload,
            };
        case USERS_CONSTS.SEARCH_USERS:
            return {
                ...state,
                searchedUsers: action.payload,
            };
        case USERS_CONSTS.SEARCH_USERS_CLEAR:
            return {
                ...state,
                searchedUsers: [],
            };
        case USERS_CONSTS.GET_USERS_PERSONS:
            return {
                ...state,
                usersPersonsList: action.payload,
            };
        case USERS_CONSTS.ADD_USER:
            return {
                ...state,
                usersPersonsList: {
                    ...state.usersPersonsList,
                    results: [action.payload, ...state.usersPersonsList.results]
                },
            };
        case USERS_CONSTS.DELETE_USER:
            return {
                ...state,
                usersPersonsList: {
                    ...state.usersPersonsList,
                    results: state.usersPersonsList.results.filter(item => item.user_id !== action.payload)
                },

            };
        case USERS_CONSTS.UPDATE_USER:
            let updatedList = state.usersPersonsList.results.map(item => {
                return item.user_id === action.payload.id ? {
                    ...item,
                    first_name: action.payload.data.first_name,
                    last_name: action.payload.data.last_name,
                    login: action.payload.data.login,
                } : item
            })
            return {
                ...state,
                usersPersonsList: {
                    ...state.usersPersonsList,
                    results: updatedList
                },
            };
        case PERSONS_CONSTS.UPDATE_PERSONS:
            let updatedPersonsList = state.usersPersonsList.results.map(item => {
                let data = {}
                Object.entries(action.payload.user).forEach(([key, value]) => {
                    if (!!value) {
                        data[key] = value
                    }
                })
                return item.person_id === action.payload.id ? {
                    ...item,
                    ...data
                } : item
            })
            return {
                ...state,
                usersPersonsList: {
                    ...state.usersPersonsList,
                    results: updatedPersonsList
                },
            };
        case USERS_CONSTS.CLEAR_USERS:
            return {
                ...state,
                usersList: {},
                usersPersonsList: {},
            };
        case USERS_CONSTS.GET_USER_BY_ID:
            return {
                ...state,
                userById: action.payload,
            };
        case USERS_CONSTS.CLEAR_USER_BY_ID:
            return {
                ...state,
                userById: null,
            };
        case PERSONS_CONSTS.UPDATE_PERSON_TAGS:
            return {
                ...state,
                usersPersonsList: {
                    ...state.usersPersonsList,
                    results: state.usersPersonsList?.results?.map(item => {
                        return item.person_id === action.payload?.id ? {
                                ...item,
                                tags: action?.payload?.tagsList?.filter(tag => {
                                    return action?.payload?.data?.tags?.find(i => tag.id === i.id).is_bound && item
                                })
                            }
                            : item
                    })
                }
            };
        default:
            return state;
    }
}
