// Import packages
import React, {useState, useEffect, useLayoutEffect} from 'react';
import {connect} from "react-redux";

// Import assets
import './manageVideo.scss';

// Import Components
import ListItem from "./ListItem/ListItem";
import CustomPagination from "../../../../../shared/features/Pagination/CustomPagination";

// Import Utils
import {getPropsFromState} from "../../../../../redux/mapStateToProps";
import {
    AddVideoDescription,
    GetVideoList,
    UpdateVideoChannel,
    ChangeHeaderTitle, GetChannels, DeleteVideo, ClearVideoList
} from "../../../../../redux/actions";
import {useTranslation} from "react-i18next";
import InfoTooltip from "../../Profile/tooltip";
import {useLocation} from "react-router-dom";
import {Form} from "react-bootstrap";
import {DeletingIcon, FilterCenterIcon} from "../../../../../image";
import DeleteModal from "../../../../../shared/modals/deleteModal/DeleteModal";
import DropdownMenu from "../../../../../shared/features/dropdownMenu/DropdownMenu";
import {sortConsts, videoFilterTypes} from "../../../../../utils/constants";
import Filters from "../../../../../shared/features/Filters/Filters";

const initFilters = {
    description_like: '',
    date_start: '',
    date_end: '',
    statuses: '',
    owner_name_like: '',
    share_link_types: '',
    channel_ids: '',
    meeting_participant_nickname_like: '',
    sort_by: '',
}
function ManageVideo(props) {
    const {videosList, channels, isFullWidth, userProfile} = props;
    const location = useLocation();
    const {t} = useTranslation();

    const [activePage, setActivePage] = useState(1);
    const [selected, setSelected] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showCheckbox, setShowCheckbox] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [filters, setFilters] = useState(initFilters);

    const isAdmin = !!userProfile?.roles?.find(i => i?.name === 'admin');

    useLayoutEffect(() => {
        props.ClearVideoList()
    }, [])

    useEffect(() => {
        props.ChangeHeaderTitle(t('manage_video_title'))
        props.GetChannels(isAdmin)
    }, []);

    useEffect(() => {
        props.GetVideoList({
            limit: 20,
            offset: (activePage - 1) * 20,
            manage: true,
            ...filters
        })
    }, [activePage, filters]);

    useEffect(() => {
        setActivePage(location?.state?.activePage || 1)
    }, [location?.state?.activePage]);

    const selectAll = () => {
        let list = videosList?.results.map(i => {
            return i.id
        })
        setSelected(selected?.length < 20 ? list : [])
        setShowCheckbox(true)
    };
    const toggleCheck = (id) => {
        let list = selected?.includes(id) ? selected.filter(i => i !== id)
            : [...selected, id]
        setSelected(list)
    };

    const changePage = (number) => {
        setActivePage(number)
    };

    function deleteVideo() {
        selected.forEach(i => {
            props.DeleteVideo(i)
        })
    }

    function toggleFilter(item) {
        setSelectedFilters(selectedFilters.includes(item.key)
            ? selectedFilters.filter(i => i !== item?.key)
            : [...selectedFilters, item.key])
    }

    let channelsList = {}

    const colors = {
        '1': {
            color: '#4F40F7',
            bgColor: '#4F40F719',
        },
        '2': {
            color: '#2877D5',
            bgColor: '#2877D419',
        },
        '3': {
            color: '#F7404B',
            bgColor: '#F7404B19',
        },
    }

    channels?.length && channels.forEach(item => {
        channelsList = {
            ...channelsList,
            [item.id]: {
                name: item.name,
                color: colors[item.id]?.color,
                bgColor: colors[item.id]?.bgColor,
            }
        }
    })

    const tableInfo = [
        {
            name: <InfoTooltip
                elem={<Form><Form.Check
                    type="checkbox"
                    checked={selected?.length === 20}
                    onChange={selectAll}
                /></Form>}
                title={'Выбрать все'}/>,
            width: '20px',
            key: 'all'
        },
        {
            name: t('table_name'),
            width: '12.8%',
            key: 'name'
        },
        {
            name: t('table_data'),
            width: '7.7%',
            key: 'data'
        },
        {
            name: <InfoTooltip elem={t('table_duration_short')}
                               title={t('table_duration')}/>,
            fullName: t('table_duration'),
            width: '6.2%',
            key: 'duration'
        },
        {
            name: t('table_status'),
            width: '6.2%',
            key: 'status'
        },
        {
            name: t('table_owner'),
            width: '12.8%',
            key: 'owner'
        },
        {
            name: t('table_link'),
            width: '10.9%',
            key: 'link'
        },
        {
            name: t('table_channels'),
            width: '15%',
            key: 'channels'
        },
        {
            name: t('table_participant'),
            width: '8.1%',
            key: 'participant'
        },
    ]


    let filterIsChecked = Object.values(filters).find(i=>!!i)

    return (
        <div className={`main-container ${isFullWidth ? '' : 'full'}`}>
            <div className="page-title" style={{padding: '10px 32px'}}>
                <span>{t('manage_video_title')}</span>
                <div className="buttons">
                    <DropdownMenu button={
                        <button className="filter-btn">
                            <FilterCenterIcon/>
                            {t('filter')}
                        </button>
                    }
                                  closeAfterToggle={false}
                                  className="filter-menu"
                                  contentClassName="filter"
                                  contentWidth={'176px'}
                    >
                        {
                            Object.keys(videoFilterTypes)?.map(item => {
                                return <MyCheckbox checked={selectedFilters.includes(item)}
                                                   label={videoFilterTypes[item]?.name}
                                                   key={item}
                                                   className={`filters-item`}
                                                   handleCheckboxChange={() => toggleFilter(videoFilterTypes[item])}/>
                            })
                        }
                    </DropdownMenu>
                    {showCheckbox && <div className="delete-buttons">
                        <button className={`close_btn`}
                                onClick={() => {
                                    setSelected([])
                                    setShowCheckbox(false)
                                }}
                        >
                            {t('button_cancel')}
                        </button>

                        <button className={`delete_btn `}
                                onClick={() => setShowDeleteModal(true)}
                        >
                            <DeletingIcon/> {t('button_delete')} {!!selected?.length && selected?.length}
                        </button>
                    </div>}
                </div>
            </div>

            <Filters selectedFilters={selectedFilters}
                     filters={filters}
                     setFilters={setFilters}
                     sortList={sortConsts}/>

            <div className="manage-video">
                <div className="table-manage-video">
                    <div className={'table-header'}>
                        {
                            tableInfo?.map((item) => {
                                return <div key={item?.key}
                                            style={{width: item?.width}}
                                >{item?.name}</div>
                            })
                        }
                        <th style={{width: '2.2%'}}></th>
                    </div>
                    {videosList?.results?.length ? videosList?.results?.map((item, index) => {
                            return <ListItem data={item}
                                             key={item.id}
                                             index={index}
                                             activePage={activePage}
                                             channelsList={channelsList}
                                             selected={selected}
                                             showCheckbox={showCheckbox}
                                             toggleCheck={toggleCheck}
                                             tableInfo={tableInfo}/>
                        })
                        : filterIsChecked && <div className="reset-filters">
                            <span>
                                Не найдено результатов по выбранным фильтрам. Попробуйте изменить условия поиска или сбросить фильтры.
                            </span>
                            <button className="reset-btn" onClick={()=> setFilters(initFilters)}>
                                Сбросить все фильтры
                            </button>
                        </div>}
                </div>
            </div>

            {videosList?.count > 20 && <CustomPagination count={videosList?.count}
                                                         activePage={activePage}
                                                         listLength={20}
                                                         changePage={changePage}/>}
            {showDeleteModal && <DeleteModal showModal={showDeleteModal}
                                             onHide={() => setShowDeleteModal(false)}
                                             handleDelete={deleteVideo}
                                             title={'видео'}
                                             text={`Вы точно хотите удалить ${selected?.length} видео? После удаления видео невозможно будет восстановить.`}
                // description={`${t('button_delete')} ${selected?.length} ${t('video')}`}
            />}
        </div>
    );
}

function MyCheckbox({checked, handleCheckboxChange, disabled, label, className}) {
    const [isChecked, setIsChecked] = useState(false);
    useEffect(() => {
        setIsChecked(checked)
    }, [checked])

    return (
        <Form>
            <Form.Check
                type="checkbox"
                checked={isChecked}
                className={className}
                disabled={disabled}
                label={label}
                onChange={handleCheckboxChange}
            />
        </Form>
    );
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'videosList',
        'channels',
        'isFullWidth',
        'userProfile'
    ])
};

const mapDispatchToProps = {
    GetVideoList,
    UpdateVideoChannel, AddVideoDescription, ChangeHeaderTitle, GetChannels, DeleteVideo,
    ClearVideoList
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageVideo);
