import React, {useEffect, useRef, useState} from "react";
import {DeletingIcon, EditIcon, EllipsisIcon} from "../../../../../../image";

import ChangeVideoPerson from "../../../../../../shared/features/ChangeVideoPerson/ChangeVideoPerson";
import Highlighter from "react-highlight-words";
import {getAuthorName, hasPermission} from "../../../../../../utils/helperFunctions";
import {useTranslation} from "react-i18next";
import UserInfoModal from "../../../Users/modals/userInfoModal/UserInfoModal";
import DropdownMenu from "../../../../../../shared/features/dropdownMenu";
import {useLocation} from "react-router-dom";
import UpdateTextModal from "../../../../../../shared/modals/UpdateTextModal/UpdateTextModal";

function TextItem({
                      index,
                      item,
                      token,
                      videoPersons,
                      changeVideoTime,
                      getTime,
                      currentItem,
                      scrollPosition,
                      videoId,
                      searchText,
                      UpdateTranscription,
                      GetVideoById,
                      handleScroll,
                      DeleteTranscription
                  }) {
    const [showTooltip, setShowTooltip] = useState(false);
    const [tooltipPosition, setTooltipPosition] = useState({top: 0, left: 0});
    const [selectedText, setSelectedText] = useState('');
    const [showModal, setShowModal] = useState(false)
    const [isEdited, setIsEdited] = useState(false)
    const [editingText, setEditingText] = useState('')
    const [showTextModal, setShowTextModal] = useState(false)

    let disabledBtn = editingText === item?.text

    const location = useLocation()
    const ref = useRef();
    const {t} = useTranslation();

    useEffect(() => {
        setShowTooltip(false)
        setTooltipPosition({top: 0, left: 0})
        setSelectedText('')
    }, [scrollPosition]);

    useEffect(() => {
        ref?.current?.offsetHeight &&
        handleScroll(null, (ref?.current?.offsetHeight * index) || 1)
    }, [currentItem]);

    useEffect(() => {
        document.addEventListener('click', toggleText, {capture: true});
        return () => {
            document.removeEventListener('click', toggleText)
        }
    }, []);

    const toggleText = (event) => {
        const path = event.composedPath ? event.composedPath() : event.path
        if (!path?.includes(ref?.current)) {
            setShowTooltip(false)
        }
    }
    const handleMouseUp = () => {
        const selection = window.getSelection();
        const text = selection.toString().trim();
        if (text) {
            const range = selection.getRangeAt(0);
            const rect = range.getBoundingClientRect();
            setShowTooltip(true);
            setTooltipPosition({
                top: rect.top - (rect.height > 30 ? rect.height / 2 : rect.height),
                left: rect.left,
            });
            setSelectedText(text);
        } else {
            setShowTooltip(false);
        }
    };

   /* function Tooltip({show, position, content}) {
        if (!show) return null;
        let data = {
            ...item,
            text: content
        }
        return (
            <div
                style={{
                    top: position.top,
                    left: position.left,
                }}
                className={'text-tooltip'}
                onClick={(e) => {
                    token && toggleAddProtocol(e, data)
                }}
            >
                <PlusIcon/>
                {t('add_to_protocol')}
            </div>
        );
    }

    function toggleProtocol(e, item) {
        e.stopPropagation()
        toggleAddProtocol(e, item)
    }*/

    function toggleUserModal() {
        setShowModal(!showModal)

        if (showModal){
            GetVideoById(videoId)
        }
    }

    function getInputValue(e) {
        setEditingText(e.target.value)
    }

    function toggleUpdate() {
        setEditingText(item?.text)
        setIsEdited(true)
    }

    function onClose() {
        setEditingText('')
        setIsEdited(false)
    }

    function saveChanges() {
        setEditingText('')
        setIsEdited(false)

        UpdateTranscription(
            item?.id,
            undefined, undefined,
            {
                text: editingText,
                start_time: Number(item?.start_time),
                end_time: Number(item?.end_time)
            })
    }

    return <div className={`texts__item ${currentItem ? 'active-text' : ''}`}
                ref={ref}>
        <div className="texts__item__action">
            {item?.start_time && <div className="texts__item__action__time"
                                      onClick={() => {
                                          changeVideoTime(item?.start_time)
                                      }}>
                {
                    getTime(item?.start_time)
                }
            </div>}
            {/*{token && <button className="texts__item__action__btn"*/}
            {/*                  onClick={(e) => toggleProtocol(e, item)}>*/}
            {/*    <PlusIcon/>*/}
            {/*</button>}*/}
        </div>
        <div className={`texts__item__text times_index_${index}`}>
            <div className={`author_name`}>
                {item?.start_time && <div className="texts__item__action__time mobile"
                                          onClick={() => {
                                              changeVideoTime(item?.start_time)
                                          }}>
                    {
                        getTime(item?.start_time)
                    }
                </div>}
                <span className={`author_name__text`} onClick={toggleUserModal}>
                {item.author ? item.author :getAuthorName(
                    videoPersons?.find(i => i.id === item.person_id), item?.person_id) || `${t('unknown')} ${item?.person_id}`}
                </span>
                {token && <ChangeVideoPerson videoPersons={videoPersons}
                                             videoId={videoId}
                                             textItem={item}
                                             showModal={showModal}
                                             toggleUserModal={toggleUserModal}
                />}
            </div>
            {
                !isEdited ? <span onMouseUp={handleMouseUp}
                                  className={`transcription-text ${item?.isMarked ? 'marked' : ''}`}>
                {/*{item?.text}*/}
                        <EditIcon className="edit-btn" onClick={(e) => {
                            e.stopPropagation()
                            setShowTextModal(true)
                            setEditingText(item?.text)
                            // setType(INTRODUCTION_TYPES.SUMMARY)
                        }}/>
                        <Highlighter
                            highlightClassName="highlight-text"
                            searchWords={[searchText]}
                            autoEscape={true}
                            textToHighlight={item?.text}
                        />

                    <DropdownMenu button={
                        <EllipsisIcon className={'icon-menu'}/>}
                                  closeAfterToggle={true}
                                  className="actions-menu"
                                  contentWidth={'176px'}
                    >
                   {hasPermission('transcriptions_update') && <div className={'menu-item'} onClick={(e) => {
                       e.stopPropagation()
                       toggleUpdate()
                   }}>
                       <EditIcon className={''}/> {t('button_edit')}
                   </div>}
                        {hasPermission('transcriptions_delete') && <div className={'menu-item delete'} onClick={(e) => {
                            e.stopPropagation()
                            DeleteTranscription(item?.id)
                        }}>
                            <DeletingIcon className={''}/> {t('button_delete')}
                        </div>}
                </DropdownMenu>
            </span>
                    : <div className="text_update">
                        <textarea
                            onChange={getInputValue}
                            value={editingText}
                            name="editingText"
                        />
                        <div className="update_buttons">
                            <button className={`close_btn`}
                                    onClick={onClose}
                            >
                                {t('button_cancel')}
                            </button>

                            <button className={`save_btn ${disabledBtn ? 'disabled' : ''}`}
                                    disabled={disabledBtn}
                                    onClick={() => {
                                        saveChanges()

                                        //change this method
                                        const urlParams = new URLSearchParams(location.search);
                                        const paramsUuid = urlParams.get('uuid')

                                        GetVideoById(videoId, paramsUuid)
                                    }}
                            >
                                {t('button_save')}
                            </button>
                        </div>
                    </div>
            }

        </div>
        {/*<Tooltip show={showTooltip} position={tooltipPosition} content={selectedText}/>*/}
        {showModal && <UserInfoModal show={showModal}
                                     closeModal={toggleUserModal}
                                     user={videoPersons.find(i => i.id === item?.person_id)}
                                     videoPersonsList={videoPersons}
        />}
        {showTextModal  && <UpdateTextModal show={showTextModal}
                                           // introduction={'editingText'}
                                           editingText={editingText}
                                           getInputValue={getInputValue}
                                           saveResult={saveChanges}
                                           onHide={() => setShowTextModal(!showTextModal)}/>}
    </div>
}

export default TextItem
