import {_urlAuth, _urlProfile, request} from "../api";
import {PROFILE_CONSTS} from "../constants";

export const GetUserProfile = () => {
    let requestData = {
        url: `${_urlProfile}`,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;

                await dispatch({
                    type: PROFILE_CONSTS.GET_USER_PROFILE,
                    payload: data
                })
                return data
            })
    };
};
export const GetAuthCheck = () => {
    let requestData = {
        url: `${_urlAuth}/check`,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;

                await dispatch({
                    type: PROFILE_CONSTS.GET_AUTH_CHECK,
                    payload: data
                })
            })
    };
};

export const SetUserAvatar = (data) => {
    return async dispatch => {
        await dispatch({
            type: PROFILE_CONSTS.SET_USER_PROFILE,
            payload: data
        })
    };
};
export const ClearProfile = () => {
    return async dispatch => {
        await dispatch({
            type: PROFILE_CONSTS.CLEAR_PROFILE
        })
    };
};

