import { useEffect } from "react";
import { AuthProvider, useAuth } from "react-oidc-context";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

import { uris } from "../../../utils/constants";
import { HOST_NAME } from "../../../redux/api/urls";
import { CompleteOIDCAuthLogIn } from "../../../redux/actions";


export function OIDCAuth({ children, CompleteOIDCAuthLogIn }) {
    const history = useHistory();

    const oidcConfig = {
        authority: uris.OAUTH2_PROVIDER,
        redirect_uri: HOST_NAME + "/auth/login-callback",
        post_logout_redirect_uri: HOST_NAME + "/auth/logout-callback",
        client_id: process.env.REACT_APP_OIDC_CLIENT_ID,
        response_type: "code",
        scope: "Max",

        onSigninCallback: (user) => {
            CompleteOIDCAuthLogIn(user?.access_token);
            window.location = uris.LOGIN_REDIRECT;  // todo: заменить на LOGIN_REDIRECT, но чтобы корректно отработало
//            history.replace(uris.LOGIN_REDIRECT);
        },
        onSignoutCallback: () => {  // todo: реализовать обработку выхода
            console.log("sign out");
            history.push(uris.LOGOUT_REDIRECT);
        },
    };

    return (
        <AuthProvider {...oidcConfig}>
           {children}
        </AuthProvider>
    );
};

const mapDispatchToProps = {CompleteOIDCAuthLogIn};

export default connect(() => {}, mapDispatchToProps)(OIDCAuth);
