// Import packages
import React, {useEffect, useLayoutEffect, useState} from 'react';
import {connect} from "react-redux";
import {Accordion, Modal} from "react-bootstrap";
import {useParams} from "react-router-dom";

// Import assets
import './userInfoModal.scss';
import {
    ArrowDownIcon, CloseIcon,
    EyeIcon,
    InfoCheckIcon, InfoIcon,
} from "../../../../../../image";

//Import Components
import ProfileMedia from "../../../Profile/media";
import ProfileContacts from "../../../Profile/contacts";
import UserRoles from "../../../../../../shared/profile/UserRoles";
import InfoTooltip from "../../../Profile/tooltip";

// Import Utils
import {getPropsFromState} from "../../../../../../redux/mapStateToProps";
import {
    GetVideos,
    GetPersonById,
    UpdatePersons,
    UpdateUser,
    GetUserById,
    GetHighlights,
    ClearPersonById,
    ClearUserById,
    ChangeGridView
} from "../../../../../../redux/actions";
import {profileErrorMessages} from "../../../../../../utils/constants";
import {useTranslation} from "react-i18next";
import config from "../../../../../../utils/config"
import {validateData} from "../../../Profile/helpers/helpers";
import {hasPermission} from "../../../../../../utils/helperFunctions";

const initialState = {
    first_name: undefined,
    last_name: undefined,
    company: undefined,
    position: undefined,
    login: undefined,
    password: undefined,
    note: undefined,
}
const initialContactState = {
    email: [''],
    phone_number: [''],
    linkedin: [''],
    facebook: [''],
}
const initialErrors = {
    login: '',
    password: '',
    first_name: '',
    last_name: '',
    phone_number: [],
    email: [],
    linkedin: [],
    facebook: [],
}

function UserInfoModal(props) {
    const {show, personById, userById, rolesList} = props;

    const [userInfo, setUserInfo] = useState(initialState);
    const [types, setTypes] = useState({
        user: true,
        participant: false,
    });
    const [contactItems, setContactItems] = useState(initialContactState);
    const [errors, setErrors] = useState(initialErrors);
    // const [videoList, setVideoList] = useState(null);
    const [user, setUser] = useState(null);
    const [haveChanges, setHaveChanges] = useState(new Map());
    const [isAdmin, setIsAdmin] = useState(false);
    const [userRole, setUserRole] = useState(undefined);
    const [passwordVisible, setPasswordVisible] = useState(false);

    const params = useParams();

    const {t} = useTranslation();

    useEffect(() => {
        let paramsUser = null
        params?.personId && (paramsUser = {
            ...paramsUser,
            person_id: params?.personId,
        })
        params?.userId && (paramsUser = {
            ...paramsUser,
            user_id: params?.userId,
        })
        setUser(paramsUser ? paramsUser : props.user)
    }, [props?.user])

    useEffect(() => {
        if (user?.person_id || user?.user_id || user?.id) {
            (user?.person_id || user?.id) && props.GetPersonById(user?.person_id || user?.id)
            user?.user_id&& props.GetUserById(user?.user_id || user?.id)
            props.GetVideos({
                limit: 1000,
                returnList: true,
                filters: [{
                    firstColumnVal: 'and',
                    secondColumnVal: 'staff',
                    thirdColumnVal: 'present',
                    fourthColumnVal: (user?.person_id || user?.id) ? (user?.person_id || user?.id) : user?.user_id
                }]
            }).then(() => {
                // setVideoList(rec)
            })

            let reqData = {}
            user?.user_id && (reqData.user_id = user?.user_id)
            user?.id && (reqData.person_id = user?.id)
            user?.person_id && (reqData.person_id = user?.person_id)
            setTypes({
                user: !!user?.user_id,
                participant: (!!user?.person_id || !!user?.id),
            })
        }
    }, [user?.user_id, user?.person_id, user?.id, show])

    useEffect(() => {
        if (user?.person_id || user?.user_id || user?.id) {
            setUserInfo({
                ...userInfo,
                first_name: personById?.first_name ? personById.first_name : userById?.first_name,
                last_name: personById?.last_name ? personById.last_name : userById?.last_name,
                company: personById?.company ? personById.company : undefined,
                position: personById?.position ? personById.position : undefined,
                note: personById?.note ? personById.note : undefined,
                login: personById?.associated_user?.login ? personById?.associated_user?.login : userById?.login,
            })
            setContactItems({
                ...contactItems,
                email: personById?.email?.length ? personById?.email : [''],
                phone_number: personById?.phone_number?.length ? personById?.phone_number : [''],
                linkedin: personById?.linkedin?.length ? personById?.linkedin : [''],
                facebook: personById?.facebook?.length ? personById?.facebook : [''],
            })
            setIsAdmin(userById?.is_admin)
            setUserRole(userById?.roles?.[0])
        }
    }, [personById, userById, user?.person_id, user?.id, show])

    useEffect(()=>{
        personById?.associated_user?.id && props.GetUserById(personById?.associated_user?.id)
    }, [personById?.associated_user?.id])

    useLayoutEffect(() => {
        props.ChangeGridView(true)
        props.ClearPersonById()
        props.ClearUserById()
    }, [])

    function checkValidation(data) {
        setErrors(initialErrors)

        const result = validateData({
            data,
            initialErrors,
            t,
            id: personById?.associated_user?.id
        })
        setErrors(result.errors)
        return result.isValid
    }

    function clearHaveChanges() {
        const changes = new Map(haveChanges);
        changes.clear()
        setHaveChanges(changes)
    }

    function saveChanges() {
        let recData = {};
        setErrors(initialErrors)
        userInfo?.first_name && (recData.first_name = userInfo.first_name)
        userInfo?.last_name && (recData.last_name = userInfo.last_name)
        if (user?.person_id || user?.id) {
            (!personById?.company_id && userInfo?.company) && (recData.company = userInfo.company)
            userInfo?.position && (recData.position = userInfo.position)
            userInfo?.note && (recData.note = userInfo.note)

            Object.entries(contactItems).forEach(([key, value]) => {
                // let item = value.filter(i => !!i)
                // item.length && (recData[key] = item)
                recData[key] = value.filter(i => !!i)
            })
            if (types.user && types.participant && !personById?.associated_with_user) {
                userInfo?.login && (recData.user = {
                    ...recData?.user,
                    email: userInfo?.login
                })
                userInfo?.password && (recData.user = {
                    ...recData?.user,
                    password: userInfo?.password
                })
            }
            if (checkValidation(recData)) {
                props.UpdatePersons(user.person_id || user?.id, recData, userInfo)
                    .then(() => {
                        onClose()
                        clearHaveChanges()
                    })
                    .catch(() => {
                        setErrors({
                            ...errors,
                            login: profileErrorMessages?.login
                        })
                    })
                onClose()
                clearHaveChanges()
            }
        }
        if (userInfo?.login || userInfo?.password || isAdmin !== userById?.isAdmin) {
            let recData = {
                is_admin: isAdmin,
                role_ids: [userRole?.id]
            }
            if (!(user?.person_id || user?.id)) {
                userInfo?.first_name && (recData.first_name = userInfo.first_name)
                userInfo?.last_name && (recData.last_name = userInfo.last_name)
            }
            userInfo?.login && (userInfo?.login !== userById?.login) && (recData.login = userInfo.login)
            userInfo?.password && (recData.password = userInfo.password)
            props.UpdateUser(user?.user_id || userById?.id, recData)
            clearHaveChanges()
            onClose()
        }
    }

    function getInputValue(e) {
        const {name, value} = e.target
        setUserInfo({
            ...userInfo,
            [name]: value
        })
        setErrors({
            ...errors,
            [name]: false
        })
        const changes = new Map(haveChanges);
        if ((personById?.[name] || userById?.[name] || '') !== value) {
            changes.set(name, value)
        } else {
            changes.delete(name)
        }
        setHaveChanges(changes)
    }

    function onClose() {
        setUserInfo(initialState)
        setErrors(initialErrors)
        setContactItems(initialContactState)
        setUser(null)
        // setDataFile(null)
        if (params?.personId || params?.userId || params?.id) {
            // history.push('/dashboard/users/persons')
        }
        props.ClearPersonById()
        props.ClearUserById()
        props.closeModal()
    }

    /*   function copyPersonLink() {
           let url = `${HOST_NAME}/dashboard/users/persons`;
           user?.user_id && (url = url + `/userId/${user?.user_id}`)
           user?.person_id && (url = url + `/personId/${user?.person_id}`)
           user?.id && (url = url + `/personId/${user?.id}`)

           navigator.clipboard.writeText(url);
           setIsCopied(true)
       }*/

    function toggleRole(e, role) {
        e.stopPropagation()
        setUserRole(role)

        const changes = new Map(haveChanges);
        if (userById?.roles?.[0].id !== role?.id) {
            changes.set('userRole', role?.id)
        } else {
            changes.delete('userRole')
        }
        setHaveChanges(changes);
    }

    // console.log(hasPermission('users_update'), 'hasPermission');
    return (personById || userById) && <Modal
        show={show}
        onHide={onClose}
        size="lg"
        centered
        className={'info_modal'}
    >
        <div className="user_info_modal">
            <div className="user-header">
                <p className="title">{t('profile_page_title')}
                    {/*{token && <span className={'copy-link'}*/}
                    {/*                onClick={copyPersonLink}>{isCopied ? t('profile_copied') : t('profile_link')}</span>}*/}
                </p>
                <CloseIcon onClick={onClose}/>
            </div>
            <div className="user_info_modal__wrapper">
                <div className="user_info_modal__media">
                    <ProfileMedia personById={personById}/>

                    {/*<TabsDropdown user={user} personById={personById} show={show}/>*/}

                </div>
                <div className="user_info_modal__content">
                    <form autoComplete={'off'} className={'input-form'}>
                        <div className="row-item">
                            <div className="input_group">
                                <label htmlFor="">{t('input_name')}</label>
                                <input type="text"
                                       value={userInfo?.first_name}
                                       autoComplete={'off'}
                                       name={'first_name'}
                                       onChange={getInputValue}
                                       placeholder={t('input_name')}
                                />
                                {!!errors.first_name && (
                                    <div className="input_group__error">{errors.first_name}</div>
                                )}
                            </div>
                            <div className="input_group">
                                <label htmlFor="">{t('input_surname')}</label>
                                <input type="text"
                                       value={userInfo?.last_name}
                                       name={'last_name'}
                                       autoComplete="off"
                                       onChange={getInputValue}
                                       placeholder={t('input_surname')}
                                />
                                {!!errors.last_name && (
                                    <div className="input_group__error">{errors.last_name}</div>
                                )}
                            </div>
                        </div>
                        <div className="row-item">
                            <div className="input_group">
                                <label htmlFor="">{t('input_company')}</label>
                                <input type="text"
                                       value={userInfo?.company}
                                       name={'company'}
                                       disabled={user?.company?.id || (!user?.person_id && !user?.id)}
                                       onChange={getInputValue}
                                       placeholder={t('input_company')}
                                />
                            </div>
                            <div className="input_group">
                                <label htmlFor="">{t('input_position')}</label>
                                <input type="text"
                                       value={userInfo?.position}
                                       name={'position'}
                                       disabled={(!user?.person_id && !user?.id)}
                                       onChange={getInputValue}
                                       placeholder={t('input_position')}
                                />
                            </div>
                        </div>
                        <div className="input_group full">
                            <label htmlFor="">{t('input_notes')}</label>
                            <textarea value={userInfo?.note}
                                      name={'note'}
                                      disabled={(!user?.person_id && !user?.id)}
                                      onChange={getInputValue}
                                      placeholder={t(config.productName)}
                            />
                        </div>
                    </form>
                </div>
            </div>

            <ProfileContacts contactItems={contactItems}
                             setContactItems={setContactItems}
                             errors={errors}
                             setErrors={setErrors}
                             haveChanges={haveChanges}
                             setHaveChanges={setHaveChanges}
                             personById={personById}/>

            {((personById?.associated_user?.login || userById?.login))
                && <Accordion className={'contacts-wrapper system'}>
                    <Accordion.Item eventKey="0" className={'profile-page__contacts'}>
                        <Accordion.Header
                            className={`contact-header`}>
                            <ArrowDownIcon className={`arrow-icon`}/>
                            {t('profile_settings_title')}
                            {!(personById?.associated_user?.login || userById?.login) ?
                                <InfoTooltip title={t('tooltip_settings_title')}
                                             description={t('tooltip_settings_description')}/>
                                : <InfoCheckIcon/>}
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className="user-info-settings">
                                {hasPermission('users_update') ? <>
                                    {/*{!personById?.id && <p>{t('profile_settings_desc')}</p>}*/}
                                    <div className="input_group">
                                        <label htmlFor="">{t('input_login')}</label>
                                        <input type="text"
                                               value={userInfo?.login}
                                               name={'login'}
                                               onChange={getInputValue}
                                               placeholder={t('input_login')}
                                               autoComplete={'off'}
                                        />
                                        {!!errors.login && (
                                            <div className="input_group__error">{errors.login}</div>
                                        )}
                                    </div>
                                    <div className="input_group">
                                        <label htmlFor="">{t('input_password')}</label>
                                        <input
                                            type={`${passwordVisible ? "text" : "password"}`}
                                            value={userInfo?.password}
                                            name={'password'}
                                            onChange={getInputValue}
                                            placeholder={t('input_password')}
                                            autoComplete="new-password"
                                        />
                                        <EyeIcon className="input-icon"
                                                 onClick={() => setPasswordVisible(!passwordVisible)}/>
                                        {!!errors.password && (
                                            <div className="input_group__error">{errors.password}</div>
                                        )}
                                    </div>
                                </> : <>
                                    <p>{t('no_access_to_update_user')}</p>
                                    <div className="warning-info">
                                        <InfoIcon/>
                                        <span>{t('no_access_to_update_user_warning')}</span>
                                    </div>
                                </>}
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1" className={'profile-page__contacts'}>
                        <Accordion.Header
                            className={`contact-header`}>
                            <ArrowDownIcon className={`arrow-icon`}/>
                            {t('profile_roles')}
                        </Accordion.Header>
                        <Accordion.Body>
                            {
                                !!userById?.roles?.length ?
                                    <UserRoles rolesList={rolesList}
                                               toggleRole={toggleRole}
                                               userRole={userRole}
                                    /> :
                                    <>
                                        <div className={'connect_person'}>
                                            {/*<span>{t('connect_person_roles')}</span>*/}

                                            {/*<CombinePersonsDropdown user={personById} setVideoList={setVideoList}/>*/}
                                        </div>
                                    </>
                            }
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>}
        </div>
        <div className="buttons-wrapper">
            <button className={`close_btn`} onClick={onClose}
            >
                {t('button_cancel')}
            </button>

            <button className={`save_btn ${!haveChanges.size ? 'disabled' : ''}`}
                    disabled={!haveChanges.size}
                    onClick={saveChanges}
            >
                {t('button_save_changes')}
            </button>
        </div>
    </Modal>
}


const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'personById', 'userById', 'highlights', 'token', 'rolesList', 'userRoles'
    ])
};

const mapDispatchToProps = {
    UpdatePersons,
    GetPersonById,
    GetVideos,
    UpdateUser,
    GetUserById,
    GetHighlights,
    ClearPersonById,
    ClearUserById,
    ChangeGridView
};

export default connect(mapStateToProps, mapDispatchToProps)(UserInfoModal);
