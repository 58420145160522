import {LINKS_CONSTS, PROTOCOL_CONSTS, TRANSCRIPTIONS_CONSTS, VIDEOS_CONSTS} from "../constants";

export const initialState = {
    videoById: null,
    videosList: {
        count: 0,
        results: []
    },
    searchVideos: {},
    serviceStatuses: true,
    videoIsEmpty: false,
    hasMoreChannels: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case VIDEOS_CONSTS.GET_VIDEOS:
            let data = action.payload?.data ? action.payload.data : action.payload
            return {
                ...state,
                videosList: {
                    ...state.videosList,
                    ...data,
                    results: !action.payload.isInfinite ? [...data.results]
                        : [...state?.videosList?.results, ...data.results]
                },
                hasMoreChannels: action.payload.hasMore
            };
        case VIDEOS_CONSTS.CLEAR_VIDEOS:
            return {
                ...state,
                videosList: initialState.videosList,
            };
        case VIDEOS_CONSTS.DELETE_VIDEOS:
            return {
                ...state,
                videosList: {
                    ...state.videosList,
                    results: state.videosList.results.filter(item => item.id !== action.payload)
                }
            };
        case VIDEOS_CONSTS.UPDATE_VIDEO_CHANNEL:
            let newList = state.videosList.results.map(item => {
                return item.id !== action.payload.id ? item
                    : {
                        ...item,
                        channels: action.payload.channels.filter(item => item.is_bound).map(i => i.id)
                    }
            })
            return {
                ...state,
                videosList: {
                    ...state.videosList,
                    results: newList
                },
                videoById: {
                    ...state.videoById,
                    channels: action.payload.channels.filter(item => item.is_bound).map(i => i.id)
                }
            };
        case LINKS_CONSTS.UPDATE_LINK:
            // console.log('action.payload', action.payload);
            let listLinks = state.videosList.results.map(item => {
                return item.id !== action.payload.video_id ? item : {
                    ...item,
                    link: {
                        ...item.link,
                        ...action.payload
                    }
                }
            })
            return {
                ...state,
                videosList: {
                    ...state.videosList,
                    results: listLinks
                },
                videoById: {
                    ...state.videoById,
                    link: {
                        ...state.videoById?.link,
                        ...action.payload
                    }
                }
            };
        case VIDEOS_CONSTS.GET_SERVICE_STATUSES:
            return {
                ...state,
                serviceStatuses: action.payload?.ml_service_running
            };
        case VIDEOS_CONSTS.GET_VIDEOS_SEARCH:
            return {
                ...state,
                searchVideos: action.payload
            };
        case VIDEOS_CONSTS.CLEAR_VIDEOS_SEARCH:
            return {
                ...state,
                searchVideos: {}
            };
        case VIDEOS_CONSTS.ADD_VIDEO_DESCRIPTION:
            return {
                ...state,
                videosList: {
                    ...state.videosList,
                    results: state.videosList.results.map(item => {
                        return item.id === action.payload.video_id ? {
                            ...item,
                            description: action.payload.description
                        } : item
                    })
                },
                videoById: {
                    ...state.videoById,
                    description: action.payload.description
                }
            };
        case VIDEOS_CONSTS.REPROCESS_VIDEOS:
            return {
                ...state,
                videosList: {
                    ...state.videosList,
                    results: state.videosList.results.map(item => {
                        return item.id === action.payload ? {
                            ...item,
                            video_status: 'processing'
                        } : item
                    })
                },
                videoById: {
                    ...state.videoById,
                    video_status: 'processing'
                }
            };
        case VIDEOS_CONSTS.CHANGE_REPROCESS_STATUS:
            return {
                ...state,
                videosList: {
                    ...state.videosList,
                    results: state.videosList.results.map(item => {
                        return item.id === action.payload ? {
                            ...item,
                            video_status: 'processing'
                        } : item
                    })
                },
                videoById: {
                    ...state.videoById,
                    video_status: 'processing'
                }
            };
        case VIDEOS_CONSTS.GET_VIDEO_BY_ID:
            return {
                ...state,
                videoById: action.payload,
                videoIsEmpty: false
            };
        case VIDEOS_CONSTS.MARK_VIDEO:
            return {
                ...state,
                videoById: {
                    ...state.videoById,
                    texts: state.videoById.texts.map(item => {
                        return (parseInt(action.payload?.start_time) === parseInt(item.start_time))
                        && item.start_time !== null ? {
                            ...item,
                            isMarked: true
                        } : item
                    }),
                    events: state.videoById.events.map(item => {
                        return (parseInt(action.payload?.start_time) === parseInt(item.start_time))
                            ? {
                                ...item,
                                isMarked: true
                            } : {...item, isMarked: false}
                    })
                }
            };
        case VIDEOS_CONSTS.UNMARK_VIDEO:
            return {
                ...state,
                videoById: {
                    ...state.videoById,
                    texts: state.videoById.texts.map(item => {
                        return (parseInt(action.payload?.start_time) === parseInt(item.start_time)) ? {
                            ...item,
                            isMarked: false
                        } : item
                    }),
                    events: state.videoById.events.map(item => {
                        return (parseInt(action.payload?.start_time) === parseInt(item.start_time)) ? {
                            ...item,
                            isMarked: false
                        } : item
                    })
                }
            };
        case PROTOCOL_CONSTS.DELETE_PROTOCOL:
            return {
                ...state,
                videoById: {
                    ...state.videoById,
                    texts: state.videoById.texts.map(item => {
                        return parseInt(action.payload?.start_time) === parseInt(item.start_time) && item.person_id === action.payload?.person_id ? {
                            ...item,
                            isMarked: false
                        } : item
                    }),
                    events: state.videoById.events.map(item => {
                        return parseInt(action.payload?.start_time) === parseInt(item.start_time) && item.person_id === action.payload?.person_id ? {
                            ...item,
                            isMarked: false
                        } : item
                    })
                }
            };
        case TRANSCRIPTIONS_CONSTS.UPDATE_TRANSCRIPTION:
            return {
                ...state,
                videoById: {
                    ...state.videoById,
                    texts: state.videoById.texts.map(item => {
                        return item.id === action.payload.transcriptions_id ? {
                            ...item,
                            person_id: action.payload.person.id,
                            author: action.payload.person.first_name
                                ? action.payload.person.first_name : '' + action.payload.person.last_name
                                    ? action.payload.person.last_name : '',
                            text: action.payload?.data?.text ? action.payload?.data?.text : item?.text
                        } : item
                    }),
                    events: state.videoById.events.map(item => {
                        return item.person_id === action.payload.transcription.person_id ? {
                            ...item,
                            person_id: action.payload.person.id
                        } : item
                    })
                }
            };
        case TRANSCRIPTIONS_CONSTS.DELETE_TRANSCRIPTION:
            return {
                ...state,
                videoById: {
                    ...state.videoById,
                    texts: state.videoById.texts.filter(item => item.id !== action.payload)
                }
            };
        case VIDEOS_CONSTS.CLEAR_VIDEO_BY_ID:
            return {
                ...state,
                videoById: null,
                videoIsEmpty: false
            };
        case VIDEOS_CONSTS.VIDEO_ERROR:
            return {
                ...state,
                videoIsEmpty: true
            };
        default:
            return state;
    }
}
