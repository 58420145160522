// Import packages
import React, {useEffect} from 'react';
import {connect} from "react-redux";

// Import assets
import './update-text-modal.scss';

//Import components
import {Modal} from "react-bootstrap";

//Import utils
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {useTranslation} from "react-i18next";
import {CloseIcon} from "../../../image";


function UpdateTextModal(props) {
    const {editingText, onHide, introduction, saveResult, getInputValue, type, closeWrapper} = props;
    const {t} = useTranslation();

    // useEffect(()=>{
    //     console.log('editingText', editingText);
    // }, [editingText])

    function onClose(type) {
        onHide(type)
        closeWrapper && closeWrapper()
    }

    return <Modal
        show={true}
        onHide={()=>onClose(type)}
        onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
        }}
        size="lg"
        className={'update-text-modal'}
        centered
    >
        <div className="update-text-modal-header">
            <span>Редактировать </span>
            <CloseIcon onClick={()=>onClose(type)}/>
        </div>

        <textarea
            onChange={getInputValue}
            value={editingText ? editingText : introduction?.[type]}
            name={type}
        />
        {/*<div className="update_buttons">
            <button className={`close_btn`}
                    onClick={() => onClose(type)}
            >
                {t('button_cancel')}
            </button>

            <button className={`save_btn ${disabledSummaryBtn ? 'disabled' : ''}`}
                    disabled={disabledSummaryBtn}
                    onClick={() => {
                        saveResult(type)
                    }}
            >
                {t('button_save')}
            </button>
        </div>*/}

        <div className="update-text-modal-buttons">
            <button className={`close_btn`}
                    onClick={() => onClose(type)}
            >
                {t('button_cancel')}
            </button>

            <button className={`save_btn `}
                    onClick={() => {
                        saveResult(type)
                        onClose(type)
                    }}
            >
                {t('button_save')}
            </button>
        </div>
    </Modal>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [])
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateTextModal);
