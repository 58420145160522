// Import packages
import React from 'react';

// Import assets
import './list-item.scss';
import { EditingIcon, EllipsisIcon, VideoIcon} from "../../../../../../image";

// ImportComponents

// Import Utils
import {generateMediaUrl} from "../../../../../../utils/generateMediaUrl";
import {history} from "../../../../../../utils/history";
import {useTranslation} from "react-i18next";
import {getAuthorName} from "../../../../../../utils/helperFunctions";
import DropdownMenu from "../../../../../../shared/features/dropdownMenu";

function ListItem({data, openModal, setCurrentUser}) {
    const {t} = useTranslation();
    function toggleUpdate() {
        setCurrentUser(data)
        openModal()
    }

    function navigateTo() {
        !!data?.videos_count && history.push({
            pathname: `/dashboard/videos/userVideos/${data.person_id ? data.person_id : data.user_id}`,
            state: {
                personId: data.person_id,
                routs: [
                    {
                        path: '/dashboard/users/new',
                        name: t('members_title')
                    },
                    {
                        id: data.person_id ? data.person_id : data.user_id,
                        name: getAuthorName(data),
                    },
                    {
                        path: `/dashboard/videos/userVideos/${data.person_id ? data.person_id : data.user_id}`,
                        name: 'Видео с ' +  getAuthorName(data),
                        active: true
                    }
                ]
            }
        })
    }

    return (
        <div className="list-item new">
            <div className="list-item__content">
                <div className="user_info item_col">
                    <img
                        src={generateMediaUrl(data?.avatar)}
                        alt=""
                        className="user_info__img"
                    />
                </div>
                <div className="item_col">
                    <div className="user_info__content">
                        <div className="name" onClick={toggleUpdate}>
                            #{data?.person_id}
                        </div>
                    </div>
                </div>
                <div className="item_col">
                    {data?.date ? data?.date : '-' }
                </div>
                <div className="video_count item_col" onClick={navigateTo}>
                    <VideoIcon/> {data?.videos_count}
                </div>
            </div>
            <div className="list-item__actions">
                <DropdownMenu button={
                    <EllipsisIcon className={'icon-menu'}/>}
                              closeAfterToggle={true}
                              className="actions-menu"
                              contentWidth={'182px'}
                >
                    <div className="actions-menu">
                        <div className="edit" onClick={toggleUpdate}><EditingIcon/> {t('button_edit')}</div>
                    </div>
                </DropdownMenu>
            </div>
        </div>
    );
}

export default ListItem;
