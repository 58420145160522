// Import packages
import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

// Import assets
import './Prompts.scss';

// Import Utils
import {getPropsFromState} from "../../../../redux/mapStateToProps";
import {DeletePrompt, GetPrompts} from "../../../../redux/actions";
import {useTranslation} from "react-i18next";
import {DeletingIcon, EditIcon, EllipsisIcon, PlusIcon, UsersGroupIcon} from "../../../../image";
import AddPromptModal from "../../../../shared/modals/AddPrompt/AddPrompt";
import DropdownMenu from "../../../../shared/features/dropdownMenu";
import DeleteModal from "../../../../shared/modals/deleteModal/DeleteModal";
import {hasPermission} from "../../../../utils/helperFunctions";

function Prompts(props) {
    const { promptList} = props;

    const [selectedPrompt, setSelectedPrompt] = useState(undefined)
    const [showAddPromptModal, setShowAddPromptModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const {t} = useTranslation();

    useEffect(() => {
        props.GetPrompts()
    }, []);

    function closeAddPromptModal() {
        setShowAddPromptModal(false)
        setSelectedPrompt(undefined)
        props.GetPrompts()
    }

    function deletePrompt() {
        props.DeletePrompt(selectedPrompt)
        setSelectedPrompt(undefined)
    }

    function openDelete(item) {
        setShowDeleteModal(true)
        setSelectedPrompt(item)
    }
    function toggleUpdate(item) {
        setShowAddPromptModal(true)
        setSelectedPrompt(item)
    }

    return (
        <div className={`main-container prompts`}>
            <div className="prompts-title">
                <div className="name">Промпты</div>
                {hasPermission('analysis_pipelines_create') && <button className="create-prompt"
                         onClick={() => setShowAddPromptModal(true)}>
                    <PlusIcon/>
                    Создать промпт
                </button>}
            </div>
            <div className="prompts-item-row items-header">
                <div className="col" style={{width: '160px'}}>название</div>
                <div className="col" style={{width: '288px'}}>Вид промпта</div>
                <div className="col" style={{width: '288px'}}>группы пользователей</div>
                <div className="col"/>
            </div>
            {
                !!promptList?.length && promptList?.map((item) => {
                    return <div className="prompts-item-row" key={item?.id}>
                        <div className="col name" style={{width: '160px'}}
                             onClick={()=> hasPermission('analysis_pipelines_update') && toggleUpdate(item)}
                        >{item?.name}</div>
                        <div className="col" style={{width: '288px'}}>
                            {item?.type === 'summary' ? 'Итоги встречи' : 'Пересказ'}
                        </div>
                        <div className="col" style={{width: '288px'}}>

                            <DropdownMenu button={<>
                                    <UsersGroupIcon/>&nbsp;
                                    {(item?.summary_analysis_pipelines?.length || 0 )
                                        + (item?.retelling_analysis_pipelines?.length || 0)}
                                </>}
                                          disabled={!(item?.summary_analysis_pipelines?.length + item?.retelling_analysis_pipelines?.length)}
                                          closeAfterToggle={true}
                                          className="prompts-list"
                                          contentWidth={'176px'}
                            >
                                {
                                    !!item?.summary_analysis_pipelines?.length && item?.summary_analysis_pipelines?.map(item =>{
                                        return <div className="prompt-item" key={item?.id}>{item?.name}</div>
                                    })
                                }
                                {
                                    !!item?.retelling_analysis_pipelines?.length && item?.retelling_analysis_pipelines?.map(item =>{
                                        return <div className="prompt-item" key={item?.id}>{item?.name}</div>
                                    })
                                }
                            </DropdownMenu>
                        </div>
                        <div className="col">
                            {!item?.undeletable && <DropdownMenu button={
                                <EllipsisIcon className={'icon-menu'}/>}
                                           closeAfterToggle={true}
                                           className="actions-menu"
                                           contentWidth={'176px'}
                            >
                                {hasPermission('analysis_pipelines_update') &&
                                    <div className={'menu-item'} onClick={(e) => {
                                        e.stopPropagation()
                                        toggleUpdate(item)
                                    }}>
                                        <EditIcon className={''}/> {t('button_edit')}
                                    </div>}
                                {hasPermission('analysis_pipelines_delete') &&
                                    <div className={'menu-item delete'} onClick={() => {
                                        openDelete(item)
                                    }}>
                                        <DeletingIcon className={''}/> {t('button_delete')}
                                    </div>}
                            </DropdownMenu>}
                        </div>
                    </div>
                })
            }
            {showAddPromptModal && <AddPromptModal show={showAddPromptModal}
                                                   selectedPrompt={selectedPrompt}
                                                   setSelectedPrompt={setSelectedPrompt}
                                                   closeModal={closeAddPromptModal}
                                                   openDelete={openDelete}
                                                   className={`${showDeleteModal ? 'hide' : ''}`}
            />}
            {showDeleteModal && <DeleteModal showModal={showDeleteModal}
                                             onHide={() => setShowDeleteModal(false)}
                                             handleDelete={deletePrompt}
                                             closeWrapper={closeAddPromptModal}
                                             title={'промпт'}
                                             text={`После удаления промпта ${selectedPrompt?.name || ''} все данные будут безвозвратно потеряны и их невозможно будет восстановить`}
                // description={`${t('button_delete')} ${selected?.length} ${t('video')}`}
            />}
        </div>
    );
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'isFullWidth',
        'promptList',
    ])
};

const mapDispatchToProps = {
    GetPrompts,
    DeletePrompt
};

export default connect(mapStateToProps, mapDispatchToProps)(Prompts);
