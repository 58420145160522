// Import packages
import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

// Import assets
import './LLMSettings.scss';

// Import Utils
import {getPropsFromState} from "../../../../redux/mapStateToProps";
import {
    GetAnalysisPipelines,
    UpdateAnalysisPipeline,
    GetLanguageModels,
    GetPrompts,
    DeletePrompt,
    DeleteAnalysisPipeline
} from "../../../../redux/actions";
import {useTranslation} from "react-i18next";
import DropdownMenu from "../../../../shared/features/dropdownMenu";
import {DeletingIcon, DownIcon, EditIcon, EllipsisIcon, PlusIcon} from "../../../../image";
import AddGroup from "../../../../shared/modals/AddGroup/AddGroup";
import AddPromptModal from "../../../../shared/modals/AddPrompt/AddPrompt";
import DeleteModal from "../../../../shared/modals/deleteModal/DeleteModal";
import {hasPermission} from "../../../../utils/helperFunctions";
import {Form} from "react-bootstrap";

function LLMSettings(props) {
    const {analysisPipelinesList, promptList, languageModels} = props;

    const [showAddModal, setShowAddModal] = useState(false)
    const [selectedGroup, setSelectedGroup] = useState(undefined)
    const [selectedPrompt, setSelectedPrompt] = useState(undefined)
    const [showAddPromptModal, setShowAddPromptModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const {t} = useTranslation();

    useEffect(() => {
        props.GetAnalysisPipelines()
        props.GetPrompts()
        props.GetLanguageModels()
    }, []);

    function closeAddModal() {
        setShowAddModal(false)
        props.GetAnalysisPipelines()
        // setSelectedPrompt(undefined)
    }

    function selectSummaryPrompt(item, promptItem) {
        //    console.log(item);
        item.summary_prompt_id = promptItem.id;
        props.UpdateAnalysisPipeline(item.id, {
            summary_prompt_id: item.summary_prompt_id,
        });
        setTimeout(() => {
            props.GetAnalysisPipelines();
        }, 300);

    }

    function selectRetellingPrompt(item, promptItem) {
        //    console.log(item);
        item.retelling_prompt_id = promptItem.id;
        props.UpdateAnalysisPipeline(item.id, {
            retelling_prompt_id: item.retelling_prompt_id,
        });
        setTimeout(() => {
            props.GetAnalysisPipelines();
        }, 300);
    }

    function selectSummaryPromptModel(item, menuItem) {
        item.summary_language_model_id = menuItem.id;
        props.UpdateAnalysisPipeline(item.id, {
            summary_language_model_id: item.summary_language_model_id,
        });
        setTimeout(() => {
            props.GetAnalysisPipelines();
        }, 300);
    }

    function selectRetellingPromptModel(item, menuItem) {
        //    console.log(item);
        item.retelling_language_model_id = menuItem.id;
        props.UpdateAnalysisPipeline(item.id, {
            retelling_language_model_id: item.retelling_language_model_id,
        });
        setTimeout(() => {
            props.GetAnalysisPipelines();
        }, 300);
    }

    function openDelete(item) {
        setShowDeleteModal(true)
        setSelectedGroup(item)
    }

    function closeAddPromptModal() {
        setShowAddPromptModal(false)
        setSelectedPrompt(undefined)
        props.GetPrompts()
    }

    function deletePrompt() {
        props.DeleteAnalysisPipeline(selectedGroup)
        setSelectedGroup(undefined)
    }

    function toggleUpdate(item) {
        setShowAddModal(true)
        setSelectedGroup(item)
    }

    return (
        <div className={`main-container llm-settings`}>
            <div className="llm-settings-title">
                <div className="name">{t('menu_item_llm')}</div>
                {hasPermission('analysis_pipelines_create') && <button className="create-prompt"
                                                                       onClick={() => setShowAddModal(true)}>
                    <PlusIcon/>
                    Создать группу
                </button>}
            </div>

            <div className="llm-settings-moderators-row">
                <div className="col">Группа пользователей</div>
                <div className="col">LLM для “Итоги встречи”</div>
                <div className="col">LLM для “Пересказ”</div>
                <div className="col">Промпт для “Итоги встречи”</div>
                <div className="col">Промпт для “Пересказ”</div>
                <div className="actions-menu"/>
            </div>

            {
                !!analysisPipelinesList?.length && analysisPipelinesList.filter(i=>!i.undeletable).map(item => {
                    return <div className="llm-settings-moderators-row" key={item?.id}>
                        <div className="group-name">{item?.name}</div>

                        <div className="select_group">
                            <DropdownMenu button={
                                <button className="sort-btn">
                            <span className="sort-btn__name">
                                {item?.summary_language_model?.name}
                        </span>

                                    <DownIcon className="arrow-icon"/>
                                </button>
                            }
                                          disabled={!hasPermission('analysis_pipelines_update')}
                                          closeAfterToggle={true}
                                          className={'sort-select'}
                                          fullWidth={true}>
                                {
                                    !!languageModels?.length && languageModels.map((modelItem, idx) => {
                                        return <div className={'sort-item'} onClick={() => {
                                            selectSummaryPromptModel(item, modelItem)
                                        }} key={idx}>
                                            <Form className="form-wrap">
                                                <Form.Check
                                                    label={modelItem.name}
                                                    // className="sort-item__check"
                                                    type={'radio'}
                                                    checked={item?.summary_language_model?.name === modelItem?.name}
                                                    onChange={() => {
                                                        selectSummaryPromptModel(item, modelItem)
                                                    }}
                                                />
                                            </Form>
                                        </div>
                                    })
                                }

                            </DropdownMenu>
                        </div>

                        <div className="select_group">
                            <DropdownMenu button={
                                <button className="sort-btn">
                            <span className="sort-btn__name">
                                {item?.retelling_language_model?.name}
                        </span>
                                    <DownIcon className="arrow-icon"/>
                                </button>
                            }
                                          disabled={!hasPermission('analysis_pipelines_update')}
                                          closeAfterToggle={true}
                                          className={'sort-select'}
                                          fullWidth={true}>

                                {
                                    !!languageModels?.length && languageModels.map((modelItem, idx) => {

                                        return <div className={'sort-item'} onClick={() => {
                                            selectRetellingPromptModel(item, modelItem)
                                        }} key={idx}>
                                            <Form className="form-wrap">
                                                <Form.Check
                                                    label={modelItem.name}
                                                    // className="sort-item__check"
                                                    type={'radio'}
                                                    checked={item?.retelling_language_model?.name === modelItem?.name}
                                                    onChange={() => {
                                                        selectRetellingPromptModel(item, modelItem)
                                                    }}
                                                />
                                            </Form>
                                        </div>
                                    })
                                }
                            </DropdownMenu>

                        </div>

                        <div className="select_group">
                            <DropdownMenu button={
                                <button className="sort-btn">
                            <span className="sort-btn__name">
                                {item?.summary_prompt?.name}
                        </span>

                                    <DownIcon className="arrow-icon"/>
                                </button>
                            }
                                          disabled={!hasPermission('analysis_pipelines_update')}
                                          closeAfterToggle={true}
                                          className={'sort-select'}
                                          fullWidth={true}>


                                {
                                    !!promptList?.length && promptList?.filter(i => i.type === 'summary').map((prompItem, idx) => {
                                        return <div className={'sort-item'} onClick={() => {
                                            selectSummaryPrompt(item, prompItem)
                                        }} key={idx}>
                                            <Form className="form-wrap">
                                                <Form.Check
                                                    label={prompItem.name}
                                                    type={'radio'}
                                                    checked={item?.summary_prompt?.name === prompItem?.name}
                                                    onChange={() => {
                                                        selectSummaryPrompt(item, prompItem)
                                                    }}
                                                />
                                            </Form>
                                        </div>
                                    })
                                }
                            </DropdownMenu>

                        </div>

                        <div className="select_group">
                            <DropdownMenu button={
                                <button className="sort-btn">
                                    <span className="sort-btn__name">{item?.retelling_prompt?.name}</span>
                                    <DownIcon className="arrow-icon"/>
                                </button>
                            }
                                          disabled={!hasPermission('analysis_pipelines_update')}
                                          closeAfterToggle={true}
                                          className={'sort-select'}
                                          fullWidth={true}>
                                {
                                    !!promptList?.length && promptList?.filter(i => i.type === 'retelling')?.map((prompItem, idx) => {
                                        return <div className={'sort-item'} onClick={() => {
                                            selectRetellingPrompt(item, prompItem)
                                        }} key={idx}>
                                            <Form className="form-wrap">
                                                <Form.Check
                                                    label={prompItem.name}
                                                    type={'radio'}
                                                    checked={item?.retelling_prompt?.name === prompItem?.name}
                                                    onChange={() => {
                                                        selectRetellingPrompt(item, prompItem)
                                                    }}
                                                />
                                            </Form>
                                        </div>
                                    })
                                }
                            </DropdownMenu>

                        </div>

                        {!item?.undeletable && <DropdownMenu button={
                            <EllipsisIcon className={'icon-menu'}/>}
                                                             closeAfterToggle={true}
                                                             className="actions-menu"
                                                             contentWidth={'176px'}
                        >
                            {hasPermission('analysis_pipelines_update') &&
                                <div className={'menu-item'} onClick={(e) => {
                                    e.stopPropagation()
                                    toggleUpdate(item)
                                }}>
                                    <EditIcon className={''}/> {t('button_edit')}
                                </div>}
                            {hasPermission('analysis_pipelines_delete') &&
                                <div className={'menu-item delete'} onClick={() => {
                                    props.DeleteAnalysisPipeline(item)
                                }}>
                                    <DeletingIcon className={''}/> {t('button_delete')}
                                </div>}
                        </DropdownMenu>}
                    </div>
                })
            }

            {showAddModal && <AddGroup show={showAddModal}
                                       selectedGroup={selectedGroup}
                                       setSelectedGroup={setSelectedGroup}
                                       closeModal={closeAddModal}
                                       openDelete={openDelete}
                                       promptList={promptList}
                                       className={`${showDeleteModal ? 'hide' : ''}`}
            />}
            {showAddPromptModal && <AddPromptModal show={showAddPromptModal}
                                                   selectedPrompt={selectedPrompt}
                                                   setSelectedPrompt={setSelectedPrompt}
                                                   closeModal={closeAddPromptModal}
                                                   openDelete={openDelete}
            />}
            {showDeleteModal && <DeleteModal showModal={showDeleteModal}
                                             onHide={() => setShowDeleteModal(false)}
                                             handleDelete={deletePrompt}
                                             closeWrapper={closeAddModal}
                                             title={'группы'}
                                             text={`После удаления группы ${selectedGroup?.name || ''} все данные будут безвозвратно потеряны и их невозможно будет восстановить`}
                // description={`${t('button_delete')} ${selected?.length} ${t('video')}`}
            />}
        </div>
    );
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'isFullWidth',
        'analysisPipelinesList',
        'promptList',
        'languageModels',
    ])
};

const mapDispatchToProps = {
    GetAnalysisPipelines,
    UpdateAnalysisPipeline,
    GetPrompts,
    GetLanguageModels,
    DeletePrompt,
    DeleteAnalysisPipeline
};

export default connect(mapStateToProps, mapDispatchToProps)(LLMSettings);
