import axios from "axios";

import {store} from "../store";
import {LogOutActionCreator} from "../actions";


export const request = ({
                            url,
                            method = "GET",
                            data = null,
                            customHeaders
                        }) => {
    const customToken = store.getState().auth.token;
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        ...customHeaders
    };
    customToken && (headers["Authorization"] = `Bearer ${customToken}`);
    const requestData = {
        url: url,
        method: method,
        headers: headers
    };
    data && (requestData.data = data);
    handleErrors()
    return axios.request(requestData)
};

// log user out when token expires
function handleErrors() {
    axios.interceptors.response.use(response => response, error => {
            if (error.response && error.response.status === 401) {
                return LogOutActionCreator()
            }
            return Promise.reject(error);
        }
    );
}

