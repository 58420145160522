// Import packages
import React from 'react';
import {connect} from "react-redux";

// Import assets
import './shear-modal.scss';

//Import components
import {Modal} from "react-bootstrap";

//Import utils
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {CloseIcon} from "../../../image";
import LinkContent from "../../../pages/Dashboard/containers/Videos/ManageVideo/LinkContent/LinkContent";
import {GetLink} from "../../../redux/actions";

function ShearModal(props) {
    const {onHide, videoById} = props;

    function onCopy() {
        props.GetLink({
            video_id: videoById?.id
        })
            .then((res) => {
                navigator.clipboard.writeText(res.link.url);
            })
        onClose()
    }

    function onClose() {
        onHide()
    }

    return <Modal
        show={true}
        onHide={onClose}
        onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
        }}
        size="lg"
        className={'shear-modal'}
        centered
    >
        <div className="shear-modal-header">
            <span>Поделиться ссылкой</span>
            <CloseIcon onClick={onClose}/>
        </div>

        <LinkContent hideTitle={true}
                     link={videoById?.link}
                     onCopy={onCopy}
                     onHide={onHide}
                     videoId={videoById?.id}
        />
    </Modal>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [])
};

const mapDispatchToProps = {GetLink};

export default connect(mapStateToProps, mapDispatchToProps)(ShearModal);
