function isMaxProduct() {
    // REACT_APP_PRODUCT_NAME - обязательная переменная
    return process.env.REACT_APP_PRODUCT_NAME.toLowerCase() === "max";
};

const config = {
    productName: isMaxProduct() ? "max_name" : "secretopus_name",
};

export default config;
