// Import packages
import React, {useEffect, useState} from 'react';

// Import assets
import './pagination.scss';

// Import Components
import Pagination from 'react-bootstrap/Pagination';
import PageItem from "react-bootstrap/PageItem";

// Import Utils

function CustomPagination(props) {
    const {count, activePage, changePage, listLength} = props;

    const showPageCount = 5
    let length = Math.floor(count / listLength) + 1;

    let items = [];
    const [activeLength, setActiveLength] = useState(showPageCount)

    for (let number = activeLength >= showPageCount ? activeLength - (showPageCount - 1) : 1; number <= activeLength; number++) {
        items.push(
            <PageItem key={number} active={number === activePage} onClick={() => changePage(number)}>
                {number}
            </PageItem>,
        );
    }

    useEffect(() => {
        setActiveLength(length >= showPageCount ? showPageCount : length)
    }, [listLength, count])

    function toggleFirstPage() {
        changePage(1)
        setActiveLength(showPageCount)
    }

    function toggleLastPage() {
        changePage(Math.floor(length))
        setActiveLength(Math.floor(length))
    }

    function togglePrev() {
        activePage > 1 && changePage(activePage - 1)
        setActiveLength(activeLength >= showPageCount + 1 ? activeLength - 1 : showPageCount)
    }

    function toggleNext() {
        activePage < length && changePage(activePage + 1)
        setActiveLength(activePage + showPageCount > length ? length : activePage + showPageCount)
    }


    return (
        <Pagination>
            {showPageCount < activeLength && <>
                <Pagination.First onClick={toggleFirstPage}/>
                <Pagination.Prev onClick={togglePrev}/>
            </>}
            {showPageCount < activeLength && <Pagination.Ellipsis
                onClick={() => setActiveLength(activeLength - showPageCount < showPageCount ? showPageCount : activeLength - showPageCount)}/>}
            {items}
            {length !== activeLength && <Pagination.Ellipsis
                onClick={() => setActiveLength(activeLength + showPageCount > length ? length : activeLength + showPageCount)}/>}
            <Pagination.Next onClick={toggleNext}/>
            <Pagination.Last onClick={toggleLastPage}/>
        </Pagination>
    );
}

export default CustomPagination;
