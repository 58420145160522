// Import packages
import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

// Import assets
import './channels-updateing-list.scss';
import {DeletingIcon, EditIcon, EllipsisIcon, PlusGreenIcon} from "../../../image";
import {useTranslation} from "react-i18next";
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {
    AddChannel,
    DeleteChannel,
    GetChannels,
    UpdateChannel,
    UpdateVideoChannel
} from "../../../redux/actions";

// Import Components
import DropdownMenu from "../dropdownMenu";
import {Form} from "react-bootstrap";
import InfoTooltip from "../../../pages/Dashboard/containers/Profile/tooltip";
import {underscoreChannelSort, hasPermission} from "../../../utils/helperFunctions";

// Import Utils

function ChannelsUpdatingList(props) {
    const {
        channels, video, handleHideModal, handleCheckedData, className, onHide, setOpenCreate,
        setEditingChannel, setShowChannelModal
    } = props;

    const [checkedChannels, setCheckedChannels] = useState(null);
    const tagColors = ['#4F40F7', '#2877D4', '#F7404B', '#CB43C6', '#CB7443'];

    let channelsList = {}

    const colors = {
        '1': {
            color: '#4F40F7',
            bgColor: '#4F40F719',
        },
        '2': {
            color: '#2877D5',
            bgColor: '#2877D419',
        },
        '3': {
            color: '#F7404B',
            bgColor: '#F7404B19',
        },
    }

    !!channels?.length && channels.forEach(item => {
        channelsList = {
            ...channelsList,
            [item.id]: {
                name: item.name,
                color: colors[item.id]?.color,
                bgColor: colors[item.id]?.bgColor,
            }
        }
    })

    // Custom sort function to ensure the element with id 5 comes first
    channels?.length && channels.sort((a, b) => {
        if (a?.is_private) {
            return -1;
        }
        if (!b?.is_private) {
            return 1;
        }
        return 0;
    });

    useEffect(() => {
        setInitChannels()
    },[])

    const {t} = useTranslation();

    function setInitChannels(){
        let channels = {
            ...checkedChannels,
        }
        !!video?.channels?.length && video?.channels.forEach(id => {
            channels = {
                ...channels,
                [id]: {
                    id,
                    is_bound: true
                }
            }
        })
        setCheckedChannels(channels)
        handleCheckedData && handleCheckedData(channels)
    }
    function checkChannel(e, id) {
        let channels = {
            ...checkedChannels,
            [id]: {
                id,
                is_bound: e.target.checked
            }
        }
        setCheckedChannels(channels)
        handleCheckedData && handleCheckedData(channels)
    }
    function onClose() {
        onHide(false)
        setEditingChannel('')
        setOpenCreate(false)
        // setShowChannelModal(false)
        // setCheckedChannels(null)
        setInitChannels()
    }

    function saveChanges() {
        if (checkedChannels) {
            const data = {
                channels: Object.values(checkedChannels)
            }
            props.UpdateVideoChannel(video.id, data).finally(()=>{
                onClose()
            })
        }
    }

    let list = checkedChannels && Object.keys(checkedChannels).filter(i => checkedChannels?.[i]?.is_bound && i !== '84')
    const disabledBtn = !!video?.channels?.length && video?.channels?.sort()?.toString() !== list?.sort()?.toString()

    let x = "Личное";
    let newChannelsList = !!channels?.length && channels?.sort((a, b) => b.id - a.id);
    hasPermission('someone_else_channels_read') &&
    (newChannelsList = !!newChannelsList?.length && newChannelsList?.sort(underscoreChannelSort))

    newChannelsList = !!newChannelsList?.length && newChannelsList?.sort((a, b) => {
        if (a.name === x) {
            return -1;
        }
        if (b.id === x) {
            return 1;
        }
        return 0;
    });

    return <div className={`channels-part ${className ? className : ''}`}>
        <div className="title">{t('manage_channels')}</div>

        {
                <button className="create-channel" onClick={() => {
                    setOpenCreate(true)
                    setShowChannelModal(true)
                }}>
                    <PlusGreenIcon/>
                    {t('create_channel')}
                </button>
        }

        <div className="list">
            {!!newChannelsList.length && newChannelsList.map((data, index) => {
                return <div key={`default-${data?.id}`} className={`channel-item ${data?.is_private ? 'private' : ''}`}>

                    <MyCheckbox checked={checkedChannels?.[data?.id]?.is_bound}
                                disabled={data?.is_private}
                                handleCheckboxChange={(e) => !data?.is_private && checkChannel(e, data.id)}/>
                    <div className="label"
                         style={{
                             backgroundColor: data?.is_private ? 'rgba(26, 187, 120, 0.10)'
                                 : tagColors[index >= 5 ? index % 5 : index] + '14',
                             color: data?.is_private ? "#1ABB78" : tagColors[index >= 5 ? index % 5 : index],
                             border: data?.is_private ? '0.6px solid #1ABB78' : '0'
                         }}
                    >{data?.name?.length > 27 ? <InfoTooltip elem={data?.name}
                                                            title={data?.name}/> : data?.name}</div>
                    {!data?.is_private && <DropdownMenu button={<EllipsisIcon className={'icon-menu'}/>}
                                                        closeAfterToggle={true}
                                                        closeOnDocumentClick={true}
                                                        className={'channel-actions'}
                                                        zIndex={1110}
                                                        contentWidth={'186px'}>
                        <div className="">
                            <div className={'menu-item'} onClick={() => {
                                setShowChannelModal(true)
                                setEditingChannel(data)
                                handleHideModal(true)
                            }}>
                                <EditIcon className={''}/> {t('button_edit')}
                            </div>
                            <div className={'menu-item'} onClick={() => props.DeleteChannel(data?.id)}>
                                <DeletingIcon className={''}/> {t('button_delete')}
                            </div>
                        </div>
                    </DropdownMenu>}

                </div>
            })}
        </div>

        <div className="buttons-wrapper">
            <button className={`close_btn`} onClick={onClose}>
                {t('button_cancel')}
            </button>

            <button className={`save_btn ${!disabledBtn ? 'disabled' : ''}`}
                    disabled={!disabledBtn}
                    onClick={saveChanges}>
                {t('button_save')}
            </button>
        </div>
    </div>;
}

function MyCheckbox({checked, handleCheckboxChange, disabled}) {
    const [isChecked, setIsChecked] = useState(false);
    useEffect(() => {
        setIsChecked(checked)
    }, [checked])

    return (
        <Form>
            <Form.Check
                type="checkbox"
                checked={isChecked}
                disabled={disabled}
                onChange={handleCheckboxChange}
            />
        </Form>
    );
}


const mapStateToProps = (state) => {
    return getPropsFromState(state, ['channels'])
};

const mapDispatchToProps = {
    UpdateChannel,
    GetChannels,
    DeleteChannel,
    UpdateVideoChannel,
    AddChannel
};

export default connect(mapStateToProps, mapDispatchToProps)(ChannelsUpdatingList);

