import React from 'react';

function FuseSearchInput(props) {
    const {
        className, placeholder, setSearchText, handleBlur, searchText, GetVideosSearch, loading, setLoadingSearch,
        handleOpenSearch, setShowIsEmpty
    } = props;

    const handleChange = (event) => {
        const searchValue = event?.target?.value;

        if (!searchValue) {
            setSearchText(undefined);
        }

        setSearchText(searchValue);
    };

    return (
        <div className="input-container" onClick={handleOpenSearch} style={{
            width: loading ? 'unset' : '100%'
        }}>
            <input
                value={searchText}
                className={className}
                placeholder={placeholder}
                onChange={handleChange}
                onBlur={handleBlur}
                style={{
                    width: loading ? `${(searchText?.length) * 16}px` : '100%'
                }}
                onKeyDown={e => {
                    if (e.key === 'Enter') {
                        if (searchText.length > 2) {
                            setLoadingSearch(true)
                            GetVideosSearch({
                                search_query: searchText
                            }).finally(() => {
                                setLoadingSearch(false)
                                setShowIsEmpty(true)
                            })
                        }
                    }
                }}
            />
            {loading && <div className="loading-dots">
                <span>.</span>
                <span>.</span>
                <span>.</span>
            </div>}
        </div>
    );
}

export default FuseSearchInput;
