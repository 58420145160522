import {HIGHLIGHT_CONSTS} from "../constants";

export const initialState = {
    highlights: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case HIGHLIGHT_CONSTS.GET_HIGHLIGHTS:
            return {
                ...state,
                highlights: action.payload,
            };
        case HIGHLIGHT_CONSTS.DELETE_HIGHLIGHT:
            return {
                ...state,
                highlights: state.highlights.filter(item => item.id !== action.payload),
            };
        default:
            return state;
    }
}
