// Import packages
import React, {useEffect} from 'react';

// Import assets
import './list-item.scss';
import {DeletingIcon, EditingIcon, EllipsisIcon} from "../../../../../../image";

// ImportComponents
import DropdownMenu from "../../../../../../shared/features/dropdownMenu";

// Import Utils
import {generateMediaUrl} from "../../../../../../utils/generateMediaUrl";
import { hasPermission} from "../../../../../../utils/helperFunctions";
import {useTranslation} from "react-i18next";


function ListItem({data, openModal, setCurrentUser, rolesList , index, openDeleteModal}) {
    const {t} = useTranslation();

    useEffect(() => {
        setCurrentUser(data)
    }, [data?.tags])

    function toggleUpdate() {
        setCurrentUser(data)
        openModal()
    }

    function toggleDelete() {
        setCurrentUser(data)
        openDeleteModal()
    }

  /*  function navigateTo() {
        !!data?.videos_count && history.push({
            pathname: `/dashboard/videos/userVideos/${data.person_id ? data.person_id : data.user_id}`,
            state: {
                personId: data.person_id ? data.person_id : data.user_id,
                routs: [
                    {
                        path: '/dashboard/users/persons',
                        name: t('user_page_title')
                    },
                    {
                        id: data.person_id ? data.person_id : data.user_id,
                        name: getAuthorName(data),
                    },
                    {
                        path: `/dashboard/videos/userVideos/${data.person_id ? data.person_id : data.user_id}`,
                        name: 'Видео с ' +  getAuthorName(data),
                        active: true
                    }
                ]
            }
        })
    }*/

    const tagColors = ['#4F40F7', '#2877D4', '#F7404B', '#CB43C6', '#CB7443']
    const role = rolesList?.find(i => i.id === data?.role_id)

    return (
        <div className="list-item persons">
            <div className="list-item__content">
                <div className="user_info item_col">
                    <img
                        src={generateMediaUrl(data?.avatar)}
                        alt=""
                        className="user_info__img"
                    />


                    <div className="user_info__content">
                        <div className="name"  onClick={toggleUpdate}>{data?.first_name || data?.name}&nbsp;
                            {data?.last_name ? data?.last_name : ''}
                        </div>
                        <div className="email">{data?.login || 'user@gmail.com'}</div>
                    </div>
                </div>

             {/*   <div className="item_col">
                    {data?.tags?.length ? <DropdownMenu
                        button={<div className={'person_tag_wrap'}>
                            <div className="person_tag"
                                 style={{
                                     borderColor: tagColors[0],
                                     color: tagColors[0]
                                 }}>
                                {data?.tags?.[0]?.name}
                            </div>
                            {data?.tags?.length > 1 && <span className="tags-count">
                            +{data?.tags?.length - 1}
                        </span>}
                        </div>}
                        closeAfterToggle={false}
                        fullWidth={false}>
                        <div className="tags-list">
                            {
                                data?.tags?.length && data?.tags?.map((item, index) => {
                                    return <div key={`${item?.id}_${index}`}
                                                className="tag-item person_tag"
                                                style={{
                                                    borderColor: tagColors[index],
                                                    color: tagColors[index]
                                                }}>{item?.name}</div>
                                })
                            }
                        </div>
                    </DropdownMenu> : '-'}
                </div>*/}

                <div className="item_col" >
                    {role?.id ? <div className="role" style={{
                        backgroundColor: tagColors?.slice(1)[index >= 5 ? index % 5 : index] + '14',
                        color: tagColors?.slice(1)[index >= 5 ? index % 5 : index],
                    }}>{role?.name}</div> : '-'}
                </div>

                <div className="item_col light">
                    {data?.organization || '-'}
                </div>

                <div className="item_col light">
                    {data?.position || '-'}
                </div>
            </div>
            <div className="list-item__actions" onClick={(e)=> e.stopPropagation()}>
                {/*<div className="video_count item_col" onClick={navigateTo}>*/}
                {/*    <VideoIcon/> {data?.videos_count}*/}
                {/*</div>*/}
                <DropdownMenu button={
                    <EllipsisIcon className={'icon-menu'}/>}
                              closeAfterToggle={true}
                              className="actions-btn"
                              contentClassName="actions-menu"
                              contentWidth={'182px'}
                >
                    <div className="actions">
                        <div className="edit" onClick={toggleUpdate}>
                            <EditingIcon/> {t('button_edit')}
                        </div>
                        {hasPermission('users_delete') && <div className="menu-item delete" onClick={toggleDelete}>
                            <DeletingIcon/> {t('button_delete')}
                        </div>}
                    </div>
                </DropdownMenu>
            </div>
        </div>
    );
}

export default ListItem;
