// Import packages
import React, {useState} from 'react';
import {connect} from "react-redux";

// Import assets
import {
    DeletingIcon,
    EditingIcon,
    EllipsisIcon,
    RefreshIcon
} from "../../../../../../image";

// Import Components
import UserIcons from "../../../../../../shared/features/userIcons/UserIcons";
import DropdownMenu from "../../../../../../shared/features/dropdownMenu";
import UpdateVideoModal from "../../../../../../shared/modals/updateVideolModal/UpdateVideoModal";
import InfoTooltip from "../../../Profile/tooltip";
import ChannelsUpdatingList from "../../../../../../shared/features/ChannelsUpdatingList/ChannelsUpdatingList";

// Import Utils
import {history} from "../../../../../../utils/history";
import {getPropsFromState} from "../../../../../../redux/mapStateToProps";
import {
    AddChannel,
    AddVideoDescription, ChangeReprocessStatus,
    DeleteChannel,
    DeleteVideo, ReprocessVideo, UpdateChannel,
    UpdateVideoChannel
} from "../../../../../../redux/actions";
import {statusesListRu} from "../../../../../../utils/constants";
import {useTranslation} from "react-i18next";
import ChannelsModal from "../../../../../../shared/modals/channelModal/ChannelsModal";
import UserInfoModal from "../../../Users/modals/userInfoModal/UserInfoModal";
import ShearModal from "../../../../../../shared/modals/shearModal/ShearModal";
import {Form} from "react-bootstrap";

function ListItem(props) {
    const {data, channelsList, userRoles, userProfile, activePage, selected, toggleCheck, showCheckbox, index} = props;

    const [isRename, setIsRename] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [channelsMenuIsClosed, setChannelsMenuIsClosed] = useState(false);
    const [editingChannel, setEditingChannel] = useState('');
    const [openCreate, setOpenCreate] = useState(false);
    const [showChannelModal, setShowChannelModal] = useState(false);
    const [showUserModal, setShowUserModal] = useState(false)
    const [showShearModal, setShowShearModal] = useState(false);

    const {t} = useTranslation();

    const tagColors = ['#4F40F7', '#2877D4', '#F7404B', '#CB43C6', '#CB7443']

    const navigateVideo = () => {
        !isRename && !showUserModal && history.push({
            pathname: `/dashboard/videos/video/${data?.id}`,
            state: {
                routs: [
                    {
                        path: '/dashboard/videos/review',
                        name: t('menu_item_video')
                    },
                    {
                        path: '/dashboard/videos/manage',
                        name: t('manage_video_title'),
                        activePage: activePage
                    },
                ],
                activePage: activePage
            }
        })
    };

    function toggleReprocess() {
        props.ChangeReprocessStatus(data?.id)
        props.ReprocessVideo(data?.id)
        props.ChangeReprocessStatus(data?.id)
    }

    function toggleEdit() {
        setIsRename(true)
        setSelectedVideo(data)
        handleCloseChannelsMenu(true)
    }

    function deleteVideo() {
        props.DeleteVideo(data.id)
    }

    function handleCloseChannelsMenu(isClosed) {
        setChannelsMenuIsClosed(isClosed)
    }


    function closeChannelsModal() {
        setShowChannelModal(false)
        setEditingChannel('')
        // handleHideModal(false)
        setOpenCreate(false)
    }

    function toggleUserModal() {
        setShowUserModal(!showUserModal);
    }

    function closeShearModal() {
        setShowShearModal(false)
    }

    const changeDateFormat = (date) => {
        const newDate = new Date(date)
        const day = newDate?.getDate()
        const month = newDate?.getMonth() + 1
        const year = newDate?.getFullYear()

        return `${day}/${month}/${year}`
    }

    const tableInfo = [
        {
            name: showCheckbox && <Form>
                <Form.Check
                    type="checkbox"
                    checked={selected?.includes(data?.id)}
                    onChange={() => toggleCheck(data.id)}
                />
            </Form>,
            width: '20px',
            // className: 'text'
        },
        {
            name: <span
                className={'clickable'}
                onClick={navigateVideo}
            >
                {(data.description?.length > 50
                    ? <InfoTooltip elem={data.description}
                                   title={data.description}/>
                    : data.description) || '-'}</span>,
            width: '12.8%',
            className: 'text'
        },
        {
            name: data.created_at,
            width: '7.7%',
            className: 'light'
        },
        {
            name: data?.duration ? data?.duration : '-',
            width: '6.2%',
            className: 'light'
        },
        {
            name: data?.video_status ? <InfoTooltip elem={
                <span style={{
                    color: statusesListRu[data?.video_status]?.color
                }}>
                    {statusesListRu[data?.video_status]?.title || ''}
                </span>
            }
                                                    title={statusesListRu[data?.video_status]?.key}/> : '-',
            width: '6.2%',
            className: 'light'
        },
        {
            name: <span className={'clickable'}
                        onClick={(e) => {
                            e.stopPropagation()
                            userProfile?.id === data?.owner?.user_id
                                ? history.push('/dashboard/profile')
                                : toggleUserModal()
                        }}
            >
                {data?.owner?.name ? data?.owner?.name : ''}<br/>
                <span className={'owner-mark'}>{userProfile?.id === data?.owner?.user_id && '(Вы)'}</span>
            </span>,
            width: '12.8%',
            className: `light ${userProfile?.id === data?.owner?.user_id ? 'active' : ''}`
        },
        {
            name: <div className={'clickable'} onClick={() => setShowShearModal(true)}>
                <div className={`link-btn ${(userProfile?.id === data?.owner?.user_id) ? 'is-owner' : ''}`}>
                    {data?.link?.is_public ? <>
                        Публичная<br/>
                        {data?.link?.expiration_time && `(до ${changeDateFormat(data?.link?.expiration_time)})`}
                    </> : 'Непубличная'}
                </div>
            </div>,
            width: '10.9%',
            className: 'light'
        },
        {
            name: data?.channels?.length > 0 && <DropdownMenu
                button={<div key={data.date} className="channels-btn">
                    {
                        data?.channels?.length ? <span className="channels">
                        <div className="channel-item"
                             style={{
                                 backgroundColor: data?.is_private ? 'rgba(26, 187, 120, 0.10)'
                                     : tagColors[0] + '14',
                                 color: data?.is_private ? "#1ABB78" : tagColors[0],
                                 border: data?.is_private ? '0.6px solid #1ABB78' : '0'
                             }}>
                                    {channelsList[data?.channels[0]]?.name}
                                </div>
                                {data?.channels.length > 1 &&
                                    <div className="channels-count">+{data?.channels.length - 1}</div>}
                                            </span>
                            : <div className="no-channels">
                                {t('no_channels')}
                            </div>
                    }
                </div>}
                closeDropdown={channelsMenuIsClosed}
                onClose={handleCloseChannelsMenu}
                className={'link-dropdown'}
                closeAfterToggle={false}
                closeOnDocumentClick={false}
                contentWidth={'347px'}
                fullWidth={false}>
                <ChannelsUpdatingList video={data}
                                      onHide={handleCloseChannelsMenu}
                                      setShowChannelModal={setShowChannelModal}
                                      setEditingChannel={setEditingChannel}
                                      setOpenCreate={setOpenCreate}
                                      className={'simple'}/>
            </DropdownMenu>,
            width: '15%',
        },
        {
            name: <UserIcons persons={data?.meeting_participants} placement={'left'}/>,
            width: '8.1%'
        },
    ]

    return <div className={'table-row'} key={data.id}>
        {
            tableInfo?.map((item, index) => {
                return <div key={index}
                            className={item?.className}
                            style={{width: item?.width}}
                >{item?.name}</div>
            })
        }

        <div className={'actions-wrapper'}
             style={{width: '2.2%'}}
             onClick={(e) => e.stopPropagation()}>
            <DropdownMenu button={
                <EllipsisIcon className={'icon-menu'}/>}
                          closeAfterToggle={true}
                          index={index}
                          contentClassName="actions-menu"
            >
                <div className="actions">
                    {userRoles?.permissions?.find(i => i.name === 'videos_reprocess') &&
                        <div className="refresh" onClick={toggleReprocess}><RefreshIcon/> {t('button_refresh')}</div>}
                    {userRoles?.permissions?.find(i => i.name === 'videos_update') &&
                        <div className="edit" onClick={toggleEdit}><EditingIcon/> {t('button_edit')}</div>}
                    {userRoles?.permissions?.find(i => i.name === 'videos_delete')
                        && <div className="delete" onClick={deleteVideo}>
                            <DeletingIcon/> {t('button_delete')}
                        </div>}
                </div>
            </DropdownMenu>
        </div>
        {isRename && <UpdateVideoModal showModal={isRename}
                                       onHide={() => setIsRename(false)}
                                       video={selectedVideo}/>}

        {showChannelModal && <ChannelsModal showModal={showChannelModal}
                                            channel={!openCreate && editingChannel}
                                            createChannel={openCreate}
                                            onHide={closeChannelsModal}/>}

        {showUserModal && <UserInfoModal show={showUserModal}
                                         closeModal={toggleUserModal}
                                         user={data.owner}
            // videoPersonsList={videoById?.persons}
        />}
        {showShearModal && <ShearModal showModal={showShearModal}
                                       onHide={closeShearModal}
                                       videoById={data}/>}
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, ['channels', 'userProfile', 'refresh', 'userRoles'])
};

const mapDispatchToProps = {
    UpdateVideoChannel,
    AddVideoDescription,
    DeleteVideo,
    AddChannel,
    UpdateChannel,
    DeleteChannel,
    ReprocessVideo,
    ChangeReprocessStatus
};

export default connect(mapStateToProps, mapDispatchToProps)(ListItem);
