// Import packages
import React, {useEffect, useRef, useState} from 'react';
import {connect} from "react-redux";
import DatePicker, {registerLocale} from "react-datepicker";

// Import assets
import "react-datepicker/dist/react-datepicker.css";
import './calendar.scss';
import {calendar_img, CloseIcon, GoogleCalendarIcon, SettingsIcon} from "../../../image";

//Import components
import EventItem from "./eventItem/EventItem";
import Synchronize from "./synchronize/Synchronize";
import SettingsModal from "./settingsModal/SettingsModal";
import {GoogleOAuthProvider, useGoogleLogin} from "@react-oauth/google";
import {Modal} from "react-bootstrap";

//Import utils
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {
    CalendarLoggedIn,
    GetCalendarsEvents,
    GetCalendarSynchronize, GetGoogleAuth, GoogleAuth
} from "../../../redux/actions";
import {changeTimeFormat, hasPermission} from "../../../utils/helperFunctions";
import {useTranslation} from "react-i18next";
import config from "../../../utils/config";
import {enGB} from 'date-fns/locale';

function Calendar(props) {
    const {calendarEvents, clientId, googleToken, onClose} = props;
    const [showSettingsModal, setShowSettingsModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(null);
    const [googleUser, setGoogleUser] = useState(undefined);
    const [eventList, setEventList] = useState({});
    const [loading, setLoading] = useState(false);

    const targetRef = useRef(null);

    const isMax = config.productName.includes('max')
    registerLocale('en-GB', enGB);
    const {t} = useTranslation();

    useEffect(() => {
        props.GetGoogleAuth()

        calendarEventsList()
    }, [googleToken])


    useEffect(() => {
        const groupedDates = {};
        !!calendarEvents?.length && calendarEvents.forEach((item) => {
            const dateStart = changeTimeFormat(item?.datetime_start)
            const dateStartDay = dateStart.split(',')?.[0]

            if (!groupedDates[dateStartDay]) {
                groupedDates[dateStartDay] = [item];
            } else {
                groupedDates[dateStartDay].push(item);
            }
        });
        setEventList(groupedDates)
    }, [calendarEvents])

    useEffect(() => {
        if (googleToken) {
            fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
                headers: {Authorization: `Bearer ${googleToken}`}
            }).then(response => response.json())
                .then(data => {
                    setGoogleUser(data);
                })
        }
    }, [googleToken])

    useEffect(() => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        props.GetCalendarSynchronize()
        getEventsList(start, end)
    }, [startDate, endDate])

    function getEventsList(dateStart, dateEnd) {
        dateStart.setHours(0, 0, 0, 0);
        dateEnd.setHours(23, 59, 59, 0);

        props.GetCalendarsEvents({
            datetime_start: dateStart.toISOString(),
            datetime_end: dateEnd.toISOString()
        })
    }

    function calendarEventsList() {
        let tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);

        setStartDate(new Date())
        setEndDate(tomorrow)
        const dateStart = new Date();

        getEventsList(dateStart, tomorrow)
    }

    function calendarSynchronize() {
        setLoading(true)
        props.GetCalendarSynchronize().finally(() => {
            setLoading(false)
        })
    }

    function handleOnSuccess(response) {
        setGoogleUser(response?.hd)
        props.GoogleAuth({
            code: response?.code,
            scope: response?.scope
        }).then(() => {
            closeSettingsModal()
            setShowSuccessModal(true)
        })
    }

    function closeSuccessModal() {
        setShowSuccessModal(false)
        calendarSynchronize()
        setShowSettingsModal(true)
    }

    function closeSettingsModal() {
        setShowSettingsModal(false)
        calendarSynchronize()
        calendarEventsList()
    }

    function SuccessModal() {
        return (
            <Modal
                show={showSuccessModal}
                onHide={closeSuccessModal}
                onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                }}
                contentClassName={'success_modal'}
                centered
            >
                <div className="calendar-title">
                    <GoogleCalendarIcon/>
                    {t('calendar_connected_title')}
                </div>
                <button className="calendar-btn" onClick={closeSuccessModal}>
                    {t('calendar_connected_btn')}
                </button>
            </Modal>
        );
    }

    function changeTime(dates) {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);

        const dateStart = new Date(start);
        const dateEnd = new Date(end);

        getEventsList(dateStart, dateEnd)
    }

    function getEventsAfterSynchronized() {
        changeTime([startDate, endDate])
    }

    return <div className="calendar">
        <div className="calendar__header">
            <div className="left_part">
                {t('calendar_title')}
                {(googleToken || isMax) && <Synchronize loading={loading}
                                                        getEvents={getEventsAfterSynchronized}/>}
            </div>
            {(googleToken || isMax) && <div className="right_part">
                {/*<ContactsIcon/>*/}
                <SettingsIcon onClick={() => setShowSettingsModal(true)}
                              className="settings-btn"/>
                <CloseIcon onClick={onClose} className="close-btn"/>
            </div>}
        </div>
        <div className="calendar__content"
             ref={targetRef}>
            {
                (googleToken || isMax) ? <>
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => changeTime(date)}
                            startDate={startDate}
                            endDate={endDate}
                            dateFormat="yyyy/MM/dd"
                            locale="en-GB"
                            selectsRange
                            inline
                        />
                        {
                            hasPermission('calendar_events_read') &&
                            Object.entries(eventList).map(([key, list]) => {

                                return <div className="calendar__day-item" key={key}>
                                    <div className="day-header">
                                        <span className="day-header__title"></span>
                                        <span
                                            className="day-header__date">{key}</span>
                                    </div>
                                    {
                                        !!list?.length && list?.map(item => {
                                            return <EventItem key={item?.id} data={item}/>
                                        })
                                    }
                                </div>
                            })
                        }

                    </>
                    : <div className="calendar__auth">
                        <img src={calendar_img} alt=""/>
                        <div className="calendar__auth__title">{t('calendar_connect')}</div>
                        <div className="calendar__auth__description">
                            {t('calendar_description')}
                        </div>
                        {clientId && <GoogleOAuthProvider clientId={clientId}>
                            <GoogleButton GoogleAuth={props?.GoogleAuth}
                                          t={t}
                                          handleOnSuccess={handleOnSuccess}/>
                        </GoogleOAuthProvider>}
                    </div>
            }
        </div>
        {showSuccessModal && SuccessModal()}
        <SettingsModal showSettingsModal={showSettingsModal}
                       targetRef={targetRef}
                       googleToken={googleToken}
                       clientId={clientId}
                       user={googleUser}
                       setGoogleUser={setGoogleUser}
                       handleOnSuccess={handleOnSuccess}
                       onHide={closeSettingsModal}/>
    </div>
}

function GoogleButton({handleOnSuccess, t}) {
    const login = useGoogleLogin({
        flow: 'auth-code',
        scope: 'https://www.googleapis.com/auth/calendar',
        onSuccess: async (codeResponse) => {
            handleOnSuccess(codeResponse)
        },
        onError: errorResponse => console.log(errorResponse),
    });
    return <button className="calendar__auth__btn"
                   onClick={() => login()}>
        <GoogleCalendarIcon/>
        {t('calendar_google_btn_title')}
    </button>;
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'calendarEvents', 'calendarIsLoggedIn', 'clientId', 'googleToken', 'userRoles'
    ])
};

const mapDispatchToProps = {
    GetCalendarsEvents,
    GetCalendarSynchronize,
    GetGoogleAuth,
    CalendarLoggedIn,
    GoogleAuth,
};

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
