// Import packages
import React, {useEffect, useRef, useState} from 'react';
import {connect} from "react-redux";

// Import assets
import './user-icons.scss';

// Import Components
import UserInfoModal from "../../../pages/Dashboard/containers/Users/modals/userInfoModal/UserInfoModal";

// Import Utils
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {generateMediaUrl} from "../../../utils/generateMediaUrl";
import {getAuthorName} from "../../../utils/helperFunctions";

function UserIcons(props) {
    const {persons, placement} = props;
    const [showMenu, setShowMenu] = useState(false);
    const [showModal, setShowModal] = useState(false)
    const [currentUser, setCurrentUser] = useState(null)
    const [openTop, setOpenTop] = useState(false);

    const ref = useRef();
    const content = useRef();


    useEffect(() => {
        if (showMenu) {
            if (content.current && ref.current && window.innerHeight) {
                const rect = content.current.getBoundingClientRect();
                const rectContent = ref.current.getBoundingClientRect();

                setOpenTop(window.innerHeight < (rect?.top + rect?.height + rectContent.height + 20))
            }
        } else {
            setOpenTop(false)
        }
    }, [showMenu]);

    useEffect(() => {
        document.addEventListener('click', togglePersonsMenu, {capture: true});
        return () => {
            document.removeEventListener('click', togglePersonsMenu)
        }
    }, []);

    const togglePersonsMenu = (event) => {
        const path = event.composedPath ? event.composedPath() : event.path
        if (!path?.includes(ref?.current)) {
            setShowMenu(false)
        }
    }

    function toggleUserModal(person) {
        setShowModal(!showModal)
        setCurrentUser(person)
    }

    let placementType = '';
    (placement === 'top' || openTop) && (placementType = 'open-top')
    placement === 'bottom' && (placementType = 'open-bottom')

    return <div className="user-icons" ref={ref}
                onClick={(e) => {
                    e.stopPropagation()
                    setShowMenu(!showMenu)
                }}>
        {/*{
            !!persons?.length && persons?.slice(0, 2)?.map(img => {
                return <img
                    src={generateMediaUrl(img?.avatar)}
                    key={img.id}
                    alt=""
                    className="user-icons__item"
                />
            })
        }*/}

        {!!persons?.length && <div className="user-icons__more">
            {/*+*/}
            {persons?.length}
        </div>}

        {showMenu && <div className={`persons_menu 
        ${placementType ? placementType : ''}
        ${persons?.length > 4 ? 'large' : ''} 
        ${placement === 'left' ? 'left' : ''}`}
                          onClick={(e) => {
                              e.stopPropagation()
                              e.preventDefault()
                          }}

                          ref={content}>
            {
                !!persons?.length && persons?.map(item => {
                    const data = {
                        ...item,
                        person_id: item.id
                    }
                    delete data.id
                    return <div key={data?.person_id} className="person_item"
                                onClick={() => !data?.nickname && toggleUserModal(data)}
                    >
                        <img
                            src={generateMediaUrl(data?.avatar)}
                            key={data?.person_id}
                            alt=""
                        />
                        <div className="person_info">
                            <div className="name">
                                {data?.nickname ? data?.nickname : getAuthorName(data)}
                                {/*{data?.getAuthorName(data)}*/}
                            </div>
                            <div className="email">
                                {data.email || 'user@gmail.com'}
                            </div>
                        </div>
                    </div>
                })
            }
        </div>}
        {showModal && <UserInfoModal show={showModal} closeModal={toggleUserModal} user={currentUser}
                                     videoPersonsList={persons}/>}
    </div>;
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [])
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UserIcons);
