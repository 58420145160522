import {PROMPT_CONSTS} from "../constants";

export const initialState = {
    promptList: [],
    promptById: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case PROMPT_CONSTS.GET_PROMPT:
            return {
                ...state,
                promptList: action.payload,
            };
        case PROMPT_CONSTS.GET_PROMPT_BY_ID:
            return {
                ...state,
                promptById: action.payload,
            };
        case PROMPT_CONSTS.CLEAR_PROMPT_BY_ID:
            return {
                ...state,
                promptById: {},
            };
        case PROMPT_CONSTS.ADD_PROMPT:
            return {
                ...state,
                promptList: [action.payload.data, ...state.promptList],
            };
        case PROMPT_CONSTS.UPDATE_PROMPT:
            return {
                ...state,
                promptList: state.promptList.map(item => item?.id === action.payload.id ? {
                    ...item,
                    ...action.payload.data,
                    isMarked: !!action.payload.data?.start_time
                } : item),
            };
        case PROMPT_CONSTS.DELETE_PROMPT:
            return {
                ...state,
                promptList: state.promptList.filter(item => item.id !== action.payload?.id),
            };
        default:
            return state;
    }
}
