import React, {useState} from "react";
import {CopyIcon, DownloadIcon, EditIcon, EllipsisIcon, ErrorIcon, SuccessIcon} from "../../../../../image";
import './update-introduction.scss'
import {useTranslation} from "react-i18next";
import DropdownMenu from "../../../../../shared/features/dropdownMenu";
import {Toast} from "react-bootstrap";

function UpdateIntroduction(props) {
    const {
        type, text, changeEditingType, setKey
    } = props;

    const [showErrorToast, setShowErrorToast] = useState(false);
    const [showSuccessToast, setShowSuccessToast] = useState(false);

    const {t} = useTranslation();

    const toggleErrorToast = () => setShowErrorToast(!showErrorToast);
    const toggleSuccessToast = () => setShowSuccessToast(!showSuccessToast);

    function ErrorToast() {
        return <Toast show={showErrorToast}
                      onClose={toggleErrorToast}
                      className={'upload_video_answer'}
                      autohide={true}
                      delay={3000}>
            <ErrorIcon/>
            <div className="toast-content">
                <strong className="title">{t('toast_error_title')}</strong>
                <p className={'description'}>{t('toast_error_description')}</p>
            </div>
        </Toast>
    }

    function SuccessToast() {
        return <Toast show={showSuccessToast}
                      onClose={toggleSuccessToast}
                      className={'upload_video_answer'}
                      autohide={true}
                      delay={3000}>
            <SuccessIcon/>
            <div className="toast-content">
                <strong className="title">{t('toast_success_title')}</strong>
                <p className={'description'}>{t('toast_success_description')}</p>
            </div>
        </Toast>
    }

    function copyPersonLink() {
        navigator.clipboard.writeText(text);
        toggleSuccessToast()
    }

    const downloadTxtFile = () => {
        const element = document.createElement("a");
        const file = new Blob([text], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = type;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
        document.body.removeChild(element);
    };

    return <>
        <CopyIcon className={'icon-copy'} onClick={(e) => {
            e.stopPropagation()
            copyPersonLink()
        }}/>
        <DropdownMenu button={
            <EllipsisIcon className={'icon-menu'}
                          onClick={() => {
                              setKey(type)
                          }}/>}
                      closeAfterToggle={true}
                      className="introduction-update-menu"
                      contentWidth={'314px'}
                      position={'bottom left'}
                      zIndex={100}
        >
            <div className={'menu-item'} onClick={(e) => {
                e.stopPropagation()
                changeEditingType(type)
            }}>
                <EditIcon className={''}/>
                {t('button_edit')}
            </div>
            <div className={'menu-item'} onClick={(e) => {
                e.stopPropagation()
                downloadTxtFile()
            }}>
                <DownloadIcon className={''}/>
                {t('download_transcription')}
            </div>
            <div className={'menu-item'} onClick={(e) => {
                e.stopPropagation()
                copyPersonLink()
            }}>
                <CopyIcon className={''}/>
                Скопировать
            </div>
        </DropdownMenu>
        {ErrorToast()}
        {SuccessToast()}
    </>
}

export default UpdateIntroduction
