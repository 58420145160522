import {CHANNEL_CONSTS} from "../constants";

export const initialState = {
    channels: [],
    channelById: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CHANNEL_CONSTS.GET_CHANNELS:
            return {
                ...state,
                channels: action.payload,
            };
        case CHANNEL_CONSTS.GET_CHANNEL_BY_ID:
            return {
                ...state,
                channelById: action.payload,
            };
        case CHANNEL_CONSTS.ADD_CHANNEL:
            return {
                ...state,
                channels: [...state.channels, action.payload],
            };
        case CHANNEL_CONSTS.UPDATE_CHANNEL:
            return {
                ...state,
                channels: state.channels.map(item => item.id === action.payload.id ? {
                    ...item,
                    name: action.payload.data.name
                } : item),
            };
        case CHANNEL_CONSTS.DELETE_CHANNEL:
            return {
                ...state,
                channels: state.channels.filter(item => item.id !== action.payload),
            };
        default:
            return state;
    }
}
