// Import packages
import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {useLocation} from "react-router-dom";
import InfiniteScroll from "react-infinite-scroller";

// Import assets
import './channels.scss';
import {PlusIcon} from "../../../../image";

// Import Components
import Section from "./section/Section";
import Calendar from "../../../../shared/features/calendar/Calendar";
import ChannelsModal from "../../../../shared/modals/channelModal/ChannelsModal";

// Import Utils
import {getPropsFromState} from "../../../../redux/mapStateToProps";
import {ChangeHeaderTitle, GetVideos, GetVideosSearch, GetPersons,ChangeGridView} from "../../../../redux/actions";
import {GetChannels} from "../../../../redux/actions";
import {useTranslation} from "react-i18next";
import {hasPermission} from "../../../../utils/helperFunctions";

function Channels(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [isFetching, setFetching] = useState(false);
    const [openCreate, setOpenCreate] = useState(false);
    const [showChannelModal, setShowChannelModal] = useState(false);
    const [hasMoreChannels, setHasMoreChannels] = useState(true);
    const [channelsData, setChannelsData] = useState(true);

    const location = useLocation();
    const {t} = useTranslation();

    useEffect(() => {
        props.ChangeGridView(true)
        props.GetChannels().then(() => {
            setIsLoading(true)
        })
        setIsLoading(false)
        const title = t('menu_item_review')
        props.ChangeHeaderTitle(title)
        props.GetPersons({
            status: 'known',
            limit: 1000
        })
    }, [location?.state?.personId]);

    useEffect(() => {
        if (!!props.channels.length){
            setChannelsData(props.channels.slice(0, 4))
            setHasMoreChannels(props.channels.length > 4)
        }
    }, [props?.channels]);


    function closeChannelsModal() {
        setShowChannelModal(false)
        setOpenCreate(false)
    }

    function loadMoreItems() {
        if (!isFetching) {
            setChannelsData(props.channels.slice(0, channelsData?.length + 4))
            setHasMoreChannels(props.channels.length !== channelsData?.length)
            setFetching(true);
            setTimeout(()=>{
                setFetching(false);
            }, 500)
        }
    }

    const noChannelList = !!props?.videosList?.results?.length && props?.videosList?.results?.filter(i => !i?.channels?.length)


    let channelsList = {}

    const colors = {
        '1': {
            color: '#4F40F7',
            bgColor: '#4F40F719',
        },
        '2': {
            color: '#2877D5',
            bgColor: '#2877D419',
        },
        '3': {
            color: '#F7404B',
            bgColor: '#F7404B19',
        },
    }

    !!channelsData?.length && channelsData.forEach(item => {
        channelsList = {
            ...channelsList,
            [item.id]: {
                name: item.name,
                color: colors[item.id]?.color,
                bgColor: colors[item.id]?.bgColor,
            }
        }
    })

    let x = "Личное";
    let newList = !!channelsData?.length && channelsData?.sort((a, b) => {
        if (a.name === x) {
            return -1;
        }
        if (b.id === x) {
            return 1;
        }
        return 0;
    });

    return <div className={`main-container channels-container full`}>
        {/*<div className="channels">*/}
            <InfiniteScroll
                hasMore={hasMoreChannels}
                loadMore={loadMoreItems}
                className={'channels'}
                pageStart={0}
                useWindow={true}
                initialLoad={false}>
                {isLoading && <>
                    <div className="page-title" style={{padding: '0 32px'}}>
                        {t('menu_item_review')}

                        <button className="create-channel" onClick={() => {
                            setOpenCreate(true)
                            setShowChannelModal(true)
                        }}>
                            <PlusIcon/>
                            {t('create_channel')}
                        </button>
                    </div>
                    {!!newList?.length && newList?.map((channel) => {
                        return <Section key={channel?.id}
                                        channel={channel}
                                        channelsList={channelsList}/>
                    })}
                    {!!noChannelList?.length && <Section badge={{name: t('no_channels')}}
                                                         channelsList={channelsList}
                                                         list={noChannelList}/>}
                </>}
            </InfiniteScroll>

        {/*</div>*/}
        {hasPermission('user_calendars_read') && <Calendar/>}
        {showChannelModal && <ChannelsModal showModal={showChannelModal}
                                            channel={!openCreate}
                                            createChannel={openCreate}
                                            onHide={closeChannelsModal}/>}

    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'isMobile',
        'channels',
        'videosList',
        'isFullWidth',
        'userRoles',
        'hasMoreChannels'
    ])
};

const mapDispatchToProps = {
    GetVideos,
    GetVideosSearch,
    GetChannels,
    ChangeHeaderTitle,
    GetPersons,
    ChangeGridView
};

export default connect(mapStateToProps, mapDispatchToProps)(Channels);
