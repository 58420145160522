import React from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter, Router} from "react-router-dom";
import App from "./App";

import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-nestable/dist/styles/index.css";
import "allotment/dist/style.css";

import "./utils/i18"

import {history} from "./utils/history";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
        <BrowserRouter>
            <Router history={history}>
                <App/>
            </Router>
        </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
