import {AUTH_CONSTS} from "../constants";

export const initialState = {
    token: undefined,
    redirectUrl: '',
    isPublic: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case AUTH_CONSTS.LOG_IN:
            return {
                ...state,
                token: action.payload.token
            };
        case AUTH_CONSTS.LOG_OUT:
            return {
                ...state,
                token: undefined
            };
        case AUTH_CONSTS.REDIRECT_URL:
            return {
                ...state,
                redirectUrl: action.payload
            };
        case AUTH_CONSTS.CLEAR_REDIRECT_URL:
            return {
                ...state,
                redirectUrl: '',
                isPublic: false
            };
        case AUTH_CONSTS.IS_PUBLIC:
            return {
                ...state,
                isPublic: true
            };
        case AUTH_CONSTS.IS_UNPUBLIC:
            return {
                ...state,
                isPublic: false
            };
        default:
            return state;
    }
}
