import {STATISTICS_CONSTS} from "../constants";

export const initialState = {
    statistics: undefined,
    statisticVideo: undefined,
    companies: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case STATISTICS_CONSTS.GET_STATISTICS:
            return {
                ...state,
                statistics: action.payload,
            };
        case STATISTICS_CONSTS.GET_STATISTICS_VIDEO:
            return {
                ...state,
                statisticVideo: action.payload,
            };
        case STATISTICS_CONSTS.GET_COMPANIES:
            return {
                ...state,
                companies: action.payload,
            };
        default:
            return state;
    }
}
