// Import packages
import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

// Import assets
import './channels-modal.scss';

//Import components
import {Form, Modal} from "react-bootstrap";

//Import utils
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {useTranslation} from "react-i18next";
import {CloseIcon, PlusGreenIcon, SearchIcon} from "../../../image";
import {
    GetChannelById,
    SearchUsers,
    UpdateChannelUsers,
    UpdateChannel,
    GetChannels,
    ClearUsersSearch,
    AddChannel
} from "../../../redux/actions";
import UserInfoItem from "../../features/userInfoItem";

const initialState = {
    channelName: '',
    managerName: '',
    participantsName: '',
    type: false
}

function ChannelsModal(props) {
    const {showModal, onHide, channel, channelById, searchedUsers, createChannel} = props;
    const {t} = useTranslation();

    const [showMangers, setShowManagers] = useState(false);
    const [haveChanges, setHaveChanges] = useState(new Map());
    const [channelInfo, setChannelInfo] = useState(initialState);
    const [participants, setParticipants] = useState(null);
    const [participantsList, setParticipantsList] = useState([]);
    const [checkedAll, setCheckedAll] = useState(true);
    // console.log(channelById, 'channelById', createChannel);
    // let isDisabled = channelById?.name !== channelInfo?.channelName
    useEffect(() => {
        props.GetChannelById(channel?.id)
        props.ClearUsersSearch()
        !showModal && setChannelInfo(initialState)
    }, [showModal]);

    useEffect(() => {
        if (!createChannel) {
            setChannelInfo({
                ...channelInfo,
                channelName: channelById?.name
            })

            let list = {}
            !!channelById?.participants?.length && channelById?.participants.forEach(i => {
                list = {
                    ...list,
                    [i.user_id]: {
                        user_id: i.user_id,
                        is_bound: !!i?.user_id
                    }
                }
            })
            setParticipants(list)
            setParticipantsList(channelById?.participants || [])
        }
    }, [channelById, channelInfo.type]);

    useEffect(() => {
        if (!createChannel) {
            setChannelInfo({
                ...channelInfo,
                channelName: channelById?.name
            })
        }
    }, [participants]);

    function getInputValue(e) {
        const {name, value} = e.target
        setChannelInfo({
            ...channelInfo,
            [name]: value
        })

        const changes = new Map(haveChanges);
        // if (roleById?.[name] !== value) {
        //     changes.set(name, value)
        // } else {
        //     changes.delete(name)
        // }
        setHaveChanges(changes)
    }

    function getSearchValue(e) {
        const {name, value} = e.target
        setChannelInfo({
            ...channelInfo,
            [name]: value
        })

        value?.length > 1 && props.SearchUsers(value)
    }

    function toggleCheck() {
        setChannelInfo({
            ...channelInfo,
            type: !channelInfo.type
        })
    }

    function checkAll() {
        let list = {}
        Object.values(participants)?.forEach((data) => {
            list = {
                ...list,
                [data.user_id]: {
                    user_id: data.user_id,
                    is_bound: !checkedAll,
                }
            }
        })
        setParticipants(list)
        setCheckedAll(!checkedAll)
    }


    function checkParticipant(e, item) {
        let list = {
            ...participants,
            [item?.id || item?.user_id]: {
                user_id: item?.id || item?.user_id,
                is_bound: e?.target?.checked
            }
        }
        setParticipants(list)
        const partList = [...participantsList]
        // !participantsList.find(i => i.user_id === item.user_id) && (partList.push(item))
        // console.log(partList, 'part  List');
        setParticipantsList(partList)
    }

    function saveResult() {
        // let reqData = {name: channelInfo?.channelName}
        // // !channelInfo.type &&
        // (reqData.all_users_are_participants = true)

        if (createChannel) {
            props.AddChannel({name: channelInfo?.channelName})
                .then((res) => {
                    props.UpdateChannel(res?.data?.id, {
                        name: channelInfo?.channelName,
                        all_users_are_participants: channelInfo.type
                    })
                    props.UpdateChannelUsers(res?.data?.id, {participants: Object.values(participants)})
                })
        } else {
            props.UpdateChannelUsers(channel?.id, {participants: Object.values(participants)})
            props.UpdateChannel(channel?.id, {
                name: channelInfo?.channelName,
                all_users_are_participants: channelInfo.type
            })
        }
        onClose()
    }

    function onClose() {
        setChannelInfo(initialState)
        setParticipants(null)
        setCheckedAll(true)
        setParticipantsList([])
        onHide()
        props.GetChannels()
        props.ClearUsersSearch()
    }

    let searchedParticipants = []
    searchedUsers?.users?.length && searchedUsers?.users?.forEach(user => {
        if (!searchedParticipants.find(i => i?.user_id === user?.id)) {
            const data = {...user, user_id: user?.id}
            searchedParticipants.push(data)
        }
    })

    return <Modal
        show={true}
        onHide={onClose}
        onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
        }}
        size="lg"
        className={'channels-modal'}
        centered
    >
        <div className="channels-modal-header">
            <span>{createChannel ? 'Создание канала' : 'Редактирование канала'}</span>
            <CloseIcon onClick={onClose}/>
        </div>
        <div className="channels-modal-content">
            <div className={`form ${createChannel ? 'create' : ''}`}>
                <input type="text"
                       value={channelInfo.channelName}
                       autoComplete={'off'}
                       name={'channelName'}
                       onChange={getInputValue}
                       placeholder={'Name'}
                />
                {!createChannel && <span className="count">{channelById?.participants?.length} участников</span>}
            </div>

            <div className="title">Менеджеры канала</div>

            <div className="channel-managers">
                {
                    !showMangers ? <button className="channel-managers-btn" onClick={() => setShowManagers(true)}>
                            <PlusGreenIcon/>
                            Добавить менеджера
                        </button>
                        : <div className="search-managers">
                            <input type="text"
                                   value={channelInfo.managerName}
                                   autoComplete={'off'}
                                   name={'managerName'}
                                   onChange={getInputValue}
                                   placeholder={'Name'}
                            />

                            <div className="managers-buttons">
                                <button className={`close-btn`}
                                        onClick={() => {
                                            setShowManagers(false)
                                        }}>
                                    {t('button_cancel')}
                                </button>

                                <button className={`save-btn ${!channelInfo?.managerName ? 'disabled' : ''}`}
                                        disabled={!channelInfo?.managerName}
                                    // onClick={saveChanges}
                                >
                                    {t('button_save')}
                                </button>

                            </div>
                        </div>
                }
            </div>

            {channelById?.managers && <Form className={`managers-list`}>
                {
                    channelById?.managers?.map((item, index) => {
                        return <div className={`manager-item disabled`}
                                    key={index}>
                            <Form.Check
                                type={'checkbox'}
                                checked={true}
                            />
                            <UserInfoItem data={item}/>
                        </div>
                    })
                }
            </Form>}

            <div className="title">Участники канала</div>

            <div className={`managers-list all`}>
                {
                    participantsList?.map((item, index) => {
                        return <div className={`manager-item`} key={index}>
                            <UserInfoItem data={item}/>
                        </div>
                    })
                }
            </div>

            {channelInfo && <Form className="check-types">
                {/*<Form.Check*/}
                {/*    inline*/}
                {/*    name="type"*/}
                {/*    label={'Выбрать пользователей вручную'}*/}
                {/*    type={'radio'}*/}
                {/*    checked={!channelInfo.type}*/}
                {/*    onChange={(e) => toggleCheck({*/}
                {/*        name: 'participant',*/}
                {/*        value: e?.target?.checked,*/}
                {/*    })}*/}
                {/*/>*/}

                <Radio label={'Выбрать пользователей вручную'}
                       checked={!channelInfo.type}
                       onChange={(e) => toggleCheck({
                           name: 'participant',
                           value: e?.target?.checked,
                       })}/>
                <Radio
                    label={'Все пользователи компании'}
                    checked={channelInfo.type}
                    onChange={(e) => toggleCheck({
                        name: 'all',
                        value: e?.target?.checked,
                    })}
                />
            </Form>}

            {!channelInfo?.type ? <>
                    <div className="channel-participants">
                        <SearchIcon/>
                        <input type="text"
                               value={channelInfo.participantsName}
                               autoComplete={'off'}
                               name={'participantsName'}
                               onChange={getSearchValue}
                               placeholder={t('input_search')}
                        />
                    </div>

                    <Form className="check-icon">
                        <Form.Check
                            checked={checkedAll}
                            className={`${participants && Object.values(participants)?.every(element => element?.is_bound)
                            && checkedAll ? '' : 'check_part'}`}
                            type={'checkbox'}
                            onChange={checkAll}
                        />
                        Все пользователи из списка
                        <div className="count">{participants && Object.values(participants)?.length} {t('speakers')}</div>
                    </Form>

                    {/* {channelInfo.participantsName?.length > 1 &&
                        !!searchedUsers?.users?.length &&
                        <Form className={`managers-list participants`}>
                            {
                                searchedUsers?.users?.map((item) => {
                                    const data = {...item, user_id: item?.id}
                                    return <div className={`manager-item`}
                                                key={item?.id}>
                                        <Form.Check
                                            type={'checkbox'}
                                            checked={!!participants?.[item?.id]?.is_bound}
                                            onChange={(e) => checkParticipant(e, data)}
                                        />
                                        <UserInfoItem data={item}/>
                                    </div>
                                })
                            }
                        </Form>}*/}

                    {!!searchedParticipants?.length && <Form className={`managers-list`}>
                        {
                            searchedParticipants?.map((item, index) => {
                                return <div className={`manager-item`} key={index}>
                                    <CheckBox
                                        checked={participants?.[item?.user_id]?.is_bound}
                                        onChange={(e) => checkParticipant(e, item)}
                                    />
                                    <UserInfoItem data={item}/>
                                </div>
                            })
                        }
                    </Form>}
                </>
                : !!participantsList?.length && <div className={`managers-list all`}>
                {/*{*/}
                {/*    participantsList?.map((item, index) => {*/}
                {/*        return <div className={`manager-item`} key={index}>*/}
                {/*            <UserInfoItem data={item}/>*/}
                {/*        </div>*/}
                {/*    })*/}
                {/*}*/}
            </div>
            }
        </div>
        <div className="channels-modal-buttons">
            <button className={`close_btn`}
                    onClick={onClose}
            >
                {t('button_cancel')}
            </button>

            <button className={`save_btn `}
                // ${!isDisabled ? 'disabled' : ''}
                // disabled={!isDisabled}
                    onClick={saveResult}
            >
                {t('button_save')}
            </button>
        </div>
    </Modal>
}

function Radio({label, checked, onChange}) {
    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
        setIsChecked(checked)
    }, [checked])

    return <Form.Check
        inline
        name="type"
        label={label}
        type={'radio'}
        checked={isChecked}
        onChange={onChange}
    />
}

function CheckBox({checked, onChange}) {
    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
        setIsChecked(checked)
    }, [checked])

    return <Form.Check
        type={'checkbox'}
        checked={isChecked}
        onChange={onChange}
    />
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, ['channelById', 'searchedUsers'])
};

const mapDispatchToProps = {
    GetChannelById,
    SearchUsers,
    UpdateChannelUsers,
    UpdateChannel,
    GetChannels,
    ClearUsersSearch,
    AddChannel
};

export default connect(mapStateToProps, mapDispatchToProps)(ChannelsModal);
