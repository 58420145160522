// Import packages
import React, {useRef, useState} from 'react';

// Import assets
import './profile-media.scss';

// Import Components

// Import Utils
import {generateMediaUrl} from "../../../../../utils/generateMediaUrl";

function ProfileMedia(props) {
    const {personById} = props;
    const [file, setFile] = useState(null)

    const uploadImg = useRef();

    function readFile(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            // Read the image via FileReader API and save image result in state.
            reader.onload = function (e) {
                let dataURL = e.target.result;
                resolve(dataURL);
            };
            reader.onerror = function (e) {
                reject(e);
            };
            reader.readAsDataURL(file);
        });
    }

    function handleImageChange(e) {
        let file = e?.target?.files[0];

        // setDataFile(file)
        readFile(file)
            .then(dataURL => {
                setFile(dataURL)
            });
    }

    return  <div className="profile-media">
        <img
            src={file ? file : generateMediaUrl(personById?.avatar)}
            alt=""
        />
        <input type="file" accept="image/*" ref={uploadImg} style={{
            display: 'none'
        }}
               onChange={(e) => handleImageChange(e)}/>
    </div>
}

export default ProfileMedia;
