import {_urlQualityControls, _urlUserRetellings, _urlUserSummaries, request} from "../api";

export const SetQualityControls = (data) => {
    let requestData = {
        url: `${_urlQualityControls}`,
        method: "POST",
        data
    };

    return () => {
        return request(requestData)
            .then(async () => {
                return data
            })
    };
};

export const ChangeUserSummaries = (data) => {
    let requestData = {
        url: `${_urlUserSummaries}`,
        method: "POST",
        data
    };

    return () => {
        return request(requestData)
            .then(async () => {
                return data
            })
    };
};
export const ChangeUserRetellings = (data) => {
    let requestData = {
        url: `${_urlUserRetellings}`,
        method: "POST",
        data
    };

    return () => {
        return request(requestData)
            .then(async () => {
                return data
            })
    };
};



