// Import packages
import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

// Import assets
import './section.scss';
import {DeletingIcon, EditIcon, EllipsisIcon, FileIcon, VideoListIcon, VideoTabs} from "../../../../../image";

// Import Components
import VideoCard from "../card/VideoCard";
import DropdownMenu from "../../../../../shared/features/dropdownMenu";
import ChannelsModal from "../../../../../shared/modals/channelModal/ChannelsModal";
import UserInfoItem from "../../../../../shared/features/userInfoItem";

// Import Utils
import {getPropsFromState} from "../../../../../redux/mapStateToProps";
import {GetVideos, GetVideosSearch, DeleteChannel, GetChannelById, GetVideoList} from "../../../../../redux/actions";
import {hasPermission} from "../../../../../utils/helperFunctions";
import {generateMediaUrl, generateVideoUrl} from "../../../../../utils/generateMediaUrl";

function Section(props) {
    const {channelsList, channel} = props;
    const {t} = useTranslation();

    const [showModal, setShowModal] = useState(false);
    const [activeChannel, setActiveChannel] = useState(undefined);
    const [activeChannelId, setActiveChannelId] = useState(undefined);
    const [showChannelCount, setShowChannelCount] = useState(false);
    const [videos, setVideos] = useState(false);

    useEffect(() => {
        props.GetVideoList({
            limit: 5,
            channel_id: channel?.id,
            returnList: true
        }).then((res)=>{
            setVideos(res)
        })
    }, [channel])

    useEffect(() => {
        // console.log('activeChannelId', activeChannelId);
        if (activeChannelId) {
            if (!showChannelCount) {
                props.GetChannelById(activeChannelId)
                    .then((res) => {
                        setActiveChannel(res)
                    })
            }
        } else {
            setActiveChannel(undefined)
            setActiveChannelId(undefined)
            setShowChannelCount(false)
        }
    }, [activeChannelId])

    function closeModal() {
        setShowModal(false)
    }

    function setChannelId(id) {
        setActiveChannelId(id)
    }

    function toggleChannelCount(isClosed) {
        setShowChannelCount(isClosed)
        isClosed && setActiveChannelId(undefined)
    }

    return (
        <>
            <div className={`channels__section ${!channel?.is_private ? '' : 'private'}`}>
                <div className="channels__section-header">
                    <p className="channels__section-header__name">
                        <span className="name">{channel?.name}</span>
                        {!!channel?.users_count && !channel?.is_private &&
                            <DropdownMenu button={
                                <span className="count"
                                      onClick={() => setChannelId(channel?.id)}>{channel?.users_count} участников</span>}
                                          closeAfterToggle={true}
                                          toggle={toggleChannelCount}
                                          className={`count-menu ${activeChannel?.managers?.length + activeChannel?.participants?.length
                                          < 7 ? '' : 'large'}`}
                                          contentWidth={
                                              activeChannel?.managers?.length + activeChannel?.participants?.length
                                              < 7 ? '317px' : '580px'
                                          }
                            >
                                {!!activeChannel?.managers?.length && <div className="content-item">
                                    <div className="title">Менеджеры канала</div>
                                    <div className="list">
                                        {
                                            activeChannel?.managers?.map(item => {
                                                return <UserInfoItem data={item}/>
                                            })
                                        }
                                    </div>
                                </div>}
                                {!!activeChannel?.participants?.length && <div className="content-item">
                                    <div className="title">Участники канала</div>
                                    {
                                        !activeChannel?.all_users_are_participants ? <div className="list">
                                                {
                                                    activeChannel?.participants?.map(item => {
                                                        return <UserInfoItem data={item}/>
                                                    })
                                                }
                                            </div>
                                            : <div className="text">Все пользователи системы</div>
                                    }
                                </div>}
                            </DropdownMenu>}

                        {!channel?.is_private && (hasPermission('channels_update') || hasPermission('channels_delete'))
                            && <DropdownMenu button={
                                <EllipsisIcon className={'icon-menu'}/>}
                                             closeAfterToggle={true}
                                             className="actions-menu"
                                             contentWidth={'176px'}
                            >
                                {hasPermission('channels_update') && <div className={'menu-item'} onClick={(e) => {
                                    e.stopPropagation()
                                    // toggleUpdate()
                                    setShowModal(true)
                                }}>
                                    <EditIcon className={''}/> {t('button_edit')}
                                </div>}
                                {hasPermission('channels_delete') &&
                                    <div className={'menu-item delete'} onClick={() => props.DeleteChannel(channel?.id)}>
                                        <DeletingIcon className={''}/> {t('button_delete')}
                                    </div>}
                            </DropdownMenu>}
                    </p>
                </div>
                <div
                    className={`channels__section-videos`}
                >
                    {!!videos?.results?.length ? <>
                            {
                                videos?.results?.slice(0, 5)?.map((item) => {
                                    return <VideoCard key={item?.id}
                                                      channel={channel}
                                                      channelsList={channelsList}
                                                      routs={[
                                                          {
                                                              path: '/dashboard/videos/review',
                                                              name: t('menu_item_video')
                                                          },
                                                          {
                                                              path: '/dashboard/videos/review',
                                                              name: t('menu_item_review')
                                                          },
                                                          {
                                                              path: `/dashboard/videos/channels/${channel.id}`,
                                                              name: channel?.name
                                                          }
                                                      ]}
                                                      video={item}/>
                                })
                            }
                            {videos?.count > 5 && <Link to={`channels/${channel.id}`} className="more_videos">
                                <FileIcon/>

                                <div className="more_videos__bottom">
                                    <div className="more_videos__bottom__count">
                                        {videos?.count}
                                        <span className="text">{t('video')}</span>
                                    </div>
                                </div>

                            </Link>}

                        </>
                        : <div className={`channels__section-videos-empty`}>
                            {t('empty_list')}
                        </div>}
                </div>
                {!channel?.is_private && showModal && <ChannelsModal showModal={showModal}
                                                                   channel={channel}
                                                                   onHide={closeModal}/>}
            </div>
            <div className={`mobile-section ${!channel?.is_private ? '' : 'private'}`}>
                <Link to={`channels/${channel?.id}`} className={'channels_cover'}>
                    <VideoTabs className={'channels_tab'}/>
                    {
                        !!videos?.results?.length && <>
                            {
                                videos?.results?.[0]?.thumbnail_url ? <img src={generateMediaUrl(videos?.results?.[0]?.thumbnail_url)} alt=""/>
                                    : <video width="279" height="167">
                                        <source src={videos?.results?.[0]?.url_s3 ? videos?.results?.[0]?.url_s3 : generateVideoUrl(videos?.results[0]?.url)} type="video/mp4"/>
                                    </video>
                            }
                        </>
                    }
                    <div className="count">
                        <VideoListIcon/>
                        {videos?.count}
                    </div>
                </Link>
                <div className="info">
                    <div className="title">{channel?.name}</div>
                    <div className="counts">
                        <span>{channel?.users_count} участников</span>
                        <span>{videos?.count} {t('video')}</span>
                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'videosList',
        'videos',
        'isMobile',
    ])
};

const mapDispatchToProps = {
    GetVideos,
    GetVideosSearch,
    DeleteChannel,
    GetChannelById,
    GetVideoList
};

export default connect(mapStateToProps, mapDispatchToProps)(Section);
