// Import packages
import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

// Import assets
import './persons.scss';

//Import components
import ListItem from "./listItem/ListItem";
import UserInfoModal from "../modals/userInfoModal/UserInfoModal";
import AddUserModal from "../modals/addUserModal/AddUserModal";
import CustomPagination from "../../../../../shared/features/Pagination";

//Import utils
import {getPropsFromState} from "../../../../../redux/mapStateToProps";
import {
    ClearPersons,
    GetPersons,
    ChangeHeaderTitle,
    GetUsersPersons,
    GetUsers,
    ClearUsers, DeleteUser, GetUserPositions
} from "../../../../../redux/actions";
import {useLocation, useParams} from "react-router-dom";
import {ArrowDownIcon, FilterCenterIcon, PersonIcon, PlusIcon} from "../../../../../image";
import {useTranslation} from "react-i18next";
import {getAuthorName, hasPermission} from "../../../../../utils/helperFunctions";
import DeleteModal from "../../../../../shared/modals/deleteModal/DeleteModal";
import DropdownMenu from "../../../../../shared/features/dropdownMenu/DropdownMenu";
import Filters from "../../../../../shared/features/Filters/Filters";
import {personsFilterTypes, sortPersonsConsts} from "../../../../../utils/constants";
import {Form} from "react-bootstrap";

const listLength = 10

const initFilters = {
    name_like: '',
    role_ids: '',
    company_ids: '',
}

function Persons(props) {
    const [showPersonsModal, setShowPersonsModal] = useState(false)
    const [showAddUserModal, setShowAddUserModal] = useState(false)
    const [currentUser, setCurrentUser] = useState(null)
    const [activePage, setActivePage] = useState(1);
    const [selectedFilter, setSelectedFilter] = useState('');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [filters, setFilters] = useState(initFilters);

    const params = useParams()
    const location = useLocation()
    const {t} = useTranslation();

    const filterList = [
        {name: t('users_title'), id: 'users', key: 'GetUsers'},
        {name: t('participant_title'), id: 'persons', key: 'GetPersons'},
        {name: t('all_users_title'), id: 'all', key: 'GetUsersPersons'},
    ]

    useEffect(() => {
        props.ChangeHeaderTitle(t('user_page_title'))
        // props.GetUserPositions()

        setSelectedFilter('users')

        !hasPermission('companies_read') &&  (delete personsFilterTypes.company)
        !hasPermission('roles_read') &&  (delete personsFilterTypes.role)

        if (params?.personId || params?.userId) {
            let user = {}
            params?.personId && (user.person_id = params?.personId)
            params?.userId && (user.user_id = params?.userId)

            setCurrentUser(user)
            setShowPersonsModal(true)
        }

        return () => {
            props.ClearPersons()
            props.ClearUsers()
        }
    }, [])

    useEffect(() => {
        props.GetUsersPersons({
            limit: 20,
            offset: (activePage - 1) * 20,
            role_id: location?.state?.role_id,
            ...filters
        })
    }, [filters]);

    function openModal() {
        setShowPersonsModal(true)
    }

    function openDeleteModal() {
        setShowDeleteModal(true)
    }

    function closeModal() {
        setShowPersonsModal(false)
        setCurrentUser(null)
        props.GetUsersPersons({
            filter: selectedFilter,
            person_status: selectedFilter === 'persons' ? 'known' : undefined,
            limit: listLength,
            offset: listLength * (activePage - 1),
            role_id: location?.state?.role_id
        })
    }

    function openAddUserModal() {
        setShowAddUserModal(true)
    }

    function closeAddUserModal() {
        setShowAddUserModal(false)
    }

    const changePage = (number) => {
        setActivePage(number)
        props.GetUsersPersons({
            filter: selectedFilter,
            person_status: selectedFilter === 'persons' ? 'known' : undefined,
            limit: listLength,
            offset: listLength * (number - 1),
            role_id: location?.state?.role_id,
            ...filters
        })
    };

    function closeDeleteModal() {
        setShowDeleteModal(false)
    }

    function toggleFilter(item) {
        setSelectedFilters(selectedFilters.includes(item.key)
            ? selectedFilters.filter(i => i !== item?.key)
            : [...selectedFilters, item.key])
    }

    return (
        <div className="persons">
            <div className="page-title" style={{padding: '20px 0 0'}}>
                {t('user_page_title')}
                <div className="buttons">
                    <DropdownMenu button={
                        <button className="filter-btn">
                            <FilterCenterIcon/>
                            {t('filter')}
                        </button>
                    }
                                  closeAfterToggle={false}
                                  className="filter-menu"
                                  contentClassName="filter"
                                  contentWidth={'176px'}
                    >
                        {
                            Object.keys(personsFilterTypes)?.map(item => {
                                return <MyCheckbox checked={selectedFilters.includes(item)}
                                                   label={personsFilterTypes[item]?.name}
                                                   key={item}
                                                   className={`filters-item`}
                                                   handleCheckboxChange={() => toggleFilter(personsFilterTypes[item])}/>
                            })
                        }
                    </DropdownMenu>
                {hasPermission('users_create') &&
                    <button className="persons__header__add-user" onClick={openAddUserModal}>
                        <PlusIcon className="plus-icon"/>
                        {t('add_person')}
                        <PersonIcon className="person-icon"/>
                    </button>}
                </div>
            </div>
            {/*<div className="persons__header">
                <div className="persons__header__right">
                    <div className="persons__title">{t('persons_title')}</div>

                    <div className="persons__filters">
                        {
                            filterList?.length && filterList?.map(item => {
                                return <div key={item.id}
                                            onClick={() => toggleFilter(item.id)}
                                            className={`filter_item ${selectedFilter === item.id ? 'active' : ''}`}>
                                    {item?.name}</div>
                            })
                        }
                    </div>
                </div>
                {hasPermission('users_create') &&
                    <button className="persons__header__add-user" onClick={openAddUserModal}>
                        <PlusIcon className="plus-icon"/>
                        {t('add_person')}
                        <PersonIcon className="person-icon"/>
                    </button>}
            </div>*/}

            <Filters selectedFilters={selectedFilters}
                     filters={filters}
                     setFilters={setFilters}
                     sortList={sortPersonsConsts}/>

            <div className="persons__list-header">
                <div className="persons__list-header__content">
                    <div className="user_info item_col">
                        <div className="user_info_title">{t('table_photo')}<ArrowDownIcon/></div>
                        <div className="user_info_title">{t('table_name')}<ArrowDownIcon/></div>
                    </div>
                    {/*<div className="item_col">*/}
                        {/*<div className="user_info_title">{t('table_tags')}<ArrowDownIcon/></div>*/}
                    {/*</div>*/}
                    <div className="item_col">
                        <div className="user_info_title">{t('table_role')}<ArrowDownIcon/></div>
                    </div>
                    <div className="item_col">
                        <div className="user_info_title">{t('table_company')}<ArrowDownIcon/></div>
                    </div>
                    <div className="item_col">
                        <div className="user_info_title">{t('table_position')}<ArrowDownIcon/></div>
                    </div>
                </div>
                <div className="persons__list-header__actions">
                    {/*<div className="video_count item_col">*/}
                    {/*    <div className="user_info_title">{t('table_video')}<ArrowDownIcon/></div>*/}
                    {/*</div>*/}
                </div>
            </div>

            {
                props.usersPersonsList?.results?.map((item, index) => {
                    return <ListItem key={`${item.person_id}_${item.user_id}`}
                                     data={item}
                                     index={index}
                                     openModal={openModal}
                                     openDeleteModal={openDeleteModal}
                                     DeleteUser={props.DeleteUser}
                                     rolesList={props.rolesList}
                                     badge={filterList?.find(i => i.id === selectedFilter)?.name}
                                     setCurrentUser={setCurrentUser}/>
                })
            }
            {props.usersPersonsList?.count > 10 && <CustomPagination count={props.usersPersonsList?.count}
                                                                     activePage={activePage}
                                                                     listLength={listLength}
                                                                     changePage={changePage}/>}
            {showPersonsModal && <UserInfoModal show={showPersonsModal} closeModal={closeModal} user={currentUser}
                                                videoPersonsList={props?.usersPersonsList?.results}/>}
            <AddUserModal show={showAddUserModal}
                          closeModal={closeAddUserModal}/>
            {showDeleteModal && <DeleteModal showModal={showDeleteModal}
                                             onHide={closeDeleteModal}
                                             title={'пользователя'}
                                             description={getAuthorName(currentUser)}
                                             handleDelete={()=>props.DeleteUser(currentUser?.user_id)}
            />}
        </div>
    );
}

function MyCheckbox({checked, handleCheckboxChange, disabled, label, className}) {
    const [isChecked, setIsChecked] = useState(false);
    useEffect(() => {
        setIsChecked(checked)
    }, [checked])

    return (
        <Form>
            <Form.Check
                type="checkbox"
                checked={isChecked}
                className={className}
                disabled={disabled}
                label={label}
                onChange={handleCheckboxChange}
            />
        </Form>
    );
}



const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'personsList',
        'usersList',
        'usersPersonsList',
        'rolesList',
        'userRoles',
    ])
};

const mapDispatchToProps = {
    GetPersons, ClearUsers, GetUsers, GetUsersPersons, ClearPersons, ChangeHeaderTitle, DeleteUser, GetUserPositions
};

export default connect(mapStateToProps, mapDispatchToProps)(Persons);
