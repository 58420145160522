// Import packages
import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {Form, Modal} from "react-bootstrap";

// Import assets
import './add-prompt.scss';
import {CloseIcon, DeletingIcon} from "../../../image";

// Import Utils
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {ClearPromptById, AddPrompt, UpdatePrompt, GetPromptById} from "../../../redux/actions";
import {useTranslation} from "react-i18next";

const initialState = {
    name: undefined,
    body: undefined,
}
const initialErrors = {
    name: false,
    body: false,
}

function AddPromptModal(props) {
    const {show, setSelectedPrompt, selectedPrompt, promptById, openDelete, className} = props;

    const {t} = useTranslation();

    const [promptInfo, setPromptInfo] = useState(initialState);
    const [errors, setErrors] = useState(initialErrors);
    const [haveChanges, setHaveChanges] = useState(new Map());
    const [type, setType] = useState(false);

    let disabledBtn = !!Object.values(promptInfo).find(item => !!item)?.length

    useEffect(() => {
        if (selectedPrompt?.id) {
            props.GetPromptById(selectedPrompt?.id)
        }
    }, [selectedPrompt])

    useEffect(() => {
        if (promptById?.id) {
            setPromptInfo({
                ...promptInfo,
                name: promptById?.name,
                body: promptById?.body,
            })
        }
    }, [promptById])

    function clearHaveChanges() {
        const changes = new Map(haveChanges);
        changes.clear()
        setHaveChanges(changes)
    }

    function getInputValue(e) {
        const {name, value} = e.target
        setPromptInfo({
            ...promptInfo,
            [name]: value
        })

        const changes = new Map(haveChanges);
        if (promptById?.[name] !== value) {
            changes.set(name, value)
        } else {
            changes.delete(name)
        }
        setHaveChanges(changes)
    }

    function validateData(data) {
        const {name, body} = data
        setErrors(initialErrors)
        let isValid = true;
        let errorList = {...initialErrors}

        if (!name?.length) {
            isValid = false
            errorList = {
                ...errorList,
                name: "Заполните поле"
            }
        }
        if (!body?.length) {
            console.log(body, 'body');
            isValid = false
            errorList = {
                ...errorList,
                body: "Заполните поле"
            }
        }

        setErrors(errorList)

        return isValid
    }

    function saveChanges() {
        const recData = {
            name: promptInfo.name,
            type: !type ? 'summary' : 'retelling',
            body: promptInfo?.body
        };

        // promptInfo?.body && (recData.body = promptInfo.body)

        if (validateData(recData)) {
            promptById?.id
                ? props.UpdatePrompt(promptById?.id, recData)
                : props.AddPrompt(recData)
            onClose()
        }
    }

    function onClose() {
        setPromptInfo(initialState)
        setErrors(initialErrors)
        setSelectedPrompt(undefined)
        props.ClearPromptById()
        clearHaveChanges()
        props.closeModal()
    }

    function handleChangeType(checked) {
        setType(!checked)
        const changes = new Map(haveChanges);
        const initType = promptById.type === 'retelling'
        if (initType === checked) {
            changes.set('type', promptById?.type)
        } else {
            changes.delete('type')
        }
        setHaveChanges(changes)
    }

    console.log(!disabledBtn, 'promptById?.undeletable', promptById?.undeletable);
    return <Modal
        show={show}
        onHide={onClose}
        centered
        size="lg"
        className={`prompt_modal_wrapper ${className}`}

    >
        <div className="prompt_modal">
            <div className="prompt_modal__title">
                Создать промт
                <CloseIcon onClick={onClose}/>
            </div>

            <div className="type-title">Вид промпта</div>

            <Form className="types">
                <Form.Check
                    label={'Итоги встречи'}
                    className="sort-item__check"
                    type={'radio'}
                    checked={!type}
                    onChange={(e) => {
                        handleChangeType(e.target.checked)
                    }}
                />
                <Form.Check
                    label={'Пересказ'}
                    className="sort-item__check"
                    type={'radio'}
                    checked={type}
                    onChange={(e) => {
                        handleChangeType(!e.target.checked)
                    }}
                />
            </Form>

            <div className="input_group">
                <label htmlFor="">Название промпта</label>
                <input type="text"
                       value={promptInfo?.name}
                       autoComplete={'off'}
                       name={'name'}
                       onChange={getInputValue}
                       placeholder={'Название промпта'}
                />
                {!!errors.name && (
                    <div className="input_group__error">{errors.name}</div>
                )}
            </div>

            <div className="input_group full">
                <label htmlFor="">Текст промпта</label>
                <textarea value={promptInfo?.body}
                          name={'body'}
                          onChange={getInputValue}
                          placeholder={'Текст промпта'}
                />
                {!!errors.body && (
                    <div className="input_group__error">{errors.body}</div>
                )}
            </div>

            {selectedPrompt?.id && <div className="prompt_modal__delete">
                <div className="title-delete">Удаление промпта</div>
                <div className="delete-content">
                    <span>После удаления промпта все данные будут безвозвратно потеряны и их невозможно будет восстановить</span>
                    <button onClick={() => {
                        openDelete(selectedPrompt)
                    }}>
                        <DeletingIcon/>
                        {t('button_delete')}
                    </button>
                </div>
            </div>}

            <div className="prompt_modal__buttons">
                <button className={`close_btn`}
                        onClick={onClose}
                >
                    {t('button_cancel')}
                </button>

                {
                    !!promptById?.id ? <button className={`save_btn ${!(haveChanges.size && !promptById?.undeletable) ? 'disabled' : ''}`}
                                               disabled={!(haveChanges.size && !promptById?.undeletable)}
                                               onClick={saveChanges}
                        >
                            {t('button_save')}
                        </button>
                        : <button className={`save_btn ${!disabledBtn ? 'disabled' : ''}`}
                                  disabled={!disabledBtn}
                                  onClick={saveChanges}
                        >
                            {t('button_save')}
                        </button>
                }

            </div>
        </div>
    </Modal>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, ['promptById'])
};

const mapDispatchToProps = {
    AddPrompt,
    ClearPromptById,
    UpdatePrompt,
    GetPromptById
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPromptModal);
