import {PROMPT_CONSTS} from "../constants";
import {_urlPrompts, request} from "../api";

export const GetPrompts = () => {
    let requestData = {
        url: `${_urlPrompts}`,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;

                await dispatch({
                    type: PROMPT_CONSTS.GET_PROMPT,
                    payload: data
                })
            })
    };
};

export const GetPromptById = (id) => {
    let requestData = {
        url: `${_urlPrompts}/${id}`,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;

                await dispatch({
                    type: PROMPT_CONSTS.GET_PROMPT_BY_ID,
                    payload: data
                })
                return data
            })
    };
};

export const AddPrompt = (data, protocolIndex) => {
    let requestData = {
        url: `${_urlPrompts}`,
        method: "POST",
        data,
    };

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                await dispatch({
                    type: PROMPT_CONSTS.ADD_PROMPT,
                    payload: {
                        data:  {
                            ...data,
                            id: res?.data?.protocol_text_id,
                            marked: true
                        },
                        protocolIndex
                    }
                })
            })
    };
};

export const UpdatePrompt = (id, data) => {
    const requestData = {
        url: `${_urlPrompts}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };

    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: PROMPT_CONSTS.UPDATE_PROMPT,
                    payload: {
                        id, data
                    }
                });
            })
            .catch((err) => {
                console.log(err, 'ERROR');
            })

    }
};

export const DeletePrompt = data => {
    const requestData = {
        url: `${_urlPrompts}/${data?.id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: PROMPT_CONSTS.DELETE_PROMPT,
                    payload: data
                });
            })
            .catch(() => {
            })
    }
};

export const ClearPromptById = () => {
    return async dispatch => {
        await dispatch({
            type: PROMPT_CONSTS.CLEAR_PROMPT_BY_ID
        })
    };
};
