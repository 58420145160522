// Import packages
import React, {useEffect} from 'react';
import {connect} from "react-redux";

// Import assets
import './delete-modal.scss';

//Import components
import {Modal} from "react-bootstrap";

//Import utils
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {useTranslation} from "react-i18next";
import {CloseIcon, DeletingIcon} from "../../../image";


function DeleteModal(props) {
    const {showModal, onHide, description, handleDelete, title, text, closeWrapper} = props;
    const {t} = useTranslation();

    useEffect(() => {

    }, [showModal]);

    function onDelete() {
        handleDelete()
        onClose()
    }

    function onClose() {
        onHide()
        closeWrapper && closeWrapper()
    }

    return <Modal
        show={true}
        onHide={onClose}
        onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
        }}
        size="lg"
        className={'delete-modal'}
        centered
    >
        <div className="delete-modal-header">
            <span>Удалить {title}</span>
            <CloseIcon onClick={onClose}/>
        </div>

        {description && <div className="delete-modal-description">
            Вы точно хотите удалить {title}&nbsp;
            {description}?
            После удаления {/*{title}*/} все данные будут безвозвратно потеряны и их невозможно будет восстановить.
        </div>}

        {text && <div className="delete-modal-description">{text}</div>}

        <div className="delete-modal-buttons">
            <button className={`close_btn`}
                    onClick={onClose}
            >
                {t('button_cancel')}
            </button>

            <button className={`delete_btn `}
                    onClick={onDelete}
            >
                <DeletingIcon/> {t('button_delete')}
            </button>
        </div>
    </Modal>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [])
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteModal);
