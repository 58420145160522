// Import packages
import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

// Import assets
import './new.scss';

// Import Utils
import {getPropsFromState} from "../../../../../redux/mapStateToProps";
import {GetNewPersons, ClearUsers, ChangeHeaderTitle, ClearPersons} from "../../../../../redux/actions";

// Import Components
import ListItem from "./listItem/ListItem";
import CustomPagination from "../../../../../shared/features/Pagination";
import UserInfoModal from "../modals/userInfoModal/UserInfoModal";
import {useTranslation} from "react-i18next";

const listLength = 10

function New(props) {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false)
    const [currentUser, setCurrentUser] = useState(null)
    const [activePage, setActivePage] = useState(1);

    useEffect(() => {
        props.ChangeHeaderTitle(t('user_page_title'))

        return () => {
            props.ClearPersons()
        }
    }, [])

    useEffect(() => {
        !showModal && props.GetNewPersons({
            status: 'new'
        })
    }, [showModal])

    function openModal() {
        setShowModal(true)
    }

    function closeModal() {
        setShowModal(false)
    }

    const changePage = (number) => {
        setActivePage(number)
        props.GetNewPersons({
            status: 'new',
            limit: listLength,
            offset: listLength * (number - 1)
        })
    };

    return (
        <div className="members">
            <div className="page-title" style={{padding: '20px 0 0'}}>{t('user_page_title')}</div>
            <div className="members__title">{t("members_title")}</div>

            <div className="members__list-header">
                <div className="members__list-header__content">
                    <div className="item_col">{t('table_name')}</div>
                    <div className="item_col">{'ID'}</div>
                    <div className="item_col">{t('table_data')}</div>
                    <div className="video_count item_col">
                        {t('table_video')}
                    </div>
                </div>
                <div className="members__list-header__actions">
                    {/*<div className="video_count item_col">*/}
                    {/*    {t('table_video')}*/}
                    {/*</div>*/}
                </div>
            </div>

                {
                    props.newPersonsList?.results?.map(item => {
                        const data = {
                            ...item,
                            person_id: item.id
                        }
                        delete data.id
                        return <ListItem key={data?.person_id} data={data} openModal={openModal}
                                         setCurrentUser={setCurrentUser}/>
                    })
                }
            {props.newPersonsList?.count > 10 && <CustomPagination count={props.newPersonsList?.count}
                                                                   activePage={activePage}
                                                                   listLength={listLength}
                                                                   changePage={changePage}/>}
            {currentUser && showModal && <UserInfoModal show={showModal}
                                           closeModal={closeModal}
                                           videoPersonsList={props?.newPersonsList?.results}
                                           user={currentUser}/>}
        </div>
    );
}


const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'newPersonsList',
    ])
};

const mapDispatchToProps = {
    GetNewPersons, ClearUsers, ChangeHeaderTitle, ClearPersons
};

export default connect(mapStateToProps, mapDispatchToProps)(New);
