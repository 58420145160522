import {_urlNotification, request} from "../api";
import {NOTIFICATIONS_CONSTS} from "../constants";


export const GetNotifications = () => {
    let requestData = {
        url: `${_urlNotification}`,
        method: "GET",
    };
    return (dispatch) => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;
                await dispatch({
                    type: NOTIFICATIONS_CONSTS.GET_NOTIFICATION,
                    payload: data
                })
            })
    };
};
export const GetNotificationsCount = () => {
    let requestData = {
        url: `${_urlNotification}/new_count`,
        method: "GET",
    };
    return (dispatch) => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;
                await dispatch({
                    type: NOTIFICATIONS_CONSTS.GET_NOTIFICATION_COUNT,
                    payload: data
                })
            })
    };
};

export const UpdateNotification = (id, data) => {
    let requestData = {
        url: `${_urlNotification}/${id}`,
        method: "PATCH",
        data
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                // console.log('UpdateTranscription', res);
                dispatch({
                    type: NOTIFICATIONS_CONSTS.UPDATE_NOTIFICATION,
                    payload: {data, id}
                })
            })
    };
};

export const SeeAllNotification = (data) => {
    let requestData = {
        url: `${_urlNotification}/all`,
        method: "PATCH",
        data
    };
    return dispatch => {
                console.log('UpdateTranscription');
        return request(requestData)
            .then(() => {
                dispatch({
                    type: NOTIFICATIONS_CONSTS.SEE_ALL_NOTIFICATIONS,
                    payload: {data}
                })
            })
    };
};
