// Import packages
import React, {useEffect, useLayoutEffect, useState} from 'react';
import {connect} from "react-redux";

// Import assets
import './profile.scss';
import 'react-phone-number-input/style.css'
import {
    ArrowDownIcon,
    EyeIcon,
    InfoCheckIcon,
} from "../../../../image";

// Import Components
import {Accordion} from "react-bootstrap";
import ProfileMedia from "./media/ProfileMedia";
import DeletePerson from "../../../../shared/features/deletePerson";
import UserRoles from "../../../../shared/profile/UserRoles";
import InfoTooltip from "./tooltip";
import ProfileContacts from "./contacts";

// Import Utils
import {getPropsFromState} from "../../../../redux/mapStateToProps";
import {
    UpdatePersons,
    ChangeHeaderTitle,
    ClearProfile,
    UpdateUser,
    GetUserProfile,
    GetUserById,
    GetVideos,
    DeleteHighlights,
    GetHighlights,
    GetPersonById, GetUsersPersons, ClearPersonById, ClearVideoList
} from "../../../../redux/actions";
import {useTranslation} from "react-i18next";
import config from "../../../../utils/config"
import {validateData} from "./helpers/helpers";


const initialState = {
    first_name: undefined,
    last_name: undefined,
    company: undefined,
    position: undefined,
    login: undefined,
    password: undefined,
    note: undefined,
}
const initialContactState = {
    email: [''],
    phone_number: [''],
    linkedin: [''],
    facebook: [''],
}
const initialErrors = {
    login: '',
    password: '',
    first_name: '',
    last_name: '',
    phone_number: [],
    email: [],
    linkedin: [],
    facebook: [],
}

function Profile(props) {
    const {userProfile, userById, personById, rolesList} = props
    const [userInfo, setUserInfo] = useState(initialState)
    const [contactItems, setContactItems] = useState(initialContactState);
    const [errors, setErrors] = useState(initialErrors);
    const [haveChanges, setHaveChanges] = useState(new Map());
    // const [activeLanguage, setActiveLanguage] = useState('ru')
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [userRole, setUserRole] = useState(undefined);

    const {t, i18n} = useTranslation();

    useLayoutEffect(()=>{
        props.ClearProfile()
        props.ClearPersonById()
        props.ClearVideoList()
    },[])


    useEffect(() => {
        props.ChangeHeaderTitle(t('profile_title'))
        props.GetUsersPersons()
        props.GetUserProfile()
    }, []);

    useEffect(() => {
        userProfile?.id && props.GetUserById(userProfile?.id)
        let reqData = {user_id: userProfile?.id}
        userProfile?.attached_person?.id && (reqData.person_id = userProfile?.attached_person?.id)

        // props.GetHighlights(reqData)
        userProfile?.attached_person?.id && props.GetPersonById(userProfile?.attached_person?.id)
        userProfile?.id && props.GetVideos({
            limit: 1000,
            filters: [{
                firstColumnVal: 'and',
                secondColumnVal: 'staff',
                thirdColumnVal: 'present',
                fourthColumnVal: userProfile?.attached_person?.id ? userProfile?.attached_person?.id : userProfile?.id
            }]
        })
    }, [userProfile?.id]);

    useEffect(() => {
        setUserInfo({
            ...userInfo,
            first_name: userById?.first_name || userProfile?.attached_person?.first_name,
            last_name: userById?.last_name || userProfile?.attached_person?.last_name,
            company: userProfile?.company?.id ? userProfile?.company?.name : userProfile?.attached_person?.company,
            position: userProfile?.attached_person?.position,
            note: userProfile?.attached_person?.note,
            login: userProfile?.login ? userProfile?.login : undefined,
        })
        setContactItems({
            ...contactItems,
            email: personById?.email?.length ? personById?.email : [''],
            phone_number: personById?.phone_number?.length ? personById?.phone_number : [''],
            linkedin: personById?.linkedin?.length ? personById?.linkedin : [''],
            facebook: personById?.facebook?.length ? personById?.facebook : [''],
        })
        setUserRole(userById?.roles?.[0])
    }, [userById?.id, personById?.id]);

    useEffect(() => {
        changeLanguage('ru')
    }, [])

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
        // setActiveLanguage(language)
    }

    function getInputValue(e) {
        const {name, value} = e.target

        setUserInfo({
            ...userInfo,
            [name]: value
        })
        setErrors({
            ...errors,
            [name]: false
        })

        const changes = new Map(haveChanges);
        if ((personById?.[name] || userProfile?.[name] || '') !== value) {
            changes.set(name, value)
        } else {
            changes.delete(name)
        }
        setHaveChanges(changes)
    }

    function checkValidation(data) {
        setErrors(initialErrors)
        const result = validateData({
            data,
            initialErrors,
            t,
            id: personById?.associated_user?.id
        })
        setErrors(result.errors)
        return result.isValid
    }

    function clearHaveChanges() {
        const changes = new Map(haveChanges);
        changes.clear()
        setHaveChanges(changes)
    }

    function toggleRole(e, role) {
        e.stopPropagation()
        setUserRole(role)

        const changes = new Map(haveChanges);
        if (userById?.role?.[0].id !== role?.id) {
            changes.set('userRole', role?.id)
        } else {
            changes.delete('userRole')
        }
        setHaveChanges(changes);
    }

    function saveChanges() {
        let recData = {role_ids: [userRole?.id]}
        let recDataPerson = {}
        if (userProfile?.attached_person.id) {
            userInfo.first_name && (recDataPerson.first_name = userInfo.first_name)
            userInfo.last_name && (recDataPerson.last_name = userInfo.last_name)
            userInfo.position && (recDataPerson.position = userInfo.position)
            userInfo.note && (recDataPerson.note = userInfo.note)
            !userProfile?.company?.id && userInfo.company && (recDataPerson.organization = userInfo.company)

            Object.entries(contactItems).forEach(([key, value]) => {
                let item = value.filter(i => !!i)
                item.length && (recDataPerson[key] = item)
            })

            if (!personById?.associated_with_user) {
                userInfo?.login && (recDataPerson.user = {
                    ...recDataPerson?.user,
                    email: userInfo?.login
                })
                userInfo?.password && (recDataPerson.user = {
                    ...recDataPerson?.user,
                    password: userInfo?.password
                })
            }

            checkValidation(recDataPerson) && props.UpdatePersons(userProfile?.attached_person.id, recDataPerson, userInfo)
                .catch(() => {
                    // setErrors({
                    //     ...errors,
                    //     login: t('error_login')
                    // })
                })
            clearHaveChanges()
        } else {
            // userInfo?.first_name && (recData.first_name = userInfo.first_name)
            // userInfo?.last_name && (recData.last_name = userInfo.last_name)
            // if (checkValidation(recDataPerson)) {
            //     props.UpdateUser(userProfile?.id, recData)
            //     props.GetUserById(userProfile?.id)
            //     clearHaveChanges()
            // }
        }
        if (userInfo?.login || userInfo?.password) {
            userInfo?.first_name && (recData.first_name = userInfo.first_name)
            userInfo?.last_name && (recData.last_name = userInfo.last_name)
            userInfo?.login && (userInfo?.login !== userById?.login) && (recData.login = userInfo.login)
            userInfo?.password && (recData.password = userInfo.password)

            if (checkValidation(recData)) {
                props.UpdateUser(userProfile?.id, recData)
                props.GetUserById(userProfile?.id)
                clearHaveChanges()
            }
        }
    }

    // const languages = [{name: t('english'), key: 'en'}, {name: t('russian'), key: 'ru'}]

    return <div className={`main-container profile-page full`}>
        <div className="profile-page__form">
            <div className="profile-page-header">{t('profile_page_title')}</div>
            <div className="profile-page__form__wrapper">
                <div className="profile-page__form__media">
                    <ProfileMedia personById={personById}/>

                    {/*<TabsDropdown user={userProfile} tagColors={tagColors} personById={personById}/>*/}

                </div>
                <form autoComplete={'off'} className="profile-page__form__content">
                    <div className="row-item">
                        <div className="input_group">
                            <label htmlFor="">{t('input_name')}</label>
                            <input type="text"
                                   value={userInfo?.first_name}
                                   name={'first_name'}
                                   onChange={getInputValue}
                                   placeholder={t('input_name')}
                                   autoComplete={'off'}
                            />
                            {!!errors?.first_name && (
                                <div className="input_group__error">{errors?.first_name}</div>
                            )}
                        </div>
                        <div className="input_group">
                            <label htmlFor="">{t('input_surname')}</label>
                            <input type="text"
                                   value={userInfo?.last_name}
                                   name={'last_name'}
                                   onChange={getInputValue}
                                   placeholder={t('input_surname')}
                                   autoComplete={'off'}
                            />
                            {!!errors.last_name && (
                                <div className="input_group__error">{errors.last_name}</div>
                            )}
                        </div>
                    </div>
                    <div className="row-item">
                        <div className="input_group">
                            <label htmlFor="">{t('input_company')}</label>
                            <input type="text"
                                   value={userInfo?.company}
                                   name={'company'}
                                   disabled={userProfile?.company?.id || !userProfile?.attached_person?.id}
                                   onChange={getInputValue}
                                   placeholder={t('input_company')}
                            />
                        </div>
                        <div className="input_group">
                            <label htmlFor="">{t('input_position')}</label>
                            <input type="text"
                                   value={userInfo?.position}
                                   name={'position'}
                                   disabled={!userProfile?.attached_person?.id}
                                   onChange={getInputValue}
                                   placeholder={t('input_position')}
                            />
                        </div>
                    </div>

                    <div className="input_group full">
                        <label htmlFor="">{t('input_notes')}</label>
                        <textarea value={userInfo?.note}
                                  name={'note'}
                                  disabled={!userProfile?.attached_person?.id}
                                  onChange={getInputValue}
                                  placeholder={t(config.productName)}
                        />
                    </div>
                </form>
            </div>

            <ProfileContacts contactItems={contactItems}
                             setContactItems={setContactItems}
                             errors={errors}
                             setErrors={setErrors}
                             haveChanges={haveChanges}
                             setHaveChanges={setHaveChanges}
                             personById={personById}/>

           {/* <div className="profile-page__settings_tab">
                <Tabs
                    defaultActiveKey="1"
                    id="uncontrolled-tab-example">
                   {/* <Tab eventKey="0"
                         className={'settings_content'}
                         title={
                             <span className="tab-title">
                                 <span>{t('profile_info_title')}</span>
                                 {!(videosList?.count || highlights?.length) ?
                                     <InfoTooltip title={t('tooltip_info_title')}
                                                  description={t('tooltip_info_description')}
                                     /> : <InfoCheckIcon/>}
                    </span>
                         }>
                        <ParticipationData videosList={videosList}
                                           user={userProfile}
                                           highlights={highlights}
                                           usersPersonsList={usersPersonsList}
                                           userRoles={props.userRoles}
                                           avatar={personById?.avatar}/>
                    </Tab>
                    <Tab eventKey="1"
                         className={'settings_content'}
                         title={
                             <span className="tab-title">
                       <span>{t('profile_settings_title')}</span>
                                 {!userInfo?.login ? <InfoTooltip title={t('tooltip_settings_title')}
                                                                  description={t('tooltip_settings_description')}/>
                                     : <InfoCheckIcon/>}
                    </span>
                         }>
                        <div className="user-info-settings">
                            <p>{t('profile_settings_desc')}</p>
                            <div className="input_group">
                                <label htmlFor="">{t('input_login')}</label>
                                <input type="text"
                                       value={userInfo?.login}
                                       name={'login'}
                                       onChange={getInputValue}
                                       placeholder={t('input_login')}
                                       autoComplete={'off'}
                                />
                                {!!errors.login && (
                                    <div className="input_group__error">{errors.login}</div>
                                )}
                            </div>
                            <div className="input_group">
                                <label htmlFor="">{t('input_password')}</label>
                                <input
                                    type={`${passwordVisible ? "text" : "password"}`}
                                    value={userInfo?.password}
                                    name={'password'}
                                    onChange={getInputValue}
                                    placeholder={t('input_password')}
                                    autoComplete="new-password"
                                    autoFocus={false}
                                />
                                <EyeIcon className="input-icon"
                                         onClick={() => setPasswordVisible(!passwordVisible)}/>
                                {!!errors.password && (
                                    <div className="input_group__error">{errors.password}</div>
                                )}
                            </div>
                        </div>
                    </Tab>
                </Tabs>
            </div>*/}

            <Accordion  className={'contacts-wrapper system'}>
                <Accordion.Item eventKey="0" className={'profile-page__contacts'}>
                    <Accordion.Header
                        className={`contact-header `}>
                        <ArrowDownIcon className={`arrow-icon`}/>
                        {t('profile_settings_title')}
                        {!userInfo?.login ? <InfoTooltip title={t('tooltip_settings_title')}
                                                         description={t('tooltip_settings_description')}/>
                            : <InfoCheckIcon/>}
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className="user-info-settings">
                            {/*<p>{t('profile_settings_desc')}</p>*/}
                            <div className="input_group">
                                <label htmlFor="">{t('input_login')}</label>
                                <input type="text"
                                       value={userInfo?.login}
                                       name={'login'}
                                       onChange={getInputValue}
                                       placeholder={t('input_login')}
                                       autoComplete={'off'}
                                />
                                {!!errors.login && (
                                    <div className="input_group__error">{errors.login}</div>
                                )}
                            </div>
                            <div className="input_group">
                                <label htmlFor="">{t('input_password')}</label>
                                <input
                                    type={`${passwordVisible ? "text" : "password"}`}
                                    value={userInfo?.password}
                                    name={'password'}
                                    onChange={getInputValue}
                                    placeholder={t('input_password')}
                                    autoComplete="new-password"
                                    autoFocus={false}
                                />
                                <EyeIcon className="input-icon"
                                         onClick={() => setPasswordVisible(!passwordVisible)}/>
                                {!!errors.password && (
                                    <div className="input_group__error">{errors.password}</div>
                                )}
                            </div>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1" className={'profile-page__contacts'}>
                    <Accordion.Header
                        className={`contact-header `}>
                        <ArrowDownIcon className={`arrow-icon`}/>
                        {t('profile_roles')}
                    </Accordion.Header>
                    <Accordion.Body>
                        <UserRoles rolesList={rolesList}
                                   toggleRole={toggleRole}
                                   userRole={userRole}
                        />
                    </Accordion.Body>
                </Accordion.Item>
           {/*     <Accordion.Item eventKey="1" className={'profile-page__contacts'}>
                    <Accordion.Header
                        className={`contact-header `}>
                        <ArrowDownIcon className={`arrow-icon`}/>
                        {t('profile_language')}
                    </Accordion.Header>
                    <Accordion.Body>
                        <Form className={`language`}>
                            {
                                languages?.map(item => {
                                    return <Form.Check
                                        name="type"
                                        key={item?.key}
                                        label={item?.name}
                                        type={'radio'}
                                        checked={activeLanguage === item?.key}
                                        onChange={() => changeLanguage(item?.key)}
                                    />
                                })
                            }

                            <div className="language-info">
                                <InfoIcon/>
                                <span>{t('language_info')}</span>
                            </div>
                        </Form>
                    </Accordion.Body>
                </Accordion.Item>*/}
            </Accordion>

            <DeletePerson/>

            <div className="buttons-wrapper">
                <button className={`close_btn`}
                >
                    {t('button_cancel')}
                </button>
                <button className={`save_btn ${!haveChanges.size ? 'disabled' : ''}`}
                        disabled={!haveChanges.size}
                        onClick={saveChanges}
                >
                    {t('button_save_changes')}
                </button>
            </div>
        </div>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'userProfile', 'userById',
        'highlights', 'videosList', 'usersPersonsList', 'personById',
        'isFullWidth', 'rolesList', 'userRoles'
    ])
};

const mapDispatchToProps = {
    UpdatePersons,
    UpdateUser,
    GetUserById,
    GetUserProfile,
    GetHighlights,
    GetVideos,
    DeleteHighlights,
    GetPersonById,
    GetUsersPersons,
    ChangeHeaderTitle,
    ClearProfile,
    ClearPersonById,
    ClearVideoList
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
