// Import packages
import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {Link, useParams} from "react-router-dom";

// Import assets
import './search-video-page.scss';
import {DownIcon, SortIcon} from "../../../../image";

// Import Components
import VideoCard from "../Channels/card/VideoCard";
import {Breadcrumb, Form} from "react-bootstrap";

// Import Utils
import {getPropsFromState} from "../../../../redux/mapStateToProps";
import {
    GetVideosSearch,
    GetPersons,
    ClearVideosSearch
} from "../../../../redux/actions";
import {useTranslation} from "react-i18next";
import DropdownMenu from "../../../../shared/features/dropdownMenu";
import {sortConsts} from "../../../../utils/constants";
import CardViewButtons from "../../../../shared/features/CardViewButtons/CardViewButtons";

function SearchVideoPage(props) {
    const [selectedSort, setSelectedSort] = useState(undefined);
    const [list, setList] = useState(undefined);

    const params = useParams();
    const {t} = useTranslation();

    useEffect(() => {
        props.GetVideosSearch({
            search_query: params?.text,
        }, true)
            .then((res) => {
                setList(res?.[params?.type])
            })
        setSelectedSort(sortConsts?.creation_time_desc?.key)

        return () => {
            props.ClearVideosSearch()
        }
    }, [params?.text])

    function handleSort(sort) {
        setSelectedSort(sort)
        // props.GetVideoList({
        //     limit: 1000,
        //     channel_id: params?.id,
        //     sort_by: sort
        // })
    }

    return <div className={`main-container search-container ${props.isFullWidth ? '' : 'full'}`}>
        <div className="search-page">
            <div className="search-page-header">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to={'/dashboard/videos/review'}>{t('menu_item_video')}</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={'/dashboard/videos/review'}>{t('menu_item_review')}</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <a>Результаты поиска</a>
                    </Breadcrumb.Item>


                    <Breadcrumb.Item active>
                        <div className="search-page__name">
                            Видео по запросу “{params?.text}”
                        </div>
                    </Breadcrumb.Item>
                </Breadcrumb>
            </div>

            <div className="select_group">
                <DropdownMenu button={
                    <button className="sort-btn">
                        <SortIcon className="sort-icon"/>
                        <span className="sort-btn__name">
                                {sortConsts?.[selectedSort]?.name}
                        </span>

                        <DownIcon className="arrow-icon"/>
                    </button>
                }
                              closeAfterToggle={false}
                              className={'sort-select'}
                              contentWidth={'100%'}>
                    <div className="select_group__list">
                        {
                            Object.keys(sortConsts)?.map(item => {
                                return <div className="sort-item" key={item}
                                            onClick={() => {
                                                handleSort(item)
                                            }}>
                                    <Form>
                                        <Form.Check
                                            className="sort-item__check"
                                            type={'radio'}
                                            checked={item === selectedSort}
                                            onChange={() => {
                                                handleSort(item)
                                            }}
                                        />
                                    </Form>

                                    {sortConsts[item]?.name}
                                </div>
                            })
                        }
                    </div>
                </DropdownMenu>
                <CardViewButtons/>
            </div>

            <div
                className={`search__section-videos`}
            >
                {
                    !!list?.length && list?.map((item) => {
                        let textItem = item?.summary?.split('\n')?.find(i => i.includes(params?.text))
                        return <VideoCard key={item?.id}
                                          routs={[
                                              {
                                                  path: '/dashboard/videos/review',
                                                  name: t('menu_item_video')
                                              },
                                              {
                                                  path: '/dashboard/videos/review',
                                                  name: t('menu_item_review')
                                              },
                                              {
                                                  name: 'Результаты поиска'
                                              },
                                              {
                                                  path: `/dashboard/videos/search/${params?.text}`,
                                                  name: `Видео по запросу “${params?.text}”`
                                              }
                                          ]}
                                          text={textItem}
                                          video={item}/>
                    })
                }
            </div>
        </div>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'channels',
        'isFullWidth',
        'searchVideos',
    ])
};

const mapDispatchToProps = {
    GetVideosSearch,
    GetPersons,
    ClearVideosSearch
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchVideoPage);
