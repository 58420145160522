import {
    ExpressIcon,
    GoogleIcon,
    IVAIcon,
    KonturIcon,
    TeamsIcon,
    TelemostIcon,
    TrueconfIcon,
    VinteoIcon,
    ZoomIcon
} from "../image";

export const profileErrorMessages = {
    first_name: 'Имя должно содержать не менее 2 символов.',
    last_name: 'Фамилия должна содержать не менее 2 символов.',
    email: 'Эл. почта неверна',
    linkedin: 'Linkedin некорректный',
    facebook: 'Facebook некорректный',
    phone_number: 'Должно быть не менее 11 символов.',
    password: 'Ваш пароль должен быть длиной не менее 8 символов',
    login: 'Пользователь уже связан с человеком',
    required: 'Обязательное поле',

    // first_name: 'Name must be at least 2 characters',
    // last_name: 'Surname must be at least 2 characters',
    // email: 'E-mail is incorrect',
    // linkedin: 'Linkedin is incorrect',
    // facebook: 'Facebook is incorrect',
    // phone_number: 'Must be at least 11 characters',
    // password: 'Your password must be at least 8 characters long',
    // login: 'The user is already associated with the person',
}

export const REQUEST_LIMIT = 20;

export const MIN_ZOOM = 0;
export const MAX_ZOOM = 1.5;

export const rolePermissions = {
    'Пользователи': ['users_all', 'users_create', 'users_read', 'users_update', 'users_delete', /*'users_check_email'*/],
    'Видео': ['videos_all', 'videos_create', 'videos_read', 'videos_update', 'videos_delete', 'videos_reprocess'],
    'Каналы': ['channels_all', 'channels_create', 'channels_read', 'channels_update', 'channels_delete'],
    'Основные моменты': ['highlights_all', 'highlights_create', 'highlights_read', 'highlights_update', 'highlights_delete'],
    'Участники': ['persons_all', 'persons_attach', 'persons_create', 'persons_read', 'persons_update', 'persons_delete'],
    'Протокол': ['protocol_texts_all', 'protocol_texts_create', 'protocol_texts_update', 'protocol_texts_delete'],
    'События календаря': ['calendar_events_all', 'calendar_events_read', 'calendar_events_update'],
    'Календари пользователей': ['user_calendars_all', 'user_calendars_read', 'user_calendars_update'],
    'События журнала': ['journal_events_read'],
    'Роли': ['roles_all', 'roles_create', 'roles_read', 'roles_update', 'roles_delete'],
    'Теги': ['tags_all', 'tags_create', 'tags_read', 'tags_update', 'tags_delete'],
    'Услуга': ['service_statuses'],
    'Поделиться': ['share_links_all', 'share_links_create', 'share_links_update'],
    'Управление ссылками чужих видео': ['someone_else_share_link_update'],
    'Видеть чужие каналы': ['someone_else_channels_read'],
    'Метрики': ['statistics_read'],
    'Группа пользователей': ['analysis_pipelines_all', 'analysis_pipelines_create', 'analysis_pipelines_read', 'analysis_pipelines_update', 'analysis_pipelines_delete'],
    'Стенограмма': ['transcriptions_all', 'transcriptions_read', 'transcriptions_update', 'transcriptions_delete'],
    // 'Компании': ['companies_all','companies_create', 'companies_read', 'companies_update', 'companies_delete'],
}
export const rolePermissionsKeys = {
    'users': ['users_all', 'users_create', 'users_read', 'users_update', 'users_delete', /*'users_check_email'*/],
    'videos': ['videos_all', 'videos_create', 'videos_read', 'videos_update', 'videos_delete', 'videos_reprocess'],
    'channels': ['channels_all', 'channels_create', 'channels_read', 'channels_update', 'channels_delete'],
    'highlights': ['highlights_all', 'highlights_create', 'highlights_read', 'highlights_update', 'highlights_delete'],
    'persons': ['persons_all', 'persons_attach', 'persons_create', 'persons_read', 'persons_update', 'persons_delete'],
    'protocol_texts': ['protocol_texts_all', 'protocol_texts_create', 'protocol_texts_update', 'protocol_texts_delete'],
    'calendar_events': ['calendar_events_all', 'calendar_events_read', 'calendar_events_update'],
    'user_calendars': ['user_calendars_all', 'user_calendars_read', 'user_calendars_update'],
    'journal_events': ['journal_events_read'],
    'roles': ['roles_all', 'roles_create', 'roles_read', 'roles_update', 'roles_delete'],
    'tags': ['tags_all', 'tags_create', 'tags_read', 'tags_update', 'tags_delete'],
    'service_statuses': ['service_statuses'],
    'share_links': ['share_links_all', 'share_links_create', 'share_links_update'],
    // 'companies': ['companies_all','companies_create', 'companies_read', 'companies_update', 'companies_delete'],
    'analysis_pipelines': ['analysis_pipelines_all', 'analysis_pipelines_create', 'analysis_pipelines_read', 'analysis_pipelines_update', 'analysis_pipelines_delete'],
    'transcriptions': ['transcriptions_all', 'transcriptions_read', 'transcriptions_update', 'transcriptions_delete'],
    'someone_else_share_link': ['someone_else_share_link_update'],
    'someone_else_channels': ['someone_else_channels_read'],
    'statistics': ['statistics_read'],
}

export const rolePermissionTypes = {
    'all': 'Всё',
    'read': 'Просмотр',
    'create': 'Создание',
    'update': 'Редактирование',
    'delete': 'Удаление',
    'check_email': 'check_email',
    'reprocess': 'Переобработка',
    'attach': 'Прикреплять',
    'statuses': 'Статусы',
}

export const uris = {
    OAUTH2_PROVIDER: "https://demo1-reis.dev.ntrnx.com",
    LOGIN_REDIRECT: "/dashboard/videos/review",
    LOGOUT_REDIRECT: "/",
}

export const statusesList = {
    uploading: {
        key: 'uploading',
        name: 'Up.',
        title: 'Up.'
    },
    converting: {
        key: 'converting',
        name: 'Cv.'
    },
    'conversion-failed': {
        key: 'conversion-failed',
        name: 'Cv-F.'
    },
    processing: {
        key: 'processing',
        name: 'Pr.'
    },
    'processing-rejected': {
        key: 'processing-rejected',
        name: 'Pr-R.'
    },
    'processing-failed': {
        key: 'processing-failed',
        name: 'Pr-F.'
    },
    'sending-to-storage-failed': {
        key: 'sending-to-storage-failed',
        name: 'Se-F.'
    },
    processed: {
        key: 'processed',
        name: 'Prd.'
    },
    'sending-to-storage': {
        key: 'sending-to-storage',
        name: 'S-S.'
    }
}

export const statusesListRu = {
    uploading: {
        key: 'Загрузка',
        title: 'Обработка',
        name: 'Загр.',
        color: '#FFA800'
    },
    converting: {
        key: 'Конвертирование',
        title: 'Обработка',
        name: 'Конв.',
        color: '#FFA800'
    },
    'conversion_failed': {
        key: 'Конвертирование не выполнено',
        title: 'Ошибка',
        name: 'Конв. н.в.',
        color: '#DB1F26'
    },
    processing: {
        key: 'Обработка',
        title: 'Обработка',
        name: 'Обр-ка',
        color: '#FFA800'
    },
    'processing_rejected': {
        key: 'Обработка отклонена',
        title: 'Ошибка',
        name: 'Обр. откл.',
        color: '#DB1F26'
    },
    'processing_failed': {
        key: 'Обработка не выполнена',
        title: 'Ошибка',
        name: 'Обр. н.в.',
        color: '#DB1F26'
    },
    'sending_to_storage_failed': {
        key: 'Отправка в хранилище не удалась',
        title: 'Ошибка',
        name: 'Обр. н.уд.',
        color: '#DB1F26'
    },
    processed: {
        key: 'Обработано',
        title: 'Обработано',
        name: 'Обр-но',
        color: '#1ABB78'
    },
    'sending_to_storage': {
        key: 'Отправка в хранилище',
        title: 'Ошибка',
        name: 'Отпр. в хр.',
        color: '#FFA800'
    }
}

export const sortConsts = {
    creation_time_desc: {
        key: 'creation_time_desc',
        name: 'вначале новые'
    },
    creation_time: {
        key: 'creation_time',
        name: 'старые'
    },
    duration: {
        key: 'duration',
        name: 'вначале короткие'
    },
    duration_desc: {
        key: 'duration_desc',
        name: ' вначале длинные'
    },
    persons: {
        key: 'persons',
        name: 'по количеству участников возрастание'
    },
    persons_desc: {
        key: 'persons_desc',
        name: 'по количеству участников убывание'
    },
    description: {
        key: 'description',
        name: 'по алфавиту от А до Я'
    },
    description_desc: {
        key: 'description_desc',
        name: 'по алфавиту от Я до А'
    },
}

export const sortPersonsConsts = {
    name: {
        key: 'name',
        name: 'Сначала новые'
    },
    name_desc: {
        key: 'name_desc',
        name: 'Сначала старые'
    },
    avatar: {
        key: 'avatar',
        name: 'Сначала с фото'
    },
    avatar_desc: {
        key: 'avatar_desc',
        name: 'Сначала без фото'
    },
    role: {
        key: 'role',
        name: 'По возрастанию уровня привилегий (роли)'
    },
    role_desc: {
        key: 'role_desc',
        name: 'По убыванию уровня привилегий (роли)'
    },
}

export const timeFilterTypes = {
    today: {
        key: 'today',
        name: 'Сегодня',
        date: 0
    },
    yesterday: {
        key: 'yesterday',
        name: 'Вчера',
        date: 0
    },
    week: {
        key: 'week',
        name: 'Неделя',
        date: 7
    },
    current_month: {
        key: 'current_month',
        name: 'Текущий месяц',
        date: -30
    },
    last_month: {
        key: 'last_month',
        name: 'Прошлый месяц',
        date: 30
    },
    other: {
        key: 'other',
    },
}
export const filterTypes = {
    name: {
        key: 'name',
        name: 'Имя'
    },
    tags: {
        key: 'tags',
        name: 'Метки'
    },
    roles: {
        key: 'roles',
        name: 'Роль'
    },
    company: {
        key: 'company',
        name: 'Компания'
    },
    position: {
        key: 'position',
        name: 'Должность'
    },
}
export const videoFilterTypes = {
    name: {
        key: 'name',
        name: 'Имя'
    },
    data: {
        key: 'data',
        name: 'Дата'
    },
    status: {
        key: 'status',
        name: 'Статус'
    },
    owner: {
        key: 'owner',
        name: 'Владелец'
    },
    link: {
        key: 'link',
        name: 'Ссылка'
    },
    channels: {
        key: 'channels',
        name: 'Каналы'
    },
    users: {
        key: 'users',
        name: 'Участники'
    },
}

export const providerTypes = [
    {
        key: 'GOOGLE_MEET',
        name: 'Google Meet',
        icon: <GoogleIcon/>
    },
    {
        key: 'ZOOM',
        name: 'Zoom',
        icon: <ZoomIcon/>
    },
    {
        key: 'MS_TEAMS',
        name: 'Microsoft Teams',
        icon: <TeamsIcon/>
    },
    {
        key: 'EXPRESS',
        name: 'Express',
        icon: <ExpressIcon/>
    },
    {
        key: 'VINTEO',
        name: 'Vinteo',
        icon: <VinteoIcon/>
    },
    {
        key: 'TRUECONF',
        name: 'TrueConf',
        icon: <TrueconfIcon/>
    },
    {
        key: 'IVA',
        name: 'IVA',
        icon: <IVAIcon/>
    },
    {
        key: 'TELEMOST',
        name: 'Telemost',
        icon: <TelemostIcon/>
    },
    {
        key: 'KONTUR_TALK',
        name: 'Kontur Talk',
        icon: <KonturIcon/>
    },
]

export const hourWithSeconds = 3600
export const personsFilterTypes = {
    person_name: {
        key: 'person_name',
        name: 'Имя'
    },
    role: {
        key: 'role',
        name: 'Роль'
    },
    company: {
        key: 'company',
        name: 'Компания'
    },
}
export const linkTypes = {
    not_public: {
        key: 'not_public',
        name: 'Непубличная'
    },
    public: {
        key: 'public',
        name: 'Публичная (бессрочная)'
    },
    public_time: {
        key: 'public_time',
        name: 'Публичная (по дате)'
    },
}
