// Import packages
import React, {useEffect} from 'react';
import {connect} from "react-redux";

// Import assets
import './MLIndication.scss';

// Import Utils
import {getPropsFromState} from "../../../../redux/mapStateToProps";
import {
    AddVideoDescription,
    GetServiceStatuses,
    UpdateVideoChannel,
    ChangeHeaderTitle
} from "../../../../redux/actions";
import {useTranslation} from "react-i18next";

function MLIndication(props) {
    const {serviceStatuses} = props;
    const {t} = useTranslation();

    useEffect(() => {
        props.GetServiceStatuses({limit: 20})
        props.ChangeHeaderTitle(t('ml_indication_title'))
    }, []);
    return (
        <div className="main-container">
            <div className="ml-text">
                {t('ml_indication_description')}
                <span>{!serviceStatuses ? t('disconnected') : t('connected')}</span>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'serviceStatuses',
    ])
};

const mapDispatchToProps = {
    GetServiceStatuses,
    UpdateVideoChannel, AddVideoDescription, ChangeHeaderTitle
};

export default connect(mapStateToProps, mapDispatchToProps)(MLIndication);
