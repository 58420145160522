export const ROLES_CONSTS = {
    GET_ROLES: "GET_ROLES",
    GET_ROLES_REIS: "GET_ROLES_REIS",
    CREATE_ROLE: "CREATE_ROLE",
    CLEAR_ROLES: "CLEAR_ROLES",
    UPDATE_ROLES: "UPDATE_ROLES",
    DELETE_ROLES: "DELETE_ROLES",
    GET_ROLE_BY_ID: "GET_ROLE_BY_ID",
    CLEAR_ROLES_BY_ID: "CLEAR_ROLES_BY_ID",
};
