import {ROLES_CONSTS} from "../constants";

export const initialState = {
    rolesList: [],
    reisList: [],
    roleById: null,
};

// todo: заменить на фабрику
export default (state = initialState, action) => {
    switch (action.type) {
        case ROLES_CONSTS.GET_ROLES:
            return {
                ...state,
                rolesList: action.payload,
            };
        case ROLES_CONSTS.GET_ROLES_REIS:
            return {
                ...state,
                reisList: action.payload,
            };
        case  ROLES_CONSTS.CREATE_ROLE:
            return {
                ...state,
                rolesList: [...state.rolesList, action.payload],
            };
        case  ROLES_CONSTS.DELETE_ROLES:
            return {
                ...state,
                rolesList: state.rolesList.filter(item => item.id !== action.payload?.id),
            };
        case ROLES_CONSTS.CLEAR_ROLES:
            return {
                ...state,
                rolesList: [],
            };
        case ROLES_CONSTS.GET_ROLE_BY_ID:
            return {
                ...state,
                roleById: action.payload,
            };
        case ROLES_CONSTS.CLEAR_ROLES_BY_ID:
            return {
                ...state,
                roleById: null,
            };
        case ROLES_CONSTS.UPDATE_ROLES:
            return {
                ...state,
                rolesList: state.rolesList?.map(item => {
                    return item.id === action.payload.id ? {
                        ...item,
                        ...action.payload.data
                    } : item
                })
            };
        default:
            return state;
    }
}
