// Import packages
import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

// Import assets
import "react-datepicker/dist/react-datepicker.css";
import './synchronize.scss';
import {RefreshGreenIcon} from "../../../../image";


//Import utils
import {getPropsFromState} from "../../../../redux/mapStateToProps";
import {GetCalendarSynchronize} from "../../../../redux/actions";
import {Toast} from "react-bootstrap";

function Synchronize(props) {
    const {loading, getEvents} = props;
    const [requestLoading, setRequestLoading] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [toastTitle, setToastTitle] = useState('');

    const toggleToast = () => setShowToast(!showToast);

    useEffect(() => {
        setRequestLoading(loading)
    }, [loading])

    function toggleSynchronize() {
        setRequestLoading(true)
        props.GetCalendarSynchronize().then((res) => {
            // console.log('res', res);
            if (res?.authorization_url) {
                window.open(res?.authorization_url)
                const urlParams = new URLSearchParams(res?.authorization_url);
                const callback = urlParams.get('callback')
                callback().then(res => {
                    console.log(res, 'callback result');
                })
            }
            switch (res?.synchronize_result) {
                case 'calendars_list_sync':
                    setShowToast(true)
                    setToastTitle('Успешно')
                    setErrorText('успешно синхронизирован список календарей пользователя')
                    break;
                case 'event_list_sync':
                    setShowToast(true)
                    setToastTitle('Успешно')
                    setErrorText('успешно синхронизирован список календарей и событий в календарях')
                    break;
                case 'no_token':
                    if (res?.synchronize_neutronix_result !== 'event_list_sync'){
                        setShowToast(true)
                        setToastTitle('Error')
                        setErrorText('синхронизации не произошло')
                    }

                    break;
            }
            getEvents()
        }).finally(() => {
            setRequestLoading(false)
        })
    }

    function ErrorToast() {
        return <Toast show={showToast} onClose={toggleToast} className={'upload_video_answer'}>
            <Toast.Header>
                <img
                    src=""
                    className="rounded me-2"
                    alt=""
                />
                <strong className="me-auto">{toastTitle}</strong>
            </Toast.Header>
            <Toast.Body>{errorText}</Toast.Body>
        </Toast>
    }

    return <>
        <RefreshGreenIcon onClick={toggleSynchronize}
                          className={`${requestLoading ? 'spinner' : ''}`}/>
        {ErrorToast()}
    </>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [])
};

const mapDispatchToProps = {
    GetCalendarSynchronize
};

export default connect(mapStateToProps, mapDispatchToProps)(Synchronize);
