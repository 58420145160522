// Import packages
import React, {useEffect, useLayoutEffect, useState} from 'react';
import {connect} from "react-redux";
import {Link, useLocation, useParams} from "react-router-dom";

// Import assets
import './channel-page.scss';

// Import Components
import VideoCard from "../card/VideoCard";
import {Breadcrumb, Form} from "react-bootstrap";

// Import Utils
import {getPropsFromState} from "../../../../../redux/mapStateToProps";
import {
    ChangeHeaderTitle,
    GetVideos,
    GetVideosSearch,
    GetPersons,
    GetVideoList,
    GetChannelById, ClearVideoList
} from "../../../../../redux/actions";
import {GetChannels} from "../../../../../redux/actions";
import {useTranslation} from "react-i18next";
import {EditIcon, FilterCenterIcon} from "../../../../../image";
import DropdownMenu from "../../../../../shared/features/dropdownMenu";
import {REQUEST_LIMIT, sortConsts, videoFilterTypes} from "../../../../../utils/constants";
import ChannelsModal from "../../../../../shared/modals/channelModal/ChannelsModal";
import {hasPermission} from "../../../../../utils/helperFunctions";
import InfiniteScroll from "react-infinite-scroller";
import Filters from "../../../../../shared/features/Filters/Filters";

const initFilters = {
    description_like: '',
    date_start: '',
    date_end: '',
    statuses: '',
    owner_name_like: '',
    share_link_types: '',
    channel_ids: '',
    meeting_participant_nickname_like: '',
    sort_by: '',
    name_like: '',
}

function ChannelsPage(props) {
    const [selectedSort, setSelectedSort] = useState(undefined);
    const [showModal, setShowModal] = useState(false);
    const [activeChannel, setActiveChannel] = useState(undefined);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [filters, setFilters] = useState(initFilters);
    const [videos, setVideos] = useState([]);

    const location = useLocation();
    const params = useParams();
    const {t} = useTranslation();

    useLayoutEffect(() => {
        props.ClearVideoList()
    }, [])

    useEffect(() => {
        window.scrollTo({
            top: 0
        })
        props.GetChannels()
        /*props.GetVideoList({
            limit: REQUEST_LIMIT,
            channel_id: params?.id,
            sort_by: selectedSort
        })*/
        hasPermission('channels_read') && props.GetChannelById(params?.id)
            .then((res) => {
                // console.log('res', res);
                setActiveChannel(res)
            })
        const title = t('menu_item_review')
        props.ChangeHeaderTitle(title)
        props.GetPersons({
            status: 'known',
            limit: 1000
        })
    }, [location?.state?.personId, params?.id]);

    useEffect(() => {
        props.GetVideoList({
            limit: REQUEST_LIMIT,
            channel_id: params?.id,
            sort_by: selectedSort,
            isInfinite: true,
            returnList: true,
            ...filters
        }).then(res => {
            setVideos(res?.results?.filter(i => !!i?.channels?.includes(Number(params?.id))))
        })
    }, [filters]);

    useEffect(() => {
        setSelectedSort(sortConsts?.creation_time_desc?.key)
    }, []);

    function closeModal() {
        setShowModal(false)
    }

    function loadMoreItems() {
        console.log(props?.hasMoreChannels, 'props?.hasMoreChannels');
        props?.hasMoreChannels &&
        props.GetVideoList({
            channel_id: params?.id,
            sort_by: selectedSort,
            offset: props.videosList?.results?.length ?? 0,
            isInfinite: true,
            returnList: true,
        }).then(res => {
            setVideos([...videos,  ...res?.results?.filter(i => !!i?.channels?.includes(Number(params?.id)))])
        })
    }

    function toggleFilter(item) {
        setSelectedFilters(selectedFilters.includes(item.key)
            ? selectedFilters.filter(i => i !== item?.key)
            : [...selectedFilters, item.key])
    }

    let channelsList = {}

    const colors = {
        '1': {
            color: '#4F40F7',
            bgColor: '#4F40F719',
        },
        '2': {
            color: '#2877D5',
            bgColor: '#2877D419',
        },
        '3': {
            color: '#F7404B',
            bgColor: '#F7404B19',
        },
    }

    !!props?.channels?.length && props.channels.forEach(item => {
        channelsList = {
            ...channelsList,
            [item.id]: {
                name: item.name,
                color: colors[item.id]?.color,
                bgColor: colors[item.id]?.bgColor,
            }
        }
    })
    const channelName = props?.channels?.find(i => i?.id === Number(params?.id))?.name

    return <div className={`main-container channels-container full`}>
        <div className="channels-page">
            <div className="channels-page-header">
                <Breadcrumb>

                    <Breadcrumb.Item>
                        <Link to={'/dashboard/videos/review'}>{t('menu_item_video')}</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <div style={{
                            cursor: 'text', display: 'flex', paddingRight: '14px'
                        }}>
                            {t('table_channels')}
                        </div>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        <div className="channels-page__name">
                            {channelName}
                        </div>
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="buttons">
                    <DropdownMenu button={
                        <button className="filter-btn">
                            <FilterCenterIcon/>
                            {t('filter')}
                        </button>
                    }
                                  closeAfterToggle={false}
                                  className="filter-menu"
                                  contentClassName="filter"
                                  contentWidth={'176px'}
                    >
                        {
                            Object.keys(videoFilterTypes)?.map(item => {
                                return <MyCheckbox checked={selectedFilters.includes(item)}
                                                   label={videoFilterTypes[item]?.name}
                                                   key={item}
                                                   className={`filters-item`}
                                                   handleCheckboxChange={() => toggleFilter(videoFilterTypes[item])}/>
                            })
                        }
                    </DropdownMenu>
                    {hasPermission('channels_update') &&
                        <button className="channels-page-header-edit-btn"
                                onClick={() => setShowModal(true)}
                        >
                            <EditIcon/>
                            {t('button_edit')}
                        </button>}
                </div>
            </div>

            <Filters selectedFilters={selectedFilters}
                     filters={filters}
                     setFilters={setFilters}
                     sortList={sortConsts}/>

            <InfiniteScroll
                hasMore={props?.hasMoreChannels}
                loadMore={loadMoreItems}
                className={'channels__section-videos'}
                pageStart={0}
                useWindow={true}
                initialLoad={false}>
                {!!videos?.length ? <>
                        {
                            videos?.map((item) => {
                                return <VideoCard key={item?.id}
                                                  badge={props?.channels?.find(i => i?.id === Number(params?.id))}
                                                  channelsList={channelsList}
                                                  routs={[
                                                      {
                                                          path: '/dashboard/videos/review',
                                                          name: t('menu_item_video')
                                                      },
                                                      {
                                                          path: '/dashboard/videos/review',
                                                          name: t('menu_item_review')
                                                      },
                                                      {
                                                          path: `/dashboard/videos/channels/${params?.id}`,
                                                          name: channelName
                                                      }
                                                  ]}
                                                  video={item}/>
                            })
                        }
                    </>
                    : <div className={`channels__section-videos-empty`}>
                        {t('empty_list')}
                    </div>}
            </InfiniteScroll>
        </div>
        {
            // !activeChannel?.is_private &&
            showModal &&
            <ChannelsModal showModal={showModal}
                           channel={activeChannel}
                           onHide={closeModal}/>}
    </div>
}

function MyCheckbox({checked, handleCheckboxChange, disabled, label, className}) {
    const [isChecked, setIsChecked] = useState(false);
    useEffect(() => {
        setIsChecked(checked)
    }, [checked])

    return (
        <Form>
            <Form.Check
                type="checkbox"
                checked={isChecked}
                className={className}
                disabled={disabled}
                label={label}
                onChange={handleCheckboxChange}
            />
        </Form>
    );
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'isMobile',
        'channels',
        'videosList',
        'isFullWidth',
        'userRoles',
        'hasMoreChannels'
    ])
};

const mapDispatchToProps = {
    GetVideos,
    GetVideosSearch,
    GetChannels,
    ChangeHeaderTitle,
    GetPersons,
    GetVideoList,
    GetChannelById,
    ClearVideoList
};

export default connect(mapStateToProps, mapDispatchToProps)(ChannelsPage);
