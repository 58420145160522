import {PROFILE_CONSTS} from "../constants";

export const initialState = {
    userProfile: {},
    userRoles: {},
    userAvatar: undefined
};

export default (state = initialState, action) => {
    switch (action.type) {
        case PROFILE_CONSTS.GET_USER_PROFILE:
            return {
                ...state,
                userProfile: action.payload,
            };
        case PROFILE_CONSTS.GET_AUTH_CHECK:
            console.log('action.payload?.my_roles', action.payload?.my_roles);
            return {
                ...state,
                userRoles: action.payload?.my_roles?.[0],
            }
        case PROFILE_CONSTS.CLEAR_PROFILE:
            return {
                ...state,
                userProfile: {},
                userAvatar: undefined
            };
        case PROFILE_CONSTS.SET_USER_PROFILE:
            return {
                ...state,
                userAvatar: action.payload,
            };
        default:
            return state;
    }
}
