// Import packages
import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {Form, Modal} from "react-bootstrap";

// Import assets
import './add-group.scss';
import {CloseIcon, DeletingIcon, PlusGreenIcon} from "../../../image";

// Import Utils
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {
    ClearPromptById,
    AddAnalysisPipeline,
    UpdateAnalysisPipeline,
    GetPromptById,
    SearchUsers,
} from "../../../redux/actions";
import {useTranslation} from "react-i18next";
import DropdownMenu from "../../features/dropdownMenu";
import UserInfoItem from "../../features/userInfoItem";

const initialState = {
    name: undefined,
    body: undefined,
}
const initialErrors = {
    name: false,
    body: false,
}

function AddGroupModal(props) {
    const {
        show,
        selectedGroup,
        setSelectedGroup,
        openDelete,
        searchedUsers,
        promptList,
        languageModels,
        className
    } = props;

    const {t} = useTranslation();

    const [promptGroup, setGroupInfo] = useState(initialState);
    const [errors, setErrors] = useState(initialErrors);
    const [haveChanges, setHaveChanges] = useState(new Map());
    const [openCreate, setOpenCreate] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [users, setUsers] = useState(null);
    const [usersList, setUsersList] = useState([]);

    let disabledBtn = !!Object.values(promptGroup).find(item => !!item)?.length


    useEffect(() => {
        if (selectedGroup?.id) {
            setGroupInfo({
                ...promptGroup,
                name: selectedGroup?.name,
            })
            setUsersList(selectedGroup?.users)
            let list = {}
            selectedGroup?.users?.length && selectedGroup?.users.forEach(item => {
                list = {
                    ...list,
                    [item?.id || item?.user_id]: {
                        user_id: item?.id || item?.user_id,
                        is_bound: true
                    }
                }
            })
            setUsers(list)
        }
    }, [selectedGroup])

    function clearHaveChanges() {
        const changes = new Map(haveChanges);
        changes.clear()
        setHaveChanges(changes)
    }

    function getInputValue(e) {
        const {name, value} = e.target
        setGroupInfo({
            ...promptGroup,
            [name]: value
        })

        const changes = new Map(haveChanges);
        if (selectedGroup?.[name] !== value) {
            changes.set(name, value)
        } else {
            changes.delete(name)
        }
        setHaveChanges(changes)
    }

    function validateData(data) {
        const {name} = data
        setErrors(initialErrors)
        let isValid = true;
        let errorList = {...initialErrors}

        if (!name?.length || name?.length < 2) {
            isValid = false
            errorList = {
                ...errorList,
                name: t('error_name')
            }
        }

        setErrors(errorList)

        return isValid
    }


    function saveChanges() {
        const recData = {
            name: promptGroup.name,
            summary_language_model_id: selectedGroup?.summary_language_model?.id || languageModels[0]?.id,
            retelling_language_model_id: selectedGroup?.retelling_language_model?.id || languageModels[0]?.id,
            summary_prompt_id: selectedGroup?.summary_prompt?.id || promptList?.filter(i => i.type === 'summary')?.[0]?.id,
            retelling_prompt_id: selectedGroup?.retelling_prompt?.id || promptList?.filter(i => i.type === 'retelling')?.[0]?.id,
        };

        if (users) {
            recData.users_ids = Object.values(users)?.filter((item) => item.is_bound && item.user_id)?.map((i) => Number(i.user_id))
        }

        if (validateData(recData)) {
            selectedGroup?.id
                ? props.UpdateAnalysisPipeline(selectedGroup?.id, recData)
                : props.AddAnalysisPipeline(recData)
            onClose()
        }
    }



    function onClose() {
        setGroupInfo(initialState)
        setErrors(initialErrors)
        setSelectedGroup(undefined)
        clearHaveChanges()
        props.closeModal()
    }

    function getSearchValue(e) {
        const {value} = e.target
        setSearchValue(value)

        value?.length > 1 && props.SearchUsers(value)
    }

    function checkParticipant(e, item) {
        let list = {
            ...users,
            [item?.id || item?.user_id]: {
                user_id: item?.id || item?.user_id,
                is_bound: e?.target?.checked
            }
        }
        setUsers(list)

        const user_index = usersList.findIndex(i => i.user_id === item.user_id);
        if (e.target.checked && user_index < 0) {
            usersList.push(item);
        } else {
            usersList.splice(user_index, 1);
        }
        const partList = [...usersList]

        setUsersList(partList)


        const changes = new Map(haveChanges);
        if (arraysEqual(selectedGroup?.users?.map(i => i?.id)?.length, partList?.map(i => i?.id)?.length)) {
            changes.set('users', list)
        } else {
            changes.delete('users')
        }

        setHaveChanges(changes)
    }

    function arraysEqual(arr1, arr2) {
        if (arr1.length !== arr2.length) {
            return false;
        }

        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i] !== arr2[i]) {
                return false;
            }
        }

        return true;
    }

    let searchedUsersList = []
    searchedUsers?.users?.length && searchedUsers?.users?.forEach(user => {
        if (!searchedUsersList.find(i => i?.user_id === user?.id)) {
            const data = {...user, user_id: user?.id}
            searchedUsersList.push(data)
        }
    })

    return <Modal
        show={show}
        onHide={onClose}
        centered
        size="lg"
        className={`prompt_modal_wrapper ${className}`}
    >
        <div className="prompt_modal">
            <div className="prompt_modal__title">
                Создать группу
                <CloseIcon onClick={onClose}/>
            </div>

            <div className="input_group">
                <label htmlFor="">Название группы</label>
                <input type="text"
                       value={promptGroup?.name}
                       autoComplete={'off'}
                       name={'name'}
                       onChange={getInputValue}
                       placeholder={'Название группы'}
                />
                {!!errors.name && (
                    <div className="input_group__error">{errors.name}</div>
                )}
            </div>

            {!openCreate ? <button className="create-channel" onClick={() => {
                    setOpenCreate(true)
                }}>
                    <PlusGreenIcon/>
                    Добавить персону
                </button>
                : <div className="search-wrapper">
                    <DropdownMenu button={
                        <input type="text"
                               value={searchValue}
                               autoComplete={'off'}
                               name={'searchValue'}
                               className={'search-input'}
                               onChange={getSearchValue}
                               placeholder={t('input_search')}
                        />}

                                  closeAfterToggle={false}
                                  className="search-menu"
                                  contentClassName={'search-menu'}
                                  contentWidth={'688px'}
                                  zIndex={1100}
                    >
                        {!!searchedUsersList?.length && <Form className={`managers-list`}>
                            {
                                searchedUsersList?.map((item) => {
                                    return <div className={`manager-item`} key={item?.id}>
                                        <CheckBox
                                            checked={users?.[item?.user_id]?.is_bound}
                                            onChange={(e) => checkParticipant(e, item)}
                                        />
                                        <UserInfoItem data={item}/>
                                    </div>
                                })
                            }
                        </Form>}
                    </DropdownMenu>

                </div>}

            <div className={`managers-list all`}>
                {
                    usersList.map((item, index) => {
                        return <div className={`manager-item`} key={index}>
                            <UserInfoItem data={item}/>
                        </div>
                    })
                }
            </div>

            {selectedGroup?.id && <div className="prompt_modal__delete">
                <div className="title-delete">Удаление группы</div>
                <div className="delete-content">
                    <span>После удаления группы все данные будут безвозвратно потеряны и их невозможно будет восстановить</span>
                    <button onClick={() => {
                        openDelete(selectedGroup)
                    }}>
                        <DeletingIcon/>
                        {t('button_delete')}
                    </button>
                </div>
            </div>}

            <div className="prompt_modal__buttons">
                <button className={`close_btn`}
                        onClick={onClose}
                >
                    {t('button_cancel')}
                </button>

                {
                    !!selectedGroup?.id ? <button className={`save_btn ${!haveChanges.size ? 'disabled' : ''}`}
                                                  disabled={!haveChanges.size}
                                                  onClick={saveChanges}
                        >
                            {t('button_save')}
                        </button>
                        : <button className={`save_btn ${!disabledBtn ? 'disabled' : ''}`}
                                  disabled={!disabledBtn}
                                  onClick={saveChanges}
                        >
                            {t('button_save')}
                        </button>
                }

            </div>
        </div>
    </Modal>
}

function CheckBox({checked, onChange}) {
    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
        setIsChecked(checked)
    }, [checked])

    return <Form.Check
        type={'checkbox'}
        checked={isChecked}
        onChange={onChange}
    />
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, ['searchedUsers', 'languageModels'])
};

const mapDispatchToProps = {
    AddAnalysisPipeline,
    ClearPromptById,
    UpdateAnalysisPipeline,
    GetPromptById,
    SearchUsers
};

export default connect(mapStateToProps, mapDispatchToProps)(AddGroupModal);
